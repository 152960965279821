// source: teamdev/licensing/client.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_people_person_name_pb = require('spine-web/proto/spine/people/person_name_pb.js');
goog.object.extend(proto, spine_people_person_name_pb);
var teamdev_licensing_values_pb = require('../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
goog.exportSymbol('proto.teamdev.licensing.commercial.Client', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.commercial.Client = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.commercial.Client, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.commercial.Client.displayName = 'proto.teamdev.licensing.commercial.Client';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.commercial.Client.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.commercial.Client.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.commercial.Client} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.commercial.Client.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    personName: (f = msg.getPersonName()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f),
    companyName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyWebsite: (f = msg.getCompanyWebsite()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && teamdev_licensing_values_pb.PhoneNumber.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.licensing.commercial.Client.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.commercial.Client;
  return proto.teamdev.licensing.commercial.Client.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.commercial.Client} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.licensing.commercial.Client.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 2:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setPersonName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 4:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setCompanyWebsite(value);
      break;
    case 5:
      var value = new teamdev_licensing_values_pb.PhoneNumber;
      reader.readMessage(value,teamdev_licensing_values_pb.PhoneNumber.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.commercial.Client.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.commercial.Client.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.commercial.Client} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.commercial.Client.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getPersonName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyWebsite();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_values_pb.PhoneNumber.serializeBinaryToWriter
    );
  }
};


/**
 * optional spine.net.EmailAddress email = 1;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.commercial.Client.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 1));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
*/
proto.teamdev.licensing.commercial.Client.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
 */
proto.teamdev.licensing.commercial.Client.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.commercial.Client.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.people.PersonName person_name = 2;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.licensing.commercial.Client.prototype.getPersonName = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 2));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
*/
proto.teamdev.licensing.commercial.Client.prototype.setPersonName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
 */
proto.teamdev.licensing.commercial.Client.prototype.clearPersonName = function() {
  return this.setPersonName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.commercial.Client.prototype.hasPersonName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string company_name = 3;
 * @return {string}
 */
proto.teamdev.licensing.commercial.Client.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
 */
proto.teamdev.licensing.commercial.Client.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional spine.net.Url company_website = 4;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.commercial.Client.prototype.getCompanyWebsite = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 4));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
*/
proto.teamdev.licensing.commercial.Client.prototype.setCompanyWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
 */
proto.teamdev.licensing.commercial.Client.prototype.clearCompanyWebsite = function() {
  return this.setCompanyWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.commercial.Client.prototype.hasCompanyWebsite = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional teamdev.licensing.PhoneNumber phone_number = 5;
 * @return {?proto.teamdev.licensing.PhoneNumber}
 */
proto.teamdev.licensing.commercial.Client.prototype.getPhoneNumber = function() {
  return /** @type{?proto.teamdev.licensing.PhoneNumber} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.PhoneNumber, 5));
};


/**
 * @param {?proto.teamdev.licensing.PhoneNumber|undefined} value
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
*/
proto.teamdev.licensing.commercial.Client.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.commercial.Client} returns this
 */
proto.teamdev.licensing.commercial.Client.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.commercial.Client.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.commercial);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.commercial.Client.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.commercial.Client.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.commercial.Client.Parser.prototype.constructor = proto.teamdev.licensing.commercial.Client.Parser;
proto.teamdev.licensing.commercial.Client.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.commercial.Client();
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.personName !== undefined) {
    if (obj.personName === null) {
      msg.setPersonName(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.personName);
      msg.setPersonName(value);
    }
  }
  
  if (obj.companyName !== undefined) {
    if (obj.companyName !== null) {
      let value = obj.companyName;
      msg.setCompanyName(value);
    }
  }
  
  if (obj.companyWebsite !== undefined) {
    if (obj.companyWebsite === null) {
      msg.setCompanyWebsite(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.companyWebsite);
      msg.setCompanyWebsite(value);
    }
  }
  
  if (obj.phoneNumber !== undefined) {
    if (obj.phoneNumber === null) {
      msg.setPhoneNumber(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PhoneNumber').fromObject(obj.phoneNumber);
      msg.setPhoneNumber(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.commercial.Client.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.commercial.Client';
};
