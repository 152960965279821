// source: teamdev/licensing/c/academic/values.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_people_person_name_pb = require('spine-web/proto/spine/people/person_name_pb.js');
goog.object.extend(proto, spine_people_person_name_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequester', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AssurancePromise', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AssurancePromise.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.ExtensionRequestReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.RequestRefusalReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.ResearchAssurancePromise', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.StudentAssurancePromise', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequester, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequester.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequester';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AssurancePromise = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.c.academic.AssurancePromise.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.c.academic.AssurancePromise, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AssurancePromise.displayName = 'proto.teamdev.licensing.c.academic.AssurancePromise';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.StudentAssurancePromise, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.StudentAssurancePromise.displayName = 'proto.teamdev.licensing.c.academic.StudentAssurancePromise';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.ResearchAssurancePromise, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.ResearchAssurancePromise.displayName = 'proto.teamdev.licensing.c.academic.ResearchAssurancePromise';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.RequestRefusalReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.RequestRefusalReason.displayName = 'proto.teamdev.licensing.c.academic.RequestRefusalReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.ExtensionRequestReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.ExtensionRequestReason.displayName = 'proto.teamdev.licensing.c.academic.ExtensionRequestReason';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequester.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requesterName: (f = msg.getRequesterName()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f),
    institutionName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    institutionMailingAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    institutionWebsite: (f = msg.getInstitutionWebsite()) && spine_net_url_pb.Url.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequester;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequester.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setRequesterName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionMailingAddress(value);
      break;
    case 6:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setInstitutionWebsite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequester.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRequesterName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getInstitutionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInstitutionMailingAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInstitutionWebsite();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind = {
  REQUESTER_KIND_UNDEFINED: 0,
  STUDENT: 1,
  RESEARCHER: 2
};

/**
 * optional spine.net.EmailAddress email = 1;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 1));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequesterKind kind = 2;
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.getKind = function() {
  return /** @type {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional spine.people.PersonName requester_name = 3;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.getRequesterName = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 3));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.setRequesterName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.clearRequesterName = function() {
  return this.setRequesterName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.hasRequesterName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string institution_name = 4;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.getInstitutionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.setInstitutionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string institution_mailing_address = 5;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.getInstitutionMailingAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.setInstitutionMailingAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional spine.net.Url institution_website = 6;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.getInstitutionWebsite = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 6));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.setInstitutionWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequester} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.clearInstitutionWebsite = function() {
  return this.setInstitutionWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.prototype.hasInstitutionWebsite = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.c.academic.AssurancePromise.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.KindCase = {
  KIND_NOT_SET: 0,
  STUDENT_ASSURANCE: 1,
  RESEARCHER_ASSURANCE: 2
};

/**
 * @return {proto.teamdev.licensing.c.academic.AssurancePromise.KindCase}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.c.academic.AssurancePromise.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.c.academic.AssurancePromise.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AssurancePromise.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AssurancePromise} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AssurancePromise.toObject = function(includeInstance, msg) {
  var f, obj = {
    studentAssurance: (f = msg.getStudentAssurance()) && proto.teamdev.licensing.c.academic.StudentAssurancePromise.toObject(includeInstance, f),
    researcherAssurance: (f = msg.getResearcherAssurance()) && proto.teamdev.licensing.c.academic.ResearchAssurancePromise.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AssurancePromise}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AssurancePromise;
  return proto.teamdev.licensing.c.academic.AssurancePromise.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AssurancePromise} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AssurancePromise}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.c.academic.StudentAssurancePromise;
      reader.readMessage(value,proto.teamdev.licensing.c.academic.StudentAssurancePromise.deserializeBinaryFromReader);
      msg.setStudentAssurance(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.c.academic.ResearchAssurancePromise;
      reader.readMessage(value,proto.teamdev.licensing.c.academic.ResearchAssurancePromise.deserializeBinaryFromReader);
      msg.setResearcherAssurance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AssurancePromise.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AssurancePromise} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AssurancePromise.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStudentAssurance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.c.academic.StudentAssurancePromise.serializeBinaryToWriter
    );
  }
  f = message.getResearcherAssurance();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.c.academic.ResearchAssurancePromise.serializeBinaryToWriter
    );
  }
};


/**
 * optional StudentAssurancePromise student_assurance = 1;
 * @return {?proto.teamdev.licensing.c.academic.StudentAssurancePromise}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.getStudentAssurance = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.StudentAssurancePromise} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.academic.StudentAssurancePromise, 1));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.StudentAssurancePromise|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AssurancePromise} returns this
*/
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.setStudentAssurance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.licensing.c.academic.AssurancePromise.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AssurancePromise} returns this
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.clearStudentAssurance = function() {
  return this.setStudentAssurance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.hasStudentAssurance = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResearchAssurancePromise researcher_assurance = 2;
 * @return {?proto.teamdev.licensing.c.academic.ResearchAssurancePromise}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.getResearcherAssurance = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.ResearchAssurancePromise} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.academic.ResearchAssurancePromise, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.ResearchAssurancePromise|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AssurancePromise} returns this
*/
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.setResearcherAssurance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.c.academic.AssurancePromise.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AssurancePromise} returns this
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.clearResearcherAssurance = function() {
  return this.setResearcherAssurance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AssurancePromise.prototype.hasResearcherAssurance = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.StudentAssurancePromise.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.StudentAssurancePromise} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.toObject = function(includeInstance, msg) {
  var f, obj = {
    supervisorReferenceExpected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    supervisorWebsite: (f = msg.getSupervisorWebsite()) && spine_net_url_pb.Url.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.StudentAssurancePromise}
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.StudentAssurancePromise;
  return proto.teamdev.licensing.c.academic.StudentAssurancePromise.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.StudentAssurancePromise} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.StudentAssurancePromise}
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupervisorReferenceExpected(value);
      break;
    case 2:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setSupervisorWebsite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.StudentAssurancePromise.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.StudentAssurancePromise} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupervisorReferenceExpected();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSupervisorWebsite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool supervisor_reference_expected = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.getSupervisorReferenceExpected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.academic.StudentAssurancePromise} returns this
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.setSupervisorReferenceExpected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional spine.net.Url supervisor_website = 2;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.getSupervisorWebsite = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 2));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.StudentAssurancePromise} returns this
*/
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.setSupervisorWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.StudentAssurancePromise} returns this
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.clearSupervisorWebsite = function() {
  return this.setSupervisorWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.StudentAssurancePromise.prototype.hasSupervisorWebsite = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.ResearchAssurancePromise.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.ResearchAssurancePromise} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectParticipationProofExpected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.ResearchAssurancePromise}
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.ResearchAssurancePromise;
  return proto.teamdev.licensing.c.academic.ResearchAssurancePromise.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.ResearchAssurancePromise} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.ResearchAssurancePromise}
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProjectParticipationProofExpected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.ResearchAssurancePromise.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.ResearchAssurancePromise} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectParticipationProofExpected();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool project_participation_proof_expected = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.prototype.getProjectParticipationProofExpected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.academic.ResearchAssurancePromise} returns this
 */
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.prototype.setProjectParticipationProofExpected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.RequestRefusalReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.RequestRefusalReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.RequestRefusalReason}
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.RequestRefusalReason;
  return proto.teamdev.licensing.c.academic.RequestRefusalReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.RequestRefusalReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.RequestRefusalReason}
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.RequestRefusalReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.RequestRefusalReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.RequestRefusalReason} returns this
 */
proto.teamdev.licensing.c.academic.RequestRefusalReason.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.ExtensionRequestReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.ExtensionRequestReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.ExtensionRequestReason}
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.ExtensionRequestReason;
  return proto.teamdev.licensing.c.academic.ExtensionRequestReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.ExtensionRequestReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.ExtensionRequestReason}
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.ExtensionRequestReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.ExtensionRequestReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.ExtensionRequestReason} returns this
 */
proto.teamdev.licensing.c.academic.ExtensionRequestReason.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.teamdev.licensing.c.academic);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.academic.AcademicLicenseRequester.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequester.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequester();
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.kind !== undefined) {
    if (obj.kind !== null) {
      let value = proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind[obj.kind];
      msg.setKind(value);
    }
  }
  
  if (obj.requesterName !== undefined) {
    if (obj.requesterName === null) {
      msg.setRequesterName(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.requesterName);
      msg.setRequesterName(value);
    }
  }
  
  if (obj.institutionName !== undefined) {
    if (obj.institutionName !== null) {
      let value = obj.institutionName;
      msg.setInstitutionName(value);
    }
  }
  
  if (obj.institutionMailingAddress !== undefined) {
    if (obj.institutionMailingAddress !== null) {
      let value = obj.institutionMailingAddress;
      msg.setInstitutionMailingAddress(value);
    }
  }
  
  if (obj.institutionWebsite !== undefined) {
    if (obj.institutionWebsite === null) {
      msg.setInstitutionWebsite(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.institutionWebsite);
      msg.setInstitutionWebsite(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AssurancePromise.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AssurancePromise.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AssurancePromise.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AssurancePromise.Parser;
proto.teamdev.licensing.c.academic.AssurancePromise.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AssurancePromise();
  
  if (obj.studentAssurance !== undefined) {
    if (obj.studentAssurance === null) {
      msg.setStudentAssurance(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.StudentAssurancePromise').fromObject(obj.studentAssurance);
      msg.setStudentAssurance(value);
    }
  }
  
  if (obj.researcherAssurance !== undefined) {
    if (obj.researcherAssurance === null) {
      msg.setResearcherAssurance(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.ResearchAssurancePromise').fromObject(obj.researcherAssurance);
      msg.setResearcherAssurance(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.RequestRefusalReason.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.RequestRefusalReason.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.RequestRefusalReason.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.RequestRefusalReason.Parser;
proto.teamdev.licensing.c.academic.RequestRefusalReason.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.RequestRefusalReason();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.ExtensionRequestReason.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.ExtensionRequestReason.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.ExtensionRequestReason.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.ExtensionRequestReason.Parser;
proto.teamdev.licensing.c.academic.ExtensionRequestReason.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.ExtensionRequestReason();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.StudentAssurancePromise.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.StudentAssurancePromise.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.StudentAssurancePromise.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.StudentAssurancePromise.Parser;
proto.teamdev.licensing.c.academic.StudentAssurancePromise.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.StudentAssurancePromise();
  
  if (obj.supervisorReferenceExpected !== undefined) {
    if (obj.supervisorReferenceExpected !== null) {
      let value = obj.supervisorReferenceExpected;
      msg.setSupervisorReferenceExpected(value);
    }
  }
  
  if (obj.supervisorWebsite !== undefined) {
    if (obj.supervisorWebsite === null) {
      msg.setSupervisorWebsite(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.supervisorWebsite);
      msg.setSupervisorWebsite(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.ResearchAssurancePromise.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.ResearchAssurancePromise.Parser;
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.ResearchAssurancePromise();
  
  if (obj.projectParticipationProofExpected !== undefined) {
    if (obj.projectParticipationProofExpected !== null) {
      let value = obj.projectParticipationProofExpected;
      msg.setProjectParticipationProofExpected(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AssurancePromise.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AssurancePromise';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.RequestRefusalReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.RequestRefusalReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.ExtensionRequestReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.ExtensionRequestReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.StudentAssurancePromise.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.StudentAssurancePromise';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.ResearchAssurancePromise.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.ResearchAssurancePromise';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind';
};
