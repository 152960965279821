// source: teamdev/licensing/c/academic/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_c_academic_values_pb = require('../../../../teamdev/licensing/c/academic/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_values_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.GrantAcademicLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.RequestAcademicLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.RevokeAcademicLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.RequestAcademicLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.RequestAcademicLicense.displayName = 'proto.teamdev.licensing.c.academic.RequestAcademicLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.displayName = 'proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.displayName = 'proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.displayName = 'proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.displayName = 'proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.displayName = 'proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.GrantAcademicLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.GrantAcademicLicense.displayName = 'proto.teamdev.licensing.c.academic.GrantAcademicLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.displayName = 'proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.displayName = 'proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.displayName = 'proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.displayName = 'proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.displayName = 'proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.displayName = 'proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.displayName = 'proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.RevokeAcademicLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.RevokeAcademicLicense.displayName = 'proto.teamdev.licensing.c.academic.RevokeAcademicLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.displayName = 'proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.RequestAcademicLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assurancePromise: (f = msg.getAssurancePromise()) && teamdev_licensing_c_academic_values_pb.AssurancePromise.toObject(includeInstance, f),
    promotionRequirementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    licenseAgreementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.RequestAcademicLicense;
  return proto.teamdev.licensing.c.academic.RequestAcademicLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 4:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 6:
      var value = new teamdev_licensing_c_academic_values_pb.AssurancePromise;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AssurancePromise.deserializeBinaryFromReader);
      msg.setAssurancePromise(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPromotionRequirementAccepted(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseAgreementAccepted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.RequestAcademicLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssurancePromise();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_c_academic_values_pb.AssurancePromise.serializeBinaryToWriter
    );
  }
  f = message.getPromotionRequirementAccepted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLicenseAgreementAccepted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 2;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AcademicLicenseRequester requester = 3;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 4;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 4));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string project_description = 5;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AssurancePromise assurance_promise = 6;
 * @return {?proto.teamdev.licensing.c.academic.AssurancePromise}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getAssurancePromise = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AssurancePromise} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AssurancePromise, 6));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AssurancePromise|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setAssurancePromise = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.clearAssurancePromise = function() {
  return this.setAssurancePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.hasAssurancePromise = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool promotion_requirement_accepted = 7;
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getPromotionRequirementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setPromotionRequirementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool license_agreement_accepted = 8;
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.getLicenseAgreementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicense.prototype.setLicenseAgreementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification;
  return proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification} returns this
*/
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification} returns this
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified;
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.core.UserId verified_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed;
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 2;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId verified_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch;
  return proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 5:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 7:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 8:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AcademicLicenseRequester requester = 4;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 5;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 5));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string project_description = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.time.LocalDate active_through = 7;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 7));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional spine.core.UserId created_by = 8;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.getCreatedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 8));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest;
  return proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest} returns this
*/
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.GrantAcademicLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.GrantAcademicLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.GrantAcademicLicense}
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.GrantAcademicLicense;
  return proto.teamdev.licensing.c.academic.GrantAcademicLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.GrantAcademicLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.GrantAcademicLicense}
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.GrantAcademicLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.GrantAcademicLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.GrantAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.GrantAcademicLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.GrantAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.GrantAcademicLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_academic_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest;
  return proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_academic_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_academic_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestRefusalReason reason = 2;
 * @return {?proto.teamdev.licensing.c.academic.RequestRefusalReason}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.RequestRefusalReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId refused_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    licenseAgreementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    reason: (f = msg.getReason()) && teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension;
  return proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseAgreementAccepted(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_academic_values_pb.ExtensionRequestReason;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getLicenseAgreementAccepted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool license_agreement_accepted = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.getLicenseAgreementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.setLicenseAgreementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional ExtensionRequestReason reason = 3;
 * @return {?proto.teamdev.licensing.c.academic.ExtensionRequestReason}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.ExtensionRequestReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.ExtensionRequestReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.ExtensionRequestReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 4;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 4));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} returns this
*/
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension} returns this
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification;
  return proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification} returns this
*/
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification} returns this
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified;
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.core.UserId verified_by = 2;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 2));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed;
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 2;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId verified_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense;
  return proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.LocalDate active_through = 2;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 2));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_academic_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest;
  return proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_academic_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_academic_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} returns this
*/
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} returns this
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestRefusalReason reason = 2;
 * @return {?proto.teamdev.licensing.c.academic.RequestRefusalReason}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.RequestRefusalReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} returns this
*/
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} returns this
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId refused_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} returns this
*/
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest} returns this
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.RevokeAcademicLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.RevokeAcademicLicense;
  return proto.teamdev.licensing.c.academic.RevokeAcademicLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.RevokeAcademicLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.NonCommercialLicenseRevocationReason reason = 2;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId revoked_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.RevokeAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired;
  return proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired} returns this
*/
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired} returns this
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.academic);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.Parser;
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.RevokeAcademicLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.RevokeAcademicLicense.Parser;
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.RevokeAcademicLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.Parser;
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.RequestAcademicLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.RequestAcademicLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.RequestAcademicLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.RequestAcademicLicense.Parser;
proto.teamdev.licensing.c.academic.RequestAcademicLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.RequestAcademicLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.assurancePromise !== undefined) {
    if (obj.assurancePromise === null) {
      msg.setAssurancePromise(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AssurancePromise').fromObject(obj.assurancePromise);
      msg.setAssurancePromise(value);
    }
  }
  
  if (obj.promotionRequirementAccepted !== undefined) {
    if (obj.promotionRequirementAccepted !== null) {
      let value = obj.promotionRequirementAccepted;
      msg.setPromotionRequirementAccepted(value);
    }
  }
  
  if (obj.licenseAgreementAccepted !== undefined) {
    if (obj.licenseAgreementAccepted !== null) {
      let value = obj.licenseAgreementAccepted;
      msg.setLicenseAgreementAccepted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.Parser;
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.Parser;
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.Parser;
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.Parser;
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.createdBy !== undefined) {
    if (obj.createdBy === null) {
      msg.setCreatedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.createdBy);
      msg.setCreatedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.Parser;
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.Parser;
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.GrantAcademicLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.GrantAcademicLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.GrantAcademicLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.GrantAcademicLicense.Parser;
proto.teamdev.licensing.c.academic.GrantAcademicLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.GrantAcademicLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.Parser;
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.Parser;
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.Parser;
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.Parser;
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.Parser;
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.licenseAgreementAccepted !== undefined) {
    if (obj.licenseAgreementAccepted !== null) {
      let value = obj.licenseAgreementAccepted;
      msg.setLicenseAgreementAccepted(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.ExtensionRequestReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.RevokeAcademicLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.RevokeAcademicLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.RequestAcademicLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.RequestAcademicLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.GrantExtendedAcademicLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.GrantAcademicLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.GrantAcademicLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.RefuseAcademicLicenseRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.RequestAcademicLicenseExtension';
};
