// source: teamdev/licensing/c/evaluation/values.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var spine_people_person_name_pb = require('spine-web/proto/spine/people/person_name_pb.js');
goog.object.extend(proto, spine_people_person_name_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.ClientEnvironment', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.ClientEnvironment.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.DotNetEnvironment', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.EvaluationType', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator.Academic', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator.Commercial', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.JavaEnvironment', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.LinuxDistribution', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.MacOsVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.WindowsVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.WindowsVersion.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.displayName = 'proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.Evaluator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.c.evaluation.Evaluator.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.Evaluator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.Evaluator.displayName = 'proto.teamdev.licensing.c.evaluation.Evaluator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.Evaluator.Academic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.Evaluator.Academic.displayName = 'proto.teamdev.licensing.c.evaluation.Evaluator.Academic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.displayName = 'proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.Evaluator.Commercial, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.displayName = 'proto.teamdev.licensing.c.evaluation.Evaluator.Commercial';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.c.evaluation.ClientEnvironment.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.ClientEnvironment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.ClientEnvironment.displayName = 'proto.teamdev.licensing.c.evaluation.ClientEnvironment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.c.evaluation.JavaEnvironment.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.JavaEnvironment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.JavaEnvironment.displayName = 'proto.teamdev.licensing.c.evaluation.JavaEnvironment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.displayName = 'proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.c.evaluation.DotNetEnvironment.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.DotNetEnvironment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.DotNetEnvironment.displayName = 'proto.teamdev.licensing.c.evaluation.DotNetEnvironment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.displayName = 'proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.displayName = 'proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.WindowsVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.WindowsVersion.displayName = 'proto.teamdev.licensing.c.evaluation.WindowsVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.MacOsVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.MacOsVersion.displayName = 'proto.teamdev.licensing.c.evaluation.MacOsVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.LinuxDistribution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.LinuxDistribution.displayName = 'proto.teamdev.licensing.c.evaluation.LinuxDistribution';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.KindCase = {
  KIND_NOT_SET: 0,
  ADDITIONAL_INFORMATION_REQUIRED: 1,
  REQUEST_INVALID: 2,
  NOT_ELIGIBLE: 3,
  SPAM: 4,
  REPETITIVE_REQUEST: 5
};

/**
 * @return {proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.KindCase}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    additionalInformationRequired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    requestInvalid: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    notEligible: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    spam: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    repetitiveRequest: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason;
  return proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdditionalInformationRequired(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestInvalid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotEligible(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpam(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRepetitiveRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool additional_information_required = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.getAdditionalInformationRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.setAdditionalInformationRequired = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.clearAdditionalInformationRequired = function() {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.hasAdditionalInformationRequired = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool request_invalid = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.getRequestInvalid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.setRequestInvalid = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.clearRequestInvalid = function() {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.hasRequestInvalid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool not_eligible = 3;
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.getNotEligible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.setNotEligible = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.clearNotEligible = function() {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.hasNotEligible = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool spam = 4;
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.getSpam = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.setSpam = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.clearSpam = function() {
  return jspb.Message.setOneofField(this, 4, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.hasSpam = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool repetitive_request = 5;
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.getRepetitiveRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.setRepetitiveRequest = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.clearRepetitiveRequest = function() {
  return jspb.Message.setOneofField(this, 5, proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.prototype.hasRepetitiveRequest = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.c.evaluation.Evaluator.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.KindCase = {
  KIND_NOT_SET: 0,
  ACADEMIC: 3,
  OPEN_SOURCE: 4,
  COMMERCIAL: 5
};

/**
 * @return {proto.teamdev.licensing.c.evaluation.Evaluator.KindCase}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.c.evaluation.Evaluator.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.c.evaluation.Evaluator.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.Evaluator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    academic: (f = msg.getAcademic()) && proto.teamdev.licensing.c.evaluation.Evaluator.Academic.toObject(includeInstance, f),
    openSource: (f = msg.getOpenSource()) && proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.toObject(includeInstance, f),
    commercial: (f = msg.getCommercial()) && proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.Evaluator;
  return proto.teamdev.licensing.c.evaluation.Evaluator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.c.evaluation.Evaluator.Academic;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.Evaluator.Academic.deserializeBinaryFromReader);
      msg.setAcademic(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.deserializeBinaryFromReader);
      msg.setOpenSource(value);
      break;
    case 5:
      var value = new proto.teamdev.licensing.c.evaluation.Evaluator.Commercial;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.deserializeBinaryFromReader);
      msg.setCommercial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.Evaluator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getAcademic();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.c.evaluation.Evaluator.Academic.serializeBinaryToWriter
    );
  }
  f = message.getOpenSource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.serializeBinaryToWriter
    );
  }
  f = message.getCommercial();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.Evaluator.Academic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionWebsite: (f = msg.getInstitutionWebsite()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.Evaluator.Academic;
  return proto.teamdev.licensing.c.evaluation.Evaluator.Academic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionName(value);
      break;
    case 2:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setInstitutionWebsite(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.Evaluator.Academic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionWebsite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_name = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.getInstitutionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.setInstitutionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.Url institution_website = 2;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.getInstitutionWebsite = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 2));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.setInstitutionWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.clearInstitutionWebsite = function() {
  return this.setInstitutionWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.hasInstitutionWebsite = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string project_description = 3;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Academic} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    repositoryLink: (f = msg.getRepositoryLink()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    openSourceLicense: jspb.Message.getFieldWithDefault(msg, 3, 0),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource;
  return proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 2:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryLink(value);
      break;
    case 3:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType} */ (reader.readEnum());
      msg.setOpenSourceLicense(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRepositoryLink();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceLicense();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType = {
  EOSL_UNKNOWN: 0,
  APACHE: 1,
  BSD_BASED: 2,
  CREATIVE_COMMONS: 3,
  LGPL: 4,
  MIT: 5
};

/**
 * optional string project_name = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.Url repository_link = 2;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.getRepositoryLink = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 2));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.setRepositoryLink = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.clearRepositoryLink = function() {
  return this.setRepositoryLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.hasRepositoryLink = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LicenseType open_source_license = 3;
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.getOpenSourceLicense = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.setOpenSourceLicense = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string project_description = 4;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyWebsite: (f = msg.getCompanyWebsite()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    position: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phoneNumber: (f = msg.getPhoneNumber()) && teamdev_licensing_values_pb.PhoneNumber.toObject(includeInstance, f),
    teamSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.Evaluator.Commercial;
  return proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 2:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setCompanyWebsite(value);
      break;
    case 3:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany} */ (reader.readEnum());
      msg.setPosition(value);
      break;
    case 4:
      var value = new teamdev_licensing_values_pb.PhoneNumber;
      reader.readMessage(value,teamdev_licensing_values_pb.PhoneNumber.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize} */ (reader.readEnum());
      msg.setTeamSize(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyWebsite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_values_pb.PhoneNumber.serializeBinaryToWriter
    );
  }
  f = message.getTeamSize();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany = {
  ECPIC_UNDEFINED: 0,
  TECHNICAL_TEAM_LEAD: 1,
  SENIOR_SOFTWARE_ENGINEER: 2,
  SOFTWARE_ENGINEER: 3,
  ARCHITECT_TECHNICAL: 4,
  ARCHITECT_SOLUTION: 5,
  TECHNICAL_PM: 6,
  PM: 7,
  SENIOR_MANAGEMENT: 8,
  BUSINESS_ANALYST: 9,
  SYSTEMS_ANALYST: 10,
  TESTING: 11,
  OPS: 12,
  PRODUCT_OWNER: 13,
  SCRUM_MASTER: 14,
  AGILE_COACH: 15,
  UX: 16,
  OTHER: 17
};

/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize = {
  ECPTS_UNDEFINED: 0,
  ONE_PERSON: 1,
  FROM_2_TO_5_PEOPLE: 2,
  FROM_6_TO_10_PEOPLE: 3,
  MORE_THAN_10_PEOPLE: 4
};

/**
 * optional string company_name = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.Url company_website = 2;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.getCompanyWebsite = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 2));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.setCompanyWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.clearCompanyWebsite = function() {
  return this.setCompanyWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.hasCompanyWebsite = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PositionInCompany position = 3;
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.getPosition = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.setPosition = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional teamdev.licensing.PhoneNumber phone_number = 4;
 * @return {?proto.teamdev.licensing.PhoneNumber}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.getPhoneNumber = function() {
  return /** @type{?proto.teamdev.licensing.PhoneNumber} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.PhoneNumber, 4));
};


/**
 * @param {?proto.teamdev.licensing.PhoneNumber|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProjectTeamSize team_size = 5;
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.getTeamSize = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.setTeamSize = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string project_description = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.people.PersonName name = 1;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.getName = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 1));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Academic academic = 3;
 * @return {?proto.teamdev.licensing.c.evaluation.Evaluator.Academic}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.getAcademic = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.Evaluator.Academic} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.Evaluator.Academic, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.Evaluator.Academic|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.setAcademic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.teamdev.licensing.c.evaluation.Evaluator.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.clearAcademic = function() {
  return this.setAcademic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.hasAcademic = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OpenSource open_source = 4;
 * @return {?proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.getOpenSource = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.setOpenSource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.teamdev.licensing.c.evaluation.Evaluator.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.clearOpenSource = function() {
  return this.setOpenSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.hasOpenSource = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Commercial commercial = 5;
 * @return {?proto.teamdev.licensing.c.evaluation.Evaluator.Commercial}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.getCommercial = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.Evaluator.Commercial} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.Evaluator.Commercial, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.Evaluator.Commercial|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.setCommercial = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.teamdev.licensing.c.evaluation.Evaluator.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluator} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.clearCommercial = function() {
  return this.setCommercial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluator.prototype.hasCommercial = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.KindCase = {
  KIND_NOT_SET: 0,
  JAVA_ENVIRONMENT: 1,
  DOT_NET_ENVIRONMENT: 2
};

/**
 * @return {proto.teamdev.licensing.c.evaluation.ClientEnvironment.KindCase}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.c.evaluation.ClientEnvironment.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.c.evaluation.ClientEnvironment.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.ClientEnvironment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.toObject = function(includeInstance, msg) {
  var f, obj = {
    javaEnvironment: (f = msg.getJavaEnvironment()) && proto.teamdev.licensing.c.evaluation.JavaEnvironment.toObject(includeInstance, f),
    dotNetEnvironment: (f = msg.getDotNetEnvironment()) && proto.teamdev.licensing.c.evaluation.DotNetEnvironment.toObject(includeInstance, f),
    otherTechnologies: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.ClientEnvironment}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.ClientEnvironment;
  return proto.teamdev.licensing.c.evaluation.ClientEnvironment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.ClientEnvironment}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.c.evaluation.JavaEnvironment;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.JavaEnvironment.deserializeBinaryFromReader);
      msg.setJavaEnvironment(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.DotNetEnvironment.deserializeBinaryFromReader);
      msg.setDotNetEnvironment(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherTechnologies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.ClientEnvironment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJavaEnvironment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.c.evaluation.JavaEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getDotNetEnvironment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.c.evaluation.DotNetEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getOtherTechnologies();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional JavaEnvironment java_environment = 1;
 * @return {?proto.teamdev.licensing.c.evaluation.JavaEnvironment}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.getJavaEnvironment = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.JavaEnvironment} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.JavaEnvironment, 1));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.JavaEnvironment|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.setJavaEnvironment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.licensing.c.evaluation.ClientEnvironment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.clearJavaEnvironment = function() {
  return this.setJavaEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.hasJavaEnvironment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DotNetEnvironment dot_net_environment = 2;
 * @return {?proto.teamdev.licensing.c.evaluation.DotNetEnvironment}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.getDotNetEnvironment = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.DotNetEnvironment} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.DotNetEnvironment, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.DotNetEnvironment|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.setDotNetEnvironment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.c.evaluation.ClientEnvironment.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.clearDotNetEnvironment = function() {
  return this.setDotNetEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.hasDotNetEnvironment = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string other_technologies = 20;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.getOtherTechnologies = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.ClientEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.ClientEnvironment.prototype.setOtherTechnologies = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.JavaEnvironment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.toObject = function(includeInstance, msg) {
  var f, obj = {
    javaVersion: (f = msg.getJavaVersion()) && proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.toObject(includeInstance, f),
    jdkVendor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    uiFrameworkList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    windowsVersionList: jspb.Message.toObjectList(msg.getWindowsVersionList(),
    proto.teamdev.licensing.c.evaluation.WindowsVersion.toObject, includeInstance),
    macOsVersionList: jspb.Message.toObjectList(msg.getMacOsVersionList(),
    proto.teamdev.licensing.c.evaluation.MacOsVersion.toObject, includeInstance),
    linuxVersionList: jspb.Message.toObjectList(msg.getLinuxVersionList(),
    proto.teamdev.licensing.c.evaluation.LinuxDistribution.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.JavaEnvironment;
  return proto.teamdev.licensing.c.evaluation.JavaEnvironment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.deserializeBinaryFromReader);
      msg.setJavaVersion(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor} */ (reader.readEnum());
      msg.setJdkVendor(value);
      break;
    case 3:
      var value = /** @type {!Array<!proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework>} */ (reader.readPackedEnum());
      msg.setUiFrameworkList(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.c.evaluation.WindowsVersion;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.WindowsVersion.deserializeBinaryFromReader);
      msg.addWindowsVersion(value);
      break;
    case 5:
      var value = new proto.teamdev.licensing.c.evaluation.MacOsVersion;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.MacOsVersion.deserializeBinaryFromReader);
      msg.addMacOsVersion(value);
      break;
    case 6:
      var value = new proto.teamdev.licensing.c.evaluation.LinuxDistribution;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.LinuxDistribution.deserializeBinaryFromReader);
      msg.addLinuxVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.JavaEnvironment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJavaVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.serializeBinaryToWriter
    );
  }
  f = message.getJdkVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUiFrameworkList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getWindowsVersionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.teamdev.licensing.c.evaluation.WindowsVersion.serializeBinaryToWriter
    );
  }
  f = message.getMacOsVersionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.teamdev.licensing.c.evaluation.MacOsVersion.serializeBinaryToWriter
    );
  }
  f = message.getLinuxVersionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.teamdev.licensing.c.evaluation.LinuxDistribution.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor = {
  CEJEJV_UNDEFINED: 0,
  ORACLE: 1,
  IBM: 2
};

/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework = {
  CEJEUF_UNDEFINED: 0,
  SWING: 1,
  JAVA_FX: 2,
  SWT: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateNumber: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version;
  return proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUpdateNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 update_number = 1;
 * @return {number}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.prototype.getUpdateNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.prototype.setUpdateNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Version java_version = 1;
 * @return {?proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.getJavaVersion = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version, 1));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.setJavaVersion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.clearJavaVersion = function() {
  return this.setJavaVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.hasJavaVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional JdkVendor jdk_vendor = 2;
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.getJdkVendor = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor} value
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.setJdkVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated UiFramework ui_framework = 3;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework>}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.getUiFrameworkList = function() {
  return /** @type {!Array<!proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework>} value
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.setUiFrameworkList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework} value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.addUiFramework = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.clearUiFrameworkList = function() {
  return this.setUiFrameworkList([]);
};


/**
 * repeated WindowsVersion windows_version = 4;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.WindowsVersion>}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.getWindowsVersionList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.evaluation.WindowsVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.c.evaluation.WindowsVersion, 4));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.WindowsVersion>} value
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.setWindowsVersionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.WindowsVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.WindowsVersion}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.addWindowsVersion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.teamdev.licensing.c.evaluation.WindowsVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.clearWindowsVersionList = function() {
  return this.setWindowsVersionList([]);
};


/**
 * repeated MacOsVersion mac_os_version = 5;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.MacOsVersion>}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.getMacOsVersionList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.evaluation.MacOsVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.c.evaluation.MacOsVersion, 5));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.MacOsVersion>} value
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.setMacOsVersionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.MacOsVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.MacOsVersion}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.addMacOsVersion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.teamdev.licensing.c.evaluation.MacOsVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.clearMacOsVersionList = function() {
  return this.setMacOsVersionList([]);
};


/**
 * repeated LinuxDistribution linux_version = 6;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.LinuxDistribution>}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.getLinuxVersionList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.evaluation.LinuxDistribution>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.c.evaluation.LinuxDistribution, 6));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.LinuxDistribution>} value
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.setLinuxVersionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.LinuxDistribution=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.LinuxDistribution}
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.addLinuxVersion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.teamdev.licensing.c.evaluation.LinuxDistribution, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.evaluation.JavaEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.JavaEnvironment.prototype.clearLinuxVersionList = function() {
  return this.setLinuxVersionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.DotNetEnvironment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.toObject = function(includeInstance, msg) {
  var f, obj = {
    dotNetVersion: (f = msg.getDotNetVersion()) && proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.toObject(includeInstance, f),
    visualStudioVersionList: jspb.Message.toObjectList(msg.getVisualStudioVersionList(),
    proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.toObject, includeInstance),
    uiFrameworkList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    windowsVersionList: jspb.Message.toObjectList(msg.getWindowsVersionList(),
    proto.teamdev.licensing.c.evaluation.WindowsVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment;
  return proto.teamdev.licensing.c.evaluation.DotNetEnvironment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.deserializeBinaryFromReader);
      msg.setDotNetVersion(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.deserializeBinaryFromReader);
      msg.addVisualStudioVersion(value);
      break;
    case 3:
      var value = /** @type {!Array<!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework>} */ (reader.readPackedEnum());
      msg.setUiFrameworkList(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.c.evaluation.WindowsVersion;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.WindowsVersion.deserializeBinaryFromReader);
      msg.addWindowsVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.DotNetEnvironment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDotNetVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.serializeBinaryToWriter
    );
  }
  f = message.getVisualStudioVersionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.serializeBinaryToWriter
    );
  }
  f = message.getUiFrameworkList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getWindowsVersionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.teamdev.licensing.c.evaluation.WindowsVersion.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework = {
  CEDNEUF_UNDEFINED: 0,
  WPF: 1,
  WIN_FORMS: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    majorVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minorVersion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version;
  return proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMajorVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinorVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajorVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMinorVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 major_version = 1;
 * @return {number}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.prototype.getMajorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.prototype.setMajorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 minor_version = 2;
 * @return {number}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.prototype.getMinorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.prototype.setMinorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion;
  return proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Version dot_net_version = 1;
 * @return {?proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.getDotNetVersion = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version, 1));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.setDotNetVersion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.clearDotNetVersion = function() {
  return this.setDotNetVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.hasDotNetVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated VisualStudioVersion visual_studio_version = 2;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion>}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.getVisualStudioVersionList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion>} value
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.setVisualStudioVersionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.addVisualStudioVersion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.clearVisualStudioVersionList = function() {
  return this.setVisualStudioVersionList([]);
};


/**
 * repeated UiFramework ui_framework = 3;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework>}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.getUiFrameworkList = function() {
  return /** @type {!Array<!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework>} value
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.setUiFrameworkList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework} value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.addUiFramework = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.clearUiFrameworkList = function() {
  return this.setUiFrameworkList([]);
};


/**
 * repeated WindowsVersion windows_version = 4;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.WindowsVersion>}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.getWindowsVersionList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.evaluation.WindowsVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.c.evaluation.WindowsVersion, 4));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.WindowsVersion>} value
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
*/
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.setWindowsVersionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.WindowsVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.WindowsVersion}
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.addWindowsVersion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.teamdev.licensing.c.evaluation.WindowsVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.evaluation.DotNetEnvironment} returns this
 */
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.prototype.clearWindowsVersionList = function() {
  return this.setWindowsVersionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.WindowsVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.WindowsVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.WindowsVersion}
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.WindowsVersion;
  return proto.teamdev.licensing.c.evaluation.WindowsVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.WindowsVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.WindowsVersion}
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.WindowsVersion.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.WindowsVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.WindowsVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.Type = {
  WVT_UNDEFINED: 0,
  DESKTOP: 1,
  SERVER: 2
};

/**
 * optional Type type = 1;
 * @return {!proto.teamdev.licensing.c.evaluation.WindowsVersion.Type}
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.prototype.getType = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.WindowsVersion.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.WindowsVersion.Type} value
 * @return {!proto.teamdev.licensing.c.evaluation.WindowsVersion} returns this
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.WindowsVersion} returns this
 */
proto.teamdev.licensing.c.evaluation.WindowsVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.MacOsVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.MacOsVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    majorVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minorVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.MacOsVersion}
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.MacOsVersion;
  return proto.teamdev.licensing.c.evaluation.MacOsVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.MacOsVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.MacOsVersion}
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMajorVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinorVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.MacOsVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.MacOsVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMajorVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMinorVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string code_name = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.getCodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.MacOsVersion} returns this
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.setCodeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 major_version = 2;
 * @return {number}
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.getMajorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.c.evaluation.MacOsVersion} returns this
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.setMajorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 minor_version = 3;
 * @return {number}
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.getMinorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.c.evaluation.MacOsVersion} returns this
 */
proto.teamdev.licensing.c.evaluation.MacOsVersion.prototype.setMinorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.LinuxDistribution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.LinuxDistribution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.LinuxDistribution}
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.LinuxDistribution;
  return proto.teamdev.licensing.c.evaluation.LinuxDistribution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.LinuxDistribution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.LinuxDistribution}
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.LinuxDistribution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.LinuxDistribution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.evaluation.LinuxDistribution} returns this
 */
proto.teamdev.licensing.c.evaluation.LinuxDistribution.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.EvaluationType = {
  ET_UNDEFINED: 0,
  ACADEMIC: 1,
  OPEN_SOURCE: 2,
  COMMERCIAL: 3
};

goog.object.extend(exports, proto.teamdev.licensing.c.evaluation);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.evaluation.Evaluator.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.Evaluator.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.Evaluator.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.Evaluator.Parser;
proto.teamdev.licensing.c.evaluation.Evaluator.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.Evaluator();
  
  if (obj.name !== undefined) {
    if (obj.name === null) {
      msg.setName(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.name);
      msg.setName(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.academic !== undefined) {
    if (obj.academic === null) {
      msg.setAcademic(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Academic').fromObject(obj.academic);
      msg.setAcademic(value);
    }
  }
  
  if (obj.openSource !== undefined) {
    if (obj.openSource === null) {
      msg.setOpenSource(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.OpenSource').fromObject(obj.openSource);
      msg.setOpenSource(value);
    }
  }
  
  if (obj.commercial !== undefined) {
    if (obj.commercial === null) {
      msg.setCommercial(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial').fromObject(obj.commercial);
      msg.setCommercial(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.Parser;
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.Parser;
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.Evaluator.Commercial();
  
  if (obj.companyName !== undefined) {
    if (obj.companyName !== null) {
      let value = obj.companyName;
      msg.setCompanyName(value);
    }
  }
  
  if (obj.companyWebsite !== undefined) {
    if (obj.companyWebsite === null) {
      msg.setCompanyWebsite(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.companyWebsite);
      msg.setCompanyWebsite(value);
    }
  }
  
  if (obj.position !== undefined) {
    if (obj.position !== null) {
      let value = proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany[obj.position];
      msg.setPosition(value);
    }
  }
  
  if (obj.phoneNumber !== undefined) {
    if (obj.phoneNumber === null) {
      msg.setPhoneNumber(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PhoneNumber').fromObject(obj.phoneNumber);
      msg.setPhoneNumber(value);
    }
  }
  
  if (obj.teamSize !== undefined) {
    if (obj.teamSize !== null) {
      let value = proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize[obj.teamSize];
      msg.setTeamSize(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.Parser;
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version();
  
  if (obj.majorVersion !== undefined) {
    if (obj.majorVersion !== null) {
      let value = obj.majorVersion;
      msg.setMajorVersion(value);
    }
  }
  
  if (obj.minorVersion !== undefined) {
    if (obj.minorVersion !== null) {
      let value = obj.minorVersion;
      msg.setMinorVersion(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.Parser;
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason();
  
  if (obj.additionalInformationRequired !== undefined) {
    if (obj.additionalInformationRequired !== null) {
      let value = obj.additionalInformationRequired;
      msg.setAdditionalInformationRequired(value);
    }
  }
  
  if (obj.requestInvalid !== undefined) {
    if (obj.requestInvalid !== null) {
      let value = obj.requestInvalid;
      msg.setRequestInvalid(value);
    }
  }
  
  if (obj.notEligible !== undefined) {
    if (obj.notEligible !== null) {
      let value = obj.notEligible;
      msg.setNotEligible(value);
    }
  }
  
  if (obj.spam !== undefined) {
    if (obj.spam !== null) {
      let value = obj.spam;
      msg.setSpam(value);
    }
  }
  
  if (obj.repetitiveRequest !== undefined) {
    if (obj.repetitiveRequest !== null) {
      let value = obj.repetitiveRequest;
      msg.setRepetitiveRequest(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.Evaluator.Academic.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.Evaluator.Academic.Parser;
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.Evaluator.Academic();
  
  if (obj.institutionName !== undefined) {
    if (obj.institutionName !== null) {
      let value = obj.institutionName;
      msg.setInstitutionName(value);
    }
  }
  
  if (obj.institutionWebsite !== undefined) {
    if (obj.institutionWebsite === null) {
      msg.setInstitutionWebsite(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.institutionWebsite);
      msg.setInstitutionWebsite(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.Parser;
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version();
  
  if (obj.updateNumber !== undefined) {
    if (obj.updateNumber !== null) {
      let value = obj.updateNumber;
      msg.setUpdateNumber(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.WindowsVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.WindowsVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.WindowsVersion.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.WindowsVersion.Parser;
proto.teamdev.licensing.c.evaluation.WindowsVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.WindowsVersion();
  
  if (obj.type !== undefined) {
    if (obj.type !== null) {
      let value = proto.teamdev.licensing.c.evaluation.WindowsVersion.Type[obj.type];
      msg.setType(value);
    }
  }
  
  if (obj.version !== undefined) {
    if (obj.version !== null) {
      let value = obj.version;
      msg.setVersion(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.LinuxDistribution.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.LinuxDistribution.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.LinuxDistribution.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.LinuxDistribution.Parser;
proto.teamdev.licensing.c.evaluation.LinuxDistribution.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.LinuxDistribution();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.JavaEnvironment.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.JavaEnvironment.Parser;
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.JavaEnvironment();
  
  if (obj.javaVersion !== undefined) {
    if (obj.javaVersion === null) {
      msg.setJavaVersion(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.Version').fromObject(obj.javaVersion);
      msg.setJavaVersion(value);
    }
  }
  
  if (obj.jdkVendor !== undefined) {
    if (obj.jdkVendor !== null) {
      let value = proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor[obj.jdkVendor];
      msg.setJdkVendor(value);
    }
  }
  
  if (obj.uiFramework !== undefined && obj.uiFramework !== null) {
    obj.uiFramework.forEach(
      (listItem, index, array) => {
        if (listItem !== null) {
          let value = proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework[listItem];
          msg.addUiFramework(value);
        }
      }
    );
  }
  
  if (obj.windowsVersion !== undefined && obj.windowsVersion !== null) {
    obj.windowsVersion.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addWindowsVersion(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.WindowsVersion').fromObject(listItem);
          msg.addWindowsVersion(value);
        }
      }
    );
  }
  
  if (obj.macOsVersion !== undefined && obj.macOsVersion !== null) {
    obj.macOsVersion.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addMacOsVersion(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.MacOsVersion').fromObject(listItem);
          msg.addMacOsVersion(value);
        }
      }
    );
  }
  
  if (obj.linuxVersion !== undefined && obj.linuxVersion !== null) {
    obj.linuxVersion.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addLinuxVersion(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.LinuxDistribution').fromObject(listItem);
          msg.addLinuxVersion(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.Parser;
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource();
  
  if (obj.projectName !== undefined) {
    if (obj.projectName !== null) {
      let value = obj.projectName;
      msg.setProjectName(value);
    }
  }
  
  if (obj.repositoryLink !== undefined) {
    if (obj.repositoryLink === null) {
      msg.setRepositoryLink(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryLink);
      msg.setRepositoryLink(value);
    }
  }
  
  if (obj.openSourceLicense !== undefined) {
    if (obj.openSourceLicense !== null) {
      let value = proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType[obj.openSourceLicense];
      msg.setOpenSourceLicense(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.ClientEnvironment.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.ClientEnvironment.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.ClientEnvironment.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.ClientEnvironment.Parser;
proto.teamdev.licensing.c.evaluation.ClientEnvironment.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.ClientEnvironment();
  
  if (obj.javaEnvironment !== undefined) {
    if (obj.javaEnvironment === null) {
      msg.setJavaEnvironment(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment').fromObject(obj.javaEnvironment);
      msg.setJavaEnvironment(value);
    }
  }
  
  if (obj.dotNetEnvironment !== undefined) {
    if (obj.dotNetEnvironment === null) {
      msg.setDotNetEnvironment(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment').fromObject(obj.dotNetEnvironment);
      msg.setDotNetEnvironment(value);
    }
  }
  
  if (obj.otherTechnologies !== undefined) {
    if (obj.otherTechnologies !== null) {
      let value = obj.otherTechnologies;
      msg.setOtherTechnologies(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.MacOsVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.MacOsVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.MacOsVersion.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.MacOsVersion.Parser;
proto.teamdev.licensing.c.evaluation.MacOsVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.MacOsVersion();
  
  if (obj.codeName !== undefined) {
    if (obj.codeName !== null) {
      let value = obj.codeName;
      msg.setCodeName(value);
    }
  }
  
  if (obj.majorVersion !== undefined) {
    if (obj.majorVersion !== null) {
      let value = obj.majorVersion;
      msg.setMajorVersion(value);
    }
  }
  
  if (obj.minorVersion !== undefined) {
    if (obj.minorVersion !== null) {
      let value = obj.minorVersion;
      msg.setMinorVersion(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Parser;
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.DotNetEnvironment();
  
  if (obj.dotNetVersion !== undefined) {
    if (obj.dotNetVersion === null) {
      msg.setDotNetVersion(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.Version').fromObject(obj.dotNetVersion);
      msg.setDotNetVersion(value);
    }
  }
  
  if (obj.visualStudioVersion !== undefined && obj.visualStudioVersion !== null) {
    obj.visualStudioVersion.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addVisualStudioVersion(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion').fromObject(listItem);
          msg.addVisualStudioVersion(value);
        }
      }
    );
  }
  
  if (obj.uiFramework !== undefined && obj.uiFramework !== null) {
    obj.uiFramework.forEach(
      (listItem, index, array) => {
        if (listItem !== null) {
          let value = proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework[listItem];
          msg.addUiFramework(value);
        }
      }
    );
  }
  
  if (obj.windowsVersion !== undefined && obj.windowsVersion !== null) {
    obj.windowsVersion.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addWindowsVersion(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.WindowsVersion').fromObject(listItem);
          msg.addWindowsVersion(value);
        }
      }
    );
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluator.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.Version';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationRefusalReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluator.Academic.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Academic';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.Version';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.WindowsVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.WindowsVersion';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.LinuxDistribution.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.LinuxDistribution';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.JavaEnvironment.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.OpenSource';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.ClientEnvironment.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.ClientEnvironment';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.MacOsVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.MacOsVersion';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.EvaluationType.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationType';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.WindowsVersion.Type.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.WindowsVersion.Type';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize';
};
