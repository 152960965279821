import { render, staticRenderFns } from "./Header3Prominent.vue?vue&type=template&id=782800ec&scoped=true&"
import script from "./Header3Prominent.vue?vue&type=script&lang=ts&"
export * from "./Header3Prominent.vue?vue&type=script&lang=ts&"
import style0 from "./Header3Prominent.vue?vue&type=style&index=0&id=782800ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "782800ec",
  null
  
)

export default component.exports