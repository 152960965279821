// source: teamdev/licensing/c/product/rejections.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.ProductAlreadyExists', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.ProductVersionAlreadyExists', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.ProductAlreadyExists, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.ProductAlreadyExists.displayName = 'proto.teamdev.licensing.c.product.ProductAlreadyExists';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.ProductVersionAlreadyExists, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.displayName = 'proto.teamdev.licensing.c.product.ProductVersionAlreadyExists';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.displayName = 'proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.displayName = 'proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.displayName = 'proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.ProductAlreadyExists.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.ProductAlreadyExists} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.ProductAlreadyExists}
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.ProductAlreadyExists;
  return proto.teamdev.licensing.c.product.ProductAlreadyExists.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.ProductAlreadyExists} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.ProductAlreadyExists}
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.ProductAlreadyExists.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.ProductAlreadyExists} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductAlreadyExists} returns this
*/
proto.teamdev.licensing.c.product.ProductAlreadyExists.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductAlreadyExists} returns this
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductAlreadyExists.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.ProductVersionAlreadyExists} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionAlreadyExists}
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.ProductVersionAlreadyExists;
  return proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.ProductVersionAlreadyExists} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionAlreadyExists}
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.ProductVersionAlreadyExists} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductVersionAlreadyExists} returns this
*/
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionAlreadyExists} returns this
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct;
  return proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct} returns this
*/
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct} returns this
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct} returns this
*/
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct} returns this
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct;
  return proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct} returns this
*/
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct} returns this
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion;
  return proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion} returns this
*/
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion} returns this
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.product);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.product.ProductAlreadyExists.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.ProductAlreadyExists.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.ProductAlreadyExists.Parser.prototype.constructor = proto.teamdev.licensing.c.product.ProductAlreadyExists.Parser;
proto.teamdev.licensing.c.product.ProductAlreadyExists.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.ProductAlreadyExists();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.Parser.prototype.constructor = proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.Parser;
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.Parser.prototype.constructor = proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.Parser;
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.ProductVersionAlreadyExists();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.Parser.prototype.constructor = proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.Parser;
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.Parser.prototype.constructor = proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.Parser;
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.ProductAlreadyExists.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.ProductAlreadyExists';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.CannotUpdateUnknownProductVersion';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.ProductVersionAlreadyExists';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.CannotUpdateUnknownProduct';
};
