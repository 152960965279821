require('./teamdev/licensing/q/product/product_view_pb.js');
require('./teamdev/users/q/user_view_pb.js');
require('./teamdev/licensing/c/evaluation/evaluation_pb.js');
require('./teamdev/licensing/c/opensource/values_pb.js');
require('./teamdev/licensing/c/sampledata/sample_data_generation_pb.js');
require('./teamdev/licensing/c/singledev/sourcecode/events_pb.js');
require('./teamdev/licensing/c/personal/events_pb.js');
require('./teamdev/licensing/c/singledev/sourcecode/single_dev_sc_license_pb.js');
require('./teamdev/licensing/c/evaluation/commands_pb.js');
require('./teamdev/licensing/client_pb.js');
require('./teamdev/licensing/c/product/values_pb.js');
require('./teamdev/users/c/rejections_pb.js');
require('./teamdev/licensing/c/product/events_pb.js');
require('./teamdev/licensing/licensing_target_pb.js');
require('./teamdev/licensing/q/evaluation/evaluation_view_pb.js');
require('./teamdev/licensing/q/demo/demo_license_view_pb.js');
require('./teamdev/licensing/values_pb.js');
require('./teamdev/licensing/c/company/commands_pb.js');
require('./teamdev/licensing/c/academic/rejections_pb.js');
require('./teamdev/licensing/c/project/sourcecode/project_sc_license_pb.js');
require('./teamdev/licensing/c/product/product_pb.js');
require('./teamdev/licensing/importado/license_data_pb.js');
require('./teamdev/users/values_pb.js');
require('./teamdev/licensing/c/company/sourcecode/events_pb.js');
require('./teamdev/licensing/c/academic/values_pb.js');
require('./teamdev/licensing/importado/commands_pb.js');
require('./teamdev/licensing/c/generation/values_pb.js');
require('./teamdev/licensing/importado/product_version_data_pb.js');
require('./teamdev/sales/c/events_pb.js');
require('./teamdev/licensing/q/licensed_period_pb.js');
require('./teamdev/licensing/c/evaluation/values_pb.js');
require('./teamdev/licensing/c/singledev/events_pb.js');
require('./teamdev/licensing/c/company/sourcecode/company_wide_sc_license_pb.js');
require('./teamdev/licensing/c/project/project_license_pb.js');
require('./teamdev/licensing/c/sampledata/events_pb.js');
require('./teamdev/licensing/c/opensource/open_source_license_pb.js');
require('./teamdev/licensing/c/generation/events_pb.js');
require('./teamdev/licensing/license_artifact_pb.js');
require('./teamdev/licensing/c/company/company_wide_license_pb.js');
require('./teamdev/licensing/c/demo/rejections_pb.js');
require('./teamdev/licensing/q/noncommercial/noncommercial_license_view_pb.js');
require('./teamdev/licensing/c/demo/events_pb.js');
require('./teamdev/licensing/c/demo/demo_license_pb.js');
require('./teamdev/licensing/c/demo/commands_pb.js');
require('./teamdev/licensing/c/singledev/rejections_pb.js');
require('./teamdev/licensing/c/generation/license_generation_pb.js');
require('./teamdev/licensing/q/commercial/commercial_license_view_pb.js');
require('./teamdev/licensing/c/project/commands_pb.js');
require('./teamdev/licensing/c/product/product_version_pb.js');
require('./teamdev/licensing/c/company/rejections_pb.js');
require('./teamdev/licensing/c/product/commands_pb.js');
require('./teamdev/licensing/identifiers_pb.js');
require('./teamdev/licensing/c/opensource/rejections_pb.js');
require('./teamdev/licensing/c/evaluation/events_pb.js');
require('./teamdev/users/c/events_pb.js');
require('./teamdev/licensing/c/singledev/single_dev_license_pb.js');
require('./teamdev/licensing/c/opensource/commands_pb.js');
require('./teamdev/licensing/c/personal/commands_pb.js');
require('./teamdev/licensing/c/generation/rejections_pb.js');
require('./teamdev/licensing/c/evaluation/rejections_pb.js');
require('./teamdev/licensing/importado/events_pb.js');
require('./teamdev/licensing/c/academic/events_pb.js');
require('./teamdev/licensing/project_binding_pb.js');
require('./teamdev/licensing/c/generation/commands_pb.js');
require('./teamdev/licensing/c/company/sourcecode/rejections_pb.js');
require('./teamdev/licensing/c/company/events_pb.js');
require('./teamdev/licensing/c/academic/commands_pb.js');
require('./teamdev/licensing/q/report/daily_stats_pb.js');
require('./teamdev/licensing/c/product/licensing_policy_pb.js');
require('./teamdev/licensing/c/singledev/commands_pb.js');
require('./teamdev/licensing/c/project/events_pb.js');
require('./teamdev/licensing/c/company/sourcecode/commands_pb.js');
require('./teamdev/licensing/c/singledev/sourcecode/commands_pb.js');
require('./teamdev/licensing/c/project/sourcecode/commands_pb.js');
require('./teamdev/licensing/q/noncommercial/noncommercial_license_request_view_pb.js');
require('./teamdev/licensing/q/noncommercial/project_profile_pb.js');
require('./teamdev/licensing/importado/v1/v1data_pb.js');
require('./teamdev/users/identifiers_pb.js');
require('./teamdev/licensing/c/academic/academic_license_pb.js');
require('./teamdev/licensing/c/sampledata/commands_pb.js');
require('./teamdev/licensing/c/personal/personal_license_pb.js');
require('./teamdev/licensing/c/project/rejections_pb.js');
require('./teamdev/users/c/user_pb.js');
require('./teamdev/licensing/c/personal/rejections_pb.js');
require('./teamdev/licensing/c/singledev/sourcecode/rejections_pb.js');
require('./teamdev/licensing/importado/product_data_pb.js');
require('./teamdev/licensing/c/opensource/events_pb.js');
require('./teamdev/users/c/commands_pb.js');
require('./teamdev/licensing/c/project/sourcecode/rejections_pb.js');
require('./teamdev/licensing/c/project/sourcecode/events_pb.js');
require('./teamdev/licensing/c/product/rejections_pb.js');

module.exports.types = new Map([
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicense', proto.teamdev.licensing.c.academic.AcademicLicense],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseAlreadyExists', proto.teamdev.licensing.c.company.CompanyWideLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseAlreadyRevoked', proto.teamdev.licensing.c.personal.PersonalLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseIssued', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseIssued],
  ['type.teamdev.com/teamdev.sales.c.CompanyWideLicenseGranted', proto.teamdev.sales.c.CompanyWideLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleMainstreamVersions', proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned', proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned],
  ['type.teamdev.com/teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense', proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseRevoked', proto.teamdev.licensing.c.demo.DemoLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseGranted', proto.teamdev.licensing.c.academic.AcademicLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest', proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseAlreadyRevoked', proto.teamdev.licensing.c.project.ProjectLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseIssued', proto.teamdev.licensing.c.academic.AcademicLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RevokeOpenSourceLicense', proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkRequestAsVerified', proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified],
  ['type.teamdev.com/teamdev.licensing.importado.v1.Products', proto.teamdev.licensing.importado.v1.Products],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseCreated', proto.teamdev.licensing.c.company.CompanyWideLicenseCreated],
  ['type.teamdev.com/teamdev.users.c.User', proto.teamdev.users.c.User],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProjectSourceCodeLicense', proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.personal.RevokePersonalLicense', proto.teamdev.licensing.c.personal.RevokePersonalLicense],
  ['type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason', proto.teamdev.licensing.LicenseRequestVerificationFailureReason],
  ['type.teamdev.com/teamdev.users.RemoteIdentityProviderId', proto.teamdev.users.RemoteIdentityProviderId],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseView', proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.RequestEvaluation', proto.teamdev.licensing.c.evaluation.RequestEvaluation],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.RevokeSingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.RevokeSingleDevSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.RequestAcademicLicenseExtension', proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension],
  ['type.teamdev.com/teamdev.licensing.c.academic.ResearchAssurancePromise', proto.teamdev.licensing.c.academic.ResearchAssurancePromise],
  ['type.teamdev.com/teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification', proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification],
  ['type.teamdev.com/teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued', proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.project.RevokeProjectLicense', proto.teamdev.licensing.c.project.RevokeProjectLicense],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleProductVersions', proto.teamdev.licensing.c.sampledata.AddSampleProductVersions],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyRevoked', proto.teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.users.UserDetails', proto.teamdev.users.UserDetails],
  ['type.teamdev.com/teamdev.licensing.importado.ProductImported', proto.teamdev.licensing.importado.ProductImported],
  ['type.teamdev.com/teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted', proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.importado.ProjectSourceCodeLicenseImported', proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseAlreadyExists', proto.teamdev.licensing.c.demo.DemoLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed', proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationExtended', proto.teamdev.licensing.c.evaluation.EvaluationExtended],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotMarkLicenseAsExpired', proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.importado.ImportSingleDevLicense', proto.teamdev.licensing.importado.ImportSingleDevLicense],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseTypeIsUnsupported', proto.teamdev.licensing.c.generation.LicenseTypeIsUnsupported],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductDetailsUpdated', proto.teamdev.licensing.c.product.ProductDetailsUpdated],
  ['type.teamdev.com/teamdev.licensing.c.product.UpdateProductVersionLicensing', proto.teamdev.licensing.c.product.UpdateProductVersionLicensing],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseAlreadyExists', proto.teamdev.licensing.c.project.ProjectLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.demo.RevokeDemoLicense', proto.teamdev.licensing.c.demo.RevokeDemoLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment', proto.teamdev.licensing.c.evaluation.JavaEnvironment],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed', proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGeneration', proto.teamdev.licensing.c.sampledata.SampleDataGeneration],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RequestRefusalReason', proto.teamdev.licensing.c.opensource.RequestRefusalReason],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch', proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequest', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequest],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyExpired', proto.teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyExpired],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseRevoked', proto.teamdev.licensing.c.personal.PersonalLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseRequested', proto.teamdev.licensing.c.demo.DemoLicenseRequested],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester],
  ['type.teamdev.com/teamdev.licensing.c.product.CreateProduct', proto.teamdev.licensing.c.product.CreateProduct],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified],
  ['type.teamdev.com/teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch', proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseRevoked', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.product.Product', proto.teamdev.licensing.c.product.Product],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.Version', proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version],
  ['type.teamdev.com/teamdev.licensing.FullyQualifiedClassName', proto.teamdev.licensing.FullyQualifiedClassName],
  ['type.teamdev.com/teamdev.licensing.CommercialLicenseViewId', proto.teamdev.licensing.CommercialLicenseViewId],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExpired', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired],
  ['type.teamdev.com/teamdev.licensing.SingleDevLicenseId', proto.teamdev.licensing.SingleDevLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductName', proto.teamdev.licensing.c.product.ProductName],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequested', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense', proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed],
  ['type.teamdev.com/teamdev.licensing.c.generation.AcademicProjectProfile', proto.teamdev.licensing.c.generation.AcademicProjectProfile],
  ['type.teamdev.com/teamdev.licensing.LicenseGenerationId', proto.teamdev.licensing.LicenseGenerationId],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleLicensesAdded', proto.teamdev.licensing.c.sampledata.SampleLicensesAdded],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyExists', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.OpenSourceLicenseId', proto.teamdev.licensing.OpenSourceLicenseId],
  ['type.teamdev.com/teamdev.licensing.importado.SingleDevSourceCodeLicenseImported', proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionDetails', proto.teamdev.licensing.c.product.ProductVersionDetails],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest', proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest],
  ['type.teamdev.com/teamdev.licensing.q.demo.DemoLicenseView', proto.teamdev.licensing.q.demo.DemoLicenseView],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductDropDownList', proto.teamdev.licensing.q.product.ProductDropDownList],
  ['type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem', proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.RevokeProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.RevokeProjectSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseIssued', proto.teamdev.licensing.c.project.ProjectLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded', proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded],
  ['type.teamdev.com/teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense', proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseIssued', proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionCreated', proto.teamdev.licensing.c.product.ProductVersionCreated],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleLicenses', proto.teamdev.licensing.c.sampledata.AddSampleLicenses],
  ['type.teamdev.com/teamdev.licensing.c.product.VersionDescription', proto.teamdev.licensing.c.product.VersionDescription],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.OpenSourceProjectProfile', proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile],
  ['type.teamdev.com/teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem', proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.importado.ProductVersionData', proto.teamdev.licensing.importado.ProductVersionData],
  ['type.teamdev.com/teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted', proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseCreated', proto.teamdev.licensing.c.personal.PersonalLicenseCreated],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseExpired', proto.teamdev.licensing.c.evaluation.EvaluationLicenseExpired],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired],
  ['type.teamdev.com/teamdev.licensing.c.product.AssignMainstreamVersion', proto.teamdev.licensing.c.product.AssignMainstreamVersion],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyRevoked', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.academic.StudentAssurancePromise', proto.teamdev.licensing.c.academic.StudentAssurancePromise],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductsAdded', proto.teamdev.licensing.c.sampledata.SampleProductsAdded],
  ['type.teamdev.com/teamdev.users.c.UserCreated', proto.teamdev.users.c.UserCreated],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotRequestExtendedLicense', proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseAlreadyRevoked', proto.teamdev.licensing.c.company.CompanyWideLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationRefusalReason', proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseRevoked', proto.teamdev.licensing.c.company.CompanyWideLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.AcademicProjectProfile', proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile],
  ['type.teamdev.com/teamdev.licensing.importado.v1.License', proto.teamdev.licensing.importado.v1.License],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRevoked', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.WindowsVersion', proto.teamdev.licensing.c.evaluation.WindowsVersion],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriod', proto.teamdev.licensing.q.LicensedPeriod],
  ['type.teamdev.com/teamdev.licensing.c.generation.EvaluationProjectProfile', proto.teamdev.licensing.c.generation.EvaluationProjectProfile],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseIssued', proto.teamdev.licensing.c.demo.DemoLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotGrantAcademicLicense', proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.RevokeAcademicLicense', proto.teamdev.licensing.c.academic.RevokeAcademicLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkRequestAsFailed', proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch', proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem', proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriodStart', proto.teamdev.licensing.q.LicensedPeriodStart],
  ['type.teamdev.com/teamdev.licensing.c.personal.CannotRevokeUnknownPersonalLicense', proto.teamdev.licensing.c.personal.CannotRevokeUnknownPersonalLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial', proto.teamdev.licensing.c.evaluation.Evaluator.Commercial],
  ['type.teamdev.com/teamdev.licensing.importado.CompanyWideLicenseImported', proto.teamdev.licensing.importado.CompanyWideLicenseImported],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseCreated', proto.teamdev.licensing.c.project.ProjectLicenseCreated],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProjectLicense', proto.teamdev.licensing.importado.ImportProjectLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted', proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted],
  ['type.teamdev.com/teamdev.users.c.DeleteUser', proto.teamdev.users.c.DeleteUser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest', proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGenerationCompleted', proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted],
  ['type.teamdev.com/teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification', proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification],
  ['type.teamdev.com/teamdev.sales.c.PersonalLicenseGranted', proto.teamdev.sales.c.PersonalLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.CannotOperateOnNewEvaluation', proto.teamdev.licensing.c.evaluation.CannotOperateOnNewEvaluation],
  ['type.teamdev.com/teamdev.licensing.c.product.CannotUpdateUnknownProduct', proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct],
  ['type.teamdev.com/teamdev.licensing.c.project.CreateProjectLicense', proto.teamdev.licensing.c.project.CreateProjectLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest', proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest],
  ['type.teamdev.com/teamdev.licensing.PersonalLicenseId', proto.teamdev.licensing.PersonalLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductDetails', proto.teamdev.licensing.c.product.ProductDetails],
  ['type.teamdev.com/teamdev.licensing.NonCommercialLicenseRequestId', proto.teamdev.licensing.NonCommercialLicenseRequestId],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseIssued', proto.teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.singledev.CreateSingleDevLicense', proto.teamdev.licensing.c.singledev.CreateSingleDevLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.AssurancePromise', proto.teamdev.licensing.c.academic.AssurancePromise],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProduct', proto.teamdev.licensing.importado.ImportProduct],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationAlreadyExists', proto.teamdev.licensing.c.evaluation.EvaluationAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch', proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseExpired', proto.teamdev.licensing.c.demo.DemoLicenseExpired],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseGranted', proto.teamdev.licensing.c.evaluation.EvaluationLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.project.CannotRevokeUnknownProjectLicense', proto.teamdev.licensing.c.project.CannotRevokeUnknownProjectLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.LinuxDistribution', proto.teamdev.licensing.c.evaluation.LinuxDistribution],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned', proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.OpenSource', proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource],
  ['type.teamdev.com/teamdev.licensing.commercial.Client', proto.teamdev.licensing.commercial.Client],
  ['type.teamdev.com/teamdev.licensing.AcademicLicenseId', proto.teamdev.licensing.AcademicLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RequestOpenSourceLicense', proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseAlreadyExists', proto.teamdev.licensing.c.personal.PersonalLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriodEnd', proto.teamdev.licensing.q.LicensedPeriodEnd],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseAlreadyRevoked', proto.teamdev.licensing.c.singledev.SingleDevLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported', proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequested', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyRevoked', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicense', proto.teamdev.licensing.c.project.ProjectLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired', proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.c.academic.ExtensionRequestReason', proto.teamdev.licensing.c.academic.ExtensionRequestReason],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.RevokeEvaluationLicense', proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseGenerationAlreadyInitialized', proto.teamdev.licensing.c.generation.LicenseGenerationAlreadyInitialized],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed],
  ['type.teamdev.com/teamdev.licensing.importado.ProductVersionImported', proto.teamdev.licensing.importado.ProductVersionImported],
  ['type.teamdev.com/teamdev.licensing.q.commercial.CommercialLicenseView', proto.teamdev.licensing.q.commercial.CommercialLicenseView],
  ['type.teamdev.com/teamdev.licensing.c.demo.CannotOperateOnNewDemoLicense', proto.teamdev.licensing.c.demo.CannotOperateOnNewDemoLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested],
  ['type.teamdev.com/teamdev.licensing.LicenseArtifact', proto.teamdev.licensing.LicenseArtifact],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotRefuseRequest', proto.teamdev.licensing.c.opensource.CannotRefuseRequest],
  ['type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason', proto.teamdev.licensing.NonCommercialLicenseRevocationReason],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest', proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProductVersion', proto.teamdev.licensing.importado.ImportProductVersion],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseAlreadyExists', proto.teamdev.licensing.c.singledev.SingleDevLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.importado.ImportCompanyWideLicense', proto.teamdev.licensing.importado.ImportCompanyWideLicense],
  ['type.teamdev.com/teamdev.licensing.CompanyWideLicenseId', proto.teamdev.licensing.CompanyWideLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension', proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension],
  ['type.teamdev.com/teamdev.licensing.EvaluationId', proto.teamdev.licensing.EvaluationId],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseIssued', proto.teamdev.licensing.c.singledev.SingleDevLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseGranted', proto.teamdev.licensing.c.demo.DemoLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRevoked', proto.teamdev.licensing.c.academic.AcademicLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionAlreadyExists', proto.teamdev.licensing.c.product.ProductVersionAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.q.evaluation.EvaluationView', proto.teamdev.licensing.q.evaluation.EvaluationView],
  ['type.teamdev.com/teamdev.sales.c.SingleDevLicenseGranted', proto.teamdev.sales.c.SingleDevLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotMarkRequestAsFailed', proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed],
  ['type.teamdev.com/teamdev.licensing.importado.v1.Licenses', proto.teamdev.licensing.importado.v1.Licenses],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest', proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseCreated', proto.teamdev.licensing.c.singledev.SingleDevLicenseCreated],
  ['type.teamdev.com/teamdev.licensing.c.personal.CreatePersonalLicense', proto.teamdev.licensing.c.personal.CreatePersonalLicense],
  ['type.teamdev.com/teamdev.licensing.SampleDataGenerationId', proto.teamdev.licensing.SampleDataGenerationId],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationRefused', proto.teamdev.licensing.c.evaluation.EvaluationRefused],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment', proto.teamdev.licensing.c.evaluation.DotNetEnvironment],
  ['type.teamdev.com/teamdev.sales.c.SingleDevSourceCodeLicenseGranted', proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.OtherReasonChoice', proto.teamdev.licensing.OtherReasonChoice],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyRevoked', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseIssued', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseArtifactGenerationFailed', proto.teamdev.licensing.c.generation.LicenseArtifactGenerationFailed],
  ['type.teamdev.com/teamdev.licensing.c.opensource.GrantOpenSourceLicense', proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion', proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified', proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified],
  ['type.teamdev.com/teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification', proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleVersionsCreated', proto.teamdev.licensing.c.sampledata.SampleVersionsCreated],
  ['type.teamdev.com/teamdev.licensing.c.product.VersionName', proto.teamdev.licensing.c.product.VersionName],
  ['type.teamdev.com/teamdev.licensing.importado.PersonalLicenseImported', proto.teamdev.licensing.importado.PersonalLicenseImported],
  ['type.teamdev.com/teamdev.sales.c.CompanyWideSourceCodeLicenseGranted', proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicense', proto.teamdev.licensing.c.singledev.SingleDevLicense],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyExists', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionLicensingUpdated', proto.teamdev.licensing.c.product.ProductVersionLicensingUpdated],
  ['type.teamdev.com/teamdev.licensing.PhoneNumber', proto.teamdev.licensing.PhoneNumber],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.ClientEnvironment', proto.teamdev.licensing.c.evaluation.ClientEnvironment],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseRevoked', proto.teamdev.licensing.c.project.ProjectLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.company.CreateCompanyWideLicense', proto.teamdev.licensing.c.company.CreateCompanyWideLicense],
  ['type.teamdev.com/teamdev.licensing.importado.ImportPersonalLicense', proto.teamdev.licensing.importado.ImportPersonalLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotStartRequestVerification', proto.teamdev.licensing.c.opensource.CannotStartRequestVerification],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluation', proto.teamdev.licensing.c.evaluation.Evaluation],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneration', proto.teamdev.licensing.c.generation.LicenseGeneration],
  ['type.teamdev.com/teamdev.licensing.DailyStatsReportId', proto.teamdev.licensing.DailyStatsReportId],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.StartSampleDataGeneration', proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.CannotRevokeUnknownSingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.CannotRevokeUnknownSingleDevSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseExpired', proto.teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseExpired],
  ['type.teamdev.com/teamdev.licensing.c.academic.GrantAcademicLicense', proto.teamdev.licensing.c.academic.GrantAcademicLicense],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseCreated', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseCreated],
  ['type.teamdev.com/teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest', proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest],
  ['type.teamdev.com/teamdev.licensing.c.academic.RefuseAcademicLicenseRequest', proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest],
  ['type.teamdev.com/teamdev.licensing.DemoLicenseId', proto.teamdev.licensing.DemoLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductAlreadyExists', proto.teamdev.licensing.c.product.ProductAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequest', proto.teamdev.licensing.c.academic.AcademicLicenseRequest],
  ['type.teamdev.com/teamdev.licensing.c.company.CannotRevokeUnknownCompanyWideLicense', proto.teamdev.licensing.c.company.CannotRevokeUnknownCompanyWideLicense],
  ['type.teamdev.com/teamdev.licensing.c.singledev.CannotRevokeUnknownSingleDevLicense', proto.teamdev.licensing.c.singledev.CannotRevokeUnknownSingleDevLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired', proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseAlreadyExpired', proto.teamdev.licensing.c.demo.DemoLicenseAlreadyExpired],
  ['type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData', proto.teamdev.licensing.importado.CommercialLicenseData],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.Version', proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version],
  ['type.teamdev.com/teamdev.licensing.c.singledev.RevokeSingleDevLicense', proto.teamdev.licensing.c.singledev.RevokeSingleDevLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified', proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified],
  ['type.teamdev.com/teamdev.users.c.CreateUser', proto.teamdev.users.c.CreateUser],
  ['type.teamdev.com/teamdev.sales.c.OnlinePurchaseRefunded', proto.teamdev.sales.c.OnlinePurchaseRefunded],
  ['type.teamdev.com/teamdev.licensing.c.generation.GenerateLicenseArtifact', proto.teamdev.licensing.c.generation.GenerateLicenseArtifact],
  ['type.teamdev.com/teamdev.licensing.CommercialLicenseRevocationReason', proto.teamdev.licensing.CommercialLicenseRevocationReason],
  ['type.teamdev.com/teamdev.licensing.ProjectBinding', proto.teamdev.licensing.ProjectBinding],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotRefuseRequest', proto.teamdev.licensing.c.academic.CannotRefuseRequest],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.CannotMarkExtendedEvaluationLicenseAsExpired', proto.teamdev.licensing.c.evaluation.CannotMarkExtendedEvaluationLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.c.academic.RequestAcademicLicense', proto.teamdev.licensing.c.academic.RequestAcademicLicense],
  ['type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy', proto.teamdev.licensing.c.product.LicensingPolicy],
  ['type.teamdev.com/teamdev.licensing.importado.ProjectLicenseImported', proto.teamdev.licensing.importado.ProjectLicenseImported],
  ['type.teamdev.com/teamdev.users.c.UserDeleted', proto.teamdev.users.c.UserDeleted],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestRefused', proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseAlreadyRevoked', proto.teamdev.licensing.c.demo.DemoLicenseAlreadyRevoked],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CannotRevokeUnknownCompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.CannotRevokeUnknownCompanyWideSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleLicensesCreated', proto.teamdev.licensing.c.sampledata.SampleLicensesCreated],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneratorRequest', proto.teamdev.licensing.c.generation.LicenseGeneratorRequest],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductsCreated', proto.teamdev.licensing.c.sampledata.SampleProductsCreated],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.AcceptEvaluationTerms', proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductLogo', proto.teamdev.licensing.c.product.ProductLogo],
  ['type.teamdev.com/teamdev.licensing.c.academic.RequestRefusalReason', proto.teamdev.licensing.c.academic.RequestRefusalReason],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Academic', proto.teamdev.licensing.c.evaluation.Evaluator.Academic],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.c.opensource.LicenseAgreementNotAccepted', proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted],
  ['type.teamdev.com/teamdev.licensing.c.product.MainstreamVersionAssigned', proto.teamdev.licensing.c.product.MainstreamVersionAssigned],
  ['type.teamdev.com/teamdev.licensing.importado.ProductData', proto.teamdev.licensing.importado.ProductData],
  ['type.teamdev.com/teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct', proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct],
  ['type.teamdev.com/teamdev.licensing.LicensingTarget', proto.teamdev.licensing.LicensingTarget],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.demo.RequestDemoLicense', proto.teamdev.licensing.c.demo.RequestDemoLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationCreated', proto.teamdev.licensing.c.evaluation.EvaluationCreated],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyExists', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseCreated', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseCreated],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicense', proto.teamdev.licensing.c.demo.DemoLicense],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseGranted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId', proto.teamdev.licensing.ProjectSourceCodeLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.product.CreateProductVersion', proto.teamdev.licensing.c.product.CreateProductVersion],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleProducts', proto.teamdev.licensing.c.sampledata.AddSampleProducts],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.CannotRevokeUnknownProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.CannotRevokeUnknownProjectSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotMarkRequestAsVerified', proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductVersionDropDownList', proto.teamdev.licensing.q.product.ProductVersionDropDownList],
  ['type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId', proto.teamdev.licensing.SingleDevSourceCodeLicenseId],
  ['type.teamdev.com/teamdev.licensing.q.report.DailyStatsReport', proto.teamdev.licensing.q.report.DailyStatsReport],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductView', proto.teamdev.licensing.q.product.ProductView],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicense', proto.teamdev.licensing.c.personal.PersonalLicense],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicense', proto.teamdev.licensing.c.company.CompanyWideLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired],
  ['type.teamdev.com/teamdev.users.q.UserView', proto.teamdev.users.q.UserView],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified', proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified],
  ['type.teamdev.com/teamdev.licensing.importado.v1.Product', proto.teamdev.licensing.importado.v1.Product],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired', proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.CannotOperateOnRefusedEvaluation', proto.teamdev.licensing.c.evaluation.CannotOperateOnRefusedEvaluation],
  ['type.teamdev.com/teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification', proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicense', proto.teamdev.licensing.c.opensource.OpenSourceLicense],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseRevoked', proto.teamdev.licensing.c.evaluation.EvaluationLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.academic.GrantExtendedAcademicLicense', proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductCreated', proto.teamdev.licensing.c.product.ProductCreated],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseIssued', proto.teamdev.licensing.c.company.CompanyWideLicenseIssued],
  ['type.teamdev.com/teamdev.users.c.UserAlreadyExists', proto.teamdev.users.c.UserAlreadyExists],
  ['type.teamdev.com/teamdev.sales.c.ExtendedEvaluationGranted', proto.teamdev.sales.c.ExtendedEvaluationGranted],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed', proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersion', proto.teamdev.licensing.c.product.ProductVersion],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified],
  ['type.teamdev.com/teamdev.licensing.q.report.EvaluationStats', proto.teamdev.licensing.q.report.EvaluationStats],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted],
  ['type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId', proto.teamdev.licensing.CompanyWideSourceCodeLicenseId],
  ['type.teamdev.com/teamdev.licensing.ProductListId', proto.teamdev.licensing.ProductListId],
  ['type.teamdev.com/teamdev.licensing.c.academic.LicenseAgreementNotAccepted', proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted],
  ['type.teamdev.com/teamdev.licensing.q.product.VersionItem', proto.teamdev.licensing.q.product.VersionItem],
  ['type.teamdev.com/teamdev.sales.c.ProjectLicenseGranted', proto.teamdev.sales.c.ProjectLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.c.product.CannotUpdateUnknownProductVersion', proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationTermsAccepted', proto.teamdev.licensing.c.evaluation.EvaluationTermsAccepted],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductVersionsAdded', proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded],
  ['type.teamdev.com/teamdev.licensing.LicensingTarget.ByMajorVersion', proto.teamdev.licensing.LicensingTarget.ByMajorVersion],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicense', proto.teamdev.licensing.c.evaluation.EvaluationLicense],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.company.RevokeCompanyWideLicense', proto.teamdev.licensing.c.company.RevokeCompanyWideLicense],
  ['type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats', proto.teamdev.licensing.q.report.NonCommercialStats],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyExists', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerified', proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified],
  ['type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem', proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem],
  ['type.teamdev.com/teamdev.sales.c.ProjectSourceCodeLicenseGranted', proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted],
  ['type.teamdev.com/teamdev.licensing.NonCommercialLicenseId', proto.teamdev.licensing.NonCommercialLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotStartRequestVerification', proto.teamdev.licensing.c.academic.CannotStartRequestVerification],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.MacOsVersion', proto.teamdev.licensing.c.evaluation.MacOsVersion],
  ['type.teamdev.com/teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued', proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseArtifactGenerated', proto.teamdev.licensing.c.generation.LicenseArtifactGenerated],
  ['type.teamdev.com/teamdev.licensing.c.opensource.ExtensionRequestReason', proto.teamdev.licensing.c.opensource.ExtensionRequestReason],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExpired', proto.teamdev.licensing.c.academic.AcademicLicenseExpired],
  ['type.teamdev.com/teamdev.licensing.importado.SingleDevLicenseImported', proto.teamdev.licensing.importado.SingleDevLicenseImported],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator', proto.teamdev.licensing.c.evaluation.Evaluator],
  ['type.teamdev.com/teamdev.licensing.EvaluationLicenseRevocationReason', proto.teamdev.licensing.EvaluationLicenseRevocationReason],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseIssued', proto.teamdev.licensing.c.evaluation.EvaluationLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.RevokeCompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.RevokeCompanyWideSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.ProductVersionId', proto.teamdev.licensing.ProductVersionId],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotRequestExtendedLicense', proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense],
  ['type.teamdev.com/teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest', proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest],
  ['type.teamdev.com/teamdev.licensing.ProductId', proto.teamdev.licensing.ProductId],
  ['type.teamdev.com/teamdev.licensing.q.report.CommercialStats', proto.teamdev.licensing.q.report.CommercialStats],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseRevoked', proto.teamdev.licensing.c.singledev.SingleDevLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired', proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.ProjectLicenseId', proto.teamdev.licensing.ProjectLicenseId],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseIssued', proto.teamdev.licensing.c.personal.PersonalLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequested', proto.teamdev.licensing.c.academic.AcademicLicenseRequested],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem', proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester', proto.teamdev.licensing.c.academic.AcademicLicenseRequester],
  ['type.teamdev.com/teamdev.licensing.c.product.UpdateProductDetails', proto.teamdev.licensing.c.product.UpdateProductDetails],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceProjectProfile', proto.teamdev.licensing.c.opensource.OpenSourceProjectProfile],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted', proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted],
  ['type.teamdev.com/teamdev.licensing.LicensingTarget.ByTime', proto.teamdev.licensing.LicensingTarget.ByTime],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseRevoked', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseRevoked],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued],
  ['type.teamdev.com/teamdev.licensing.importado.ImportSingleDevSourceCodeLicense', proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense],
  ['type.teamdev.com/teamdev.licensing.c.demo.MarkDemoLicenseAsExpired', proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationType', proto.teamdev.licensing.c.evaluation.EvaluationType],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseType', proto.teamdev.licensing.c.generation.LicenseType],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType', proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.LicenseType],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework', proto.teamdev.licensing.c.evaluation.JavaEnvironment.UiFramework],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequest.Status', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequest.Status],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany', proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.PositionInCompany],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind', proto.teamdev.licensing.c.academic.AcademicLicenseRequester.RequesterKind],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicense.Status', proto.teamdev.licensing.c.opensource.OpenSourceLicense.Status],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluation.Status', proto.teamdev.licensing.c.evaluation.Evaluation.Status],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGeneration.Status', proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status', proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequest.Status', proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status],
  ['type.teamdev.com/teamdev.licensing.SampleDataGenerationId.Value', proto.teamdev.licensing.SampleDataGenerationId.Value],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize', proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.ProjectTeamSize],
  ['type.teamdev.com/teamdev.licensing.q.evaluation.EvaluationView.Status', proto.teamdev.licensing.q.evaluation.EvaluationView.Status],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriodEndReason', proto.teamdev.licensing.q.LicensedPeriodEndReason],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type', proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type],
  ['type.teamdev.com/teamdev.licensing.CreatedFrom', proto.teamdev.licensing.CreatedFrom],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status', proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status],
  ['type.teamdev.com/teamdev.licensing.q.commercial.CommercialLicenseView.Type', proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type],
  ['type.teamdev.com/teamdev.licensing.q.commercial.CommercialLicenseView.Status', proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status],
  ['type.teamdev.com/teamdev.licensing.importado.v1.LicenseType', proto.teamdev.licensing.importado.v1.LicenseType],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor', proto.teamdev.licensing.c.evaluation.JavaEnvironment.JdkVendor],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicense.Status', proto.teamdev.licensing.c.academic.AcademicLicense.Status],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriodStartReason', proto.teamdev.licensing.q.LicensedPeriodStartReason],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework', proto.teamdev.licensing.c.evaluation.DotNetEnvironment.UiFramework],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.WindowsVersion.Type', proto.teamdev.licensing.c.evaluation.WindowsVersion.Type],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneration.Status', proto.teamdev.licensing.c.generation.LicenseGeneration.Status]
]);

module.exports.parsers = new Map([
  ['type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy', proto.teamdev.licensing.c.product.LicensingPolicy.Parser],
  ['type.teamdev.com/teamdev.licensing.OpenSourceLicenseId', proto.teamdev.licensing.OpenSourceLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.EvaluationId', proto.teamdev.licensing.EvaluationId.Parser],
  ['type.teamdev.com/teamdev.licensing.ProductVersionId', proto.teamdev.licensing.ProductVersionId.Parser],
  ['type.teamdev.com/teamdev.licensing.DailyStatsReportId', proto.teamdev.licensing.DailyStatsReportId.Parser],
  ['type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId', proto.teamdev.licensing.ProjectSourceCodeLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.ProductId', proto.teamdev.licensing.ProductId.Parser],
  ['type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId', proto.teamdev.licensing.SingleDevSourceCodeLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.ProjectLicenseId', proto.teamdev.licensing.ProjectLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.PersonalLicenseId', proto.teamdev.licensing.PersonalLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.DemoLicenseId', proto.teamdev.licensing.DemoLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.NonCommercialLicenseRequestId', proto.teamdev.licensing.NonCommercialLicenseRequestId.Parser],
  ['type.teamdev.com/teamdev.licensing.CommercialLicenseViewId', proto.teamdev.licensing.CommercialLicenseViewId.Parser],
  ['type.teamdev.com/teamdev.licensing.SingleDevLicenseId', proto.teamdev.licensing.SingleDevLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId', proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.ProductListId', proto.teamdev.licensing.ProductListId.Parser],
  ['type.teamdev.com/teamdev.licensing.NonCommercialLicenseId', proto.teamdev.licensing.NonCommercialLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.SampleDataGenerationId', proto.teamdev.licensing.SampleDataGenerationId.Parser],
  ['type.teamdev.com/teamdev.licensing.AcademicLicenseId', proto.teamdev.licensing.AcademicLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.CompanyWideLicenseId', proto.teamdev.licensing.CompanyWideLicenseId.Parser],
  ['type.teamdev.com/teamdev.licensing.LicenseGenerationId', proto.teamdev.licensing.LicenseGenerationId.Parser],
  ['type.teamdev.com/teamdev.licensing.q.report.EvaluationStats', proto.teamdev.licensing.q.report.EvaluationStats.Parser],
  ['type.teamdev.com/teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem', proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.Parser],
  ['type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem', proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.Parser],
  ['type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem', proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.Parser],
  ['type.teamdev.com/teamdev.licensing.q.report.DailyStatsReport', proto.teamdev.licensing.q.report.DailyStatsReport.Parser],
  ['type.teamdev.com/teamdev.licensing.q.report.CommercialStats', proto.teamdev.licensing.q.report.CommercialStats.Parser],
  ['type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats', proto.teamdev.licensing.q.report.NonCommercialStats.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseGranted', proto.teamdev.licensing.c.demo.DemoLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseExpired', proto.teamdev.licensing.c.demo.DemoLicenseExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseRequested', proto.teamdev.licensing.c.demo.DemoLicenseRequested.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseRevoked', proto.teamdev.licensing.c.demo.DemoLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseIssued', proto.teamdev.licensing.c.demo.DemoLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyExpired', proto.teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationAlreadyExists', proto.teamdev.licensing.c.evaluation.EvaluationAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.CannotOperateOnRefusedEvaluation', proto.teamdev.licensing.c.evaluation.CannotOperateOnRefusedEvaluation.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyRevoked', proto.teamdev.licensing.c.evaluation.EvaluationLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.CannotMarkExtendedEvaluationLicenseAsExpired', proto.teamdev.licensing.c.evaluation.CannotMarkExtendedEvaluationLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.CannotOperateOnNewEvaluation', proto.teamdev.licensing.c.evaluation.CannotOperateOnNewEvaluation.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseRevoked', proto.teamdev.licensing.c.company.CompanyWideLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseCreated', proto.teamdev.licensing.c.company.CompanyWideLicenseCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseIssued', proto.teamdev.licensing.c.company.CompanyWideLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ProductVersionImported', proto.teamdev.licensing.importado.ProductVersionImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.CompanyWideLicenseImported', proto.teamdev.licensing.importado.CompanyWideLicenseImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.SingleDevSourceCodeLicenseImported', proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported', proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.SingleDevLicenseImported', proto.teamdev.licensing.importado.SingleDevLicenseImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ProductImported', proto.teamdev.licensing.importado.ProductImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ProjectLicenseImported', proto.teamdev.licensing.importado.ProjectLicenseImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ProjectSourceCodeLicenseImported', proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.PersonalLicenseImported', proto.teamdev.licensing.importado.PersonalLicenseImported.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.UpdateProductDetails', proto.teamdev.licensing.c.product.UpdateProductDetails.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.UpdateProductVersionLicensing', proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.CreateProduct', proto.teamdev.licensing.c.product.CreateProduct.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.AssignMainstreamVersion', proto.teamdev.licensing.c.product.AssignMainstreamVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.CreateProductVersion', proto.teamdev.licensing.c.product.CreateProductVersion.Parser],
  ['type.teamdev.com/teamdev.users.c.CreateUser', proto.teamdev.users.c.CreateUser.Parser],
  ['type.teamdev.com/teamdev.users.c.DeleteUser', proto.teamdev.users.c.DeleteUser.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersion', proto.teamdev.licensing.c.product.ProductVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.LicenseArtifact', proto.teamdev.licensing.LicenseArtifact.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseAlreadyExists', proto.teamdev.licensing.c.company.CompanyWideLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicenseAlreadyRevoked', proto.teamdev.licensing.c.company.CompanyWideLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CannotRevokeUnknownCompanyWideLicense', proto.teamdev.licensing.c.company.CannotRevokeUnknownCompanyWideLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification', proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.GrantOpenSourceLicense', proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest', proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest', proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RevokeOpenSourceLicense', proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest', proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense', proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch', proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension', proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RequestOpenSourceLicense', proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification', proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified', proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriodEnd', proto.teamdev.licensing.q.LicensedPeriodEnd.Parser],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriod', proto.teamdev.licensing.q.LicensedPeriod.Parser],
  ['type.teamdev.com/teamdev.licensing.q.LicensedPeriodStart', proto.teamdev.licensing.q.LicensedPeriodStart.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ProductData', proto.teamdev.licensing.importado.ProductData.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotStartRequestVerification', proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotRequestExtendedLicense', proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkRequestAsFailed', proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkRequestAsVerified', proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotRefuseRequest', proto.teamdev.licensing.c.opensource.CannotRefuseRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired', proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.LicenseAgreementNotAccepted', proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense', proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseAlreadyExists', proto.teamdev.licensing.c.project.ProjectLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.CannotRevokeUnknownProjectLicense', proto.teamdev.licensing.c.project.CannotRevokeUnknownProjectLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseAlreadyRevoked', proto.teamdev.licensing.c.project.ProjectLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseIssued', proto.teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseExpired', proto.teamdev.licensing.c.evaluation.EvaluationLicenseExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationCreated', proto.teamdev.licensing.c.evaluation.EvaluationCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationTermsAccepted', proto.teamdev.licensing.c.evaluation.EvaluationTermsAccepted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseIssued', proto.teamdev.licensing.c.evaluation.EvaluationLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseGranted', proto.teamdev.licensing.c.evaluation.EvaluationLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicenseRevoked', proto.teamdev.licensing.c.evaluation.EvaluationLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationExtended', proto.teamdev.licensing.c.evaluation.EvaluationExtended.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseExpired', proto.teamdev.licensing.c.evaluation.ExtendedEvaluationLicenseExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationRefused', proto.teamdev.licensing.c.evaluation.EvaluationRefused.Parser],
  ['type.teamdev.com/teamdev.users.c.UserCreated', proto.teamdev.users.c.UserCreated.Parser],
  ['type.teamdev.com/teamdev.users.c.UserDeleted', proto.teamdev.users.c.UserDeleted.Parser],
  ['type.teamdev.com/teamdev.users.c.User', proto.teamdev.users.c.User.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleProductVersions', proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleLicenses', proto.teamdev.licensing.c.sampledata.AddSampleLicenses.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleMainstreamVersions', proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.StartSampleDataGeneration', proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleProducts', proto.teamdev.licensing.c.sampledata.AddSampleProducts.Parser],
  ['type.teamdev.com/teamdev.users.RemoteIdentityProviderId', proto.teamdev.users.RemoteIdentityProviderId.Parser],
  ['type.teamdev.com/teamdev.licensing.q.commercial.CommercialLicenseView', proto.teamdev.licensing.q.commercial.CommercialLicenseView.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator', proto.teamdev.licensing.c.evaluation.Evaluator.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion', proto.teamdev.licensing.c.evaluation.DotNetEnvironment.VisualStudioVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Commercial', proto.teamdev.licensing.c.evaluation.Evaluator.Commercial.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment.Version', proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Version.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationRefusalReason', proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.Academic', proto.teamdev.licensing.c.evaluation.Evaluator.Academic.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment.Version', proto.teamdev.licensing.c.evaluation.JavaEnvironment.Version.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.WindowsVersion', proto.teamdev.licensing.c.evaluation.WindowsVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.LinuxDistribution', proto.teamdev.licensing.c.evaluation.LinuxDistribution.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.JavaEnvironment', proto.teamdev.licensing.c.evaluation.JavaEnvironment.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator.OpenSource', proto.teamdev.licensing.c.evaluation.Evaluator.OpenSource.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.ClientEnvironment', proto.teamdev.licensing.c.evaluation.ClientEnvironment.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.MacOsVersion', proto.teamdev.licensing.c.evaluation.MacOsVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.DotNetEnvironment', proto.teamdev.licensing.c.evaluation.DotNetEnvironment.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyRevoked', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyExists', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.CannotRevokeUnknownProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.CannotRevokeUnknownProjectSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.CannotOperateOnNewDemoLicense', proto.teamdev.licensing.c.demo.CannotOperateOnNewDemoLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseAlreadyExpired', proto.teamdev.licensing.c.demo.DemoLicenseAlreadyExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseAlreadyExists', proto.teamdev.licensing.c.demo.DemoLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicenseAlreadyRevoked', proto.teamdev.licensing.c.demo.DemoLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequest', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicense', proto.teamdev.licensing.c.opensource.OpenSourceLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.DemoLicense', proto.teamdev.licensing.c.demo.DemoLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.ProjectBinding', proto.teamdev.licensing.ProjectBinding.Parser],
  ['type.teamdev.com/teamdev.licensing.FullyQualifiedClassName', proto.teamdev.licensing.FullyQualifiedClassName.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.EvaluationProjectProfile', proto.teamdev.licensing.c.generation.EvaluationProjectProfile.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.AcademicProjectProfile', proto.teamdev.licensing.c.generation.AcademicProjectProfile.Parser],
  ['type.teamdev.com/teamdev.licensing.q.demo.DemoLicenseView', proto.teamdev.licensing.q.demo.DemoLicenseView.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductsCreated', proto.teamdev.licensing.c.sampledata.SampleProductsCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGenerationCompleted', proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned', proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductVersionsAdded', proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned', proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleVersionsCreated', proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleLicensesCreated', proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded', proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleLicensesAdded', proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductsAdded', proto.teamdev.licensing.c.sampledata.SampleProductsAdded.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CompanyWideLicense', proto.teamdev.licensing.c.company.CompanyWideLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.LicensingTarget.ByTime', proto.teamdev.licensing.LicensingTarget.ByTime.Parser],
  ['type.teamdev.com/teamdev.licensing.LicensingTarget.ByMajorVersion', proto.teamdev.licensing.LicensingTarget.ByMajorVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.LicensingTarget', proto.teamdev.licensing.LicensingTarget.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseArtifactGenerationFailed', proto.teamdev.licensing.c.generation.LicenseArtifactGenerationFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseArtifactGenerated', proto.teamdev.licensing.c.generation.LicenseArtifactGenerated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicense', proto.teamdev.licensing.c.singledev.SingleDevLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionDetails', proto.teamdev.licensing.c.product.ProductVersionDetails.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.VersionDescription', proto.teamdev.licensing.c.product.VersionDescription.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductLogo', proto.teamdev.licensing.c.product.ProductLogo.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductName', proto.teamdev.licensing.c.product.ProductName.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.VersionName', proto.teamdev.licensing.c.product.VersionName.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductDetails', proto.teamdev.licensing.c.product.ProductDetails.Parser],
  ['type.teamdev.com/teamdev.users.c.UserAlreadyExists', proto.teamdev.users.c.UserAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.sales.c.ExtendedEvaluationGranted', proto.teamdev.sales.c.ExtendedEvaluationGranted.Parser],
  ['type.teamdev.com/teamdev.sales.c.SingleDevSourceCodeLicenseGranted', proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.sales.c.OnlinePurchaseRefunded', proto.teamdev.sales.c.OnlinePurchaseRefunded.Parser],
  ['type.teamdev.com/teamdev.sales.c.CompanyWideLicenseGranted', proto.teamdev.sales.c.CompanyWideLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.sales.c.PersonalLicenseGranted', proto.teamdev.sales.c.PersonalLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.sales.c.ProjectSourceCodeLicenseGranted', proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.sales.c.SingleDevLicenseGranted', proto.teamdev.sales.c.SingleDevLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.sales.c.ProjectLicenseGranted', proto.teamdev.sales.c.ProjectLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.sales.c.CompanyWideSourceCodeLicenseGranted', proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.users.UserDetails', proto.teamdev.users.UserDetails.Parser],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest', proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.RevokePersonalLicense', proto.teamdev.licensing.c.personal.RevokePersonalLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.CreatePersonalLicense', proto.teamdev.licensing.c.personal.CreatePersonalLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseCreated', proto.teamdev.licensing.c.project.ProjectLicenseCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseIssued', proto.teamdev.licensing.c.project.ProjectLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicenseRevoked', proto.teamdev.licensing.c.project.ProjectLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.CreateSingleDevLicense', proto.teamdev.licensing.c.singledev.CreateSingleDevLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.RevokeSingleDevLicense', proto.teamdev.licensing.c.singledev.RevokeSingleDevLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotGrantAcademicLicense', proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotRefuseRequest', proto.teamdev.licensing.c.academic.CannotRefuseRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotMarkRequestAsVerified', proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotMarkLicenseAsExpired', proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotMarkRequestAsFailed', proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotRequestExtendedLicense', proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyExists', proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.LicenseAgreementNotAccepted', proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CannotStartRequestVerification', proto.teamdev.licensing.c.academic.CannotStartRequestVerification.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified', proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.Product', proto.teamdev.licensing.c.product.Product.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductDetailsUpdated', proto.teamdev.licensing.c.product.ProductDetailsUpdated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionCreated', proto.teamdev.licensing.c.product.ProductVersionCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionLicensingUpdated', proto.teamdev.licensing.c.product.ProductVersionLicensingUpdated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.MainstreamVersionAssigned', proto.teamdev.licensing.c.product.MainstreamVersionAssigned.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductCreated', proto.teamdev.licensing.c.product.ProductCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseRevoked', proto.teamdev.licensing.c.personal.PersonalLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseCreated', proto.teamdev.licensing.c.personal.PersonalLicenseCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseIssued', proto.teamdev.licensing.c.personal.PersonalLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGeneration', proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Parser],
  ['type.teamdev.com/teamdev.licensing.OtherReasonChoice', proto.teamdev.licensing.OtherReasonChoice.Parser],
  ['type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason', proto.teamdev.licensing.LicenseRequestVerificationFailureReason.Parser],
  ['type.teamdev.com/teamdev.licensing.CommercialLicenseRevocationReason', proto.teamdev.licensing.CommercialLicenseRevocationReason.Parser],
  ['type.teamdev.com/teamdev.licensing.EvaluationLicenseRevocationReason', proto.teamdev.licensing.EvaluationLicenseRevocationReason.Parser],
  ['type.teamdev.com/teamdev.licensing.PhoneNumber', proto.teamdev.licensing.PhoneNumber.Parser],
  ['type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason', proto.teamdev.licensing.NonCommercialLicenseRevocationReason.Parser],
  ['type.teamdev.com/teamdev.licensing.q.evaluation.EvaluationView', proto.teamdev.licensing.q.evaluation.EvaluationView.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.RequestDemoLicense', proto.teamdev.licensing.c.demo.RequestDemoLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.RevokeDemoLicense', proto.teamdev.licensing.c.demo.RevokeDemoLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.demo.MarkDemoLicenseAsExpired', proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseAlreadyRevoked', proto.teamdev.licensing.c.singledev.SingleDevLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.CannotRevokeUnknownSingleDevLicense', proto.teamdev.licensing.c.singledev.CannotRevokeUnknownSingleDevLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseAlreadyExists', proto.teamdev.licensing.c.singledev.SingleDevLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductAlreadyExists', proto.teamdev.licensing.c.product.ProductAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.CannotUpdateUnknownProductVersion', proto.teamdev.licensing.c.product.CannotUpdateUnknownProductVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.ProductVersionAlreadyExists', proto.teamdev.licensing.c.product.ProductVersionAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct', proto.teamdev.licensing.c.product.MainstreamVersionDoesNotMatchProduct.Parser],
  ['type.teamdev.com/teamdev.licensing.c.product.CannotUpdateUnknownProduct', proto.teamdev.licensing.c.product.CannotUpdateUnknownProduct.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.v1.Product', proto.teamdev.licensing.importado.v1.Product.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.v1.License', proto.teamdev.licensing.importado.v1.License.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.v1.Products', proto.teamdev.licensing.importado.v1.Products.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.v1.Licenses', proto.teamdev.licensing.importado.v1.Licenses.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ProductVersionData', proto.teamdev.licensing.importado.ProductVersionData.Parser],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductDropDownList', proto.teamdev.licensing.q.product.ProductDropDownList.Parser],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem', proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.Parser],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductVersionDropDownList', proto.teamdev.licensing.q.product.ProductVersionDropDownList.Parser],
  ['type.teamdev.com/teamdev.licensing.q.product.VersionItem', proto.teamdev.licensing.q.product.VersionItem.Parser],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductView', proto.teamdev.licensing.q.product.ProductView.Parser],
  ['type.teamdev.com/teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem', proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.Evaluation', proto.teamdev.licensing.c.evaluation.Evaluation.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicense', proto.teamdev.licensing.c.evaluation.EvaluationLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.RevokeProjectLicense', proto.teamdev.licensing.c.project.RevokeProjectLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.CreateProjectLicense', proto.teamdev.licensing.c.project.CreateProjectLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest', proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseGranted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch', proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseIssued', proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExpired', proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted', proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRevoked', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequested', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued', proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted', proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked', proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.users.q.UserView', proto.teamdev.users.q.UserView.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceProjectProfile', proto.teamdev.licensing.c.opensource.OpenSourceProjectProfile.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester', proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.RequestRefusalReason', proto.teamdev.licensing.c.opensource.RequestRefusalReason.Parser],
  ['type.teamdev.com/teamdev.licensing.c.opensource.ExtensionRequestReason', proto.teamdev.licensing.c.opensource.ExtensionRequestReason.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneration', proto.teamdev.licensing.c.generation.LicenseGeneration.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneratorRequest', proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseView', proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyRevoked', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.CannotRevokeUnknownSingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.CannotRevokeUnknownSingleDevSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyExists', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicense', proto.teamdev.licensing.c.personal.PersonalLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseTypeIsUnsupported', proto.teamdev.licensing.c.generation.LicenseTypeIsUnsupported.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.LicenseGenerationAlreadyInitialized', proto.teamdev.licensing.c.generation.LicenseGenerationAlreadyInitialized.Parser],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.OpenSourceProjectProfile', proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile.Parser],
  ['type.teamdev.com/teamdev.licensing.q.noncommercial.AcademicProjectProfile', proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile.Parser],
  ['type.teamdev.com/teamdev.licensing.c.generation.GenerateLicenseArtifact', proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyExists', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyRevoked', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CannotRevokeUnknownCompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.CannotRevokeUnknownCompanyWideSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.RevokeProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.RevokeProjectSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense', proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester', proto.teamdev.licensing.c.academic.AcademicLicenseRequester.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AssurancePromise', proto.teamdev.licensing.c.academic.AssurancePromise.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.RequestRefusalReason', proto.teamdev.licensing.c.academic.RequestRefusalReason.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.ExtensionRequestReason', proto.teamdev.licensing.c.academic.ExtensionRequestReason.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.StudentAssurancePromise', proto.teamdev.licensing.c.academic.StudentAssurancePromise.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.ResearchAssurancePromise', proto.teamdev.licensing.c.academic.ResearchAssurancePromise.Parser],
  ['type.teamdev.com/teamdev.licensing.c.project.ProjectLicense', proto.teamdev.licensing.c.project.ProjectLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExpired', proto.teamdev.licensing.c.academic.AcademicLicenseExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequested', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRevoked', proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted', proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestRefused', proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseGranted', proto.teamdev.licensing.c.academic.AcademicLicenseGranted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseIssued', proto.teamdev.licensing.c.academic.AcademicLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequested', proto.teamdev.licensing.c.academic.AcademicLicenseRequested.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed', proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted', proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest', proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerified', proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted', proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch', proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified', proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued', proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseIssued', proto.teamdev.licensing.c.singledev.SingleDevLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseCreated', proto.teamdev.licensing.c.singledev.SingleDevLicenseCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.SingleDevLicenseRevoked', proto.teamdev.licensing.c.singledev.SingleDevLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.RevokeCompanyWideLicense', proto.teamdev.licensing.c.company.RevokeCompanyWideLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.CreateCompanyWideLicense', proto.teamdev.licensing.c.company.CreateCompanyWideLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified', proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.RevokeAcademicLicense', proto.teamdev.licensing.c.academic.RevokeAcademicLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification', proto.teamdev.licensing.c.academic.StartAcademicLicenseRequestVerification.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.RequestAcademicLicense', proto.teamdev.licensing.c.academic.RequestAcademicLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest', proto.teamdev.licensing.c.academic.RefuseAcademicLicenseExtensionRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed', proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired', proto.teamdev.licensing.c.academic.MarkAcademicLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch', proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification', proto.teamdev.licensing.c.academic.StartAcademicLicenseExtensionRequestVerification.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.GrantExtendedAcademicLicense', proto.teamdev.licensing.c.academic.GrantExtendedAcademicLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.GrantAcademicLicense', proto.teamdev.licensing.c.academic.GrantAcademicLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest', proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.RefuseAcademicLicenseRequest', proto.teamdev.licensing.c.academic.RefuseAcademicLicenseRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified', proto.teamdev.licensing.c.academic.MarkAcademicLicenseExtensionRequestAsVerified.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed', proto.teamdev.licensing.c.academic.MarkAcademicLicenseRequestAsFailed.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.RequestAcademicLicenseExtension', proto.teamdev.licensing.c.academic.RequestAcademicLicenseExtension.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseIssued', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseCreated', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseRevoked', proto.teamdev.licensing.c.singledev.sourcecode.SingleDevSourceCodeLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseAlreadyRevoked', proto.teamdev.licensing.c.personal.PersonalLicenseAlreadyRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.CannotRevokeUnknownPersonalLicense', proto.teamdev.licensing.c.personal.CannotRevokeUnknownPersonalLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.personal.PersonalLicenseAlreadyExists', proto.teamdev.licensing.c.personal.PersonalLicenseAlreadyExists.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.AcceptEvaluationTerms', proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired', proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.RequestEvaluation', proto.teamdev.licensing.c.evaluation.RequestEvaluation.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.RevokeEvaluationLicense', proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired', proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.Parser],
  ['type.teamdev.com/teamdev.licensing.commercial.Client', proto.teamdev.licensing.commercial.Client.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData', proto.teamdev.licensing.importado.CommercialLicenseData.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.RevokeCompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.RevokeCompanyWideSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense', proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicense', proto.teamdev.licensing.c.academic.AcademicLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequest', proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportPersonalLicense', proto.teamdev.licensing.importado.ImportPersonalLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProduct', proto.teamdev.licensing.importado.ImportProduct.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportSingleDevSourceCodeLicense', proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense', proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProductVersion', proto.teamdev.licensing.importado.ImportProductVersion.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportCompanyWideLicense', proto.teamdev.licensing.importado.ImportCompanyWideLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProjectLicense', proto.teamdev.licensing.importado.ImportProjectLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportProjectSourceCodeLicense', proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.importado.ImportSingleDevLicense', proto.teamdev.licensing.importado.ImportSingleDevLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseIssued', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseIssued.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseRevoked', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseRevoked.Parser],
  ['type.teamdev.com/teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseCreated', proto.teamdev.licensing.c.company.sourcecode.CompanyWideSourceCodeLicenseCreated.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense.Parser],
  ['type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.RevokeSingleDevSourceCodeLicense', proto.teamdev.licensing.c.singledev.sourcecode.RevokeSingleDevSourceCodeLicense.Parser]
]);
