import { render, staticRenderFns } from "./AlertNoticeRow.vue?vue&type=template&id=2fc49118&scoped=true&"
import script from "./AlertNoticeRow.vue?vue&type=script&lang=ts&"
export * from "./AlertNoticeRow.vue?vue&type=script&lang=ts&"
import style0 from "./AlertNoticeRow.vue?vue&type=style&index=0&id=2fc49118&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc49118",
  null
  
)

export default component.exports