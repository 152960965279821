// source: teamdev/licensing/c/evaluation/evaluation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_license_artifact_pb = require('../../../../teamdev/licensing/license_artifact_pb.js');
goog.object.extend(proto, teamdev_licensing_license_artifact_pb);
var teamdev_licensing_c_evaluation_values_pb = require('../../../../teamdev/licensing/c/evaluation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_values_pb);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluation', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.Evaluation.Status', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.EvaluationLicense', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.Evaluation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.Evaluation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.Evaluation.displayName = 'proto.teamdev.licensing.c.evaluation.Evaluation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.EvaluationLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.EvaluationLicense.displayName = 'proto.teamdev.licensing.c.evaluation.EvaluationLicense';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.Evaluation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    whenCreated: (f = msg.getWhenCreated()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    license: (f = msg.getLicense()) && proto.teamdev.licensing.c.evaluation.EvaluationLicense.toObject(includeInstance, f),
    evaluator: (f = msg.getEvaluator()) && teamdev_licensing_c_evaluation_values_pb.Evaluator.toObject(includeInstance, f),
    clientEnvironment: (f = msg.getClientEnvironment()) && teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.toObject(includeInstance, f),
    whenRefused: (f = msg.getWhenRefused()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    refusalReason: (f = msg.getRefusalReason()) && teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.Evaluation;
  return proto.teamdev.licensing.c.evaluation.Evaluation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.EvaluationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenCreated(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.c.evaluation.EvaluationLicense;
      reader.readMessage(value,proto.teamdev.licensing.c.evaluation.EvaluationLicense.deserializeBinaryFromReader);
      msg.setLicense(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_evaluation_values_pb.Evaluator;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.Evaluator.deserializeBinaryFromReader);
      msg.setEvaluator(value);
      break;
    case 6:
      var value = new teamdev_licensing_c_evaluation_values_pb.ClientEnvironment;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.deserializeBinaryFromReader);
      msg.setClientEnvironment(value);
      break;
    case 7:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRefused(value);
      break;
    case 8:
      var value = new teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason.deserializeBinaryFromReader);
      msg.setRefusalReason(value);
      break;
    case 9:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.Evaluation.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.Evaluation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.Evaluation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getWhenCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getLicense();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.teamdev.licensing.c.evaluation.EvaluationLicense.serializeBinaryToWriter
    );
  }
  f = message.getEvaluator();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_evaluation_values_pb.Evaluator.serializeBinaryToWriter
    );
  }
  f = message.getClientEnvironment();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getWhenRefused();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRefusalReason();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.Status = {
  ES_UNDEFINED: 0,
  CREATED: 1,
  REFUSED: 2,
  LICENSE_ISSUED: 3,
  LICENSE_EXPIRED: 4,
  EXTENDED: 5,
  EXTENDED_LICENSE_ISSUED: 6,
  EXTENDED_LICENSE_EXPIRED: 7,
  LICENSE_REVOKED: 8
};

/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EvaluationType type = 2;
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationType}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getType = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.EvaluationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationType} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional spine.time.ZonedDateTime when_created = 3;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getWhenCreated = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 3));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setWhenCreated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.clearWhenCreated = function() {
  return this.setWhenCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.hasWhenCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EvaluationLicense license = 4;
 * @return {?proto.teamdev.licensing.c.evaluation.EvaluationLicense}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getLicense = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.EvaluationLicense} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.evaluation.EvaluationLicense, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.EvaluationLicense|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setLicense = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.clearLicense = function() {
  return this.setLicense(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.hasLicense = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Evaluator evaluator = 5;
 * @return {?proto.teamdev.licensing.c.evaluation.Evaluator}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getEvaluator = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.Evaluator} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.Evaluator, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.Evaluator|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setEvaluator = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.clearEvaluator = function() {
  return this.setEvaluator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.hasEvaluator = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ClientEnvironment client_environment = 6;
 * @return {?proto.teamdev.licensing.c.evaluation.ClientEnvironment}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getClientEnvironment = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.ClientEnvironment} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.ClientEnvironment, 6));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.ClientEnvironment|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setClientEnvironment = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.clearClientEnvironment = function() {
  return this.setClientEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.hasClientEnvironment = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional spine.time.ZonedDateTime when_refused = 7;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getWhenRefused = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 7));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setWhenRefused = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.clearWhenRefused = function() {
  return this.setWhenRefused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.hasWhenRefused = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EvaluationRefusalReason refusal_reason = 8;
 * @return {?proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getRefusalReason = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason, 8));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setRefusalReason = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.clearRefusalReason = function() {
  return this.setRefusalReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.hasRefusalReason = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Status status = 9;
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation.Status}
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.Evaluation.Status} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.Evaluation.Status} value
 * @return {!proto.teamdev.licensing.c.evaluation.Evaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.Evaluation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.EvaluationLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    mainstreamVersionId: (f = msg.getMainstreamVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    whenExpired: (f = msg.getWhenExpired()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    whenRevoked: (f = msg.getWhenRevoked()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    revocationReason: (f = msg.getRevocationReason()) && teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.toObject(includeInstance, f),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.EvaluationLicense;
  return proto.teamdev.licensing.c.evaluation.EvaluationLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setMainstreamVersionId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 4:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 5:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhenExpired(value);
      break;
    case 6:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRevoked(value);
      break;
    case 7:
      var value = new teamdev_licensing_values_pb.EvaluationLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setRevocationReason(value);
      break;
    case 8:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.EvaluationLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMainstreamVersionId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getWhenExpired();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getWhenRevoked();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRevocationReason();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId mainstream_version_id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getMainstreamVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setMainstreamVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearMainstreamVersionId = function() {
  return this.setMainstreamVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasMainstreamVersionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.LocalDate active_through = 3;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 3));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 4;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 4));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.LocalDate when_expired = 5;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getWhenExpired = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 5));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setWhenExpired = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearWhenExpired = function() {
  return this.setWhenExpired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasWhenExpired = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.time.ZonedDateTime when_revoked = 6;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getWhenRevoked = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 6));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setWhenRevoked = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearWhenRevoked = function() {
  return this.setWhenRevoked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasWhenRevoked = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional teamdev.licensing.EvaluationLicenseRevocationReason revocation_reason = 7;
 * @return {?proto.teamdev.licensing.EvaluationLicenseRevocationReason}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getRevocationReason = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.EvaluationLicenseRevocationReason, 7));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setRevocationReason = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearRevocationReason = function() {
  return this.setRevocationReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasRevocationReason = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 8;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 8));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.EvaluationLicense.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.evaluation);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.evaluation.Evaluation.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.Evaluation.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.Evaluation.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.Evaluation.Parser;
proto.teamdev.licensing.c.evaluation.Evaluation.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.Evaluation();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.type !== undefined) {
    if (obj.type !== null) {
      let value = proto.teamdev.licensing.c.evaluation.EvaluationType[obj.type];
      msg.setType(value);
    }
  }
  
  if (obj.whenCreated !== undefined) {
    if (obj.whenCreated === null) {
      msg.setWhenCreated(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenCreated);
      msg.setWhenCreated(value);
    }
  }
  
  if (obj.license !== undefined) {
    if (obj.license === null) {
      msg.setLicense(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicense').fromObject(obj.license);
      msg.setLicense(value);
    }
  }
  
  if (obj.evaluator !== undefined) {
    if (obj.evaluator === null) {
      msg.setEvaluator(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator').fromObject(obj.evaluator);
      msg.setEvaluator(value);
    }
  }
  
  if (obj.clientEnvironment !== undefined) {
    if (obj.clientEnvironment === null) {
      msg.setClientEnvironment(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.ClientEnvironment').fromObject(obj.clientEnvironment);
      msg.setClientEnvironment(value);
    }
  }
  
  if (obj.whenRefused !== undefined) {
    if (obj.whenRefused === null) {
      msg.setWhenRefused(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRefused);
      msg.setWhenRefused(value);
    }
  }
  
  if (obj.refusalReason !== undefined) {
    if (obj.refusalReason === null) {
      msg.setRefusalReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationRefusalReason').fromObject(obj.refusalReason);
      msg.setRefusalReason(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.c.evaluation.Evaluation.Status[obj.status];
      msg.setStatus(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.EvaluationLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.EvaluationLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.EvaluationLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.EvaluationLicense.Parser;
proto.teamdev.licensing.c.evaluation.EvaluationLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.EvaluationLicense();
  
  if (obj.mainstreamVersionId !== undefined) {
    if (obj.mainstreamVersionId === null) {
      msg.setMainstreamVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.mainstreamVersionId);
      msg.setMainstreamVersionId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.whenExpired !== undefined) {
    if (obj.whenExpired === null) {
      msg.setWhenExpired(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.whenExpired);
      msg.setWhenExpired(value);
    }
  }
  
  if (obj.whenRevoked !== undefined) {
    if (obj.whenRevoked === null) {
      msg.setWhenRevoked(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRevoked);
      msg.setWhenRevoked(value);
    }
  }
  
  if (obj.revocationReason !== undefined) {
    if (obj.revocationReason === null) {
      msg.setRevocationReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationLicenseRevocationReason').fromObject(obj.revocationReason);
      msg.setRevocationReason(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluation.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluation';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.EvaluationLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.Evaluation.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.Evaluation.Status';
};
