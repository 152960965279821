// source: teamdev/licensing/c/opensource/rejections.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CannotRefuseRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CannotStartRequestVerification', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.displayName = 'proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CannotStartRequestVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.displayName = 'proto.teamdev.licensing.c.opensource.CannotStartRequestVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.displayName = 'proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.displayName = 'proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CannotRefuseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CannotRefuseRequest.displayName = 'proto.teamdev.licensing.c.opensource.CannotRefuseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.displayName = 'proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.displayName = 'proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.displayName = 'proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted}
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted;
  return proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted}
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted} returns this
*/
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted} returns this
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CannotStartRequestVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CannotStartRequestVerification}
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CannotStartRequestVerification;
  return proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CannotStartRequestVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CannotStartRequestVerification}
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CannotStartRequestVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CannotStartRequestVerification} returns this
*/
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CannotStartRequestVerification} returns this
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified;
  return proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed;
  return proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CannotRefuseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CannotRefuseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CannotRefuseRequest}
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CannotRefuseRequest;
  return proto.teamdev.licensing.c.opensource.CannotRefuseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CannotRefuseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CannotRefuseRequest}
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CannotRefuseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CannotRefuseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CannotRefuseRequest} returns this
*/
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CannotRefuseRequest} returns this
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense}
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense;
  return proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense}
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense} returns this
*/
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense} returns this
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense;
  return proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired}
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired;
  return proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired}
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired} returns this
*/
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired} returns this
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.opensource);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.Parser;
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CannotStartRequestVerification();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.Parser;
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.Parser;
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.Parser;
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CannotRefuseRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CannotRefuseRequest.Parser;
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CannotRefuseRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.Parser;
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.Parser;
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.Parser;
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionAlreadyGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CannotStartRequestVerification.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CannotStartRequestVerification';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CannotRequestExtendedLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CannotRequestExtendedLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkRequestAsFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CannotMarkRequestAsVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkRequestAsVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestNotVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CannotRefuseRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CannotRefuseRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CannotMarkLicenseAsExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyExists';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.LicenseAgreementNotAccepted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.LicenseAgreementNotAccepted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAlreadyRevoked';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CannotGrantOpenSourceLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestNotVerified';
};
