// source: teamdev/licensing/c/generation/license_generation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_people_person_name_pb = require('spine-web/proto/spine/people/person_name_pb.js');
goog.object.extend(proto, spine_people_person_name_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_license_artifact_pb = require('../../../../teamdev/licensing/license_artifact_pb.js');
goog.object.extend(proto, teamdev_licensing_license_artifact_pb);
var teamdev_licensing_project_binding_pb = require('../../../../teamdev/licensing/project_binding_pb.js');
goog.object.extend(proto, teamdev_licensing_project_binding_pb);
var teamdev_licensing_c_academic_values_pb = require('../../../../teamdev/licensing/c/academic/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_values_pb);
var teamdev_licensing_client_pb = require('../../../../teamdev/licensing/client_pb.js');
goog.object.extend(proto, teamdev_licensing_client_pb);
var teamdev_licensing_c_opensource_values_pb = require('../../../../teamdev/licensing/c/opensource/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_opensource_values_pb);
var teamdev_licensing_c_evaluation_values_pb = require('../../../../teamdev/licensing/c/evaluation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_values_pb);
var teamdev_licensing_c_generation_values_pb = require('../../../../teamdev/licensing/c/generation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_generation_values_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
goog.exportSymbol('proto.teamdev.licensing.c.generation.LicenseGeneration', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.generation.LicenseGeneration.Status', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.generation.LicenseGeneratorRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.generation.LicenseGeneration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.generation.LicenseGeneration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.generation.LicenseGeneration.displayName = 'proto.teamdev.licensing.c.generation.LicenseGeneration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.generation.LicenseGeneratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.displayName = 'proto.teamdev.licensing.c.generation.LicenseGeneratorRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.generation.LicenseGeneration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.generation.LicenseGeneration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.LicenseGenerationId.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    versionName: (f = msg.getVersionName()) && teamdev_licensing_c_product_values_pb.VersionName.toObject(includeInstance, f),
    clientName: (f = msg.getClientName()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f),
    organizationName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    supportExpirationDate: (f = msg.getSupportExpirationDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    licenseType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.generation.LicenseGeneration;
  return proto.teamdev.licensing.c.generation.LicenseGeneration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.generation.LicenseGeneration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.LicenseGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.LicenseGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.c.generation.LicenseGeneration.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_product_values_pb.VersionName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionName.deserializeBinaryFromReader);
      msg.setVersionName(value);
      break;
    case 6:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setClientName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 8:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setSupportExpirationDate(value);
      break;
    case 9:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 10:
      var value = /** @type {!proto.teamdev.licensing.c.generation.LicenseType} */ (reader.readEnum());
      msg.setLicenseType(value);
      break;
    case 11:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 12:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.generation.LicenseGeneration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.generation.LicenseGeneration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.LicenseGenerationId.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getVersionName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_product_values_pb.VersionName.serializeBinaryToWriter
    );
  }
  f = message.getClientName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSupportExpirationDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getLicenseType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.Status = {
  LGS_UNDEFINED: 0,
  STARTED: 1,
  GENERATION_IN_PROGRESS: 2,
  GENERATED: 3
};

/**
 * optional teamdev.licensing.LicenseGenerationId id = 1;
 * @return {?proto.teamdev.licensing.LicenseGenerationId}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.LicenseGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.LicenseGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.LicenseGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration.Status}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.c.generation.LicenseGeneration.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.generation.LicenseGeneration.Status} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductName product_name = 4;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getProductName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional teamdev.licensing.c.product.VersionName version_name = 5;
 * @return {?proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getVersionName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionName, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionName|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setVersionName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearVersionName = function() {
  return this.setVersionName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasVersionName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.people.PersonName client_name = 6;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getClientName = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 6));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setClientName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearClientName = function() {
  return this.setClientName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasClientName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string organization_name = 7;
 * @return {string}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional spine.time.LocalDate support_expiration_date = 8;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getSupportExpirationDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 8));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setSupportExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearSupportExpirationDate = function() {
  return this.setSupportExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasSupportExpirationDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 9;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 9));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional LicenseType license_type = 10;
 * @return {!proto.teamdev.licensing.c.generation.LicenseType}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getLicenseType = function() {
  return /** @type {!proto.teamdev.licensing.c.generation.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.generation.LicenseType} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setLicenseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 11;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 11));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional spine.time.LocalDate active_through = 12;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 12));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneration} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneration.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productName: (f = msg.getProductName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    versionName: (f = msg.getVersionName()) && teamdev_licensing_c_product_values_pb.VersionName.toObject(includeInstance, f),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    supportExpirationDate: (f = msg.getSupportExpirationDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    platform: jspb.Message.getFieldWithDefault(msg, 7, ""),
    organizationName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    clientName: (f = msg.getClientName()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.generation.LicenseGeneratorRequest;
  return proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.teamdev.licensing.c.generation.LicenseType} */ (reader.readEnum());
      msg.setLicenseType(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_product_values_pb.VersionName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionName.deserializeBinaryFromReader);
      msg.setVersionName(value);
      break;
    case 4:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 5:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 6:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setSupportExpirationDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 9:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setClientName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getVersionName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_product_values_pb.VersionName.serializeBinaryToWriter
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getSupportExpirationDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getClientName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
};


/**
 * optional LicenseType license_type = 1;
 * @return {!proto.teamdev.licensing.c.generation.LicenseType}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getLicenseType = function() {
  return /** @type {!proto.teamdev.licensing.c.generation.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.generation.LicenseType} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setLicenseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional teamdev.licensing.c.product.ProductName product_name = 2;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getProductName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.c.product.VersionName version_name = 3;
 * @return {?proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getVersionName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionName, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionName|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setVersionName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.clearVersionName = function() {
  return this.setVersionName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.hasVersionName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 4;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.LocalDate active_through = 5;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 5));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.time.LocalDate support_expiration_date = 6;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getSupportExpirationDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 6));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setSupportExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.clearSupportExpirationDate = function() {
  return this.setSupportExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.hasSupportExpirationDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string platform = 7;
 * @return {string}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string organization_name = 8;
 * @return {string}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional spine.people.PersonName client_name = 9;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.getClientName = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 9));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
*/
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.setClientName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.LicenseGeneratorRequest} returns this
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.clearClientName = function() {
  return this.setClientName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.prototype.hasClientName = function() {
  return jspb.Message.getField(this, 9) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.generation);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.generation.LicenseGeneration.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.generation.LicenseGeneration.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.generation.LicenseGeneration.Parser.prototype.constructor = proto.teamdev.licensing.c.generation.LicenseGeneration.Parser;
proto.teamdev.licensing.c.generation.LicenseGeneration.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.generation.LicenseGeneration();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.c.generation.LicenseGeneration.Status[obj.status];
      msg.setStatus(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName === null) {
      msg.setProductName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.productName);
      msg.setProductName(value);
    }
  }
  
  if (obj.versionName !== undefined) {
    if (obj.versionName === null) {
      msg.setVersionName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionName').fromObject(obj.versionName);
      msg.setVersionName(value);
    }
  }
  
  if (obj.clientName !== undefined) {
    if (obj.clientName === null) {
      msg.setClientName(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.clientName);
      msg.setClientName(value);
    }
  }
  
  if (obj.organizationName !== undefined) {
    if (obj.organizationName !== null) {
      let value = obj.organizationName;
      msg.setOrganizationName(value);
    }
  }
  
  if (obj.supportExpirationDate !== undefined) {
    if (obj.supportExpirationDate === null) {
      msg.setSupportExpirationDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.supportExpirationDate);
      msg.setSupportExpirationDate(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.licenseType !== undefined) {
    if (obj.licenseType !== null) {
      let value = proto.teamdev.licensing.c.generation.LicenseType[obj.licenseType];
      msg.setLicenseType(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.Parser;
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.generation.LicenseGeneratorRequest();
  
  if (obj.licenseType !== undefined) {
    if (obj.licenseType !== null) {
      let value = proto.teamdev.licensing.c.generation.LicenseType[obj.licenseType];
      msg.setLicenseType(value);
    }
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName === null) {
      msg.setProductName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.productName);
      msg.setProductName(value);
    }
  }
  
  if (obj.versionName !== undefined) {
    if (obj.versionName === null) {
      msg.setVersionName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionName').fromObject(obj.versionName);
      msg.setVersionName(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.supportExpirationDate !== undefined) {
    if (obj.supportExpirationDate === null) {
      msg.setSupportExpirationDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.supportExpirationDate);
      msg.setSupportExpirationDate(value);
    }
  }
  
  if (obj.platform !== undefined) {
    if (obj.platform !== null) {
      let value = obj.platform;
      msg.setPlatform(value);
    }
  }
  
  if (obj.organizationName !== undefined) {
    if (obj.organizationName !== null) {
      let value = obj.organizationName;
      msg.setOrganizationName(value);
    }
  }
  
  if (obj.clientName !== undefined) {
    if (obj.clientName === null) {
      msg.setClientName(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.clientName);
      msg.setClientName(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.generation.LicenseGeneration.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneration';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.generation.LicenseGeneratorRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneratorRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.generation.LicenseGeneration.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.generation.LicenseGeneration.Status';
};
