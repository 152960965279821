// source: teamdev/licensing/importado/events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_importado_license_data_pb = require('../../../teamdev/licensing/importado/license_data_pb.js');
goog.object.extend(proto, teamdev_licensing_importado_license_data_pb);
var teamdev_licensing_importado_product_data_pb = require('../../../teamdev/licensing/importado/product_data_pb.js');
goog.object.extend(proto, teamdev_licensing_importado_product_data_pb);
var teamdev_licensing_importado_product_version_data_pb = require('../../../teamdev/licensing/importado/product_version_data_pb.js');
goog.object.extend(proto, teamdev_licensing_importado_product_version_data_pb);
goog.exportSymbol('proto.teamdev.licensing.importado.CompanyWideLicenseImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.PersonalLicenseImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ProductImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ProductVersionImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ProjectLicenseImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.SingleDevLicenseImported', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ProductImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ProductImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ProductImported.displayName = 'proto.teamdev.licensing.importado.ProductImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ProductVersionImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ProductVersionImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ProductVersionImported.displayName = 'proto.teamdev.licensing.importado.ProductVersionImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.PersonalLicenseImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.PersonalLicenseImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.PersonalLicenseImported.displayName = 'proto.teamdev.licensing.importado.PersonalLicenseImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.SingleDevLicenseImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.SingleDevLicenseImported.displayName = 'proto.teamdev.licensing.importado.SingleDevLicenseImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.CompanyWideLicenseImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.CompanyWideLicenseImported.displayName = 'proto.teamdev.licensing.importado.CompanyWideLicenseImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ProjectLicenseImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ProjectLicenseImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ProjectLicenseImported.displayName = 'proto.teamdev.licensing.importado.ProjectLicenseImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.displayName = 'proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.displayName = 'proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.displayName = 'proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ProductImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ProductImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ProductImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProductImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_product_data_pb.ProductData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ProductImported}
 */
proto.teamdev.licensing.importado.ProductImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ProductImported;
  return proto.teamdev.licensing.importado.ProductImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ProductImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ProductImported}
 */
proto.teamdev.licensing.importado.ProductImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_product_data_pb.ProductData;
      reader.readMessage(value,teamdev_licensing_importado_product_data_pb.ProductData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ProductImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ProductImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ProductImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProductImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_product_data_pb.ProductData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.importado.ProductImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProductImported} returns this
*/
proto.teamdev.licensing.importado.ProductImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProductImported} returns this
 */
proto.teamdev.licensing.importado.ProductImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProductImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductData data = 2;
 * @return {?proto.teamdev.licensing.importado.ProductData}
 */
proto.teamdev.licensing.importado.ProductImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.ProductData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_product_data_pb.ProductData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.ProductData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProductImported} returns this
*/
proto.teamdev.licensing.importado.ProductImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProductImported} returns this
 */
proto.teamdev.licensing.importado.ProductImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProductImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ProductVersionImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ProductVersionImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProductVersionImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_product_version_data_pb.ProductVersionData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ProductVersionImported}
 */
proto.teamdev.licensing.importado.ProductVersionImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ProductVersionImported;
  return proto.teamdev.licensing.importado.ProductVersionImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ProductVersionImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ProductVersionImported}
 */
proto.teamdev.licensing.importado.ProductVersionImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_product_version_data_pb.ProductVersionData;
      reader.readMessage(value,teamdev_licensing_importado_product_version_data_pb.ProductVersionData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ProductVersionImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ProductVersionImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProductVersionImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_product_version_data_pb.ProductVersionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProductVersionImported} returns this
*/
proto.teamdev.licensing.importado.ProductVersionImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProductVersionImported} returns this
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductVersionData data = 2;
 * @return {?proto.teamdev.licensing.importado.ProductVersionData}
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.ProductVersionData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_product_version_data_pb.ProductVersionData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.ProductVersionData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProductVersionImported} returns this
*/
proto.teamdev.licensing.importado.ProductVersionImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProductVersionImported} returns this
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProductVersionImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.PersonalLicenseImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.PersonalLicenseImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.PersonalLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.PersonalLicenseImported}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.PersonalLicenseImported;
  return proto.teamdev.licensing.importado.PersonalLicenseImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.PersonalLicenseImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.PersonalLicenseImported}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.PersonalLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.PersonalLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.PersonalLicenseImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.PersonalLicenseImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.PersonalLicenseId id = 1;
 * @return {?proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.PersonalLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.PersonalLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.PersonalLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.PersonalLicenseImported} returns this
*/
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.PersonalLicenseImported} returns this
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.PersonalLicenseImported} returns this
*/
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.PersonalLicenseImported} returns this
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.PersonalLicenseImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.SingleDevLicenseImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.SingleDevLicenseImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SingleDevLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.SingleDevLicenseImported}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.SingleDevLicenseImported;
  return proto.teamdev.licensing.importado.SingleDevLicenseImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.SingleDevLicenseImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.SingleDevLicenseImported}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SingleDevLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.SingleDevLicenseImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.SingleDevLicenseImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SingleDevLicenseId id = 1;
 * @return {?proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.SingleDevLicenseImported} returns this
*/
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.SingleDevLicenseImported} returns this
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.SingleDevLicenseImported} returns this
*/
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.SingleDevLicenseImported} returns this
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.SingleDevLicenseImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.CompanyWideLicenseImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.CompanyWideLicenseImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.CompanyWideLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.CompanyWideLicenseImported}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.CompanyWideLicenseImported;
  return proto.teamdev.licensing.importado.CompanyWideLicenseImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.CompanyWideLicenseImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.CompanyWideLicenseImported}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.CompanyWideLicenseImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.CompanyWideLicenseImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.CompanyWideLicenseId id = 1;
 * @return {?proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.CompanyWideLicenseImported} returns this
*/
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.CompanyWideLicenseImported} returns this
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.CompanyWideLicenseImported} returns this
*/
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.CompanyWideLicenseImported} returns this
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.CompanyWideLicenseImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ProjectLicenseImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ProjectLicenseImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ProjectLicenseImported}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ProjectLicenseImported;
  return proto.teamdev.licensing.importado.ProjectLicenseImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ProjectLicenseImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ProjectLicenseImported}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ProjectLicenseImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ProjectLicenseImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProjectLicenseImported} returns this
*/
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProjectLicenseImported} returns this
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProjectLicenseImported} returns this
*/
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProjectLicenseImported} returns this
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProjectLicenseImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported;
  return proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SingleDevSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported} returns this
*/
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported} returns this
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported} returns this
*/
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported} returns this
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported;
  return proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported} returns this
*/
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported} returns this
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported} returns this
*/
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported} returns this
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported;
  return proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.CompanyWideSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported} returns this
*/
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported} returns this
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported} returns this
*/
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported} returns this
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.importado);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.importado.ProductVersionImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ProductVersionImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ProductVersionImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.ProductVersionImported.Parser;
proto.teamdev.licensing.importado.ProductVersionImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ProductVersionImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.ProductVersionData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.CompanyWideLicenseImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.CompanyWideLicenseImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.CompanyWideLicenseImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.CompanyWideLicenseImported.Parser;
proto.teamdev.licensing.importado.CompanyWideLicenseImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.CompanyWideLicenseImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.Parser;
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.Parser;
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.SingleDevLicenseImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.SingleDevLicenseImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.SingleDevLicenseImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.SingleDevLicenseImported.Parser;
proto.teamdev.licensing.importado.SingleDevLicenseImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.SingleDevLicenseImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ProductImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ProductImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ProductImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.ProductImported.Parser;
proto.teamdev.licensing.importado.ProductImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ProductImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.ProductData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ProjectLicenseImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ProjectLicenseImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ProjectLicenseImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.ProjectLicenseImported.Parser;
proto.teamdev.licensing.importado.ProjectLicenseImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ProjectLicenseImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.Parser;
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.PersonalLicenseImported.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.PersonalLicenseImported.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.PersonalLicenseImported.Parser.prototype.constructor = proto.teamdev.licensing.importado.PersonalLicenseImported.Parser;
proto.teamdev.licensing.importado.PersonalLicenseImported.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.PersonalLicenseImported();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ProductVersionImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ProductVersionImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.CompanyWideLicenseImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.CompanyWideLicenseImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.SingleDevSourceCodeLicenseImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.SingleDevSourceCodeLicenseImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.CompanyWideSourceCodeLicenseImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.SingleDevLicenseImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.SingleDevLicenseImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ProductImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ProductImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ProjectLicenseImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ProjectLicenseImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ProjectSourceCodeLicenseImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ProjectSourceCodeLicenseImported';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.PersonalLicenseImported.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.PersonalLicenseImported';
};
