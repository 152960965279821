// source: teamdev/licensing/c/academic/rejections.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CannotRefuseRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.CannotStartRequestVerification', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.displayName = 'proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CannotStartRequestVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CannotStartRequestVerification.displayName = 'proto.teamdev.licensing.c.academic.CannotStartRequestVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.displayName = 'proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.displayName = 'proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CannotRefuseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CannotRefuseRequest.displayName = 'proto.teamdev.licensing.c.academic.CannotRefuseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.displayName = 'proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.displayName = 'proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.displayName = 'proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists;
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted;
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked;
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired;
  return proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted}
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted;
  return proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted}
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted} returns this
*/
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted} returns this
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CannotStartRequestVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CannotStartRequestVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CannotStartRequestVerification}
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CannotStartRequestVerification;
  return proto.teamdev.licensing.c.academic.CannotStartRequestVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CannotStartRequestVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CannotStartRequestVerification}
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CannotStartRequestVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CannotStartRequestVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CannotStartRequestVerification} returns this
*/
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CannotStartRequestVerification} returns this
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified;
  return proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed;
  return proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CannotRefuseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CannotRefuseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CannotRefuseRequest}
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CannotRefuseRequest;
  return proto.teamdev.licensing.c.academic.CannotRefuseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CannotRefuseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CannotRefuseRequest}
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CannotRefuseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CannotRefuseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CannotRefuseRequest} returns this
*/
proto.teamdev.licensing.c.academic.CannotRefuseRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CannotRefuseRequest} returns this
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CannotRefuseRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense}
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense;
  return proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense}
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense} returns this
*/
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense} returns this
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense}
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense;
  return proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense}
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired}
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired;
  return proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired}
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired} returns this
*/
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired} returns this
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.academic);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.Parser;
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CannotRefuseRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CannotRefuseRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CannotRefuseRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CannotRefuseRequest.Parser;
proto.teamdev.licensing.c.academic.CannotRefuseRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CannotRefuseRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.Parser;
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.Parser;
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.Parser;
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.Parser;
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.Parser;
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.CannotStartRequestVerification.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.CannotStartRequestVerification.Parser;
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.CannotStartRequestVerification();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CannotGrantAcademicLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CannotGrantAcademicLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CannotRefuseRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CannotRefuseRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyRevoked';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CannotMarkRequestAsVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CannotMarkRequestAsVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CannotMarkLicenseAsExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CannotMarkLicenseAsExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CannotMarkRequestAsFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CannotMarkRequestAsFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CannotRequestExtendedLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CannotRequestExtendedLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionAlreadyGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseAlreadyExists.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAlreadyExists';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.LicenseAgreementNotAccepted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.LicenseAgreementNotAccepted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.CannotStartRequestVerification.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.CannotStartRequestVerification';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestNotVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestNotVerified';
};
