// source: teamdev/licensing/q/noncommercial/noncommercial_license_request_view.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_c_academic_values_pb = require('../../../../teamdev/licensing/c/academic/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_values_pb);
var teamdev_licensing_c_opensource_values_pb = require('../../../../teamdev/licensing/c/opensource/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_opensource_values_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
var teamdev_licensing_q_noncommercial_project_profile_pb = require('../../../../teamdev/licensing/q/noncommercial/project_profile_pb.js');
goog.object.extend(proto, teamdev_licensing_q_noncommercial_project_profile_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.ProjectProfileCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.RequesterCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.displayName = 'proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_ = [[8,9],[11,12]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.RequesterCase = {
  REQUESTER_NOT_SET: 0,
  ACADEMIC_REQUESTER: 8,
  OPEN_SOURCE_REQUESTER: 9
};

/**
 * @return {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.RequesterCase}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getRequesterCase = function() {
  return /** @type {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.RequesterCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.ProjectProfileCase = {
  PROJECT_PROFILE_NOT_SET: 0,
  ACADEMIC_PROJECT_PROFILE: 11,
  OPEN_SOURCE_PROJECT_PROFILE: 12
};

/**
 * @return {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.ProjectProfileCase}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getProjectProfileCase = function() {
  return /** @type {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.ProjectProfileCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.NonCommercialLicenseRequestId.toObject(includeInstance, f),
    whenRequested: (f = msg.getWhenRequested()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    count: jspb.Message.getFieldWithDefault(msg, 6, 0),
    extension: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    academicRequester: (f = msg.getAcademicRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    openSourceRequester: (f = msg.getOpenSourceRequester()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    verificationFailureReason: (f = msg.getVerificationFailureReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    academicProjectProfile: (f = msg.getAcademicProjectProfile()) && teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile.toObject(includeInstance, f),
    openSourceProjectProfile: (f = msg.getOpenSourceProjectProfile()) && teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest;
  return proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.NonCommercialLicenseRequestId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.NonCommercialLicenseRequestId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRequested(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 5:
      var value = /** @type {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtension$(value);
      break;
    case 8:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setAcademicRequester(value);
      break;
    case 9:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setOpenSourceRequester(value);
      break;
    case 10:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setVerificationFailureReason(value);
      break;
    case 11:
      var value = new teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile;
      reader.readMessage(value,teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile.deserializeBinaryFromReader);
      msg.setAcademicProjectProfile(value);
      break;
    case 12:
      var value = new teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile;
      reader.readMessage(value,teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile.deserializeBinaryFromReader);
      msg.setOpenSourceProjectProfile(value);
      break;
    case 13:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    case 14:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.NonCommercialLicenseRequestId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRequested();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getExtension$();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAcademicRequester();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceRequester();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getVerificationFailureReason();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getAcademicProjectProfile();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceProjectProfile();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status = {
  NCLR_UNDEFINED: 0,
  RECEIVED: 1,
  IN_PROGRESS: 2,
  VERIFIED: 3,
  VERIFICATION_FAILED: 4,
  REFUSED: 5
};

/**
 * optional teamdev.licensing.NonCommercialLicenseRequestId id = 1;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseRequestId}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseRequestId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.NonCommercialLicenseRequestId, 1));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseRequestId|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_requested = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getWhenRequested = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setWhenRequested = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearWhenRequested = function() {
  return this.setWhenRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasWhenRequested = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductName product_name = 3;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getProductName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Status status = 5;
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional uint32 count = 6;
 * @return {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool extension = 7;
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getExtension$ = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional teamdev.licensing.c.academic.AcademicLicenseRequester academic_requester = 8;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getAcademicRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 8));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setAcademicRequester = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearAcademicRequester = function() {
  return this.setAcademicRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasAcademicRequester = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional teamdev.licensing.c.opensource.OpenSourceLicenseRequester open_source_requester = 9;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getOpenSourceRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 9));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setOpenSourceRequester = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearOpenSourceRequester = function() {
  return this.setOpenSourceRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasOpenSourceRequester = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason verification_failure_reason = 10;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getVerificationFailureReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 10));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setVerificationFailureReason = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearVerificationFailureReason = function() {
  return this.setVerificationFailureReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasVerificationFailureReason = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AcademicProjectProfile academic_project_profile = 11;
 * @return {?proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getAcademicProjectProfile = function() {
  return /** @type{?proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile, 11));
};


/**
 * @param {?proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setAcademicProjectProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearAcademicProjectProfile = function() {
  return this.setAcademicProjectProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasAcademicProjectProfile = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional OpenSourceProjectProfile open_source_project_profile = 12;
 * @return {?proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getOpenSourceProjectProfile = function() {
  return /** @type{?proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile, 12));
};


/**
 * @param {?proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setOpenSourceProjectProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearOpenSourceProjectProfile = function() {
  return this.setOpenSourceProjectProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasOpenSourceProjectProfile = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional spine.core.UserId verified_by = 13;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 13));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional spine.core.UserId refused_by = 14;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 14));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 14) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.q.noncommercial);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Parser.prototype.constructor = proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Parser;
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseRequestId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRequested !== undefined) {
    if (obj.whenRequested === null) {
      msg.setWhenRequested(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRequested);
      msg.setWhenRequested(value);
    }
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName === null) {
      msg.setProductName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.productName);
      msg.setProductName(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status[obj.status];
      msg.setStatus(value);
    }
  }
  
  if (obj.count !== undefined) {
    if (obj.count !== null) {
      let value = obj.count;
      msg.setCount(value);
    }
  }
  
  if (obj.extension !== undefined) {
    if (obj.extension !== null) {
      let value = obj.extension;
      msg.setExtension(value);
    }
  }
  
  if (obj.academicRequester !== undefined) {
    if (obj.academicRequester === null) {
      msg.setAcademicRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.academicRequester);
      msg.setAcademicRequester(value);
    }
  }
  
  if (obj.openSourceRequester !== undefined) {
    if (obj.openSourceRequester === null) {
      msg.setOpenSourceRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.openSourceRequester);
      msg.setOpenSourceRequester(value);
    }
  }
  
  if (obj.verificationFailureReason !== undefined) {
    if (obj.verificationFailureReason === null) {
      msg.setVerificationFailureReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.verificationFailureReason);
      msg.setVerificationFailureReason(value);
    }
  }
  
  if (obj.academicProjectProfile !== undefined) {
    if (obj.academicProjectProfile === null) {
      msg.setAcademicProjectProfile(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.noncommercial.AcademicProjectProfile').fromObject(obj.academicProjectProfile);
      msg.setAcademicProjectProfile(value);
    }
  }
  
  if (obj.openSourceProjectProfile !== undefined) {
    if (obj.openSourceProjectProfile === null) {
      msg.setOpenSourceProjectProfile(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.noncommercial.OpenSourceProjectProfile').fromObject(obj.openSourceProjectProfile);
      msg.setOpenSourceProjectProfile(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseRequest.Status';
};
