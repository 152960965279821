var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"records-browser-view"},[_c('filter-bar',{ref:"filterBar",model:{value:(_vm.filterBarValue),callback:function ($$v) {_vm.filterBarValue=$$v},expression:"filterBarValue"}},[_vm._t("filter-bar-content",null,{"value":_vm.filterBarValue,"input":_vm.handleNestedFilterInput})],2),_vm._v(" "),_c('grouped-expansion-panel-list',{attrs:{"items":_vm.itemsFiltered,"item-to-id":_vm.itemToId,"item-to-group-def":_vm.itemToGroupDef},scopedSlots:_vm._u([{key:"record-collapsed",fn:function(ref){
var item = ref.item;
var focused = ref.focused;
return [_vm._t("record-collapsed",null,{"item":item,"focused":focused})]}},{key:"record-expanded-header",fn:function( ref){
var item = ref.item;
var focused = ref.focused;
return [_vm._t("record-expanded-header",null,{"item":item,"focused":focused})]}},{key:"record-expanded-content",fn:function(ref){
var item = ref.item;
var focused = ref.focused;
return [_vm._t("record-expanded-content",null,{"item":item,"focused":focused})]}}],null,true)}),_vm._v(" "),(_vm.itemForDialog)?[_vm._t("dialogs",null,{"item":_vm.itemForDialog})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }