// source: teamdev/licensing/identifiers.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
goog.exportSymbol('proto.teamdev.licensing.AcademicLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.CommercialLicenseViewId', null, global);
goog.exportSymbol('proto.teamdev.licensing.CommercialLicenseViewId.IdCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.CompanyWideLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.CompanyWideSourceCodeLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.DailyStatsReportId', null, global);
goog.exportSymbol('proto.teamdev.licensing.DemoLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.EvaluationId', null, global);
goog.exportSymbol('proto.teamdev.licensing.LicenseGenerationId', null, global);
goog.exportSymbol('proto.teamdev.licensing.LicenseGenerationId.LicenseIdCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.NonCommercialLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.NonCommercialLicenseId.IdCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.NonCommercialLicenseRequestId', null, global);
goog.exportSymbol('proto.teamdev.licensing.NonCommercialLicenseRequestId.IdCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.OpenSourceLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.PersonalLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.ProductId', null, global);
goog.exportSymbol('proto.teamdev.licensing.ProductListId', null, global);
goog.exportSymbol('proto.teamdev.licensing.ProductVersionId', null, global);
goog.exportSymbol('proto.teamdev.licensing.ProjectLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.ProjectSourceCodeLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.SampleDataGenerationId', null, global);
goog.exportSymbol('proto.teamdev.licensing.SampleDataGenerationId.Value', null, global);
goog.exportSymbol('proto.teamdev.licensing.SingleDevLicenseId', null, global);
goog.exportSymbol('proto.teamdev.licensing.SingleDevSourceCodeLicenseId', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.ProductId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.ProductId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.ProductId.displayName = 'proto.teamdev.licensing.ProductId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.ProductVersionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.ProductVersionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.ProductVersionId.displayName = 'proto.teamdev.licensing.ProductVersionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.DemoLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.DemoLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.DemoLicenseId.displayName = 'proto.teamdev.licensing.DemoLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.AcademicLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.AcademicLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.AcademicLicenseId.displayName = 'proto.teamdev.licensing.AcademicLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.OpenSourceLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.OpenSourceLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.OpenSourceLicenseId.displayName = 'proto.teamdev.licensing.OpenSourceLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.SingleDevLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.SingleDevLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.SingleDevLicenseId.displayName = 'proto.teamdev.licensing.SingleDevLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.ProjectLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.ProjectLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.ProjectLicenseId.displayName = 'proto.teamdev.licensing.ProjectLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.CompanyWideLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.CompanyWideLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.CompanyWideLicenseId.displayName = 'proto.teamdev.licensing.CompanyWideLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.SingleDevSourceCodeLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.SingleDevSourceCodeLicenseId.displayName = 'proto.teamdev.licensing.SingleDevSourceCodeLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.ProjectSourceCodeLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.ProjectSourceCodeLicenseId.displayName = 'proto.teamdev.licensing.ProjectSourceCodeLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.CompanyWideSourceCodeLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.displayName = 'proto.teamdev.licensing.CompanyWideSourceCodeLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.PersonalLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.PersonalLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.PersonalLicenseId.displayName = 'proto.teamdev.licensing.PersonalLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.EvaluationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.EvaluationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.EvaluationId.displayName = 'proto.teamdev.licensing.EvaluationId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.DailyStatsReportId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.DailyStatsReportId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.DailyStatsReportId.displayName = 'proto.teamdev.licensing.DailyStatsReportId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.NonCommercialLicenseId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.NonCommercialLicenseId.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.NonCommercialLicenseId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.NonCommercialLicenseId.displayName = 'proto.teamdev.licensing.NonCommercialLicenseId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.NonCommercialLicenseRequestId.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.NonCommercialLicenseRequestId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.NonCommercialLicenseRequestId.displayName = 'proto.teamdev.licensing.NonCommercialLicenseRequestId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.CommercialLicenseViewId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.CommercialLicenseViewId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.CommercialLicenseViewId.displayName = 'proto.teamdev.licensing.CommercialLicenseViewId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.ProductListId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.ProductListId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.ProductListId.displayName = 'proto.teamdev.licensing.ProductListId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.LicenseGenerationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.LicenseGenerationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.LicenseGenerationId.displayName = 'proto.teamdev.licensing.LicenseGenerationId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.SampleDataGenerationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.SampleDataGenerationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.SampleDataGenerationId.displayName = 'proto.teamdev.licensing.SampleDataGenerationId';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.ProductId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.ProductId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.ProductId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProductId.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.ProductId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.ProductId;
  return proto.teamdev.licensing.ProductId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.ProductId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.ProductId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.ProductId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.ProductId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.ProductId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProductId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.ProductId.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.ProductId} returns this
 */
proto.teamdev.licensing.ProductId.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.ProductVersionId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.ProductVersionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.ProductVersionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProductVersionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.ProductVersionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.ProductVersionId;
  return proto.teamdev.licensing.ProductVersionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.ProductVersionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.ProductVersionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.ProductVersionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.ProductVersionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.ProductVersionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProductVersionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.ProductVersionId.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.ProductVersionId} returns this
 */
proto.teamdev.licensing.ProductVersionId.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProductId product_id = 2;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.ProductVersionId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.ProductVersionId} returns this
*/
proto.teamdev.licensing.ProductVersionId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.ProductVersionId} returns this
 */
proto.teamdev.licensing.ProductVersionId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.ProductVersionId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.DemoLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.DemoLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.DemoLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.DemoLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.DemoLicenseId}
 */
proto.teamdev.licensing.DemoLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.DemoLicenseId;
  return proto.teamdev.licensing.DemoLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.DemoLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.DemoLicenseId}
 */
proto.teamdev.licensing.DemoLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.DemoLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.DemoLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.DemoLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.DemoLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.DemoLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.DemoLicenseId} returns this
 */
proto.teamdev.licensing.DemoLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.DemoLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.DemoLicenseId} returns this
*/
proto.teamdev.licensing.DemoLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.DemoLicenseId} returns this
 */
proto.teamdev.licensing.DemoLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.DemoLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.DemoLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.DemoLicenseId} returns this
*/
proto.teamdev.licensing.DemoLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.DemoLicenseId} returns this
 */
proto.teamdev.licensing.DemoLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.DemoLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.AcademicLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.AcademicLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.AcademicLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.AcademicLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.AcademicLicenseId;
  return proto.teamdev.licensing.AcademicLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.AcademicLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.AcademicLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.AcademicLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.AcademicLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.AcademicLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.AcademicLicenseId} returns this
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.AcademicLicenseId} returns this
*/
proto.teamdev.licensing.AcademicLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.AcademicLicenseId} returns this
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.AcademicLicenseId} returns this
*/
proto.teamdev.licensing.AcademicLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.AcademicLicenseId} returns this
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.AcademicLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.OpenSourceLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.OpenSourceLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.OpenSourceLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.OpenSourceLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.OpenSourceLicenseId;
  return proto.teamdev.licensing.OpenSourceLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.OpenSourceLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.OpenSourceLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.OpenSourceLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.OpenSourceLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.OpenSourceLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId} returns this
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId} returns this
*/
proto.teamdev.licensing.OpenSourceLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId} returns this
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId} returns this
*/
proto.teamdev.licensing.OpenSourceLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId} returns this
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.OpenSourceLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.SingleDevLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.SingleDevLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.SingleDevLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.SingleDevLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.SingleDevLicenseId;
  return proto.teamdev.licensing.SingleDevLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.SingleDevLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.SingleDevLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.SingleDevLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.SingleDevLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.SingleDevLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.SingleDevLicenseId} returns this
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.SingleDevLicenseId} returns this
*/
proto.teamdev.licensing.SingleDevLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.SingleDevLicenseId} returns this
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.SingleDevLicenseId} returns this
*/
proto.teamdev.licensing.SingleDevLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.SingleDevLicenseId} returns this
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.SingleDevLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.ProjectLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.ProjectLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProjectLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.ProjectLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.ProjectLicenseId;
  return proto.teamdev.licensing.ProjectLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.ProjectLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.ProjectLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.ProjectLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.ProjectLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProjectLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.ProjectLicenseId} returns this
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.ProjectLicenseId} returns this
*/
proto.teamdev.licensing.ProjectLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.ProjectLicenseId} returns this
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.ProjectLicenseId} returns this
*/
proto.teamdev.licensing.ProjectLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.ProjectLicenseId} returns this
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.ProjectLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.CompanyWideLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.CompanyWideLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CompanyWideLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.CompanyWideLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.CompanyWideLicenseId;
  return proto.teamdev.licensing.CompanyWideLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.CompanyWideLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.CompanyWideLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.CompanyWideLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.CompanyWideLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CompanyWideLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId} returns this
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId} returns this
*/
proto.teamdev.licensing.CompanyWideLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId} returns this
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId} returns this
*/
proto.teamdev.licensing.CompanyWideLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId} returns this
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CompanyWideLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.SingleDevSourceCodeLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.SingleDevSourceCodeLicenseId;
  return proto.teamdev.licensing.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.SingleDevSourceCodeLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} returns this
*/
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} returns this
*/
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.ProjectSourceCodeLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.ProjectSourceCodeLicenseId;
  return proto.teamdev.licensing.ProjectSourceCodeLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.ProjectSourceCodeLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} returns this
*/
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} returns this
*/
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.ProjectSourceCodeLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.CompanyWideSourceCodeLicenseId;
  return proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} returns this
*/
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} returns this
*/
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} returns this
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.PersonalLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.PersonalLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.PersonalLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.PersonalLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.PersonalLicenseId;
  return proto.teamdev.licensing.PersonalLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.PersonalLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.PersonalLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.PersonalLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.PersonalLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.PersonalLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.PersonalLicenseId} returns this
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.PersonalLicenseId} returns this
*/
proto.teamdev.licensing.PersonalLicenseId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.PersonalLicenseId} returns this
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.PersonalLicenseId} returns this
*/
proto.teamdev.licensing.PersonalLicenseId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.PersonalLicenseId} returns this
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.PersonalLicenseId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.EvaluationId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.EvaluationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.EvaluationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.EvaluationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.EvaluationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.EvaluationId;
  return proto.teamdev.licensing.EvaluationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.EvaluationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.EvaluationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.EvaluationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.EvaluationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.EvaluationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.EvaluationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.teamdev.licensing.EvaluationId.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.EvaluationId} returns this
 */
proto.teamdev.licensing.EvaluationId.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.licensing.EvaluationId.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.licensing.EvaluationId} returns this
*/
proto.teamdev.licensing.EvaluationId.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.EvaluationId} returns this
 */
proto.teamdev.licensing.EvaluationId.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationId.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.EvaluationId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.EvaluationId} returns this
*/
proto.teamdev.licensing.EvaluationId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.EvaluationId} returns this
 */
proto.teamdev.licensing.EvaluationId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.DailyStatsReportId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.DailyStatsReportId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.DailyStatsReportId.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: (f = msg.getProductId()) && proto.teamdev.licensing.ProductId.toObject(includeInstance, f),
    date: (f = msg.getDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.DailyStatsReportId}
 */
proto.teamdev.licensing.DailyStatsReportId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.DailyStatsReportId;
  return proto.teamdev.licensing.DailyStatsReportId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.DailyStatsReportId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.DailyStatsReportId}
 */
proto.teamdev.licensing.DailyStatsReportId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.ProductId;
      reader.readMessage(value,proto.teamdev.licensing.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.DailyStatsReportId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.DailyStatsReportId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.DailyStatsReportId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProductId product_id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.DailyStatsReportId} returns this
*/
proto.teamdev.licensing.DailyStatsReportId.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.DailyStatsReportId} returns this
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.LocalDate date = 2;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.getDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 2));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.DailyStatsReportId} returns this
*/
proto.teamdev.licensing.DailyStatsReportId.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.DailyStatsReportId} returns this
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.DailyStatsReportId.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.NonCommercialLicenseId.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.NonCommercialLicenseId.IdCase = {
  ID_NOT_SET: 0,
  ACADEMIC_ID: 1,
  OPEN_SOURCE_ID: 2
};

/**
 * @return {proto.teamdev.licensing.NonCommercialLicenseId.IdCase}
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.getIdCase = function() {
  return /** @type {proto.teamdev.licensing.NonCommercialLicenseId.IdCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.NonCommercialLicenseId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.NonCommercialLicenseId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.NonCommercialLicenseId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.NonCommercialLicenseId.toObject = function(includeInstance, msg) {
  var f, obj = {
    academicId: (f = msg.getAcademicId()) && proto.teamdev.licensing.AcademicLicenseId.toObject(includeInstance, f),
    openSourceId: (f = msg.getOpenSourceId()) && proto.teamdev.licensing.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseId}
 */
proto.teamdev.licensing.NonCommercialLicenseId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.NonCommercialLicenseId;
  return proto.teamdev.licensing.NonCommercialLicenseId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.NonCommercialLicenseId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseId}
 */
proto.teamdev.licensing.NonCommercialLicenseId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.AcademicLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setAcademicId(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.OpenSourceLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setOpenSourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.NonCommercialLicenseId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.NonCommercialLicenseId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.NonCommercialLicenseId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcademicId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional AcademicLicenseId academic_id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.getAcademicId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseId} returns this
*/
proto.teamdev.licensing.NonCommercialLicenseId.prototype.setAcademicId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.licensing.NonCommercialLicenseId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseId} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.clearAcademicId = function() {
  return this.setAcademicId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.hasAcademicId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OpenSourceLicenseId open_source_id = 2;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.getOpenSourceId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.OpenSourceLicenseId, 2));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseId} returns this
*/
proto.teamdev.licensing.NonCommercialLicenseId.prototype.setOpenSourceId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.NonCommercialLicenseId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseId} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.clearOpenSourceId = function() {
  return this.setOpenSourceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseId.prototype.hasOpenSourceId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.IdCase = {
  ID_NOT_SET: 0,
  ACADEMIC_ID: 1,
  OPEN_SOURCE_ID: 2
};

/**
 * @return {proto.teamdev.licensing.NonCommercialLicenseRequestId.IdCase}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.getIdCase = function() {
  return /** @type {proto.teamdev.licensing.NonCommercialLicenseRequestId.IdCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.NonCommercialLicenseRequestId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.NonCommercialLicenseRequestId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.NonCommercialLicenseRequestId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.toObject = function(includeInstance, msg) {
  var f, obj = {
    academicId: (f = msg.getAcademicId()) && proto.teamdev.licensing.AcademicLicenseId.toObject(includeInstance, f),
    openSourceId: (f = msg.getOpenSourceId()) && proto.teamdev.licensing.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRequestId}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.NonCommercialLicenseRequestId;
  return proto.teamdev.licensing.NonCommercialLicenseRequestId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.NonCommercialLicenseRequestId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRequestId}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.AcademicLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setAcademicId(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.OpenSourceLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setOpenSourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.NonCommercialLicenseRequestId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.NonCommercialLicenseRequestId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcademicId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional AcademicLicenseId academic_id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.getAcademicId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRequestId} returns this
*/
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.setAcademicId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.licensing.NonCommercialLicenseRequestId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRequestId} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.clearAcademicId = function() {
  return this.setAcademicId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.hasAcademicId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OpenSourceLicenseId open_source_id = 2;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.getOpenSourceId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.OpenSourceLicenseId, 2));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRequestId} returns this
*/
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.setOpenSourceId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.NonCommercialLicenseRequestId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRequestId} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.clearOpenSourceId = function() {
  return this.setOpenSourceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRequestId.prototype.hasOpenSourceId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.CommercialLicenseViewId.IdCase = {
  ID_NOT_SET: 0,
  SINGLE_DEV_LICENSE_ID: 1,
  PROJECT_LICENSE_ID: 2,
  COMPANY_WIDE_LICENSE_ID: 3,
  PERSONAL_LICENSE_ID: 4,
  SINGLE_DEV_SOURCE_CODE_LICENSE_ID: 5,
  PROJECT_SOURCE_CODE_LICENSE_ID: 6,
  COMPANY_WIDE_SOURCE_CODE_LICENSE_ID: 7
};

/**
 * @return {proto.teamdev.licensing.CommercialLicenseViewId.IdCase}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getIdCase = function() {
  return /** @type {proto.teamdev.licensing.CommercialLicenseViewId.IdCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.CommercialLicenseViewId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.CommercialLicenseViewId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CommercialLicenseViewId.toObject = function(includeInstance, msg) {
  var f, obj = {
    singleDevLicenseId: (f = msg.getSingleDevLicenseId()) && proto.teamdev.licensing.SingleDevLicenseId.toObject(includeInstance, f),
    projectLicenseId: (f = msg.getProjectLicenseId()) && proto.teamdev.licensing.ProjectLicenseId.toObject(includeInstance, f),
    companyWideLicenseId: (f = msg.getCompanyWideLicenseId()) && proto.teamdev.licensing.CompanyWideLicenseId.toObject(includeInstance, f),
    personalLicenseId: (f = msg.getPersonalLicenseId()) && proto.teamdev.licensing.PersonalLicenseId.toObject(includeInstance, f),
    singleDevSourceCodeLicenseId: (f = msg.getSingleDevSourceCodeLicenseId()) && proto.teamdev.licensing.SingleDevSourceCodeLicenseId.toObject(includeInstance, f),
    projectSourceCodeLicenseId: (f = msg.getProjectSourceCodeLicenseId()) && proto.teamdev.licensing.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    companyWideSourceCodeLicenseId: (f = msg.getCompanyWideSourceCodeLicenseId()) && proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.CommercialLicenseViewId;
  return proto.teamdev.licensing.CommercialLicenseViewId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.CommercialLicenseViewId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.SingleDevLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.setSingleDevLicenseId(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.ProjectLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.ProjectLicenseId.deserializeBinaryFromReader);
      msg.setProjectLicenseId(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.CompanyWideLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWideLicenseId(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.PersonalLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.PersonalLicenseId.deserializeBinaryFromReader);
      msg.setPersonalLicenseId(value);
      break;
    case 5:
      var value = new proto.teamdev.licensing.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setSingleDevSourceCodeLicenseId(value);
      break;
    case 6:
      var value = new proto.teamdev.licensing.ProjectSourceCodeLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setProjectSourceCodeLicenseId(value);
      break;
    case 7:
      var value = new proto.teamdev.licensing.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWideSourceCodeLicenseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.CommercialLicenseViewId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.CommercialLicenseViewId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CommercialLicenseViewId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingleDevLicenseId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectLicenseId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideLicenseId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getPersonalLicenseId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.teamdev.licensing.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.teamdev.licensing.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.teamdev.licensing.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional SingleDevLicenseId single_dev_license_id = 1;
 * @return {?proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getSingleDevLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.SingleDevLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
*/
proto.teamdev.licensing.CommercialLicenseViewId.prototype.setSingleDevLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.clearSingleDevLicenseId = function() {
  return this.setSingleDevLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.hasSingleDevLicenseId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProjectLicenseId project_license_id = 2;
 * @return {?proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getProjectLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProjectLicenseId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProjectLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
*/
proto.teamdev.licensing.CommercialLicenseViewId.prototype.setProjectLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.clearProjectLicenseId = function() {
  return this.setProjectLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.hasProjectLicenseId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CompanyWideLicenseId company_wide_license_id = 3;
 * @return {?proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getCompanyWideLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.CompanyWideLicenseId, 3));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
*/
proto.teamdev.licensing.CommercialLicenseViewId.prototype.setCompanyWideLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.clearCompanyWideLicenseId = function() {
  return this.setCompanyWideLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.hasCompanyWideLicenseId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PersonalLicenseId personal_license_id = 4;
 * @return {?proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getPersonalLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.PersonalLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.PersonalLicenseId, 4));
};


/**
 * @param {?proto.teamdev.licensing.PersonalLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
*/
proto.teamdev.licensing.CommercialLicenseViewId.prototype.setPersonalLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.clearPersonalLicenseId = function() {
  return this.setPersonalLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.hasPersonalLicenseId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SingleDevSourceCodeLicenseId single_dev_source_code_license_id = 5;
 * @return {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getSingleDevSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.SingleDevSourceCodeLicenseId, 5));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
*/
proto.teamdev.licensing.CommercialLicenseViewId.prototype.setSingleDevSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.clearSingleDevSourceCodeLicenseId = function() {
  return this.setSingleDevSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.hasSingleDevSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ProjectSourceCodeLicenseId project_source_code_license_id = 6;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getProjectSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProjectSourceCodeLicenseId, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
*/
proto.teamdev.licensing.CommercialLicenseViewId.prototype.setProjectSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.clearProjectSourceCodeLicenseId = function() {
  return this.setProjectSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.hasProjectSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CompanyWideSourceCodeLicenseId company_wide_source_code_license_id = 7;
 * @return {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.getCompanyWideSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.CompanyWideSourceCodeLicenseId, 7));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
*/
proto.teamdev.licensing.CommercialLicenseViewId.prototype.setCompanyWideSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.teamdev.licensing.CommercialLicenseViewId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseViewId} returns this
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.clearCompanyWideSourceCodeLicenseId = function() {
  return this.setCompanyWideSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseViewId.prototype.hasCompanyWideSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.ProductListId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.ProductListId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.ProductListId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProductListId.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.ProductListId}
 */
proto.teamdev.licensing.ProductListId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.ProductListId;
  return proto.teamdev.licensing.ProductListId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.ProductListId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.ProductListId}
 */
proto.teamdev.licensing.ProductListId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.ProductListId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.ProductListId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.ProductListId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.ProductListId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.ProductListId.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.ProductListId} returns this
 */
proto.teamdev.licensing.ProductListId.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.LicenseGenerationId.oneofGroups_ = [[10,11,12,13,14,15,16,17,18,19,20]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.LicenseGenerationId.LicenseIdCase = {
  LICENSE_ID_NOT_SET: 0,
  PERSONAL_LICENSE_ID: 10,
  SINGLE_DEV_LICENSE_ID: 11,
  PROJECT_LICENSE_ID: 12,
  COMPANY_WIDE_LICENSE_ID: 13,
  SINGLE_DEV_SOURCE_CODE_LICENSE_ID: 14,
  PROJECT_SOURCE_CODE_LICENSE_ID: 15,
  COMPANY_WIDE_SOURCE_CODE_LICENSE_ID: 16,
  OPEN_SOURCE_LICENSE_ID: 17,
  ACADEMIC_LICENSE_ID: 18,
  EVALUATION_LICENSE_ID: 19,
  DEMO_LICENSE_ID: 20
};

/**
 * @return {proto.teamdev.licensing.LicenseGenerationId.LicenseIdCase}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getLicenseIdCase = function() {
  return /** @type {proto.teamdev.licensing.LicenseGenerationId.LicenseIdCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.LicenseGenerationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.LicenseGenerationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicenseGenerationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    personalLicenseId: (f = msg.getPersonalLicenseId()) && proto.teamdev.licensing.PersonalLicenseId.toObject(includeInstance, f),
    singleDevLicenseId: (f = msg.getSingleDevLicenseId()) && proto.teamdev.licensing.SingleDevLicenseId.toObject(includeInstance, f),
    projectLicenseId: (f = msg.getProjectLicenseId()) && proto.teamdev.licensing.ProjectLicenseId.toObject(includeInstance, f),
    companyWideLicenseId: (f = msg.getCompanyWideLicenseId()) && proto.teamdev.licensing.CompanyWideLicenseId.toObject(includeInstance, f),
    singleDevSourceCodeLicenseId: (f = msg.getSingleDevSourceCodeLicenseId()) && proto.teamdev.licensing.SingleDevSourceCodeLicenseId.toObject(includeInstance, f),
    projectSourceCodeLicenseId: (f = msg.getProjectSourceCodeLicenseId()) && proto.teamdev.licensing.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    companyWideSourceCodeLicenseId: (f = msg.getCompanyWideSourceCodeLicenseId()) && proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.toObject(includeInstance, f),
    openSourceLicenseId: (f = msg.getOpenSourceLicenseId()) && proto.teamdev.licensing.OpenSourceLicenseId.toObject(includeInstance, f),
    academicLicenseId: (f = msg.getAcademicLicenseId()) && proto.teamdev.licensing.AcademicLicenseId.toObject(includeInstance, f),
    evaluationLicenseId: (f = msg.getEvaluationLicenseId()) && proto.teamdev.licensing.EvaluationId.toObject(includeInstance, f),
    demoLicenseId: (f = msg.getDemoLicenseId()) && proto.teamdev.licensing.DemoLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.LicenseGenerationId}
 */
proto.teamdev.licensing.LicenseGenerationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.LicenseGenerationId;
  return proto.teamdev.licensing.LicenseGenerationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.LicenseGenerationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.LicenseGenerationId}
 */
proto.teamdev.licensing.LicenseGenerationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.teamdev.licensing.PersonalLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.PersonalLicenseId.deserializeBinaryFromReader);
      msg.setPersonalLicenseId(value);
      break;
    case 11:
      var value = new proto.teamdev.licensing.SingleDevLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.setSingleDevLicenseId(value);
      break;
    case 12:
      var value = new proto.teamdev.licensing.ProjectLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.ProjectLicenseId.deserializeBinaryFromReader);
      msg.setProjectLicenseId(value);
      break;
    case 13:
      var value = new proto.teamdev.licensing.CompanyWideLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWideLicenseId(value);
      break;
    case 14:
      var value = new proto.teamdev.licensing.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setSingleDevSourceCodeLicenseId(value);
      break;
    case 15:
      var value = new proto.teamdev.licensing.ProjectSourceCodeLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setProjectSourceCodeLicenseId(value);
      break;
    case 16:
      var value = new proto.teamdev.licensing.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWideSourceCodeLicenseId(value);
      break;
    case 17:
      var value = new proto.teamdev.licensing.OpenSourceLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setOpenSourceLicenseId(value);
      break;
    case 18:
      var value = new proto.teamdev.licensing.AcademicLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setAcademicLicenseId(value);
      break;
    case 19:
      var value = new proto.teamdev.licensing.EvaluationId;
      reader.readMessage(value,proto.teamdev.licensing.EvaluationId.deserializeBinaryFromReader);
      msg.setEvaluationLicenseId(value);
      break;
    case 20:
      var value = new proto.teamdev.licensing.DemoLicenseId;
      reader.readMessage(value,proto.teamdev.licensing.DemoLicenseId.deserializeBinaryFromReader);
      msg.setDemoLicenseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.LicenseGenerationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.LicenseGenerationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicenseGenerationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonalLicenseId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.teamdev.licensing.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevLicenseId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.teamdev.licensing.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectLicenseId();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.teamdev.licensing.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideLicenseId();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.teamdev.licensing.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.teamdev.licensing.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.teamdev.licensing.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceLicenseId();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.teamdev.licensing.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getAcademicLicenseId();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.teamdev.licensing.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getEvaluationLicenseId();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.teamdev.licensing.EvaluationId.serializeBinaryToWriter
    );
  }
  f = message.getDemoLicenseId();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.teamdev.licensing.DemoLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional PersonalLicenseId personal_license_id = 10;
 * @return {?proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getPersonalLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.PersonalLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.PersonalLicenseId, 10));
};


/**
 * @param {?proto.teamdev.licensing.PersonalLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setPersonalLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearPersonalLicenseId = function() {
  return this.setPersonalLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasPersonalLicenseId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SingleDevLicenseId single_dev_license_id = 11;
 * @return {?proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getSingleDevLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.SingleDevLicenseId, 11));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setSingleDevLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearSingleDevLicenseId = function() {
  return this.setSingleDevLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasSingleDevLicenseId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ProjectLicenseId project_license_id = 12;
 * @return {?proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getProjectLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProjectLicenseId, 12));
};


/**
 * @param {?proto.teamdev.licensing.ProjectLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setProjectLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearProjectLicenseId = function() {
  return this.setProjectLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasProjectLicenseId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CompanyWideLicenseId company_wide_license_id = 13;
 * @return {?proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getCompanyWideLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.CompanyWideLicenseId, 13));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setCompanyWideLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearCompanyWideLicenseId = function() {
  return this.setCompanyWideLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasCompanyWideLicenseId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional SingleDevSourceCodeLicenseId single_dev_source_code_license_id = 14;
 * @return {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getSingleDevSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.SingleDevSourceCodeLicenseId, 14));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setSingleDevSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearSingleDevSourceCodeLicenseId = function() {
  return this.setSingleDevSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasSingleDevSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ProjectSourceCodeLicenseId project_source_code_license_id = 15;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getProjectSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.ProjectSourceCodeLicenseId, 15));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setProjectSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearProjectSourceCodeLicenseId = function() {
  return this.setProjectSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasProjectSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional CompanyWideSourceCodeLicenseId company_wide_source_code_license_id = 16;
 * @return {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getCompanyWideSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.CompanyWideSourceCodeLicenseId, 16));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setCompanyWideSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearCompanyWideSourceCodeLicenseId = function() {
  return this.setCompanyWideSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasCompanyWideSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional OpenSourceLicenseId open_source_license_id = 17;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getOpenSourceLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.OpenSourceLicenseId, 17));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setOpenSourceLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearOpenSourceLicenseId = function() {
  return this.setOpenSourceLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasOpenSourceLicenseId = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional AcademicLicenseId academic_license_id = 18;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getAcademicLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.AcademicLicenseId, 18));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setAcademicLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearAcademicLicenseId = function() {
  return this.setAcademicLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasAcademicLicenseId = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional EvaluationId evaluation_license_id = 19;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getEvaluationLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.EvaluationId, 19));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setEvaluationLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearEvaluationLicenseId = function() {
  return this.setEvaluationLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasEvaluationLicenseId = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional DemoLicenseId demo_license_id = 20;
 * @return {?proto.teamdev.licensing.DemoLicenseId}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.getDemoLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.DemoLicenseId} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.DemoLicenseId, 20));
};


/**
 * @param {?proto.teamdev.licensing.DemoLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
*/
proto.teamdev.licensing.LicenseGenerationId.prototype.setDemoLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.teamdev.licensing.LicenseGenerationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseGenerationId} returns this
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.clearDemoLicenseId = function() {
  return this.setDemoLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseGenerationId.prototype.hasDemoLicenseId = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.SampleDataGenerationId.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.SampleDataGenerationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.SampleDataGenerationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.SampleDataGenerationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.SampleDataGenerationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.SampleDataGenerationId;
  return proto.teamdev.licensing.SampleDataGenerationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.SampleDataGenerationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.SampleDataGenerationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.teamdev.licensing.SampleDataGenerationId.Value} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.SampleDataGenerationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.SampleDataGenerationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.SampleDataGenerationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.SampleDataGenerationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.SampleDataGenerationId.Value = {
  SDGI_UNDEFINED: 0,
  SINGLETON_ID: 1
};

/**
 * optional Value value = 1;
 * @return {!proto.teamdev.licensing.SampleDataGenerationId.Value}
 */
proto.teamdev.licensing.SampleDataGenerationId.prototype.getValue = function() {
  return /** @type {!proto.teamdev.licensing.SampleDataGenerationId.Value} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.teamdev.licensing.SampleDataGenerationId.Value} value
 * @return {!proto.teamdev.licensing.SampleDataGenerationId} returns this
 */
proto.teamdev.licensing.SampleDataGenerationId.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


goog.object.extend(exports, proto.teamdev.licensing);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.OpenSourceLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.OpenSourceLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.OpenSourceLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.OpenSourceLicenseId.Parser;
proto.teamdev.licensing.OpenSourceLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.OpenSourceLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.EvaluationId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.EvaluationId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.EvaluationId.Parser.prototype.constructor = proto.teamdev.licensing.EvaluationId.Parser;
proto.teamdev.licensing.EvaluationId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.EvaluationId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.ProductVersionId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.ProductVersionId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.ProductVersionId.Parser.prototype.constructor = proto.teamdev.licensing.ProductVersionId.Parser;
proto.teamdev.licensing.ProductVersionId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.ProductVersionId();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.DailyStatsReportId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.DailyStatsReportId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.DailyStatsReportId.Parser.prototype.constructor = proto.teamdev.licensing.DailyStatsReportId.Parser;
proto.teamdev.licensing.DailyStatsReportId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.DailyStatsReportId();
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.date !== undefined) {
    if (obj.date === null) {
      msg.setDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.date);
      msg.setDate(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.ProjectSourceCodeLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.ProjectSourceCodeLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.ProjectSourceCodeLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.ProjectSourceCodeLicenseId.Parser;
proto.teamdev.licensing.ProjectSourceCodeLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.ProjectSourceCodeLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.ProductId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.ProductId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.ProductId.Parser.prototype.constructor = proto.teamdev.licensing.ProductId.Parser;
proto.teamdev.licensing.ProductId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.ProductId();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.SingleDevSourceCodeLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.SingleDevSourceCodeLicenseId.Parser;
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.SingleDevSourceCodeLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.ProjectLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.ProjectLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.ProjectLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.ProjectLicenseId.Parser;
proto.teamdev.licensing.ProjectLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.ProjectLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.PersonalLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.PersonalLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.PersonalLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.PersonalLicenseId.Parser;
proto.teamdev.licensing.PersonalLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.PersonalLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.DemoLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.DemoLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.DemoLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.DemoLicenseId.Parser;
proto.teamdev.licensing.DemoLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.DemoLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.NonCommercialLicenseRequestId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.NonCommercialLicenseRequestId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.NonCommercialLicenseRequestId.Parser.prototype.constructor = proto.teamdev.licensing.NonCommercialLicenseRequestId.Parser;
proto.teamdev.licensing.NonCommercialLicenseRequestId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.NonCommercialLicenseRequestId();
  
  if (obj.academicId !== undefined) {
    if (obj.academicId === null) {
      msg.setAcademicId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.academicId);
      msg.setAcademicId(value);
    }
  }
  
  if (obj.openSourceId !== undefined) {
    if (obj.openSourceId === null) {
      msg.setOpenSourceId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.openSourceId);
      msg.setOpenSourceId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.CommercialLicenseViewId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.CommercialLicenseViewId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.CommercialLicenseViewId.Parser.prototype.constructor = proto.teamdev.licensing.CommercialLicenseViewId.Parser;
proto.teamdev.licensing.CommercialLicenseViewId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.CommercialLicenseViewId();
  
  if (obj.singleDevLicenseId !== undefined) {
    if (obj.singleDevLicenseId === null) {
      msg.setSingleDevLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(obj.singleDevLicenseId);
      msg.setSingleDevLicenseId(value);
    }
  }
  
  if (obj.projectLicenseId !== undefined) {
    if (obj.projectLicenseId === null) {
      msg.setProjectLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(obj.projectLicenseId);
      msg.setProjectLicenseId(value);
    }
  }
  
  if (obj.companyWideLicenseId !== undefined) {
    if (obj.companyWideLicenseId === null) {
      msg.setCompanyWideLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(obj.companyWideLicenseId);
      msg.setCompanyWideLicenseId(value);
    }
  }
  
  if (obj.personalLicenseId !== undefined) {
    if (obj.personalLicenseId === null) {
      msg.setPersonalLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(obj.personalLicenseId);
      msg.setPersonalLicenseId(value);
    }
  }
  
  if (obj.singleDevSourceCodeLicenseId !== undefined) {
    if (obj.singleDevSourceCodeLicenseId === null) {
      msg.setSingleDevSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(obj.singleDevSourceCodeLicenseId);
      msg.setSingleDevSourceCodeLicenseId(value);
    }
  }
  
  if (obj.projectSourceCodeLicenseId !== undefined) {
    if (obj.projectSourceCodeLicenseId === null) {
      msg.setProjectSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.projectSourceCodeLicenseId);
      msg.setProjectSourceCodeLicenseId(value);
    }
  }
  
  if (obj.companyWideSourceCodeLicenseId !== undefined) {
    if (obj.companyWideSourceCodeLicenseId === null) {
      msg.setCompanyWideSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(obj.companyWideSourceCodeLicenseId);
      msg.setCompanyWideSourceCodeLicenseId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.SingleDevLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.SingleDevLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.SingleDevLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.SingleDevLicenseId.Parser;
proto.teamdev.licensing.SingleDevLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.SingleDevLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.Parser;
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.CompanyWideSourceCodeLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.ProductListId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.ProductListId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.ProductListId.Parser.prototype.constructor = proto.teamdev.licensing.ProductListId.Parser;
proto.teamdev.licensing.ProductListId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.ProductListId();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.NonCommercialLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.NonCommercialLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.NonCommercialLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.NonCommercialLicenseId.Parser;
proto.teamdev.licensing.NonCommercialLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.NonCommercialLicenseId();
  
  if (obj.academicId !== undefined) {
    if (obj.academicId === null) {
      msg.setAcademicId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.academicId);
      msg.setAcademicId(value);
    }
  }
  
  if (obj.openSourceId !== undefined) {
    if (obj.openSourceId === null) {
      msg.setOpenSourceId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.openSourceId);
      msg.setOpenSourceId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.SampleDataGenerationId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.SampleDataGenerationId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.SampleDataGenerationId.Parser.prototype.constructor = proto.teamdev.licensing.SampleDataGenerationId.Parser;
proto.teamdev.licensing.SampleDataGenerationId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.SampleDataGenerationId();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = proto.teamdev.licensing.SampleDataGenerationId.Value[obj.value];
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.AcademicLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.AcademicLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.AcademicLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.AcademicLicenseId.Parser;
proto.teamdev.licensing.AcademicLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.AcademicLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.CompanyWideLicenseId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.CompanyWideLicenseId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.CompanyWideLicenseId.Parser.prototype.constructor = proto.teamdev.licensing.CompanyWideLicenseId.Parser;
proto.teamdev.licensing.CompanyWideLicenseId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.CompanyWideLicenseId();
  
  if (obj.uuid !== undefined) {
    if (obj.uuid !== null) {
      let value = obj.uuid;
      msg.setUuid(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.LicenseGenerationId.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.LicenseGenerationId.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.LicenseGenerationId.Parser.prototype.constructor = proto.teamdev.licensing.LicenseGenerationId.Parser;
proto.teamdev.licensing.LicenseGenerationId.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.LicenseGenerationId();
  
  if (obj.personalLicenseId !== undefined) {
    if (obj.personalLicenseId === null) {
      msg.setPersonalLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(obj.personalLicenseId);
      msg.setPersonalLicenseId(value);
    }
  }
  
  if (obj.singleDevLicenseId !== undefined) {
    if (obj.singleDevLicenseId === null) {
      msg.setSingleDevLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(obj.singleDevLicenseId);
      msg.setSingleDevLicenseId(value);
    }
  }
  
  if (obj.projectLicenseId !== undefined) {
    if (obj.projectLicenseId === null) {
      msg.setProjectLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(obj.projectLicenseId);
      msg.setProjectLicenseId(value);
    }
  }
  
  if (obj.companyWideLicenseId !== undefined) {
    if (obj.companyWideLicenseId === null) {
      msg.setCompanyWideLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(obj.companyWideLicenseId);
      msg.setCompanyWideLicenseId(value);
    }
  }
  
  if (obj.singleDevSourceCodeLicenseId !== undefined) {
    if (obj.singleDevSourceCodeLicenseId === null) {
      msg.setSingleDevSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(obj.singleDevSourceCodeLicenseId);
      msg.setSingleDevSourceCodeLicenseId(value);
    }
  }
  
  if (obj.projectSourceCodeLicenseId !== undefined) {
    if (obj.projectSourceCodeLicenseId === null) {
      msg.setProjectSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.projectSourceCodeLicenseId);
      msg.setProjectSourceCodeLicenseId(value);
    }
  }
  
  if (obj.companyWideSourceCodeLicenseId !== undefined) {
    if (obj.companyWideSourceCodeLicenseId === null) {
      msg.setCompanyWideSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(obj.companyWideSourceCodeLicenseId);
      msg.setCompanyWideSourceCodeLicenseId(value);
    }
  }
  
  if (obj.openSourceLicenseId !== undefined) {
    if (obj.openSourceLicenseId === null) {
      msg.setOpenSourceLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.openSourceLicenseId);
      msg.setOpenSourceLicenseId(value);
    }
  }
  
  if (obj.academicLicenseId !== undefined) {
    if (obj.academicLicenseId === null) {
      msg.setAcademicLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.academicLicenseId);
      msg.setAcademicLicenseId(value);
    }
  }
  
  if (obj.evaluationLicenseId !== undefined) {
    if (obj.evaluationLicenseId === null) {
      msg.setEvaluationLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.evaluationLicenseId);
      msg.setEvaluationLicenseId(value);
    }
  }
  
  if (obj.demoLicenseId !== undefined) {
    if (obj.demoLicenseId === null) {
      msg.setDemoLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.DemoLicenseId').fromObject(obj.demoLicenseId);
      msg.setDemoLicenseId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.OpenSourceLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.OpenSourceLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.EvaluationId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.EvaluationId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.ProductVersionId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.ProductVersionId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.DailyStatsReportId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.DailyStatsReportId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.ProjectSourceCodeLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.ProductId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.ProductId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.SingleDevSourceCodeLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.ProjectLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.ProjectLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.PersonalLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.PersonalLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.DemoLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.DemoLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.NonCommercialLicenseRequestId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.NonCommercialLicenseRequestId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.CommercialLicenseViewId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.CommercialLicenseViewId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.SingleDevLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.SingleDevLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.CompanyWideSourceCodeLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.ProductListId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.ProductListId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.NonCommercialLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.NonCommercialLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.SampleDataGenerationId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.SampleDataGenerationId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.AcademicLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.AcademicLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.CompanyWideLicenseId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.CompanyWideLicenseId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.LicenseGenerationId.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.LicenseGenerationId';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.SampleDataGenerationId.Value.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.SampleDataGenerationId.Value';
};
