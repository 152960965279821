// source: teamdev/licensing/c/product/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
var teamdev_licensing_c_product_licensing_policy_pb = require('../../../../teamdev/licensing/c/product/licensing_policy_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_licensing_policy_pb);
goog.exportSymbol('proto.teamdev.licensing.c.product.AssignMainstreamVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.CreateProduct', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.CreateProductVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.UpdateProductDetails', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.UpdateProductVersionLicensing', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.CreateProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.CreateProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.CreateProduct.displayName = 'proto.teamdev.licensing.c.product.CreateProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.UpdateProductDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.UpdateProductDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.UpdateProductDetails.displayName = 'proto.teamdev.licensing.c.product.UpdateProductDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.AssignMainstreamVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.AssignMainstreamVersion.displayName = 'proto.teamdev.licensing.c.product.AssignMainstreamVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.CreateProductVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.CreateProductVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.CreateProductVersion.displayName = 'proto.teamdev.licensing.c.product.CreateProductVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.UpdateProductVersionLicensing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.displayName = 'proto.teamdev.licensing.c.product.UpdateProductVersionLicensing';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.CreateProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.CreateProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CreateProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    displayName: (f = msg.getDisplayName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    defaultPolicy: (f = msg.getDefaultPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f),
    logo: (f = msg.getLogo()) && teamdev_licensing_c_product_values_pb.ProductLogo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.CreateProduct}
 */
proto.teamdev.licensing.c.product.CreateProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.CreateProduct;
  return proto.teamdev.licensing.c.product.CreateProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.CreateProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.CreateProduct}
 */
proto.teamdev.licensing.c.product.CreateProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setDefaultPolicy(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_values_pb.ProductLogo;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductLogo.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.CreateProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.CreateProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CreateProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getDefaultPolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_product_values_pb.ProductLogo.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
*/
proto.teamdev.licensing.c.product.CreateProduct.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductName display_name = 2;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
*/
proto.teamdev.licensing.c.product.CreateProduct.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LicensingPolicy default_policy = 3;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.getDefaultPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
*/
proto.teamdev.licensing.c.product.CreateProduct.prototype.setDefaultPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.clearDefaultPolicy = function() {
  return this.setDefaultPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.hasDefaultPolicy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProductLogo logo = 4;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.getLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductLogo, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
*/
proto.teamdev.licensing.c.product.CreateProduct.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProduct} returns this
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProduct.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.UpdateProductDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.UpdateProductDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    displayName: (f = msg.getDisplayName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    defaultPolicy: (f = msg.getDefaultPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f),
    logo: (f = msg.getLogo()) && teamdev_licensing_c_product_values_pb.ProductLogo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.UpdateProductDetails;
  return proto.teamdev.licensing.c.product.UpdateProductDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.UpdateProductDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setDefaultPolicy(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_values_pb.ProductLogo;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductLogo.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.UpdateProductDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.UpdateProductDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getDefaultPolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_product_values_pb.ProductLogo.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
*/
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductName display_name = 2;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
*/
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LicensingPolicy default_policy = 3;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.getDefaultPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
*/
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.setDefaultPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.clearDefaultPolicy = function() {
  return this.setDefaultPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.hasDefaultPolicy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProductLogo logo = 4;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.getLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductLogo, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
*/
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductDetails} returns this
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.UpdateProductDetails.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.AssignMainstreamVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.AssignMainstreamVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.AssignMainstreamVersion;
  return proto.teamdev.licensing.c.product.AssignMainstreamVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.AssignMainstreamVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.AssignMainstreamVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.AssignMainstreamVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion} returns this
*/
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion} returns this
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion} returns this
*/
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion} returns this
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.AssignMainstreamVersion.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.CreateProductVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.CreateProductVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CreateProductVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    displayName: (f = msg.getDisplayName()) && teamdev_licensing_c_product_values_pb.VersionName.toObject(includeInstance, f),
    licensingPolicy: (f = msg.getLicensingPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && teamdev_licensing_c_product_values_pb.VersionDescription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.CreateProductVersion;
  return proto.teamdev.licensing.c.product.CreateProductVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.CreateProductVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.VersionName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setLicensingPolicy(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_values_pb.VersionDescription;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionDescription.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.CreateProductVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.CreateProductVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.CreateProductVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.VersionName.serializeBinaryToWriter
    );
  }
  f = message.getLicensingPolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_product_values_pb.VersionDescription.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
*/
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional VersionName display_name = 2;
 * @return {?proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionName|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
*/
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LicensingPolicy licensing_policy = 3;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.getLicensingPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
*/
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.setLicensingPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.clearLicensingPolicy = function() {
  return this.setLicensingPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.hasLicensingPolicy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional VersionDescription description = 4;
 * @return {?proto.teamdev.licensing.c.product.VersionDescription}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.getDescription = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionDescription} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionDescription, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionDescription|undefined} value
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
*/
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion} returns this
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.CreateProductVersion.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    newPolicy: (f = msg.getNewPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.UpdateProductVersionLicensing;
  return proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setNewPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getNewPolicy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing} returns this
*/
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing} returns this
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicensingPolicy new_policy = 2;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.getNewPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing} returns this
*/
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.setNewPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.UpdateProductVersionLicensing} returns this
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.clearNewPolicy = function() {
  return this.setNewPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.prototype.hasNewPolicy = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.product);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.product.UpdateProductDetails.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.UpdateProductDetails.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.UpdateProductDetails.Parser.prototype.constructor = proto.teamdev.licensing.c.product.UpdateProductDetails.Parser;
proto.teamdev.licensing.c.product.UpdateProductDetails.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.UpdateProductDetails();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.defaultPolicy !== undefined) {
    if (obj.defaultPolicy === null) {
      msg.setDefaultPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.defaultPolicy);
      msg.setDefaultPolicy(value);
    }
  }
  
  if (obj.logo !== undefined) {
    if (obj.logo === null) {
      msg.setLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.logo);
      msg.setLogo(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.Parser.prototype.constructor = proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.Parser;
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.UpdateProductVersionLicensing();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.newPolicy !== undefined) {
    if (obj.newPolicy === null) {
      msg.setNewPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.newPolicy);
      msg.setNewPolicy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.CreateProduct.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.CreateProduct.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.CreateProduct.Parser.prototype.constructor = proto.teamdev.licensing.c.product.CreateProduct.Parser;
proto.teamdev.licensing.c.product.CreateProduct.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.CreateProduct();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.defaultPolicy !== undefined) {
    if (obj.defaultPolicy === null) {
      msg.setDefaultPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.defaultPolicy);
      msg.setDefaultPolicy(value);
    }
  }
  
  if (obj.logo !== undefined) {
    if (obj.logo === null) {
      msg.setLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.logo);
      msg.setLogo(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.AssignMainstreamVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.AssignMainstreamVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.AssignMainstreamVersion.Parser.prototype.constructor = proto.teamdev.licensing.c.product.AssignMainstreamVersion.Parser;
proto.teamdev.licensing.c.product.AssignMainstreamVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.AssignMainstreamVersion();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.CreateProductVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.CreateProductVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.CreateProductVersion.Parser.prototype.constructor = proto.teamdev.licensing.c.product.CreateProductVersion.Parser;
proto.teamdev.licensing.c.product.CreateProductVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.CreateProductVersion();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.licensingPolicy !== undefined) {
    if (obj.licensingPolicy === null) {
      msg.setLicensingPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.licensingPolicy);
      msg.setLicensingPolicy(value);
    }
  }
  
  if (obj.description !== undefined) {
    if (obj.description === null) {
      msg.setDescription(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionDescription').fromObject(obj.description);
      msg.setDescription(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.UpdateProductDetails.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.UpdateProductDetails';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.UpdateProductVersionLicensing.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.UpdateProductVersionLicensing';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.CreateProduct.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.CreateProduct';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.AssignMainstreamVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.AssignMainstreamVersion';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.CreateProductVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.CreateProductVersion';
};
