import { render, staticRenderFns } from "./LservTooltip.vue?vue&type=template&id=976c2d64&scoped=true&"
import script from "./LservTooltip.vue?vue&type=script&lang=ts&"
export * from "./LservTooltip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "976c2d64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
