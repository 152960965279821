// source: teamdev/licensing/c/opensource/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_c_opensource_values_pb = require('../../../../teamdev/licensing/c/opensource/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_opensource_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_project_binding_pb = require('../../../../teamdev/licensing/project_binding_pb.js');
goog.object.extend(proto, teamdev_licensing_project_binding_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.displayName = 'proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.displayName = 'proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.displayName = 'proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.displayName = 'proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.displayName = 'proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.displayName = 'proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.displayName = 'proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.displayName = 'proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.displayName = 'proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.displayName = 'proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.displayName = 'proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.displayName = 'proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.displayName = 'proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.displayName = 'proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.displayName = 'proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.displayName = 'proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    projectName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    distributionLicense: jspb.Message.getFieldWithDefault(msg, 5, ""),
    repositoryUrl: (f = msg.getRepositoryUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    promotionRequirementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    licenseAgreementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    disclaimerNoticeRequirementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense;
  return proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionLicense(value);
      break;
    case 6:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 8:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPromotionRequirementAccepted(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseAgreementAccepted(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisclaimerNoticeRequirementAccepted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDistributionLicense();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRepositoryUrl();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getPromotionRequirementAccepted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLicenseAgreementAccepted();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDisclaimerNoticeRequirementAccepted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 2;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OpenSourceLicenseRequester requester = 3;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string project_name = 4;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string distribution_license = 5;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getDistributionLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setDistributionLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional spine.net.Url repository_url = 6;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getRepositoryUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 6));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setRepositoryUrl = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.clearRepositoryUrl = function() {
  return this.setRepositoryUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.hasRepositoryUrl = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string project_description = 7;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 8;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 8));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool promotion_requirement_accepted = 9;
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getPromotionRequirementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setPromotionRequirementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool license_agreement_accepted = 10;
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getLicenseAgreementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setLicenseAgreementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool disclaimer_notice_requirement_accepted = 11;
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.getDisclaimerNoticeRequirementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.prototype.setDisclaimerNoticeRequirementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification;
  return proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification} returns this
*/
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification} returns this
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified;
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.core.UserId verified_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed;
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 2;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId verified_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense;
  return proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    projectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    distributionLicense: jspb.Message.getFieldWithDefault(msg, 6, ""),
    repositoryUrl: (f = msg.getRepositoryUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 8, ""),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch;
  return proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionLicense(value);
      break;
    case 7:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 9:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 10:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 11:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDistributionLicense();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRepositoryUrl();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OpenSourceLicenseRequester requester = 4;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string project_name = 5;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string distribution_license = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getDistributionLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setDistributionLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.net.Url repository_url = 7;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getRepositoryUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 7));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setRepositoryUrl = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearRepositoryUrl = function() {
  return this.setRepositoryUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasRepositoryUrl = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string project_description = 8;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 9;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 9));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional spine.time.LocalDate active_through = 10;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 10));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional spine.core.UserId created_by = 11;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.getCreatedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 11));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest;
  return proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest;
  return proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_opensource_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} returns this
*/
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} returns this
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestRefusalReason reason = 2;
 * @return {?proto.teamdev.licensing.c.opensource.RequestRefusalReason}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.RequestRefusalReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} returns this
*/
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} returns this
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId refused_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} returns this
*/
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest} returns this
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    licenseAgreementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    reason: (f = msg.getReason()) && teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason.toObject(includeInstance, f),
    distributionLicense: jspb.Message.getFieldWithDefault(msg, 4, ""),
    repositoryUrl: (f = msg.getRepositoryUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension;
  return proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseAgreementAccepted(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionLicense(value);
      break;
    case 5:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryUrl(value);
      break;
    case 6:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getLicenseAgreementAccepted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason.serializeBinaryToWriter
    );
  }
  f = message.getDistributionLicense();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepositoryUrl();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool license_agreement_accepted = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.getLicenseAgreementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.setLicenseAgreementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional ExtensionRequestReason reason = 3;
 * @return {?proto.teamdev.licensing.c.opensource.ExtensionRequestReason}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.ExtensionRequestReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.ExtensionRequestReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string distribution_license = 4;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.getDistributionLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.setDistributionLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional spine.net.Url repository_url = 5;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.getRepositoryUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 5));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.setRepositoryUrl = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.clearRepositoryUrl = function() {
  return this.setRepositoryUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.hasRepositoryUrl = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 6;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
*/
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension} returns this
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification;
  return proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification} returns this
*/
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification} returns this
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified;
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.core.UserId verified_by = 2;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 2));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed;
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 2;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId verified_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense;
  return proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.LocalDate active_through = 2;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 2));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest;
  return proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_opensource_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} returns this
*/
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} returns this
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestRefusalReason reason = 2;
 * @return {?proto.teamdev.licensing.c.opensource.RequestRefusalReason}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.RequestRefusalReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} returns this
*/
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} returns this
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId refused_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} returns this
*/
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest} returns this
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense;
  return proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.NonCommercialLicenseRevocationReason reason = 2;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId revoked_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} returns this
*/
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense} returns this
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired;
  return proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired} returns this
*/
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired} returns this
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.opensource);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.Parser;
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.Parser;
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.Parser;
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.Parser;
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.Parser;
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.Parser;
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.Parser;
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.Parser;
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.Parser;
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.Parser;
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectName !== undefined) {
    if (obj.projectName !== null) {
      let value = obj.projectName;
      msg.setProjectName(value);
    }
  }
  
  if (obj.distributionLicense !== undefined) {
    if (obj.distributionLicense !== null) {
      let value = obj.distributionLicense;
      msg.setDistributionLicense(value);
    }
  }
  
  if (obj.repositoryUrl !== undefined) {
    if (obj.repositoryUrl === null) {
      msg.setRepositoryUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryUrl);
      msg.setRepositoryUrl(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.createdBy !== undefined) {
    if (obj.createdBy === null) {
      msg.setCreatedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.createdBy);
      msg.setCreatedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.Parser;
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.Parser;
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.Parser;
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.licenseAgreementAccepted !== undefined) {
    if (obj.licenseAgreementAccepted !== null) {
      let value = obj.licenseAgreementAccepted;
      msg.setLicenseAgreementAccepted(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.ExtensionRequestReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.distributionLicense !== undefined) {
    if (obj.distributionLicense !== null) {
      let value = obj.distributionLicense;
      msg.setDistributionLicense(value);
    }
  }
  
  if (obj.repositoryUrl !== undefined) {
    if (obj.repositoryUrl === null) {
      msg.setRepositoryUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryUrl);
      msg.setRepositoryUrl(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.Parser;
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectName !== undefined) {
    if (obj.projectName !== null) {
      let value = obj.projectName;
      msg.setProjectName(value);
    }
  }
  
  if (obj.distributionLicense !== undefined) {
    if (obj.distributionLicense !== null) {
      let value = obj.distributionLicense;
      msg.setDistributionLicense(value);
    }
  }
  
  if (obj.repositoryUrl !== undefined) {
    if (obj.repositoryUrl === null) {
      msg.setRepositoryUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryUrl);
      msg.setRepositoryUrl(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.promotionRequirementAccepted !== undefined) {
    if (obj.promotionRequirementAccepted !== null) {
      let value = obj.promotionRequirementAccepted;
      msg.setPromotionRequirementAccepted(value);
    }
  }
  
  if (obj.licenseAgreementAccepted !== undefined) {
    if (obj.licenseAgreementAccepted !== null) {
      let value = obj.licenseAgreementAccepted;
      msg.setLicenseAgreementAccepted(value);
    }
  }
  
  if (obj.disclaimerNoticeRequirementAccepted !== undefined) {
    if (obj.disclaimerNoticeRequirementAccepted !== null) {
      let value = obj.disclaimerNoticeRequirementAccepted;
      msg.setDisclaimerNoticeRequirementAccepted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.Parser;
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.Parser;
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.StartOpenSourceLicenseRequestVerification';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.GrantOpenSourceLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.GrantOpenSourceLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.RefuseOpenSourceLicenseRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.RefuseOpenSourceLicenseExtensionRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.RevokeOpenSourceLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.RevokeOpenSourceLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.GrantExtendedOpenSourceLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseExtensionRequestAsVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseAsExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.RequestOpenSourceLicenseExtension';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.RequestOpenSourceLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.RequestOpenSourceLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.StartOpenSourceLicenseExtensionRequestVerification';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.MarkOpenSourceLicenseRequestAsVerified';
};
