import { render, staticRenderFns } from "./MenuItem.vue?vue&type=template&id=c6b38faa&scoped=true&"
import script from "./MenuItem.vue?vue&type=script&lang=ts&"
export * from "./MenuItem.vue?vue&type=script&lang=ts&"
import style0 from "./MenuItem.vue?vue&type=style&index=0&id=c6b38faa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b38faa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCheckbox,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemTitle})
