// source: teamdev/licensing/q/product/product_view.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
var teamdev_licensing_c_product_licensing_policy_pb = require('../../../../teamdev/licensing/c/product/licensing_policy_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_licensing_policy_pb);
goog.exportSymbol('proto.teamdev.licensing.q.product.ProductDropDownList', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.product.ProductVersionDropDownList', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.product.ProductView', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.product.VersionItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.product.ProductView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.product.ProductView.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.q.product.ProductView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.product.ProductView.displayName = 'proto.teamdev.licensing.q.product.ProductView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.product.VersionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.product.VersionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.product.VersionItem.displayName = 'proto.teamdev.licensing.q.product.VersionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.product.ProductDropDownList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.product.ProductDropDownList.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.q.product.ProductDropDownList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.product.ProductDropDownList.displayName = 'proto.teamdev.licensing.q.product.ProductDropDownList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.displayName = 'proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.product.ProductVersionDropDownList.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.q.product.ProductVersionDropDownList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.product.ProductVersionDropDownList.displayName = 'proto.teamdev.licensing.q.product.ProductVersionDropDownList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.displayName = 'proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.product.ProductView.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.product.ProductView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.product.ProductView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    versionItemList: jspb.Message.toObjectList(msg.getVersionItemList(),
    proto.teamdev.licensing.q.product.VersionItem.toObject, includeInstance),
    logo: (f = msg.getLogo()) && teamdev_licensing_c_product_values_pb.ProductLogo.toObject(includeInstance, f),
    whenCreated: (f = msg.getWhenCreated()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.product.ProductView}
 */
proto.teamdev.licensing.q.product.ProductView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.product.ProductView;
  return proto.teamdev.licensing.q.product.ProductView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.product.ProductView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.product.ProductView}
 */
proto.teamdev.licensing.q.product.ProductView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.q.product.VersionItem;
      reader.readMessage(value,proto.teamdev.licensing.q.product.VersionItem.deserializeBinaryFromReader);
      msg.addVersionItem(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_product_values_pb.ProductLogo;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductLogo.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    case 6:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.product.ProductView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.product.ProductView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getVersionItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.teamdev.licensing.q.product.VersionItem.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_product_values_pb.ProductLogo.serializeBinaryToWriter
    );
  }
  f = message.getWhenCreated();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
*/
proto.teamdev.licensing.q.product.ProductView.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
 */
proto.teamdev.licensing.q.product.ProductView.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductName product_name = 2;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.getProductName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
*/
proto.teamdev.licensing.q.product.ProductView.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
 */
proto.teamdev.licensing.q.product.ProductView.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated VersionItem version_item = 3;
 * @return {!Array<!proto.teamdev.licensing.q.product.VersionItem>}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.getVersionItemList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.product.VersionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.product.VersionItem, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.product.VersionItem>} value
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
*/
proto.teamdev.licensing.q.product.ProductView.prototype.setVersionItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.teamdev.licensing.q.product.VersionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.product.VersionItem}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.addVersionItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.teamdev.licensing.q.product.VersionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
 */
proto.teamdev.licensing.q.product.ProductView.prototype.clearVersionItemList = function() {
  return this.setVersionItemList([]);
};


/**
 * optional teamdev.licensing.c.product.ProductLogo logo = 5;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.getLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductLogo, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
*/
proto.teamdev.licensing.q.product.ProductView.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
 */
proto.teamdev.licensing.q.product.ProductView.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.time.ZonedDateTime when_created = 6;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.getWhenCreated = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 6));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
*/
proto.teamdev.licensing.q.product.ProductView.prototype.setWhenCreated = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductView} returns this
 */
proto.teamdev.licensing.q.product.ProductView.prototype.clearWhenCreated = function() {
  return this.setWhenCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductView.prototype.hasWhenCreated = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.product.VersionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.product.VersionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.VersionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    displayName: (f = msg.getDisplayName()) && teamdev_licensing_c_product_values_pb.VersionName.toObject(includeInstance, f),
    licensingPolicy: (f = msg.getLicensingPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && teamdev_licensing_c_product_values_pb.VersionDescription.toObject(includeInstance, f),
    whenReleased: (f = msg.getWhenReleased()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.product.VersionItem}
 */
proto.teamdev.licensing.q.product.VersionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.product.VersionItem;
  return proto.teamdev.licensing.q.product.VersionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.product.VersionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.product.VersionItem}
 */
proto.teamdev.licensing.q.product.VersionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.VersionName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setLicensingPolicy(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_values_pb.VersionDescription;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionDescription.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenReleased(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.product.VersionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.product.VersionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.VersionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.VersionName.serializeBinaryToWriter
    );
  }
  f = message.getLicensingPolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_product_values_pb.VersionDescription.serializeBinaryToWriter
    );
  }
  f = message.getWhenReleased();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
*/
proto.teamdev.licensing.q.product.VersionItem.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.c.product.VersionName display_name = 2;
 * @return {?proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionName|undefined} value
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
*/
proto.teamdev.licensing.q.product.VersionItem.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.c.product.LicensingPolicy licensing_policy = 3;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.getLicensingPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
*/
proto.teamdev.licensing.q.product.VersionItem.prototype.setLicensingPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.clearLicensingPolicy = function() {
  return this.setLicensingPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.hasLicensingPolicy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.c.product.VersionDescription description = 4;
 * @return {?proto.teamdev.licensing.c.product.VersionDescription}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.getDescription = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionDescription} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionDescription, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionDescription|undefined} value
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
*/
proto.teamdev.licensing.q.product.VersionItem.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.ZonedDateTime when_released = 5;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.getWhenReleased = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 5));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
*/
proto.teamdev.licensing.q.product.VersionItem.prototype.setWhenReleased = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.VersionItem} returns this
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.clearWhenReleased = function() {
  return this.setWhenReleased(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.VersionItem.prototype.hasWhenReleased = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.product.ProductDropDownList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.product.ProductDropDownList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.product.ProductDropDownList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductDropDownList.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.product.ProductDropDownList;
  return proto.teamdev.licensing.q.product.ProductDropDownList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.product.ProductDropDownList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem;
      reader.readMessage(value,proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.product.ProductDropDownList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.product.ProductDropDownList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductDropDownList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    displayName: (f = msg.getDisplayName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    logo: (f = msg.getLogo()) && teamdev_licensing_c_product_values_pb.ProductLogo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem;
  return proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_product_values_pb.ProductLogo;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductLogo.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_product_values_pb.ProductLogo.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId product_id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} returns this
*/
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} returns this
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductName display_name = 2;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} returns this
*/
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} returns this
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductLogo logo = 3;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.getLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductLogo, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} returns this
*/
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem} returns this
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ProductDropDownListItem products = 1;
 * @return {!Array<!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem>}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem, 1));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem>} value
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList} returns this
*/
proto.teamdev.licensing.q.product.ProductDropDownList.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem}
 */
proto.teamdev.licensing.q.product.ProductDropDownList.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.product.ProductDropDownList} returns this
 */
proto.teamdev.licensing.q.product.ProductDropDownList.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.product.ProductVersionDropDownList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.product.ProductVersionDropDownList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.product.ProductVersionDropDownList;
  return proto.teamdev.licensing.q.product.ProductVersionDropDownList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.product.ProductVersionDropDownList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem;
      reader.readMessage(value,proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.product.ProductVersionDropDownList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.product.ProductVersionDropDownList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    productVersionId: (f = msg.getProductVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    displayName: (f = msg.getDisplayName()) && teamdev_licensing_c_product_values_pb.VersionName.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem;
  return proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setProductVersionId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.VersionName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductVersionId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.VersionName.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId product_version_id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.getProductVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem} returns this
*/
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.setProductVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem} returns this
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.clearProductVersionId = function() {
  return this.setProductVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.hasProductVersionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.c.product.VersionName display_name = 2;
 * @return {?proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionName|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem} returns this
*/
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem} returns this
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList} returns this
*/
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList} returns this
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProductVersionDropDownListItem versions = 2;
 * @return {!Array<!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem>}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem>} value
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList} returns this
*/
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem}
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.product.ProductVersionDropDownList} returns this
 */
proto.teamdev.licensing.q.product.ProductVersionDropDownList.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


goog.object.extend(exports, proto.teamdev.licensing.q.product);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.q.product.ProductDropDownList.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.product.ProductDropDownList.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.product.ProductDropDownList.Parser.prototype.constructor = proto.teamdev.licensing.q.product.ProductDropDownList.Parser;
proto.teamdev.licensing.q.product.ProductDropDownList.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.product.ProductDropDownList();
  
  if (obj.products !== undefined && obj.products !== null) {
    obj.products.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProducts(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem').fromObject(listItem);
          msg.addProducts(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.Parser.prototype.constructor = proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.Parser;
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem();
  
  if (obj.productVersionId !== undefined) {
    if (obj.productVersionId === null) {
      msg.setProductVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.productVersionId);
      msg.setProductVersionId(value);
    }
  }
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.product.ProductVersionDropDownList.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.product.ProductVersionDropDownList.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.product.ProductVersionDropDownList.Parser.prototype.constructor = proto.teamdev.licensing.q.product.ProductVersionDropDownList.Parser;
proto.teamdev.licensing.q.product.ProductVersionDropDownList.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.product.ProductVersionDropDownList();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.versions !== undefined && obj.versions !== null) {
    obj.versions.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addVersions(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem').fromObject(listItem);
          msg.addVersions(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.q.product.VersionItem.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.product.VersionItem.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.product.VersionItem.Parser.prototype.constructor = proto.teamdev.licensing.q.product.VersionItem.Parser;
proto.teamdev.licensing.q.product.VersionItem.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.product.VersionItem();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.licensingPolicy !== undefined) {
    if (obj.licensingPolicy === null) {
      msg.setLicensingPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.licensingPolicy);
      msg.setLicensingPolicy(value);
    }
  }
  
  if (obj.description !== undefined) {
    if (obj.description === null) {
      msg.setDescription(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionDescription').fromObject(obj.description);
      msg.setDescription(value);
    }
  }
  
  if (obj.whenReleased !== undefined) {
    if (obj.whenReleased === null) {
      msg.setWhenReleased(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenReleased);
      msg.setWhenReleased(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.product.ProductView.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.product.ProductView.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.product.ProductView.Parser.prototype.constructor = proto.teamdev.licensing.q.product.ProductView.Parser;
proto.teamdev.licensing.q.product.ProductView.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.product.ProductView();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName === null) {
      msg.setProductName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.productName);
      msg.setProductName(value);
    }
  }
  
  if (obj.versionItem !== undefined && obj.versionItem !== null) {
    obj.versionItem.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addVersionItem(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.product.VersionItem').fromObject(listItem);
          msg.addVersionItem(value);
        }
      }
    );
  }
  
  if (obj.logo !== undefined) {
    if (obj.logo === null) {
      msg.setLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.logo);
      msg.setLogo(value);
    }
  }
  
  if (obj.whenCreated !== undefined) {
    if (obj.whenCreated === null) {
      msg.setWhenCreated(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenCreated);
      msg.setWhenCreated(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.Parser.prototype.constructor = proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.Parser;
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem();
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.logo !== undefined) {
    if (obj.logo === null) {
      msg.setLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.logo);
      msg.setLogo(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.product.ProductDropDownList.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.product.ProductDropDownList';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.product.ProductVersionDropDownList.ProductVersionDropDownListItem';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.product.ProductVersionDropDownList.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.product.ProductVersionDropDownList';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.product.VersionItem.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.product.VersionItem';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.product.ProductView.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.product.ProductView';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.product.ProductDropDownList.ProductDropDownListItem';
};
