// source: teamdev/licensing/c/demo/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.demo.RequestDemoLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.demo.RevokeDemoLicense', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.demo.RequestDemoLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.demo.RequestDemoLicense.displayName = 'proto.teamdev.licensing.c.demo.RequestDemoLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.demo.RevokeDemoLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.demo.RevokeDemoLicense.displayName = 'proto.teamdev.licensing.c.demo.RevokeDemoLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.displayName = 'proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.demo.RequestDemoLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.demo.RequestDemoLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.DemoLicenseId.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    productid: (f = msg.getProductid()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.demo.RequestDemoLicense;
  return proto.teamdev.licensing.c.demo.RequestDemoLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.demo.RequestDemoLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.DemoLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.DemoLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 3:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.demo.RequestDemoLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.demo.RequestDemoLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.DemoLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getProductid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.DemoLicenseId id = 1;
 * @return {?proto.teamdev.licensing.DemoLicenseId}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.DemoLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.DemoLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.DemoLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
*/
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.LocalDate active_through = 2;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 2));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
*/
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 3;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
*/
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId productId = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.getProductid = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
*/
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.setProductid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense} returns this
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.clearProductid = function() {
  return this.setProductid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.RequestDemoLicense.prototype.hasProductid = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.demo.RevokeDemoLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.DemoLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.demo.RevokeDemoLicense;
  return proto.teamdev.licensing.c.demo.RevokeDemoLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.DemoLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.DemoLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.demo.RevokeDemoLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.DemoLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.DemoLicenseId id = 1;
 * @return {?proto.teamdev.licensing.DemoLicenseId}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.DemoLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.DemoLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.DemoLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} returns this
*/
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} returns this
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.NonCommercialLicenseRevocationReason reason = 2;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} returns this
*/
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} returns this
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId revoked_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} returns this
*/
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.RevokeDemoLicense} returns this
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.RevokeDemoLicense.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.DemoLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired}
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired;
  return proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired}
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.DemoLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.DemoLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.DemoLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.DemoLicenseId id = 1;
 * @return {?proto.teamdev.licensing.DemoLicenseId}
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.DemoLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.DemoLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.DemoLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired} returns this
*/
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired} returns this
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.demo);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.demo.RequestDemoLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.demo.RequestDemoLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.demo.RequestDemoLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.demo.RequestDemoLicense.Parser;
proto.teamdev.licensing.c.demo.RequestDemoLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.demo.RequestDemoLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.DemoLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.demo.RevokeDemoLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.demo.RevokeDemoLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.demo.RevokeDemoLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.demo.RevokeDemoLicense.Parser;
proto.teamdev.licensing.c.demo.RevokeDemoLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.demo.RevokeDemoLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.DemoLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.Parser;
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.DemoLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.demo.RequestDemoLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.demo.RequestDemoLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.demo.RevokeDemoLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.demo.RevokeDemoLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.demo.MarkDemoLicenseAsExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.demo.MarkDemoLicenseAsExpired';
};
