var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.recordGroups),function(recordGroup){return _c('div',{key:recordGroup.groupDef.id},[_c('flex-horizontal',{staticClass:"group-header"},[_c('subscript-gray',[_vm._v(_vm._s(recordGroup.groupDef.title))])],1),_vm._v(" "),_c('expansion-panel-list',{attrs:{"items":recordGroup.records,"item-to-id":_vm.itemToId,"value":_vm.expandedItemInternal,"focused-item":_vm.focusedItem},on:{"input":_vm.expandedItemChangedFromUi,"focused-item-change":function($event){_vm.focusedItem = $event},"item-home-press":function($event){return _vm.homeKeyPressed()},"item-end-press":function($event){return _vm.endKeyPressed()},"item-up-press":function($event){return _vm.upKeyPressed()},"item-down-press":function($event){return _vm.downKeyPressed()},"item-esc-press":function($event){return _vm.escKeyPressed()}},scopedSlots:_vm._u([{key:"record-collapsed",fn:function(ref){
var item = ref.item;
var focused = ref.focused;
return [_vm._t("record-collapsed",null,{"item":item,"focused":focused})]}},{key:"record-expanded-header",fn:function(ref){
var item = ref.item;
var focused = ref.focused;
return [_vm._t("record-expanded-header",null,{"item":item,"focused":focused})]}},{key:"record-expanded-content",fn:function(ref){
var item = ref.item;
var focused = ref.focused;
return [_vm._t("record-expanded-content",null,{"item":item,"focused":focused})]}}],null,true)})],1)}),_vm._v(" "),_c('div',{staticClass:"bottom-spacer"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }