// source: teamdev/licensing/c/academic/academic_license.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_license_artifact_pb = require('../../../../teamdev/licensing/license_artifact_pb.js');
goog.object.extend(proto, teamdev_licensing_license_artifact_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_c_academic_values_pb = require('../../../../teamdev/licensing/c/academic/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_values_pb);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicense.Status', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicense.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequest.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    whenRevoked: (f = msg.getWhenRevoked()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    whenExpired: (f = msg.getWhenExpired()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    createdFrom: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicense;
  return proto.teamdev.licensing.c.academic.AcademicLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 4:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 5:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRevoked(value);
      break;
    case 6:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhenExpired(value);
      break;
    case 7:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 8:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 10:
      var value = /** @type {!proto.teamdev.licensing.c.academic.AcademicLicense.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 12:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 13:
      var value = /** @type {!proto.teamdev.licensing.CreatedFrom} */ (reader.readEnum());
      msg.setCreatedFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getWhenRevoked();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getWhenExpired();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getCreatedFrom();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.Status = {
  ALS_UNDEFINED: 0,
  CREATED: 1,
  GRANTED: 2,
  ISSUED: 3,
  EXTENSION_GRANTED: 4,
  EXTENSION_ISSUED: 5,
  EXPIRED: 6,
  REVOKED: 7
};

/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.LocalDate active_through = 3;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 3));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 4;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 4));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.ZonedDateTime when_revoked = 5;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getWhenRevoked = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 5));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setWhenRevoked = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearWhenRevoked = function() {
  return this.setWhenRevoked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasWhenRevoked = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.time.LocalDate when_expired = 6;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getWhenExpired = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 6));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setWhenExpired = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearWhenExpired = function() {
  return this.setWhenExpired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasWhenExpired = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AcademicLicenseRequester requester = 7;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 7));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 8;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 8));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string project_description = 9;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional Status status = 10;
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense.Status}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.c.academic.AcademicLicense.Status} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicense.Status} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 11;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 11));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 12;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 12));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional teamdev.licensing.CreatedFrom created_from = 13;
 * @return {!proto.teamdev.licensing.CreatedFrom}
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.getCreatedFrom = function() {
  return /** @type {!proto.teamdev.licensing.CreatedFrom} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.teamdev.licensing.CreatedFrom} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicense} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicense.prototype.setCreatedFrom = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    mainstreamVersionId: (f = msg.getMainstreamVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    extensionRequest: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    whenRequested: (f = msg.getWhenRequested()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    extensionReason: (f = msg.getExtensionReason()) && teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 10, ""),
    whenRefused: (f = msg.getWhenRefused()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequest;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setMainstreamVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtensionRequest(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 6:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRequested(value);
      break;
    case 7:
      var value = new teamdev_licensing_c_academic_values_pb.ExtensionRequestReason;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.deserializeBinaryFromReader);
      msg.setExtensionReason(value);
      break;
    case 8:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 9:
      var value = /** @type {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 11:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRefused(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getMainstreamVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getExtensionRequest();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getWhenRequested();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getExtensionReason();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWhenRefused();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status = {
  ALRS_UNDEFINED: 0,
  REQUESTED: 1,
  REQUEST_VERIFICATION_STARTED: 2,
  REQUEST_VERIFIED: 3,
  REQUEST_VERIFICATION_FAILED: 4,
  REQUEST_REFUSED: 5,
  EXTENSION_REQUESTED: 6,
  EXTENSION_REQUEST_VERIFICATION_STARTED: 7,
  EXTENSION_REQUEST_VERIFIED: 8,
  EXTENSION_REQUEST_VERIFICATION_FAILED: 9,
  EXTENSION_REQUEST_REFUSED: 10
};

/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId mainstream_version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getMainstreamVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setMainstreamVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearMainstreamVersionId = function() {
  return this.setMainstreamVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasMainstreamVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool extension_request = 4;
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getExtensionRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setExtensionRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional AcademicLicenseRequester requester = 5;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.time.ZonedDateTime when_requested = 6;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getWhenRequested = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 6));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setWhenRequested = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearWhenRequested = function() {
  return this.setWhenRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasWhenRequested = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ExtensionRequestReason extension_reason = 7;
 * @return {?proto.teamdev.licensing.c.academic.ExtensionRequestReason}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getExtensionReason = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.ExtensionRequestReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.ExtensionRequestReason, 7));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.ExtensionRequestReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setExtensionReason = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearExtensionReason = function() {
  return this.setExtensionReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasExtensionReason = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 8;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 8));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Status status = 9;
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string project_description = 10;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional spine.time.ZonedDateTime when_refused = 11;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.getWhenRefused = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 11));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.setWhenRefused = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.clearWhenRefused = function() {
  return this.setWhenRefused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.prototype.hasWhenRefused = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.academic);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.academic.AcademicLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicense.Parser;
proto.teamdev.licensing.c.academic.AcademicLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.whenRevoked !== undefined) {
    if (obj.whenRevoked === null) {
      msg.setWhenRevoked(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRevoked);
      msg.setWhenRevoked(value);
    }
  }
  
  if (obj.whenExpired !== undefined) {
    if (obj.whenExpired === null) {
      msg.setWhenExpired(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.whenExpired);
      msg.setWhenExpired(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.c.academic.AcademicLicense.Status[obj.status];
      msg.setStatus(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.createdFrom !== undefined) {
    if (obj.createdFrom !== null) {
      let value = proto.teamdev.licensing.CreatedFrom[obj.createdFrom];
      msg.setCreatedFrom(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.mainstreamVersionId !== undefined) {
    if (obj.mainstreamVersionId === null) {
      msg.setMainstreamVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.mainstreamVersionId);
      msg.setMainstreamVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.extensionRequest !== undefined) {
    if (obj.extensionRequest !== null) {
      let value = obj.extensionRequest;
      msg.setExtensionRequest(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.whenRequested !== undefined) {
    if (obj.whenRequested === null) {
      msg.setWhenRequested(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRequested);
      msg.setWhenRequested(value);
    }
  }
  
  if (obj.extensionReason !== undefined) {
    if (obj.extensionReason === null) {
      msg.setExtensionReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.ExtensionRequestReason').fromObject(obj.extensionReason);
      msg.setExtensionReason(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status[obj.status];
      msg.setStatus(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.whenRefused !== undefined) {
    if (obj.whenRefused === null) {
      msg.setWhenRefused(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRefused);
      msg.setWhenRefused(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicense.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicense.Status';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequest.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequest.Status';
};
