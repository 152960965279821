// source: teamdev/licensing/c/evaluation/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_options_pb = require('spine-web/proto/spine/time_options_pb.js');
goog.object.extend(proto, spine_time_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_c_evaluation_evaluation_pb = require('../../../../teamdev/licensing/c/evaluation/evaluation_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_evaluation_pb);
var teamdev_licensing_c_evaluation_values_pb = require('../../../../teamdev/licensing/c/evaluation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_values_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.RequestEvaluation', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.RequestEvaluation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.RequestEvaluation.displayName = 'proto.teamdev.licensing.c.evaluation.RequestEvaluation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.displayName = 'proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.displayName = 'proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.displayName = 'proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.displayName = 'proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.RequestEvaluation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    evaluator: (f = msg.getEvaluator()) && teamdev_licensing_c_evaluation_values_pb.Evaluator.toObject(includeInstance, f),
    clientEnvironment: (f = msg.getClientEnvironment()) && teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.toObject(includeInstance, f),
    productid: (f = msg.getProductid()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.RequestEvaluation;
  return proto.teamdev.licensing.c.evaluation.RequestEvaluation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.EvaluationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_evaluation_values_pb.Evaluator;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.Evaluator.deserializeBinaryFromReader);
      msg.setEvaluator(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_evaluation_values_pb.ClientEnvironment;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.deserializeBinaryFromReader);
      msg.setClientEnvironment(value);
      break;
    case 6:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.RequestEvaluation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getEvaluator();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_evaluation_values_pb.Evaluator.serializeBinaryToWriter
    );
  }
  f = message.getClientEnvironment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getProductid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EvaluationType type = 2;
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationType}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.getType = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.EvaluationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationType} value
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional spine.time.LocalDate active_through = 3;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 3));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Evaluator evaluator = 4;
 * @return {?proto.teamdev.licensing.c.evaluation.Evaluator}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.getEvaluator = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.Evaluator} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.Evaluator, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.Evaluator|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.setEvaluator = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.clearEvaluator = function() {
  return this.setEvaluator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.hasEvaluator = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ClientEnvironment client_environment = 5;
 * @return {?proto.teamdev.licensing.c.evaluation.ClientEnvironment}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.getClientEnvironment = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.ClientEnvironment} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.ClientEnvironment, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.ClientEnvironment|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.setClientEnvironment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.clearClientEnvironment = function() {
  return this.setClientEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.hasClientEnvironment = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.ProductId productId = 6;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.getProductid = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
*/
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.setProductid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation} returns this
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.clearProductid = function() {
  return this.setProductid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RequestEvaluation.prototype.hasProductid = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms}
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms;
  return proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms}
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms} returns this
*/
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms} returns this
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired}
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired;
  return proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired}
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired} returns this
*/
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired} returns this
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired}
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired;
  return proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired}
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired} returns this
*/
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired} returns this
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense;
  return proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_values_pb.EvaluationLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.EvaluationLicenseRevocationReason reason = 2;
 * @return {?proto.teamdev.licensing.EvaluationLicenseRevocationReason}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.EvaluationLicenseRevocationReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId revoked_by = 3;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 3));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} returns this
*/
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense} returns this
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.evaluation);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.Parser;
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.Parser;
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.RequestEvaluation.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.RequestEvaluation.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.RequestEvaluation.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.RequestEvaluation.Parser;
proto.teamdev.licensing.c.evaluation.RequestEvaluation.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.RequestEvaluation();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.type !== undefined) {
    if (obj.type !== null) {
      let value = proto.teamdev.licensing.c.evaluation.EvaluationType[obj.type];
      msg.setType(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.evaluator !== undefined) {
    if (obj.evaluator === null) {
      msg.setEvaluator(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator').fromObject(obj.evaluator);
      msg.setEvaluator(value);
    }
  }
  
  if (obj.clientEnvironment !== undefined) {
    if (obj.clientEnvironment === null) {
      msg.setClientEnvironment(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.ClientEnvironment').fromObject(obj.clientEnvironment);
      msg.setClientEnvironment(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.Parser;
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationLicenseRevocationReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.Parser;
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.AcceptEvaluationTerms.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.AcceptEvaluationTerms';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.MarkEvaluationLicenseAsExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.RequestEvaluation.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.RequestEvaluation';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.RevokeEvaluationLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.RevokeEvaluationLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.evaluation.MarkExtendedEvaluationLicenseAsExpired';
};
