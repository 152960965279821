import {Vue} from 'vue-property-decorator';
import {useOverlayScrollbars} from '@/shell/util/useOverlayScrollbars';
import {parentWithClass} from '@/shell/util/components';
import WizardPage from '@/shell/basic/wizard/WizardPage.vue';
import LservWizard from '@/shell/basic/wizard/LservWizard.vue';
import IWizardPage from '@/shell/basic/wizard/IWizardPage';


/**
 * A base class for components that are placed into `WizardPage` instances. Each `WizardPage` is
 * expected to have exactly one component that extends this `WizardPageContent` class.
 */
export default abstract class WizardPageContent extends Vue {
  visible = false;

  mounted(): void {
    useOverlayScrollbars(this.$el);
  }

  /**
   * Invoked when the wizard is shown anew (either for the first time, or when the instance is
   * reused, so this method can be overridden to reset the page's state to the initial one.
   */
  reset(): void {
    // can optionally be overridden in subclasses
  }

  private getWizardPage(): WizardPage {
    const wizardPage = parentWithClass(this, WizardPage as typeof WizardPage) as WizardPage;
    if (!wizardPage) {
      throw new Error(
          'Expecting WizardPageContent implementation to be placed inside of ' +
          '<wizard-page> component'
      );
    }
    return wizardPage;
  }

  /**
   * The `LservWizard` where this wizard's page is embedded.
   */
  protected get wizard(): LservWizard {
    const wizardPage: IWizardPage = this.getWizardPage() as unknown as IWizardPage;
    return wizardPage.wizard;
  }

  /**
   * Invoked by the wizard to identify whether the page's content is filled in and valid before
   * going to the next page (e.g. to identify whether the "Next" page should be enabled or not).
   *
   * Should be implemented for each page's subclass to provide a page-specific logic for this.
   */
  abstract get allDataEntered(): boolean;

  /**
   * Invoked by the wizard when its "Next" button is clicked. By default, this method invokes
   * the passed `selectNextPage()` function, which confirms that page navigation should
   * be performed. This algorithm can be overridden in subclasses.
   *
   * @param selectNextPage  a function that should be invoked to perform the actual navigation to
   *                        the next page (it is the responsibility of this method to do so with any
   *                        other logic that can be relevant to do at this point)
   *
   *                        This method can choose not to switch to the next page (e.g. if field
   *                        validation is not passed, or do some other action  (e.g. if it's
   *                        the last wizard's page).
   */
  handleNextPageClick(selectNextPage: () => void): void {
    selectNextPage();
  }

  /**
   * Can be overridden to return the page's menu text that overrides the default one.
   */
  get menuTextOverride(): string | null {
    return null;
  }
}
