// source: teamdev/licensing/c/generation/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_project_binding_pb = require('../../../../teamdev/licensing/project_binding_pb.js');
goog.object.extend(proto, teamdev_licensing_project_binding_pb);
var teamdev_licensing_client_pb = require('../../../../teamdev/licensing/client_pb.js');
goog.object.extend(proto, teamdev_licensing_client_pb);
var teamdev_licensing_c_generation_values_pb = require('../../../../teamdev/licensing/c/generation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_generation_values_pb);
var teamdev_licensing_c_academic_values_pb = require('../../../../teamdev/licensing/c/academic/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_values_pb);
var teamdev_licensing_c_evaluation_values_pb = require('../../../../teamdev/licensing/c/evaluation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_values_pb);
var teamdev_licensing_c_opensource_values_pb = require('../../../../teamdev/licensing/c/opensource/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_opensource_values_pb);
goog.exportSymbol('proto.teamdev.licensing.c.generation.GenerateLicenseArtifact', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.generation.GenerateLicenseArtifact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.displayName = 'proto.teamdev.licensing.c.generation.GenerateLicenseArtifact';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.generation.GenerateLicenseArtifact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.LicenseGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.generation.GenerateLicenseArtifact}
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.generation.GenerateLicenseArtifact;
  return proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.generation.GenerateLicenseArtifact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.generation.GenerateLicenseArtifact}
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.LicenseGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.LicenseGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.generation.GenerateLicenseArtifact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.LicenseGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.LicenseGenerationId id = 1;
 * @return {?proto.teamdev.licensing.LicenseGenerationId}
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.LicenseGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.LicenseGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.LicenseGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.GenerateLicenseArtifact} returns this
*/
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.GenerateLicenseArtifact} returns this
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.generation);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.Parser.prototype.constructor = proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.Parser;
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.generation.GenerateLicenseArtifact();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.generation.GenerateLicenseArtifact.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.generation.GenerateLicenseArtifact';
};
