import { render, staticRenderFns } from "./PersonNameText.vue?vue&type=template&id=138a68e8&scoped=true&"
import script from "./PersonNameText.vue?vue&type=script&lang=ts&"
export * from "./PersonNameText.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138a68e8",
  null
  
)

export default component.exports