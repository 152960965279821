// source: teamdev/licensing/c/product/licensing_policy.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
goog.exportSymbol('proto.teamdev.licensing.c.product.LicensingPolicy', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.LicensingPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.LicensingPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.LicensingPolicy.displayName = 'proto.teamdev.licensing.c.product.LicensingPolicy';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.LicensingPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.LicensingPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.LicensingPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    academic: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    openSource: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    demo: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    evaluation: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    personal: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    singleDev: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    project: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    companyWide: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    singleDevSourceCode: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    projectSourceCode: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    companyWideSourceCode: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.LicensingPolicy;
  return proto.teamdev.licensing.c.product.LicensingPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.LicensingPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAcademic(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenSource(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDemo(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEvaluation(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonal(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSingleDev(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProject(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanyWide(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSingleDevSourceCode(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProjectSourceCode(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanyWideSourceCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.LicensingPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.LicensingPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.LicensingPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcademic();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOpenSource();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDemo();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEvaluation();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPersonal();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSingleDev();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getProject();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCompanyWide();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSingleDevSourceCode();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getProjectSourceCode();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCompanyWideSourceCode();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional bool academic = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getAcademic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setAcademic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool open_source = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getOpenSource = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setOpenSource = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool demo = 3;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getDemo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setDemo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool evaluation = 4;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getEvaluation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setEvaluation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool personal = 5;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getPersonal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setPersonal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool single_dev = 6;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getSingleDev = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setSingleDev = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool project = 7;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getProject = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setProject = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool company_wide = 8;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getCompanyWide = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setCompanyWide = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool single_dev_source_code = 9;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getSingleDevSourceCode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setSingleDevSourceCode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool project_source_code = 10;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getProjectSourceCode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setProjectSourceCode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool company_wide_source_code = 11;
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.getCompanyWideSourceCode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.product.LicensingPolicy} returns this
 */
proto.teamdev.licensing.c.product.LicensingPolicy.prototype.setCompanyWideSourceCode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


goog.object.extend(exports, proto.teamdev.licensing.c.product);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.product.LicensingPolicy.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.LicensingPolicy.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.LicensingPolicy.Parser.prototype.constructor = proto.teamdev.licensing.c.product.LicensingPolicy.Parser;
proto.teamdev.licensing.c.product.LicensingPolicy.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.LicensingPolicy();
  
  if (obj.academic !== undefined) {
    if (obj.academic !== null) {
      let value = obj.academic;
      msg.setAcademic(value);
    }
  }
  
  if (obj.openSource !== undefined) {
    if (obj.openSource !== null) {
      let value = obj.openSource;
      msg.setOpenSource(value);
    }
  }
  
  if (obj.demo !== undefined) {
    if (obj.demo !== null) {
      let value = obj.demo;
      msg.setDemo(value);
    }
  }
  
  if (obj.evaluation !== undefined) {
    if (obj.evaluation !== null) {
      let value = obj.evaluation;
      msg.setEvaluation(value);
    }
  }
  
  if (obj.personal !== undefined) {
    if (obj.personal !== null) {
      let value = obj.personal;
      msg.setPersonal(value);
    }
  }
  
  if (obj.singleDev !== undefined) {
    if (obj.singleDev !== null) {
      let value = obj.singleDev;
      msg.setSingleDev(value);
    }
  }
  
  if (obj.project !== undefined) {
    if (obj.project !== null) {
      let value = obj.project;
      msg.setProject(value);
    }
  }
  
  if (obj.companyWide !== undefined) {
    if (obj.companyWide !== null) {
      let value = obj.companyWide;
      msg.setCompanyWide(value);
    }
  }
  
  if (obj.singleDevSourceCode !== undefined) {
    if (obj.singleDevSourceCode !== null) {
      let value = obj.singleDevSourceCode;
      msg.setSingleDevSourceCode(value);
    }
  }
  
  if (obj.projectSourceCode !== undefined) {
    if (obj.projectSourceCode !== null) {
      let value = obj.projectSourceCode;
      msg.setProjectSourceCode(value);
    }
  }
  
  if (obj.companyWideSourceCode !== undefined) {
    if (obj.companyWideSourceCode !== null) {
      let value = obj.companyWideSourceCode;
      msg.setCompanyWideSourceCode(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.LicensingPolicy.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy';
};
