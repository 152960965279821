// source: teamdev/licensing/c/project/sourcecode/events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_license_artifact_pb = require('../../../../../teamdev/licensing/license_artifact_pb.js');
goog.object.extend(proto, teamdev_licensing_license_artifact_pb);
var teamdev_licensing_values_pb = require('../../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_project_binding_pb = require('../../../../../teamdev/licensing/project_binding_pb.js');
goog.object.extend(proto, teamdev_licensing_project_binding_pb);
var teamdev_licensing_client_pb = require('../../../../../teamdev/licensing/client_pb.js');
goog.object.extend(proto, teamdev_licensing_client_pb);
var teamdev_users_identifiers_pb = require('../../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.displayName = 'proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.displayName = 'proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.displayName = 'proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated;
  return proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    case 4:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 5:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 6:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 3;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 4;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 4));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.hasClient = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 5;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 5));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 6;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional spine.core.UserId granted_by = 8;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 8));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued;
  return proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 3:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 3;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    whenRevoked: (f = msg.getWhenRevoked()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    revocationReason: (f = msg.getRevocationReason()) && teamdev_licensing_values_pb.CommercialLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked;
  return proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRevoked(value);
      break;
    case 3:
      var value = new teamdev_licensing_values_pb.CommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.CommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setRevocationReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRevoked();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRevocationReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_values_pb.CommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_revoked = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.getWhenRevoked = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.setWhenRevoked = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.clearWhenRevoked = function() {
  return this.setWhenRevoked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.hasWhenRevoked = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.CommercialLicenseRevocationReason revocation_reason = 3;
 * @return {?proto.teamdev.licensing.CommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.getRevocationReason = function() {
  return /** @type{?proto.teamdev.licensing.CommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.CommercialLicenseRevocationReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.CommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.setRevocationReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.clearRevocationReason = function() {
  return this.setRevocationReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.hasRevocationReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId revoked_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.project.sourcecode);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.Parser.prototype.constructor = proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.Parser;
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.Parser.prototype.constructor = proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.Parser;
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.comment !== undefined) {
    if (obj.comment !== null) {
      let value = obj.comment;
      msg.setComment(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.Parser.prototype.constructor = proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.Parser;
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRevoked !== undefined) {
    if (obj.whenRevoked === null) {
      msg.setWhenRevoked(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRevoked);
      msg.setWhenRevoked(value);
    }
  }
  
  if (obj.revocationReason !== undefined) {
    if (obj.revocationReason === null) {
      msg.setRevocationReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CommercialLicenseRevocationReason').fromObject(obj.revocationReason);
      msg.setRevocationReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseIssued';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseCreated';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.project.sourcecode.ProjectSourceCodeLicenseRevoked';
};
