// source: teamdev/licensing/c/generation/values.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_people_person_name_pb = require('spine-web/proto/spine/people/person_name_pb.js');
goog.object.extend(proto, spine_people_person_name_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_c_evaluation_values_pb = require('../../../../teamdev/licensing/c/evaluation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_values_pb);
goog.exportSymbol('proto.teamdev.licensing.c.generation.AcademicProjectProfile', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.generation.EvaluationProjectProfile', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.generation.LicenseType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.generation.AcademicProjectProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.generation.AcademicProjectProfile.displayName = 'proto.teamdev.licensing.c.generation.AcademicProjectProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.generation.EvaluationProjectProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.generation.EvaluationProjectProfile.displayName = 'proto.teamdev.licensing.c.generation.EvaluationProjectProfile';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.generation.AcademicProjectProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.generation.AcademicProjectProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.generation.AcademicProjectProfile}
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.generation.AcademicProjectProfile;
  return proto.teamdev.licensing.c.generation.AcademicProjectProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.generation.AcademicProjectProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.generation.AcademicProjectProfile}
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.generation.AcademicProjectProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.generation.AcademicProjectProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional spine.time.LocalDate project_completion_date = 1;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 1));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.AcademicProjectProfile} returns this
*/
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.AcademicProjectProfile} returns this
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string project_description = 2;
 * @return {string}
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.generation.AcademicProjectProfile} returns this
 */
proto.teamdev.licensing.c.generation.AcademicProjectProfile.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.generation.EvaluationProjectProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.generation.EvaluationProjectProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientEnvironment: (f = msg.getClientEnvironment()) && teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.generation.EvaluationProjectProfile}
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.generation.EvaluationProjectProfile;
  return proto.teamdev.licensing.c.generation.EvaluationProjectProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.generation.EvaluationProjectProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.generation.EvaluationProjectProfile}
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_c_evaluation_values_pb.ClientEnvironment;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.deserializeBinaryFromReader);
      msg.setClientEnvironment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.generation.EvaluationProjectProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.generation.EvaluationProjectProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientEnvironment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.c.evaluation.ClientEnvironment client_environment = 1;
 * @return {?proto.teamdev.licensing.c.evaluation.ClientEnvironment}
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.prototype.getClientEnvironment = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.ClientEnvironment} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.ClientEnvironment, 1));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.ClientEnvironment|undefined} value
 * @return {!proto.teamdev.licensing.c.generation.EvaluationProjectProfile} returns this
*/
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.prototype.setClientEnvironment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.generation.EvaluationProjectProfile} returns this
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.prototype.clearClientEnvironment = function() {
  return this.setClientEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.prototype.hasClientEnvironment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.generation.LicenseType = {
  CLT_UNDEFINED: 0,
  SINGLE_DEV: 1,
  PROJECT: 2,
  COMPANY_WIDE: 3,
  PERSONAL: 4,
  SINGLE_DEV_SOURCE_CODE: 5,
  PROJECT_SOURCE_CODE: 6,
  COMPANY_WIDE_SOURCE_CODE: 7,
  OPEN_SOURCE: 8,
  ACADEMIC: 9,
  DEMO: 10,
  EVALUATION: 11
};

goog.object.extend(exports, proto.teamdev.licensing.c.generation);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.generation.EvaluationProjectProfile.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.Parser.prototype.constructor = proto.teamdev.licensing.c.generation.EvaluationProjectProfile.Parser;
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.generation.EvaluationProjectProfile();
  
  if (obj.clientEnvironment !== undefined) {
    if (obj.clientEnvironment === null) {
      msg.setClientEnvironment(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.ClientEnvironment').fromObject(obj.clientEnvironment);
      msg.setClientEnvironment(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.generation.AcademicProjectProfile.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.generation.AcademicProjectProfile.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.generation.AcademicProjectProfile.Parser.prototype.constructor = proto.teamdev.licensing.c.generation.AcademicProjectProfile.Parser;
proto.teamdev.licensing.c.generation.AcademicProjectProfile.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.generation.AcademicProjectProfile();
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.generation.EvaluationProjectProfile.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.generation.EvaluationProjectProfile';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.generation.AcademicProjectProfile.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.generation.AcademicProjectProfile';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.generation.LicenseType.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.generation.LicenseType';
};
