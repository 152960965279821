// source: teamdev/sales/c/events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_client_pb = require('../../../teamdev/licensing/client_pb.js');
goog.object.extend(proto, teamdev_licensing_client_pb);
var teamdev_licensing_project_binding_pb = require('../../../teamdev/licensing/project_binding_pb.js');
goog.object.extend(proto, teamdev_licensing_project_binding_pb);
var teamdev_licensing_licensing_target_pb = require('../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_users_identifiers_pb = require('../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.sales.c.CompanyWideLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.sales.c.ExtendedEvaluationGranted', null, global);
goog.exportSymbol('proto.teamdev.sales.c.OnlinePurchaseRefunded', null, global);
goog.exportSymbol('proto.teamdev.sales.c.OnlinePurchaseRefunded.IdCase', null, global);
goog.exportSymbol('proto.teamdev.sales.c.PersonalLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.sales.c.ProjectLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.sales.c.SingleDevLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.ExtendedEvaluationGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.ExtendedEvaluationGranted.displayName = 'proto.teamdev.sales.c.ExtendedEvaluationGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.PersonalLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.PersonalLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.PersonalLicenseGranted.displayName = 'proto.teamdev.sales.c.PersonalLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.SingleDevLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.SingleDevLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.SingleDevLicenseGranted.displayName = 'proto.teamdev.sales.c.SingleDevLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.ProjectLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.ProjectLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.ProjectLicenseGranted.displayName = 'proto.teamdev.sales.c.ProjectLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.CompanyWideLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.CompanyWideLicenseGranted.displayName = 'proto.teamdev.sales.c.CompanyWideLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.displayName = 'proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.displayName = 'proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.displayName = 'proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_);
};
goog.inherits(proto.teamdev.sales.c.OnlinePurchaseRefunded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.sales.c.OnlinePurchaseRefunded.displayName = 'proto.teamdev.sales.c.OnlinePurchaseRefunded';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.ExtendedEvaluationGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.ExtendedEvaluationGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.ExtendedEvaluationGranted}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.ExtendedEvaluationGranted;
  return proto.teamdev.sales.c.ExtendedEvaluationGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.ExtendedEvaluationGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.ExtendedEvaluationGranted}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.ExtendedEvaluationGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.ExtendedEvaluationGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.sales.c.ExtendedEvaluationGranted} returns this
*/
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ExtendedEvaluationGranted} returns this
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.LocalDate active_through = 2;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 2));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.sales.c.ExtendedEvaluationGranted} returns this
*/
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ExtendedEvaluationGranted} returns this
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ExtendedEvaluationGranted.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.PersonalLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.PersonalLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.PersonalLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.PersonalLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.PersonalLicenseGranted;
  return proto.teamdev.sales.c.PersonalLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.PersonalLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.PersonalLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.PersonalLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.PersonalLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.PersonalLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.PersonalLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.PersonalLicenseId id = 1;
 * @return {?proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.PersonalLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.PersonalLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.PersonalLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
*/
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
*/
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 3;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 3));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
*/
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId granted_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
*/
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.PersonalLicenseGranted} returns this
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.PersonalLicenseGranted.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.SingleDevLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.SingleDevLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SingleDevLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.SingleDevLicenseGranted;
  return proto.teamdev.sales.c.SingleDevLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.SingleDevLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SingleDevLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.SingleDevLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.SingleDevLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SingleDevLicenseId id = 1;
 * @return {?proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 3;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 3));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId granted_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevLicenseGranted.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.ProjectLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.ProjectLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.ProjectLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.ProjectLicenseGranted;
  return proto.teamdev.sales.c.ProjectLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.ProjectLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 5:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.ProjectLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.ProjectLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.ProjectLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 3;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 3));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 4;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.core.UserId granted_by = 5;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 5));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectLicenseGranted.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.CompanyWideLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.CompanyWideLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.CompanyWideLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.CompanyWideLicenseGranted;
  return proto.teamdev.sales.c.CompanyWideLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.CompanyWideLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.CompanyWideLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.CompanyWideLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.CompanyWideLicenseId id = 1;
 * @return {?proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 3;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 3));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId granted_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideLicenseGranted.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted;
  return proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SingleDevSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 3;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 3));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId granted_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted;
  return proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 5:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 3;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 3));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 4;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.core.UserId granted_by = 5;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 5));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.toObject(includeInstance, f),
    target: (f = msg.getTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted;
  return proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 3:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.CompanyWideSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget target = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.getTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 3;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 3));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId granted_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
*/
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted} returns this
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.IdCase = {
  ID_NOT_SET: 0,
  PERSONAL_LICENSE_ID: 1,
  SINGLE_DEV_LICENSE_ID: 2,
  PROJECT_LICENSE_ID: 3,
  COMPANY_WIDE_LICENSE_ID: 4,
  SINGLE_DEV_SOURCE_CODE_LICENSE_ID: 5,
  PROJECT_SOURCE_CODE_LICENSE_ID: 6,
  COMPANY_WIDE_SOURCE_CODE_LICENSE_ID: 7
};

/**
 * @return {proto.teamdev.sales.c.OnlinePurchaseRefunded.IdCase}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getIdCase = function() {
  return /** @type {proto.teamdev.sales.c.OnlinePurchaseRefunded.IdCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.sales.c.OnlinePurchaseRefunded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.sales.c.OnlinePurchaseRefunded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.toObject = function(includeInstance, msg) {
  var f, obj = {
    personalLicenseId: (f = msg.getPersonalLicenseId()) && teamdev_licensing_identifiers_pb.PersonalLicenseId.toObject(includeInstance, f),
    singleDevLicenseId: (f = msg.getSingleDevLicenseId()) && teamdev_licensing_identifiers_pb.SingleDevLicenseId.toObject(includeInstance, f),
    projectLicenseId: (f = msg.getProjectLicenseId()) && teamdev_licensing_identifiers_pb.ProjectLicenseId.toObject(includeInstance, f),
    companyWideLicenseId: (f = msg.getCompanyWideLicenseId()) && teamdev_licensing_identifiers_pb.CompanyWideLicenseId.toObject(includeInstance, f),
    singleDevSourceCodeLicenseId: (f = msg.getSingleDevSourceCodeLicenseId()) && teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.toObject(includeInstance, f),
    projectSourceCodeLicenseId: (f = msg.getProjectSourceCodeLicenseId()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    companyWideSourceCodeLicenseId: (f = msg.getCompanyWideSourceCodeLicenseId()) && teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.sales.c.OnlinePurchaseRefunded;
  return proto.teamdev.sales.c.OnlinePurchaseRefunded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.sales.c.OnlinePurchaseRefunded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.PersonalLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.PersonalLicenseId.deserializeBinaryFromReader);
      msg.setPersonalLicenseId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.SingleDevLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.setSingleDevLicenseId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProjectLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectLicenseId.deserializeBinaryFromReader);
      msg.setProjectLicenseId(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWideLicenseId(value);
      break;
    case 5:
      var value = new teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setSingleDevSourceCodeLicenseId(value);
      break;
    case 6:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setProjectSourceCodeLicenseId(value);
      break;
    case 7:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWideSourceCodeLicenseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.sales.c.OnlinePurchaseRefunded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.sales.c.OnlinePurchaseRefunded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonalLicenseId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevLicenseId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectLicenseId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideLicenseId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideSourceCodeLicenseId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.PersonalLicenseId personal_license_id = 1;
 * @return {?proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getPersonalLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.PersonalLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.PersonalLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.PersonalLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
*/
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.setPersonalLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.clearPersonalLicenseId = function() {
  return this.setPersonalLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.hasPersonalLicenseId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.SingleDevLicenseId single_dev_license_id = 2;
 * @return {?proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getSingleDevLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevLicenseId, 2));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
*/
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.setSingleDevLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.clearSingleDevLicenseId = function() {
  return this.setSingleDevLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.hasSingleDevLicenseId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProjectLicenseId project_license_id = 3;
 * @return {?proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getProjectLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectLicenseId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProjectLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
*/
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.setProjectLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.clearProjectLicenseId = function() {
  return this.setProjectLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.hasProjectLicenseId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.CompanyWideLicenseId company_wide_license_id = 4;
 * @return {?proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getCompanyWideLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideLicenseId, 4));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
*/
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.setCompanyWideLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.clearCompanyWideLicenseId = function() {
  return this.setCompanyWideLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.hasCompanyWideLicenseId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional teamdev.licensing.SingleDevSourceCodeLicenseId single_dev_source_code_license_id = 5;
 * @return {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getSingleDevSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId, 5));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
*/
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.setSingleDevSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.clearSingleDevSourceCodeLicenseId = function() {
  return this.setSingleDevSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.hasSingleDevSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId project_source_code_license_id = 6;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getProjectSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
*/
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.setProjectSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.clearProjectSourceCodeLicenseId = function() {
  return this.setProjectSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.hasProjectSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional teamdev.licensing.CompanyWideSourceCodeLicenseId company_wide_source_code_license_id = 7;
 * @return {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.getCompanyWideSourceCodeLicenseId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId, 7));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
*/
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.setCompanyWideSourceCodeLicenseId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.teamdev.sales.c.OnlinePurchaseRefunded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.sales.c.OnlinePurchaseRefunded} returns this
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.clearCompanyWideSourceCodeLicenseId = function() {
  return this.setCompanyWideSourceCodeLicenseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.sales.c.OnlinePurchaseRefunded.prototype.hasCompanyWideSourceCodeLicenseId = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.teamdev.sales.c);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.sales.c.ExtendedEvaluationGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.ExtendedEvaluationGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.ExtendedEvaluationGranted.Parser.prototype.constructor = proto.teamdev.sales.c.ExtendedEvaluationGranted.Parser;
proto.teamdev.sales.c.ExtendedEvaluationGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.ExtendedEvaluationGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.Parser.prototype.constructor = proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.Parser;
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.OnlinePurchaseRefunded.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.OnlinePurchaseRefunded.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.OnlinePurchaseRefunded.Parser.prototype.constructor = proto.teamdev.sales.c.OnlinePurchaseRefunded.Parser;
proto.teamdev.sales.c.OnlinePurchaseRefunded.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.OnlinePurchaseRefunded();
  
  if (obj.personalLicenseId !== undefined) {
    if (obj.personalLicenseId === null) {
      msg.setPersonalLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(obj.personalLicenseId);
      msg.setPersonalLicenseId(value);
    }
  }
  
  if (obj.singleDevLicenseId !== undefined) {
    if (obj.singleDevLicenseId === null) {
      msg.setSingleDevLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(obj.singleDevLicenseId);
      msg.setSingleDevLicenseId(value);
    }
  }
  
  if (obj.projectLicenseId !== undefined) {
    if (obj.projectLicenseId === null) {
      msg.setProjectLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(obj.projectLicenseId);
      msg.setProjectLicenseId(value);
    }
  }
  
  if (obj.companyWideLicenseId !== undefined) {
    if (obj.companyWideLicenseId === null) {
      msg.setCompanyWideLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(obj.companyWideLicenseId);
      msg.setCompanyWideLicenseId(value);
    }
  }
  
  if (obj.singleDevSourceCodeLicenseId !== undefined) {
    if (obj.singleDevSourceCodeLicenseId === null) {
      msg.setSingleDevSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(obj.singleDevSourceCodeLicenseId);
      msg.setSingleDevSourceCodeLicenseId(value);
    }
  }
  
  if (obj.projectSourceCodeLicenseId !== undefined) {
    if (obj.projectSourceCodeLicenseId === null) {
      msg.setProjectSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.projectSourceCodeLicenseId);
      msg.setProjectSourceCodeLicenseId(value);
    }
  }
  
  if (obj.companyWideSourceCodeLicenseId !== undefined) {
    if (obj.companyWideSourceCodeLicenseId === null) {
      msg.setCompanyWideSourceCodeLicenseId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(obj.companyWideSourceCodeLicenseId);
      msg.setCompanyWideSourceCodeLicenseId(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.CompanyWideLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.CompanyWideLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.CompanyWideLicenseGranted.Parser.prototype.constructor = proto.teamdev.sales.c.CompanyWideLicenseGranted.Parser;
proto.teamdev.sales.c.CompanyWideLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.CompanyWideLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.PersonalLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.PersonalLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.PersonalLicenseGranted.Parser.prototype.constructor = proto.teamdev.sales.c.PersonalLicenseGranted.Parser;
proto.teamdev.sales.c.PersonalLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.PersonalLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.Parser.prototype.constructor = proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.Parser;
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.SingleDevLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.SingleDevLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.SingleDevLicenseGranted.Parser.prototype.constructor = proto.teamdev.sales.c.SingleDevLicenseGranted.Parser;
proto.teamdev.sales.c.SingleDevLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.SingleDevLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.ProjectLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.ProjectLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.ProjectLicenseGranted.Parser.prototype.constructor = proto.teamdev.sales.c.ProjectLicenseGranted.Parser;
proto.teamdev.sales.c.ProjectLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.ProjectLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.Parser.prototype.constructor = proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.Parser;
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.target !== undefined) {
    if (obj.target === null) {
      msg.setTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.target);
      msg.setTarget(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.ExtendedEvaluationGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.ExtendedEvaluationGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.SingleDevSourceCodeLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.SingleDevSourceCodeLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.OnlinePurchaseRefunded.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.OnlinePurchaseRefunded';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.CompanyWideLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.CompanyWideLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.PersonalLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.PersonalLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.ProjectSourceCodeLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.ProjectSourceCodeLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.SingleDevLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.SingleDevLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.ProjectLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.ProjectLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.sales.c.CompanyWideSourceCodeLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.sales.c.CompanyWideSourceCodeLicenseGranted';
};
