// source: teamdev/licensing/q/licensed_period.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.q.LicensedPeriod', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.LicensedPeriodEnd', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.LicensedPeriodEndReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.LicensedPeriodStart', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.LicensedPeriodStartReason', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.LicensedPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.LicensedPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.LicensedPeriod.displayName = 'proto.teamdev.licensing.q.LicensedPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.LicensedPeriodStart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.LicensedPeriodStart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.LicensedPeriodStart.displayName = 'proto.teamdev.licensing.q.LicensedPeriodStart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.LicensedPeriodEnd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.LicensedPeriodEnd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.LicensedPeriodEnd.displayName = 'proto.teamdev.licensing.q.LicensedPeriodEnd';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.LicensedPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.LicensedPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.LicensedPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.teamdev.licensing.q.LicensedPeriodStart.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.teamdev.licensing.q.LicensedPeriodEnd.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.LicensedPeriod}
 */
proto.teamdev.licensing.q.LicensedPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.LicensedPeriod;
  return proto.teamdev.licensing.q.LicensedPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.LicensedPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.LicensedPeriod}
 */
proto.teamdev.licensing.q.LicensedPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.q.LicensedPeriodStart;
      reader.readMessage(value,proto.teamdev.licensing.q.LicensedPeriodStart.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.q.LicensedPeriodEnd;
      reader.readMessage(value,proto.teamdev.licensing.q.LicensedPeriodEnd.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.LicensedPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.LicensedPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.LicensedPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.q.LicensedPeriodStart.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.q.LicensedPeriodEnd.serializeBinaryToWriter
    );
  }
};


/**
 * optional LicensedPeriodStart start = 1;
 * @return {?proto.teamdev.licensing.q.LicensedPeriodStart}
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.getStart = function() {
  return /** @type{?proto.teamdev.licensing.q.LicensedPeriodStart} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.q.LicensedPeriodStart, 1));
};


/**
 * @param {?proto.teamdev.licensing.q.LicensedPeriodStart|undefined} value
 * @return {!proto.teamdev.licensing.q.LicensedPeriod} returns this
*/
proto.teamdev.licensing.q.LicensedPeriod.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.LicensedPeriod} returns this
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicensedPeriodEnd end = 2;
 * @return {?proto.teamdev.licensing.q.LicensedPeriodEnd}
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.getEnd = function() {
  return /** @type{?proto.teamdev.licensing.q.LicensedPeriodEnd} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.q.LicensedPeriodEnd, 2));
};


/**
 * @param {?proto.teamdev.licensing.q.LicensedPeriodEnd|undefined} value
 * @return {!proto.teamdev.licensing.q.LicensedPeriod} returns this
*/
proto.teamdev.licensing.q.LicensedPeriod.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.LicensedPeriod} returns this
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.LicensedPeriod.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.LicensedPeriodStart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.LicensedPeriodStart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.LicensedPeriodStart.toObject = function(includeInstance, msg) {
  var f, obj = {
    when: (f = msg.getWhen()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.LicensedPeriodStart}
 */
proto.teamdev.licensing.q.LicensedPeriodStart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.LicensedPeriodStart;
  return proto.teamdev.licensing.q.LicensedPeriodStart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.LicensedPeriodStart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.LicensedPeriodStart}
 */
proto.teamdev.licensing.q.LicensedPeriodStart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhen(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.q.LicensedPeriodStartReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.LicensedPeriodStart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.LicensedPeriodStart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.LicensedPeriodStart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWhen();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional spine.time.LocalDate when = 1;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.getWhen = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 1));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.q.LicensedPeriodStart} returns this
*/
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.setWhen = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.LicensedPeriodStart} returns this
 */
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.clearWhen = function() {
  return this.setWhen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.hasWhen = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicensedPeriodStartReason reason = 2;
 * @return {!proto.teamdev.licensing.q.LicensedPeriodStartReason}
 */
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.getReason = function() {
  return /** @type {!proto.teamdev.licensing.q.LicensedPeriodStartReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.LicensedPeriodStartReason} value
 * @return {!proto.teamdev.licensing.q.LicensedPeriodStart} returns this
 */
proto.teamdev.licensing.q.LicensedPeriodStart.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.LicensedPeriodEnd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.LicensedPeriodEnd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.toObject = function(includeInstance, msg) {
  var f, obj = {
    when: (f = msg.getWhen()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.LicensedPeriodEnd}
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.LicensedPeriodEnd;
  return proto.teamdev.licensing.q.LicensedPeriodEnd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.LicensedPeriodEnd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.LicensedPeriodEnd}
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhen(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.q.LicensedPeriodEndReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.LicensedPeriodEnd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.LicensedPeriodEnd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWhen();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional spine.time.LocalDate when = 1;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.getWhen = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 1));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.q.LicensedPeriodEnd} returns this
*/
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.setWhen = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.LicensedPeriodEnd} returns this
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.clearWhen = function() {
  return this.setWhen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.hasWhen = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicensedPeriodEndReason reason = 2;
 * @return {!proto.teamdev.licensing.q.LicensedPeriodEndReason}
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.getReason = function() {
  return /** @type {!proto.teamdev.licensing.q.LicensedPeriodEndReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.LicensedPeriodEndReason} value
 * @return {!proto.teamdev.licensing.q.LicensedPeriodEnd} returns this
 */
proto.teamdev.licensing.q.LicensedPeriodEnd.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.q.LicensedPeriodStartReason = {
  LPS_UNDEFINED: 0,
  GRANTED: 1,
  ISSUED: 2,
  EXTENDED: 3
};

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.LicensedPeriodEndReason = {
  LPE_UNDEFINED: 0,
  EXPIRED: 1,
  EXTENSION_EXPIRED: 2,
  REVOKED: 3
};

goog.object.extend(exports, proto.teamdev.licensing.q);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.q.LicensedPeriodEnd.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.LicensedPeriodEnd.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.LicensedPeriodEnd.Parser.prototype.constructor = proto.teamdev.licensing.q.LicensedPeriodEnd.Parser;
proto.teamdev.licensing.q.LicensedPeriodEnd.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.LicensedPeriodEnd();
  
  if (obj.when !== undefined) {
    if (obj.when === null) {
      msg.setWhen(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.when);
      msg.setWhen(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason !== null) {
      let value = proto.teamdev.licensing.q.LicensedPeriodEndReason[obj.reason];
      msg.setReason(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.LicensedPeriod.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.LicensedPeriod.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.LicensedPeriod.Parser.prototype.constructor = proto.teamdev.licensing.q.LicensedPeriod.Parser;
proto.teamdev.licensing.q.LicensedPeriod.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.LicensedPeriod();
  
  if (obj.start !== undefined) {
    if (obj.start === null) {
      msg.setStart(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.LicensedPeriodStart').fromObject(obj.start);
      msg.setStart(value);
    }
  }
  
  if (obj.end !== undefined) {
    if (obj.end === null) {
      msg.setEnd(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.LicensedPeriodEnd').fromObject(obj.end);
      msg.setEnd(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.LicensedPeriodStart.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.LicensedPeriodStart.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.LicensedPeriodStart.Parser.prototype.constructor = proto.teamdev.licensing.q.LicensedPeriodStart.Parser;
proto.teamdev.licensing.q.LicensedPeriodStart.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.LicensedPeriodStart();
  
  if (obj.when !== undefined) {
    if (obj.when === null) {
      msg.setWhen(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.when);
      msg.setWhen(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason !== null) {
      let value = proto.teamdev.licensing.q.LicensedPeriodStartReason[obj.reason];
      msg.setReason(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.LicensedPeriodEnd.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.LicensedPeriodEnd';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.LicensedPeriod.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.LicensedPeriod';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.LicensedPeriodStart.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.LicensedPeriodStart';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.LicensedPeriodEndReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.LicensedPeriodEndReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.LicensedPeriodStartReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.LicensedPeriodStartReason';
};
