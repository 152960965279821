// source: teamdev/licensing/importado/v1/v1data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var google_protobuf_timestamp_pb = require('spine-web/proto/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.teamdev.licensing.importado.v1.License', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.v1.LicenseType', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.v1.Licenses', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.v1.Product', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.v1.Products', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.v1.Products = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.importado.v1.Products.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.importado.v1.Products, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.v1.Products.displayName = 'proto.teamdev.licensing.importado.v1.Products';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.v1.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.v1.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.v1.Product.displayName = 'proto.teamdev.licensing.importado.v1.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.v1.Licenses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.importado.v1.Licenses.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.importado.v1.Licenses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.v1.Licenses.displayName = 'proto.teamdev.licensing.importado.v1.Licenses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.v1.License = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.v1.License, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.v1.License.displayName = 'proto.teamdev.licensing.importado.v1.License';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.importado.v1.Products.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.v1.Products.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.v1.Products.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.v1.Products} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.Products.toObject = function(includeInstance, msg) {
  var f, obj = {
    productList: jspb.Message.toObjectList(msg.getProductList(),
    proto.teamdev.licensing.importado.v1.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.v1.Products}
 */
proto.teamdev.licensing.importado.v1.Products.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.v1.Products;
  return proto.teamdev.licensing.importado.v1.Products.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.v1.Products} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.v1.Products}
 */
proto.teamdev.licensing.importado.v1.Products.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.importado.v1.Product;
      reader.readMessage(value,proto.teamdev.licensing.importado.v1.Product.deserializeBinaryFromReader);
      msg.addProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.v1.Products.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.v1.Products.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.v1.Products} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.Products.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.teamdev.licensing.importado.v1.Product.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Product product = 1;
 * @return {!Array<!proto.teamdev.licensing.importado.v1.Product>}
 */
proto.teamdev.licensing.importado.v1.Products.prototype.getProductList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.importado.v1.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.importado.v1.Product, 1));
};


/**
 * @param {!Array<!proto.teamdev.licensing.importado.v1.Product>} value
 * @return {!proto.teamdev.licensing.importado.v1.Products} returns this
*/
proto.teamdev.licensing.importado.v1.Products.prototype.setProductList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.teamdev.licensing.importado.v1.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.importado.v1.Product}
 */
proto.teamdev.licensing.importado.v1.Products.prototype.addProduct = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.teamdev.licensing.importado.v1.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.importado.v1.Products} returns this
 */
proto.teamdev.licensing.importado.v1.Products.prototype.clearProductList = function() {
  return this.setProductList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.v1.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.v1.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.v1.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.v1.Product}
 */
proto.teamdev.licensing.importado.v1.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.v1.Product;
  return proto.teamdev.licensing.importado.v1.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.v1.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.v1.Product}
 */
proto.teamdev.licensing.importado.v1.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.v1.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.v1.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.v1.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.teamdev.licensing.importado.v1.Product.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.importado.v1.Product} returns this
 */
proto.teamdev.licensing.importado.v1.Product.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.teamdev.licensing.importado.v1.Product.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.importado.v1.Product} returns this
 */
proto.teamdev.licensing.importado.v1.Product.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 product_version = 3;
 * @return {number}
 */
proto.teamdev.licensing.importado.v1.Product.prototype.getProductVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.importado.v1.Product} returns this
 */
proto.teamdev.licensing.importado.v1.Product.prototype.setProductVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.importado.v1.Licenses.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.v1.Licenses.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.v1.Licenses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.v1.Licenses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.Licenses.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseList: jspb.Message.toObjectList(msg.getLicenseList(),
    proto.teamdev.licensing.importado.v1.License.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.v1.Licenses}
 */
proto.teamdev.licensing.importado.v1.Licenses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.v1.Licenses;
  return proto.teamdev.licensing.importado.v1.Licenses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.v1.Licenses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.v1.Licenses}
 */
proto.teamdev.licensing.importado.v1.Licenses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.importado.v1.License;
      reader.readMessage(value,proto.teamdev.licensing.importado.v1.License.deserializeBinaryFromReader);
      msg.addLicense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.v1.Licenses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.v1.Licenses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.v1.Licenses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.Licenses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.teamdev.licensing.importado.v1.License.serializeBinaryToWriter
    );
  }
};


/**
 * repeated License license = 1;
 * @return {!Array<!proto.teamdev.licensing.importado.v1.License>}
 */
proto.teamdev.licensing.importado.v1.Licenses.prototype.getLicenseList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.importado.v1.License>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.importado.v1.License, 1));
};


/**
 * @param {!Array<!proto.teamdev.licensing.importado.v1.License>} value
 * @return {!proto.teamdev.licensing.importado.v1.Licenses} returns this
*/
proto.teamdev.licensing.importado.v1.Licenses.prototype.setLicenseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.teamdev.licensing.importado.v1.License=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.importado.v1.License}
 */
proto.teamdev.licensing.importado.v1.Licenses.prototype.addLicense = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.teamdev.licensing.importado.v1.License, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.importado.v1.Licenses} returns this
 */
proto.teamdev.licensing.importado.v1.Licenses.prototype.clearLicenseList = function() {
  return this.setLicenseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.v1.License.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.v1.License.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.v1.License} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.License.toObject = function(includeInstance, msg) {
  var f, obj = {
    issuedTo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    whenIssued: (f = msg.getWhenIssued()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    productId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.v1.License}
 */
proto.teamdev.licensing.importado.v1.License.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.v1.License;
  return proto.teamdev.licensing.importado.v1.License.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.v1.License} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.v1.License}
 */
proto.teamdev.licensing.importado.v1.License.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuedTo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 6:
      var value = /** @type {!proto.teamdev.licensing.importado.v1.LicenseType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.v1.License.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.v1.License.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.v1.License} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.v1.License.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIssuedTo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string issued_to = 1;
 * @return {string}
 */
proto.teamdev.licensing.importado.v1.License.prototype.getIssuedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.importado.v1.License} returns this
 */
proto.teamdev.licensing.importado.v1.License.prototype.setIssuedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_name = 2;
 * @return {string}
 */
proto.teamdev.licensing.importado.v1.License.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.importado.v1.License} returns this
 */
proto.teamdev.licensing.importado.v1.License.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp when_issued = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.teamdev.licensing.importado.v1.License.prototype.getWhenIssued = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.teamdev.licensing.importado.v1.License} returns this
*/
proto.teamdev.licensing.importado.v1.License.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.v1.License} returns this
 */
proto.teamdev.licensing.importado.v1.License.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.v1.License.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 product_id = 4;
 * @return {number}
 */
proto.teamdev.licensing.importado.v1.License.prototype.getProductId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.importado.v1.License} returns this
 */
proto.teamdev.licensing.importado.v1.License.prototype.setProductId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.teamdev.licensing.importado.v1.License.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.importado.v1.License} returns this
 */
proto.teamdev.licensing.importado.v1.License.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional LicenseType type = 6;
 * @return {!proto.teamdev.licensing.importado.v1.LicenseType}
 */
proto.teamdev.licensing.importado.v1.License.prototype.getType = function() {
  return /** @type {!proto.teamdev.licensing.importado.v1.LicenseType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.teamdev.licensing.importado.v1.LicenseType} value
 * @return {!proto.teamdev.licensing.importado.v1.License} returns this
 */
proto.teamdev.licensing.importado.v1.License.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.importado.v1.LicenseType = {
  LTYPE_UNDEFINED: 0,
  EVALUATION: 1,
  PERSONAL: 2,
  PER_DEVELOPER: 3,
  COMPANY_WIDE: 4,
  PROJECT: 5,
  ACADEMIC: 6,
  OPEN_SOURCE: 7,
  EXTENDED_EVALUATION: 8,
  SOURCE_CODE_PER_DEVELOPER: 9,
  SOURCE_CODE_PROJECT: 10,
  SOURCE_CODE_COMPANY: 11,
  OPEN_SOURCE_ANNUAL: 12,
  INDIE: 13,
  ENTERPRISE: 14
};

goog.object.extend(exports, proto.teamdev.licensing.importado.v1);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.importado.v1.Product.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.v1.Product.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.v1.Product.Parser.prototype.constructor = proto.teamdev.licensing.importado.v1.Product.Parser;
proto.teamdev.licensing.importado.v1.Product.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.v1.Product();
  
  if (obj.id !== undefined) {
    if (obj.id !== null) {
      let value = parseInt(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName !== null) {
      let value = obj.productName;
      msg.setProductName(value);
    }
  }
  
  if (obj.productVersion !== undefined) {
    if (obj.productVersion !== null) {
      let value = obj.productVersion;
      msg.setProductVersion(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.v1.License.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.v1.License.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.v1.License.Parser.prototype.constructor = proto.teamdev.licensing.importado.v1.License.Parser;
proto.teamdev.licensing.importado.v1.License.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.v1.License();
  
  if (obj.issuedTo !== undefined) {
    if (obj.issuedTo !== null) {
      let value = obj.issuedTo;
      msg.setIssuedTo(value);
    }
  }
  
  if (obj.customerName !== undefined) {
    if (obj.customerName !== null) {
      let value = obj.customerName;
      msg.setCustomerName(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.googleapis.com/google.protobuf.Timestamp').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId !== null) {
      let value = parseInt(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.comment !== undefined) {
    if (obj.comment !== null) {
      let value = obj.comment;
      msg.setComment(value);
    }
  }
  
  if (obj.type !== undefined) {
    if (obj.type !== null) {
      let value = proto.teamdev.licensing.importado.v1.LicenseType[obj.type];
      msg.setType(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.v1.Products.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.v1.Products.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.v1.Products.Parser.prototype.constructor = proto.teamdev.licensing.importado.v1.Products.Parser;
proto.teamdev.licensing.importado.v1.Products.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.v1.Products();
  
  if (obj.product !== undefined && obj.product !== null) {
    obj.product.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProduct(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.v1.Product').fromObject(listItem);
          msg.addProduct(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.importado.v1.Licenses.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.v1.Licenses.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.v1.Licenses.Parser.prototype.constructor = proto.teamdev.licensing.importado.v1.Licenses.Parser;
proto.teamdev.licensing.importado.v1.Licenses.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.v1.Licenses();
  
  if (obj.license !== undefined && obj.license !== null) {
    obj.license.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addLicense(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.v1.License').fromObject(listItem);
          msg.addLicense(value);
        }
      }
    );
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.v1.Product.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.v1.Product';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.v1.License.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.v1.License';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.v1.Products.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.v1.Products';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.v1.Licenses.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.v1.Licenses';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.v1.LicenseType.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.v1.LicenseType';
};
