// source: teamdev/licensing/c/sampledata/sample_data_generation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var google_protobuf_any_pb = require('spine-web/proto/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_c_product_commands_pb = require('../../../../teamdev/licensing/c/product/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_commands_pb);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleDataGeneration', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.c.sampledata.SampleDataGeneration.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleDataGeneration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleDataGeneration.displayName = 'proto.teamdev.licensing.c.sampledata.SampleDataGeneration';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.repeatedFields_ = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleDataGeneration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f),
    createProductCommandList: jspb.Message.toObjectList(msg.getCreateProductCommandList(),
    teamdev_licensing_c_product_commands_pb.CreateProduct.toObject, includeInstance),
    productsToCreateList: jspb.Message.toObjectList(msg.getProductsToCreateList(),
    teamdev_licensing_identifiers_pb.ProductId.toObject, includeInstance),
    createVersionCommandList: jspb.Message.toObjectList(msg.getCreateVersionCommandList(),
    teamdev_licensing_c_product_commands_pb.CreateProductVersion.toObject, includeInstance),
    versionsToCreateList: jspb.Message.toObjectList(msg.getVersionsToCreateList(),
    teamdev_licensing_identifiers_pb.ProductVersionId.toObject, includeInstance),
    assignVersionCommandList: jspb.Message.toObjectList(msg.getAssignVersionCommandList(),
    teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion.toObject, includeInstance),
    versionsToAssignList: jspb.Message.toObjectList(msg.getVersionsToAssignList(),
    teamdev_licensing_identifiers_pb.ProductVersionId.toObject, includeInstance),
    createLicenseCommandList: jspb.Message.toObjectList(msg.getCreateLicenseCommandList(),
    google_protobuf_any_pb.Any.toObject, includeInstance),
    demoLicenseIdsList: jspb.Message.toObjectList(msg.getDemoLicenseIdsList(),
    teamdev_licensing_identifiers_pb.DemoLicenseId.toObject, includeInstance),
    academicLicenseIdsList: jspb.Message.toObjectList(msg.getAcademicLicenseIdsList(),
    teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject, includeInstance),
    opensourceLicenseIdsList: jspb.Message.toObjectList(msg.getOpensourceLicenseIdsList(),
    teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject, includeInstance),
    singledevLicenseIdsList: jspb.Message.toObjectList(msg.getSingledevLicenseIdsList(),
    teamdev_licensing_identifiers_pb.SingleDevLicenseId.toObject, includeInstance),
    projectLicenseIdsList: jspb.Message.toObjectList(msg.getProjectLicenseIdsList(),
    teamdev_licensing_identifiers_pb.ProjectLicenseId.toObject, includeInstance),
    companywideLicenseIdsList: jspb.Message.toObjectList(msg.getCompanywideLicenseIdsList(),
    teamdev_licensing_identifiers_pb.CompanyWideLicenseId.toObject, includeInstance),
    singledevsourcecodeLicenseIdsList: jspb.Message.toObjectList(msg.getSingledevsourcecodeLicenseIdsList(),
    teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.toObject, includeInstance),
    projectsourcecodeLicenseIdsList: jspb.Message.toObjectList(msg.getProjectsourcecodeLicenseIdsList(),
    teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject, includeInstance),
    companywidesourcecodeLicenseIdsList: jspb.Message.toObjectList(msg.getCompanywidesourcecodeLicenseIdsList(),
    teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.toObject, includeInstance),
    personalLicenseIdsList: jspb.Message.toObjectList(msg.getPersonalLicenseIdsList(),
    teamdev_licensing_identifiers_pb.PersonalLicenseId.toObject, includeInstance),
    evaluationIdsList: jspb.Message.toObjectList(msg.getEvaluationIdsList(),
    teamdev_licensing_identifiers_pb.EvaluationId.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleDataGeneration;
  return proto.teamdev.licensing.c.sampledata.SampleDataGeneration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_commands_pb.CreateProduct;
      reader.readMessage(value,teamdev_licensing_c_product_commands_pb.CreateProduct.deserializeBinaryFromReader);
      msg.addCreateProductCommand(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.addProductsToCreate(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_commands_pb.CreateProductVersion;
      reader.readMessage(value,teamdev_licensing_c_product_commands_pb.CreateProductVersion.deserializeBinaryFromReader);
      msg.addCreateVersionCommand(value);
      break;
    case 5:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.addVersionsToCreate(value);
      break;
    case 6:
      var value = new teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion;
      reader.readMessage(value,teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion.deserializeBinaryFromReader);
      msg.addAssignVersionCommand(value);
      break;
    case 7:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.addVersionsToAssign(value);
      break;
    case 8:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.addCreateLicenseCommand(value);
      break;
    case 9:
      var value = new teamdev_licensing_identifiers_pb.DemoLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.DemoLicenseId.deserializeBinaryFromReader);
      msg.addDemoLicenseIds(value);
      break;
    case 10:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.addAcademicLicenseIds(value);
      break;
    case 11:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.addOpensourceLicenseIds(value);
      break;
    case 12:
      var value = new teamdev_licensing_identifiers_pb.SingleDevLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.addSingledevLicenseIds(value);
      break;
    case 13:
      var value = new teamdev_licensing_identifiers_pb.ProjectLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectLicenseId.deserializeBinaryFromReader);
      msg.addProjectLicenseIds(value);
      break;
    case 14:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.addCompanywideLicenseIds(value);
      break;
    case 15:
      var value = new teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.addSingledevsourcecodeLicenseIds(value);
      break;
    case 16:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.addProjectsourcecodeLicenseIds(value);
      break;
    case 17:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.addCompanywidesourcecodeLicenseIds(value);
      break;
    case 18:
      var value = new teamdev_licensing_identifiers_pb.PersonalLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.PersonalLicenseId.deserializeBinaryFromReader);
      msg.addPersonalLicenseIds(value);
      break;
    case 19:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.addEvaluationIds(value);
      break;
    case 20:
      var value = /** @type {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleDataGeneration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
  f = message.getCreateProductCommandList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      teamdev_licensing_c_product_commands_pb.CreateProduct.serializeBinaryToWriter
    );
  }
  f = message.getProductsToCreateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getCreateVersionCommandList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      teamdev_licensing_c_product_commands_pb.CreateProductVersion.serializeBinaryToWriter
    );
  }
  f = message.getVersionsToCreateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getAssignVersionCommandList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion.serializeBinaryToWriter
    );
  }
  f = message.getVersionsToAssignList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getCreateLicenseCommandList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getDemoLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      teamdev_licensing_identifiers_pb.DemoLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getAcademicLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getOpensourceLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingledevLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      teamdev_licensing_identifiers_pb.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      teamdev_licensing_identifiers_pb.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanywideLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingledevsourcecodeLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectsourcecodeLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanywidesourcecodeLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getPersonalLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      teamdev_licensing_identifiers_pb.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getEvaluationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status = {
  TDG_UNDEFINED: 0,
  CREATING_PRODUCTS: 2,
  CREATING_VERSIONS: 3,
  ASSIGNING_MAINSTREAM_VERSIONS: 4,
  CREATING_LICENSES: 5,
  COMPLETED: 6
};

/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated teamdev.licensing.c.product.CreateProduct create_product_command = 2;
 * @return {!Array<!proto.teamdev.licensing.c.product.CreateProduct>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getCreateProductCommandList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.product.CreateProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_product_commands_pb.CreateProduct, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.product.CreateProduct>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setCreateProductCommandList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.c.product.CreateProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.product.CreateProduct}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addCreateProductCommand = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.c.product.CreateProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearCreateProductCommandList = function() {
  return this.setCreateProductCommandList([]);
};


/**
 * repeated teamdev.licensing.ProductId products_to_create = 3;
 * @return {!Array<!proto.teamdev.licensing.ProductId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getProductsToCreateList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.ProductId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.ProductId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setProductsToCreateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.teamdev.licensing.ProductId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addProductsToCreate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.teamdev.licensing.ProductId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearProductsToCreateList = function() {
  return this.setProductsToCreateList([]);
};


/**
 * repeated teamdev.licensing.c.product.CreateProductVersion create_version_command = 4;
 * @return {!Array<!proto.teamdev.licensing.c.product.CreateProductVersion>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getCreateVersionCommandList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.product.CreateProductVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_product_commands_pb.CreateProductVersion, 4));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.product.CreateProductVersion>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setCreateVersionCommandList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.teamdev.licensing.c.product.CreateProductVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addCreateVersionCommand = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.teamdev.licensing.c.product.CreateProductVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearCreateVersionCommandList = function() {
  return this.setCreateVersionCommandList([]);
};


/**
 * repeated teamdev.licensing.ProductVersionId versions_to_create = 5;
 * @return {!Array<!proto.teamdev.licensing.ProductVersionId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getVersionsToCreateList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.ProductVersionId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 5));
};


/**
 * @param {!Array<!proto.teamdev.licensing.ProductVersionId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setVersionsToCreateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.teamdev.licensing.ProductVersionId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addVersionsToCreate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.teamdev.licensing.ProductVersionId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearVersionsToCreateList = function() {
  return this.setVersionsToCreateList([]);
};


/**
 * repeated teamdev.licensing.c.product.AssignMainstreamVersion assign_version_command = 6;
 * @return {!Array<!proto.teamdev.licensing.c.product.AssignMainstreamVersion>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getAssignVersionCommandList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.product.AssignMainstreamVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion, 6));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.product.AssignMainstreamVersion>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setAssignVersionCommandList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.teamdev.licensing.c.product.AssignMainstreamVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addAssignVersionCommand = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.teamdev.licensing.c.product.AssignMainstreamVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearAssignVersionCommandList = function() {
  return this.setAssignVersionCommandList([]);
};


/**
 * repeated teamdev.licensing.ProductVersionId versions_to_assign = 7;
 * @return {!Array<!proto.teamdev.licensing.ProductVersionId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getVersionsToAssignList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.ProductVersionId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 7));
};


/**
 * @param {!Array<!proto.teamdev.licensing.ProductVersionId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setVersionsToAssignList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.teamdev.licensing.ProductVersionId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addVersionsToAssign = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.teamdev.licensing.ProductVersionId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearVersionsToAssignList = function() {
  return this.setVersionsToAssignList([]);
};


/**
 * repeated google.protobuf.Any create_license_command = 8;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getCreateLicenseCommandList = function() {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 8));
};


/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setCreateLicenseCommandList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addCreateLicenseCommand = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.protobuf.Any, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearCreateLicenseCommandList = function() {
  return this.setCreateLicenseCommandList([]);
};


/**
 * repeated teamdev.licensing.DemoLicenseId demo_license_ids = 9;
 * @return {!Array<!proto.teamdev.licensing.DemoLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getDemoLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.DemoLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.DemoLicenseId, 9));
};


/**
 * @param {!Array<!proto.teamdev.licensing.DemoLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setDemoLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.teamdev.licensing.DemoLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.DemoLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addDemoLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.teamdev.licensing.DemoLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearDemoLicenseIdsList = function() {
  return this.setDemoLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.AcademicLicenseId academic_license_ids = 10;
 * @return {!Array<!proto.teamdev.licensing.AcademicLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getAcademicLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.AcademicLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 10));
};


/**
 * @param {!Array<!proto.teamdev.licensing.AcademicLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setAcademicLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.teamdev.licensing.AcademicLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addAcademicLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.teamdev.licensing.AcademicLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearAcademicLicenseIdsList = function() {
  return this.setAcademicLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.OpenSourceLicenseId openSource_license_ids = 11;
 * @return {!Array<!proto.teamdev.licensing.OpenSourceLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getOpensourceLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.OpenSourceLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 11));
};


/**
 * @param {!Array<!proto.teamdev.licensing.OpenSourceLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setOpensourceLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.teamdev.licensing.OpenSourceLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addOpensourceLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.teamdev.licensing.OpenSourceLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearOpensourceLicenseIdsList = function() {
  return this.setOpensourceLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.SingleDevLicenseId singleDev_license_ids = 12;
 * @return {!Array<!proto.teamdev.licensing.SingleDevLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getSingledevLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.SingleDevLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevLicenseId, 12));
};


/**
 * @param {!Array<!proto.teamdev.licensing.SingleDevLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setSingledevLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.teamdev.licensing.SingleDevLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addSingledevLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.teamdev.licensing.SingleDevLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearSingledevLicenseIdsList = function() {
  return this.setSingledevLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.ProjectLicenseId project_license_ids = 13;
 * @return {!Array<!proto.teamdev.licensing.ProjectLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getProjectLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.ProjectLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.ProjectLicenseId, 13));
};


/**
 * @param {!Array<!proto.teamdev.licensing.ProjectLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setProjectLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.teamdev.licensing.ProjectLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addProjectLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.teamdev.licensing.ProjectLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearProjectLicenseIdsList = function() {
  return this.setProjectLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.CompanyWideLicenseId companyWide_license_ids = 14;
 * @return {!Array<!proto.teamdev.licensing.CompanyWideLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getCompanywideLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.CompanyWideLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideLicenseId, 14));
};


/**
 * @param {!Array<!proto.teamdev.licensing.CompanyWideLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setCompanywideLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.teamdev.licensing.CompanyWideLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addCompanywideLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.teamdev.licensing.CompanyWideLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearCompanywideLicenseIdsList = function() {
  return this.setCompanywideLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.SingleDevSourceCodeLicenseId singleDevSourceCode_license_ids = 15;
 * @return {!Array<!proto.teamdev.licensing.SingleDevSourceCodeLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getSingledevsourcecodeLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.SingleDevSourceCodeLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId, 15));
};


/**
 * @param {!Array<!proto.teamdev.licensing.SingleDevSourceCodeLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setSingledevsourcecodeLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addSingledevsourcecodeLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.teamdev.licensing.SingleDevSourceCodeLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearSingledevsourcecodeLicenseIdsList = function() {
  return this.setSingledevsourcecodeLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.ProjectSourceCodeLicenseId projectSourceCode_license_ids = 16;
 * @return {!Array<!proto.teamdev.licensing.ProjectSourceCodeLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getProjectsourcecodeLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.ProjectSourceCodeLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 16));
};


/**
 * @param {!Array<!proto.teamdev.licensing.ProjectSourceCodeLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setProjectsourcecodeLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.teamdev.licensing.ProjectSourceCodeLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addProjectsourcecodeLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.teamdev.licensing.ProjectSourceCodeLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearProjectsourcecodeLicenseIdsList = function() {
  return this.setProjectsourcecodeLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.CompanyWideSourceCodeLicenseId companyWideSourceCode_license_ids = 17;
 * @return {!Array<!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getCompanywidesourcecodeLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId, 17));
};


/**
 * @param {!Array<!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setCompanywidesourcecodeLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addCompanywidesourcecodeLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.teamdev.licensing.CompanyWideSourceCodeLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearCompanywidesourcecodeLicenseIdsList = function() {
  return this.setCompanywidesourcecodeLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.PersonalLicenseId personal_license_ids = 18;
 * @return {!Array<!proto.teamdev.licensing.PersonalLicenseId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getPersonalLicenseIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.PersonalLicenseId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.PersonalLicenseId, 18));
};


/**
 * @param {!Array<!proto.teamdev.licensing.PersonalLicenseId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setPersonalLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.teamdev.licensing.PersonalLicenseId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addPersonalLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.teamdev.licensing.PersonalLicenseId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearPersonalLicenseIdsList = function() {
  return this.setPersonalLicenseIdsList([]);
};


/**
 * repeated teamdev.licensing.EvaluationId evaluation_ids = 19;
 * @return {!Array<!proto.teamdev.licensing.EvaluationId>}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getEvaluationIdsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.EvaluationId>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 19));
};


/**
 * @param {!Array<!proto.teamdev.licensing.EvaluationId>} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setEvaluationIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.teamdev.licensing.EvaluationId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.addEvaluationIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.teamdev.licensing.EvaluationId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.clearEvaluationIdsList = function() {
  return this.setEvaluationIdsList([]);
};


/**
 * optional Status status = 20;
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


goog.object.extend(exports, proto.teamdev.licensing.c.sampledata);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Parser;
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleDataGeneration();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.createProductCommand !== undefined && obj.createProductCommand !== null) {
    obj.createProductCommand.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCreateProductCommand(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.CreateProduct').fromObject(listItem);
          msg.addCreateProductCommand(value);
        }
      }
    );
  }
  
  if (obj.productsToCreate !== undefined && obj.productsToCreate !== null) {
    obj.productsToCreate.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProductsToCreate(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(listItem);
          msg.addProductsToCreate(value);
        }
      }
    );
  }
  
  if (obj.createVersionCommand !== undefined && obj.createVersionCommand !== null) {
    obj.createVersionCommand.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCreateVersionCommand(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.CreateProductVersion').fromObject(listItem);
          msg.addCreateVersionCommand(value);
        }
      }
    );
  }
  
  if (obj.versionsToCreate !== undefined && obj.versionsToCreate !== null) {
    obj.versionsToCreate.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addVersionsToCreate(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(listItem);
          msg.addVersionsToCreate(value);
        }
      }
    );
  }
  
  if (obj.assignVersionCommand !== undefined && obj.assignVersionCommand !== null) {
    obj.assignVersionCommand.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addAssignVersionCommand(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.AssignMainstreamVersion').fromObject(listItem);
          msg.addAssignVersionCommand(value);
        }
      }
    );
  }
  
  if (obj.versionsToAssign !== undefined && obj.versionsToAssign !== null) {
    obj.versionsToAssign.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addVersionsToAssign(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(listItem);
          msg.addVersionsToAssign(value);
        }
      }
    );
  }
  
  if (obj.createLicenseCommand !== undefined && obj.createLicenseCommand !== null) {
    obj.createLicenseCommand.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCreateLicenseCommand(null);
        } else {
          let value = TypeParsers.parserFor('type.googleapis.com/google.protobuf.Any').fromObject(listItem);
          msg.addCreateLicenseCommand(value);
        }
      }
    );
  }
  
  if (obj.demoLicenseIds !== undefined && obj.demoLicenseIds !== null) {
    obj.demoLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addDemoLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.DemoLicenseId').fromObject(listItem);
          msg.addDemoLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.academicLicenseIds !== undefined && obj.academicLicenseIds !== null) {
    obj.academicLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addAcademicLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(listItem);
          msg.addAcademicLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.openSourceLicenseIds !== undefined && obj.openSourceLicenseIds !== null) {
    obj.openSourceLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addOpenSourceLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(listItem);
          msg.addOpenSourceLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.singleDevLicenseIds !== undefined && obj.singleDevLicenseIds !== null) {
    obj.singleDevLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addSingleDevLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(listItem);
          msg.addSingleDevLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.projectLicenseIds !== undefined && obj.projectLicenseIds !== null) {
    obj.projectLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProjectLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(listItem);
          msg.addProjectLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.companyWideLicenseIds !== undefined && obj.companyWideLicenseIds !== null) {
    obj.companyWideLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCompanyWideLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(listItem);
          msg.addCompanyWideLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.singleDevSourceCodeLicenseIds !== undefined && obj.singleDevSourceCodeLicenseIds !== null) {
    obj.singleDevSourceCodeLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addSingleDevSourceCodeLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(listItem);
          msg.addSingleDevSourceCodeLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.projectSourceCodeLicenseIds !== undefined && obj.projectSourceCodeLicenseIds !== null) {
    obj.projectSourceCodeLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProjectSourceCodeLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(listItem);
          msg.addProjectSourceCodeLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.companyWideSourceCodeLicenseIds !== undefined && obj.companyWideSourceCodeLicenseIds !== null) {
    obj.companyWideSourceCodeLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCompanyWideSourceCodeLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(listItem);
          msg.addCompanyWideSourceCodeLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.personalLicenseIds !== undefined && obj.personalLicenseIds !== null) {
    obj.personalLicenseIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addPersonalLicenseIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(listItem);
          msg.addPersonalLicenseIds(value);
        }
      }
    );
  }
  
  if (obj.evaluationIds !== undefined && obj.evaluationIds !== null) {
    obj.evaluationIds.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addEvaluationIds(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(listItem);
          msg.addEvaluationIds(value);
        }
      }
    );
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status[obj.status];
      msg.setStatus(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGeneration';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleDataGeneration.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGeneration.Status';
};
