// source: teamdev/licensing/c/sampledata/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_c_product_commands_pb = require('../../../../teamdev/licensing/c/product/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_commands_pb);
var teamdev_licensing_c_academic_commands_pb = require('../../../../teamdev/licensing/c/academic/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_commands_pb);
var teamdev_licensing_c_opensource_commands_pb = require('../../../../teamdev/licensing/c/opensource/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_opensource_commands_pb);
var teamdev_licensing_c_evaluation_commands_pb = require('../../../../teamdev/licensing/c/evaluation/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_commands_pb);
var teamdev_licensing_c_demo_commands_pb = require('../../../../teamdev/licensing/c/demo/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_demo_commands_pb);
var teamdev_licensing_c_company_commands_pb = require('../../../../teamdev/licensing/c/company/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_company_commands_pb);
var teamdev_licensing_c_company_sourcecode_commands_pb = require('../../../../teamdev/licensing/c/company/sourcecode/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_company_sourcecode_commands_pb);
var teamdev_licensing_c_personal_commands_pb = require('../../../../teamdev/licensing/c/personal/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_personal_commands_pb);
var teamdev_licensing_c_project_commands_pb = require('../../../../teamdev/licensing/c/project/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_project_commands_pb);
var teamdev_licensing_c_project_sourcecode_commands_pb = require('../../../../teamdev/licensing/c/project/sourcecode/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_project_sourcecode_commands_pb);
var teamdev_licensing_c_singledev_commands_pb = require('../../../../teamdev/licensing/c/singledev/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_singledev_commands_pb);
var teamdev_licensing_c_singledev_sourcecode_commands_pb = require('../../../../teamdev/licensing/c/singledev/sourcecode/commands_pb.js');
goog.object.extend(proto, teamdev_licensing_c_singledev_sourcecode_commands_pb);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.AddSampleLicenses', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.AddSampleProductVersions', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.AddSampleProducts', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.c.sampledata.AddSampleProducts.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.AddSampleProducts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.AddSampleProducts.displayName = 'proto.teamdev.licensing.c.sampledata.AddSampleProducts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.AddSampleProductVersions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.displayName = 'proto.teamdev.licensing.c.sampledata.AddSampleProductVersions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.displayName = 'proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.c.sampledata.AddSampleLicenses.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.AddSampleLicenses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.AddSampleLicenses.displayName = 'proto.teamdev.licensing.c.sampledata.AddSampleLicenses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.displayName = 'proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.AddSampleProducts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleProducts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f),
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    teamdev_licensing_c_product_commands_pb.CreateProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProducts}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.AddSampleProducts;
  return proto.teamdev.licensing.c.sampledata.AddSampleProducts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleProducts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProducts}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_commands_pb.CreateProduct;
      reader.readMessage(value,teamdev_licensing_c_product_commands_pb.CreateProduct.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.AddSampleProducts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleProducts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      teamdev_licensing_c_product_commands_pb.CreateProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProducts} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProducts} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated teamdev.licensing.c.product.CreateProduct commands = 2;
 * @return {!Array<!proto.teamdev.licensing.c.product.CreateProduct>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.product.CreateProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_product_commands_pb.CreateProduct, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.product.CreateProduct>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProducts} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.c.product.CreateProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.product.CreateProduct}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.c.product.CreateProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProducts} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleProducts.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f),
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    teamdev_licensing_c_product_commands_pb.CreateProductVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.AddSampleProductVersions;
  return proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_commands_pb.CreateProductVersion;
      reader.readMessage(value,teamdev_licensing_c_product_commands_pb.CreateProductVersion.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      teamdev_licensing_c_product_commands_pb.CreateProductVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated teamdev.licensing.c.product.CreateProductVersion commands = 2;
 * @return {!Array<!proto.teamdev.licensing.c.product.CreateProductVersion>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.product.CreateProductVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_product_commands_pb.CreateProductVersion, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.product.CreateProductVersion>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.c.product.CreateProductVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.product.CreateProductVersion}
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.c.product.CreateProductVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleProductVersions} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f),
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions;
  return proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion;
      reader.readMessage(value,teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated teamdev.licensing.c.product.AssignMainstreamVersion commands = 2;
 * @return {!Array<!proto.teamdev.licensing.c.product.AssignMainstreamVersion>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.product.AssignMainstreamVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_product_commands_pb.AssignMainstreamVersion, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.product.AssignMainstreamVersion>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.c.product.AssignMainstreamVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.product.AssignMainstreamVersion}
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.c.product.AssignMainstreamVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.repeatedFields_ = [2,3,4,5,6,7,8,9,10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.AddSampleLicenses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f),
    academicList: jspb.Message.toObjectList(msg.getAcademicList(),
    teamdev_licensing_c_academic_commands_pb.CreateAcademicLicenseFromScratch.toObject, includeInstance),
    openSourceList: jspb.Message.toObjectList(msg.getOpenSourceList(),
    teamdev_licensing_c_opensource_commands_pb.CreateOpenSourceLicenseFromScratch.toObject, includeInstance),
    evaluationList: jspb.Message.toObjectList(msg.getEvaluationList(),
    teamdev_licensing_c_evaluation_commands_pb.RequestEvaluation.toObject, includeInstance),
    demoList: jspb.Message.toObjectList(msg.getDemoList(),
    teamdev_licensing_c_demo_commands_pb.RequestDemoLicense.toObject, includeInstance),
    companyList: jspb.Message.toObjectList(msg.getCompanyList(),
    teamdev_licensing_c_company_commands_pb.CreateCompanyWideLicense.toObject, includeInstance),
    companyScList: jspb.Message.toObjectList(msg.getCompanyScList(),
    teamdev_licensing_c_company_sourcecode_commands_pb.CreateCompanyWideSourceCodeLicense.toObject, includeInstance),
    projectList: jspb.Message.toObjectList(msg.getProjectList(),
    teamdev_licensing_c_project_commands_pb.CreateProjectLicense.toObject, includeInstance),
    projectScList: jspb.Message.toObjectList(msg.getProjectScList(),
    teamdev_licensing_c_project_sourcecode_commands_pb.CreateProjectSourceCodeLicense.toObject, includeInstance),
    singleDevList: jspb.Message.toObjectList(msg.getSingleDevList(),
    teamdev_licensing_c_singledev_commands_pb.CreateSingleDevLicense.toObject, includeInstance),
    singleDevScList: jspb.Message.toObjectList(msg.getSingleDevScList(),
    teamdev_licensing_c_singledev_sourcecode_commands_pb.CreateSingleDevSourceCodeLicense.toObject, includeInstance),
    personalList: jspb.Message.toObjectList(msg.getPersonalList(),
    teamdev_licensing_c_personal_commands_pb.CreatePersonalLicense.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.AddSampleLicenses;
  return proto.teamdev.licensing.c.sampledata.AddSampleLicenses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_academic_commands_pb.CreateAcademicLicenseFromScratch;
      reader.readMessage(value,teamdev_licensing_c_academic_commands_pb.CreateAcademicLicenseFromScratch.deserializeBinaryFromReader);
      msg.addAcademic(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_opensource_commands_pb.CreateOpenSourceLicenseFromScratch;
      reader.readMessage(value,teamdev_licensing_c_opensource_commands_pb.CreateOpenSourceLicenseFromScratch.deserializeBinaryFromReader);
      msg.addOpenSource(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_evaluation_commands_pb.RequestEvaluation;
      reader.readMessage(value,teamdev_licensing_c_evaluation_commands_pb.RequestEvaluation.deserializeBinaryFromReader);
      msg.addEvaluation(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_demo_commands_pb.RequestDemoLicense;
      reader.readMessage(value,teamdev_licensing_c_demo_commands_pb.RequestDemoLicense.deserializeBinaryFromReader);
      msg.addDemo(value);
      break;
    case 6:
      var value = new teamdev_licensing_c_company_commands_pb.CreateCompanyWideLicense;
      reader.readMessage(value,teamdev_licensing_c_company_commands_pb.CreateCompanyWideLicense.deserializeBinaryFromReader);
      msg.addCompany(value);
      break;
    case 7:
      var value = new teamdev_licensing_c_company_sourcecode_commands_pb.CreateCompanyWideSourceCodeLicense;
      reader.readMessage(value,teamdev_licensing_c_company_sourcecode_commands_pb.CreateCompanyWideSourceCodeLicense.deserializeBinaryFromReader);
      msg.addCompanySc(value);
      break;
    case 8:
      var value = new teamdev_licensing_c_project_commands_pb.CreateProjectLicense;
      reader.readMessage(value,teamdev_licensing_c_project_commands_pb.CreateProjectLicense.deserializeBinaryFromReader);
      msg.addProject(value);
      break;
    case 9:
      var value = new teamdev_licensing_c_project_sourcecode_commands_pb.CreateProjectSourceCodeLicense;
      reader.readMessage(value,teamdev_licensing_c_project_sourcecode_commands_pb.CreateProjectSourceCodeLicense.deserializeBinaryFromReader);
      msg.addProjectSc(value);
      break;
    case 10:
      var value = new teamdev_licensing_c_singledev_commands_pb.CreateSingleDevLicense;
      reader.readMessage(value,teamdev_licensing_c_singledev_commands_pb.CreateSingleDevLicense.deserializeBinaryFromReader);
      msg.addSingleDev(value);
      break;
    case 11:
      var value = new teamdev_licensing_c_singledev_sourcecode_commands_pb.CreateSingleDevSourceCodeLicense;
      reader.readMessage(value,teamdev_licensing_c_singledev_sourcecode_commands_pb.CreateSingleDevSourceCodeLicense.deserializeBinaryFromReader);
      msg.addSingleDevSc(value);
      break;
    case 12:
      var value = new teamdev_licensing_c_personal_commands_pb.CreatePersonalLicense;
      reader.readMessage(value,teamdev_licensing_c_personal_commands_pb.CreatePersonalLicense.deserializeBinaryFromReader);
      msg.addPersonal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.AddSampleLicenses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
  f = message.getAcademicList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      teamdev_licensing_c_academic_commands_pb.CreateAcademicLicenseFromScratch.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      teamdev_licensing_c_opensource_commands_pb.CreateOpenSourceLicenseFromScratch.serializeBinaryToWriter
    );
  }
  f = message.getEvaluationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      teamdev_licensing_c_evaluation_commands_pb.RequestEvaluation.serializeBinaryToWriter
    );
  }
  f = message.getDemoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      teamdev_licensing_c_demo_commands_pb.RequestDemoLicense.serializeBinaryToWriter
    );
  }
  f = message.getCompanyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      teamdev_licensing_c_company_commands_pb.CreateCompanyWideLicense.serializeBinaryToWriter
    );
  }
  f = message.getCompanyScList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      teamdev_licensing_c_company_sourcecode_commands_pb.CreateCompanyWideSourceCodeLicense.serializeBinaryToWriter
    );
  }
  f = message.getProjectList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      teamdev_licensing_c_project_commands_pb.CreateProjectLicense.serializeBinaryToWriter
    );
  }
  f = message.getProjectScList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      teamdev_licensing_c_project_sourcecode_commands_pb.CreateProjectSourceCodeLicense.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      teamdev_licensing_c_singledev_commands_pb.CreateSingleDevLicense.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevScList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      teamdev_licensing_c_singledev_sourcecode_commands_pb.CreateSingleDevSourceCodeLicense.serializeBinaryToWriter
    );
  }
  f = message.getPersonalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      teamdev_licensing_c_personal_commands_pb.CreatePersonalLicense.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch academic = 2;
 * @return {!Array<!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getAcademicList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_academic_commands_pb.CreateAcademicLicenseFromScratch, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setAcademicList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addAcademic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearAcademicList = function() {
  return this.setAcademicList([]);
};


/**
 * repeated teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch open_source = 3;
 * @return {!Array<!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getOpenSourceList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_opensource_commands_pb.CreateOpenSourceLicenseFromScratch, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setOpenSourceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addOpenSource = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearOpenSourceList = function() {
  return this.setOpenSourceList([]);
};


/**
 * repeated teamdev.licensing.c.evaluation.RequestEvaluation evaluation = 4;
 * @return {!Array<!proto.teamdev.licensing.c.evaluation.RequestEvaluation>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getEvaluationList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.evaluation.RequestEvaluation>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_evaluation_commands_pb.RequestEvaluation, 4));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.evaluation.RequestEvaluation>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setEvaluationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.RequestEvaluation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.evaluation.RequestEvaluation}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addEvaluation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.teamdev.licensing.c.evaluation.RequestEvaluation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearEvaluationList = function() {
  return this.setEvaluationList([]);
};


/**
 * repeated teamdev.licensing.c.demo.RequestDemoLicense demo = 5;
 * @return {!Array<!proto.teamdev.licensing.c.demo.RequestDemoLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getDemoList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.demo.RequestDemoLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_demo_commands_pb.RequestDemoLicense, 5));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.demo.RequestDemoLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setDemoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.teamdev.licensing.c.demo.RequestDemoLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.demo.RequestDemoLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addDemo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.teamdev.licensing.c.demo.RequestDemoLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearDemoList = function() {
  return this.setDemoList([]);
};


/**
 * repeated teamdev.licensing.c.company.CreateCompanyWideLicense company = 6;
 * @return {!Array<!proto.teamdev.licensing.c.company.CreateCompanyWideLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getCompanyList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.company.CreateCompanyWideLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_company_commands_pb.CreateCompanyWideLicense, 6));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.company.CreateCompanyWideLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setCompanyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.teamdev.licensing.c.company.CreateCompanyWideLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.company.CreateCompanyWideLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addCompany = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.teamdev.licensing.c.company.CreateCompanyWideLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearCompanyList = function() {
  return this.setCompanyList([]);
};


/**
 * repeated teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense company_sc = 7;
 * @return {!Array<!proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getCompanyScList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_company_sourcecode_commands_pb.CreateCompanyWideSourceCodeLicense, 7));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setCompanyScList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addCompanySc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearCompanyScList = function() {
  return this.setCompanyScList([]);
};


/**
 * repeated teamdev.licensing.c.project.CreateProjectLicense project = 8;
 * @return {!Array<!proto.teamdev.licensing.c.project.CreateProjectLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getProjectList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.project.CreateProjectLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_project_commands_pb.CreateProjectLicense, 8));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.project.CreateProjectLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setProjectList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.teamdev.licensing.c.project.CreateProjectLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.project.CreateProjectLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addProject = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.teamdev.licensing.c.project.CreateProjectLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearProjectList = function() {
  return this.setProjectList([]);
};


/**
 * repeated teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense project_sc = 9;
 * @return {!Array<!proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getProjectScList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_project_sourcecode_commands_pb.CreateProjectSourceCodeLicense, 9));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setProjectScList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addProjectSc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearProjectScList = function() {
  return this.setProjectScList([]);
};


/**
 * repeated teamdev.licensing.c.singledev.CreateSingleDevLicense single_dev = 10;
 * @return {!Array<!proto.teamdev.licensing.c.singledev.CreateSingleDevLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getSingleDevList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.singledev.CreateSingleDevLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_singledev_commands_pb.CreateSingleDevLicense, 10));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.singledev.CreateSingleDevLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setSingleDevList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.teamdev.licensing.c.singledev.CreateSingleDevLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.singledev.CreateSingleDevLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addSingleDev = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.teamdev.licensing.c.singledev.CreateSingleDevLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearSingleDevList = function() {
  return this.setSingleDevList([]);
};


/**
 * repeated teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense single_dev_sc = 11;
 * @return {!Array<!proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getSingleDevScList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_singledev_sourcecode_commands_pb.CreateSingleDevSourceCodeLicense, 11));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setSingleDevScList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addSingleDevSc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearSingleDevScList = function() {
  return this.setSingleDevScList([]);
};


/**
 * repeated teamdev.licensing.c.personal.CreatePersonalLicense personal = 12;
 * @return {!Array<!proto.teamdev.licensing.c.personal.CreatePersonalLicense>}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.getPersonalList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.c.personal.CreatePersonalLicense>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_c_personal_commands_pb.CreatePersonalLicense, 12));
};


/**
 * @param {!Array<!proto.teamdev.licensing.c.personal.CreatePersonalLicense>} value
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
*/
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.setPersonalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.teamdev.licensing.c.personal.CreatePersonalLicense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.c.personal.CreatePersonalLicense}
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.addPersonal = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.teamdev.licensing.c.personal.CreatePersonalLicense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.c.sampledata.AddSampleLicenses} returns this
 */
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.prototype.clearPersonalList = function() {
  return this.setPersonalList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration}
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration;
  return proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration}
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration} returns this
*/
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration} returns this
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.sampledata);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.Parser;
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.AddSampleProductVersions();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.commands !== undefined && obj.commands !== null) {
    obj.commands.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCommands(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.CreateProductVersion').fromObject(listItem);
          msg.addCommands(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.AddSampleLicenses.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.AddSampleLicenses.Parser;
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.AddSampleLicenses();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.academic !== undefined && obj.academic !== null) {
    obj.academic.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addAcademic(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.CreateAcademicLicenseFromScratch').fromObject(listItem);
          msg.addAcademic(value);
        }
      }
    );
  }
  
  if (obj.openSource !== undefined && obj.openSource !== null) {
    obj.openSource.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addOpenSource(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.CreateOpenSourceLicenseFromScratch').fromObject(listItem);
          msg.addOpenSource(value);
        }
      }
    );
  }
  
  if (obj.evaluation !== undefined && obj.evaluation !== null) {
    obj.evaluation.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addEvaluation(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.RequestEvaluation').fromObject(listItem);
          msg.addEvaluation(value);
        }
      }
    );
  }
  
  if (obj.demo !== undefined && obj.demo !== null) {
    obj.demo.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addDemo(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.demo.RequestDemoLicense').fromObject(listItem);
          msg.addDemo(value);
        }
      }
    );
  }
  
  if (obj.company !== undefined && obj.company !== null) {
    obj.company.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCompany(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.company.CreateCompanyWideLicense').fromObject(listItem);
          msg.addCompany(value);
        }
      }
    );
  }
  
  if (obj.companySc !== undefined && obj.companySc !== null) {
    obj.companySc.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCompanySc(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.company.sourcecode.CreateCompanyWideSourceCodeLicense').fromObject(listItem);
          msg.addCompanySc(value);
        }
      }
    );
  }
  
  if (obj.project !== undefined && obj.project !== null) {
    obj.project.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProject(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.project.CreateProjectLicense').fromObject(listItem);
          msg.addProject(value);
        }
      }
    );
  }
  
  if (obj.projectSc !== undefined && obj.projectSc !== null) {
    obj.projectSc.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProjectSc(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.project.sourcecode.CreateProjectSourceCodeLicense').fromObject(listItem);
          msg.addProjectSc(value);
        }
      }
    );
  }
  
  if (obj.singleDev !== undefined && obj.singleDev !== null) {
    obj.singleDev.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addSingleDev(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.singledev.CreateSingleDevLicense').fromObject(listItem);
          msg.addSingleDev(value);
        }
      }
    );
  }
  
  if (obj.singleDevSc !== undefined && obj.singleDevSc !== null) {
    obj.singleDevSc.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addSingleDevSc(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.singledev.sourcecode.CreateSingleDevSourceCodeLicense').fromObject(listItem);
          msg.addSingleDevSc(value);
        }
      }
    );
  }
  
  if (obj.personal !== undefined && obj.personal !== null) {
    obj.personal.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addPersonal(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.personal.CreatePersonalLicense').fromObject(listItem);
          msg.addPersonal(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.Parser;
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.commands !== undefined && obj.commands !== null) {
    obj.commands.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCommands(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.AssignMainstreamVersion').fromObject(listItem);
          msg.addCommands(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.Parser;
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.AddSampleProducts.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.AddSampleProducts.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.AddSampleProducts.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.AddSampleProducts.Parser;
proto.teamdev.licensing.c.sampledata.AddSampleProducts.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.AddSampleProducts();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.commands !== undefined && obj.commands !== null) {
    obj.commands.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCommands(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.CreateProduct').fromObject(listItem);
          msg.addCommands(value);
        }
      }
    );
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.AddSampleProductVersions.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleProductVersions';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.AddSampleLicenses.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleLicenses';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.AddSampleMainstreamVersions.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleMainstreamVersions';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.StartSampleDataGeneration.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.StartSampleDataGeneration';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.AddSampleProducts.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.AddSampleProducts';
};
