// source: teamdev/licensing/values.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.CommercialLicenseRevocationReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.CommercialLicenseRevocationReason.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.CreatedFrom', null, global);
goog.exportSymbol('proto.teamdev.licensing.EvaluationLicenseRevocationReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.EvaluationLicenseRevocationReason.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.LicenseRequestVerificationFailureReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.LicenseRequestVerificationFailureReason.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.NonCommercialLicenseRevocationReason', null, global);
goog.exportSymbol('proto.teamdev.licensing.NonCommercialLicenseRevocationReason.KindCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.OtherReasonChoice', null, global);
goog.exportSymbol('proto.teamdev.licensing.PhoneNumber', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.CommercialLicenseRevocationReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.CommercialLicenseRevocationReason.displayName = 'proto.teamdev.licensing.CommercialLicenseRevocationReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.NonCommercialLicenseRevocationReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.NonCommercialLicenseRevocationReason.displayName = 'proto.teamdev.licensing.NonCommercialLicenseRevocationReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.EvaluationLicenseRevocationReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.EvaluationLicenseRevocationReason.displayName = 'proto.teamdev.licensing.EvaluationLicenseRevocationReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.OtherReasonChoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.OtherReasonChoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.OtherReasonChoice.displayName = 'proto.teamdev.licensing.OtherReasonChoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.LicenseRequestVerificationFailureReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.LicenseRequestVerificationFailureReason.displayName = 'proto.teamdev.licensing.LicenseRequestVerificationFailureReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.PhoneNumber = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.PhoneNumber, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.PhoneNumber.displayName = 'proto.teamdev.licensing.PhoneNumber';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.KindCase = {
  KIND_NOT_SET: 0,
  LICENSE_OVERUSE: 1,
  LICENSE_ILLEGAL_DISTRIBUTION: 2,
  LICENSE_INVALIDATED: 3,
  REFUND: 4,
  OTHER: 5
};

/**
 * @return {proto.teamdev.licensing.CommercialLicenseRevocationReason.KindCase}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.CommercialLicenseRevocationReason.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.CommercialLicenseRevocationReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.CommercialLicenseRevocationReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseOveruse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    licenseIllegalDistribution: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    licenseInvalidated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    refund: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    other: (f = msg.getOther()) && proto.teamdev.licensing.OtherReasonChoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.CommercialLicenseRevocationReason;
  return proto.teamdev.licensing.CommercialLicenseRevocationReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.CommercialLicenseRevocationReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseOveruse(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseIllegalDistribution(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseInvalidated(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefund(value);
      break;
    case 5:
      var value = new proto.teamdev.licensing.OtherReasonChoice;
      reader.readMessage(value,proto.teamdev.licensing.OtherReasonChoice.deserializeBinaryFromReader);
      msg.setOther(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.CommercialLicenseRevocationReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.CommercialLicenseRevocationReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOther();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.teamdev.licensing.OtherReasonChoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool license_overuse = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.getLicenseOveruse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.setLicenseOveruse = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.clearLicenseOveruse = function() {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.hasLicenseOveruse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool license_illegal_distribution = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.getLicenseIllegalDistribution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.setLicenseIllegalDistribution = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.clearLicenseIllegalDistribution = function() {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.hasLicenseIllegalDistribution = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool license_invalidated = 3;
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.getLicenseInvalidated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.setLicenseInvalidated = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.clearLicenseInvalidated = function() {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.hasLicenseInvalidated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool refund = 4;
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.getRefund = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.setRefund = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.clearRefund = function() {
  return jspb.Message.setOneofField(this, 4, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.hasRefund = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OtherReasonChoice other = 5;
 * @return {?proto.teamdev.licensing.OtherReasonChoice}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.getOther = function() {
  return /** @type{?proto.teamdev.licensing.OtherReasonChoice} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.OtherReasonChoice, 5));
};


/**
 * @param {?proto.teamdev.licensing.OtherReasonChoice|undefined} value
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
*/
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.setOther = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.teamdev.licensing.CommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.CommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.clearOther = function() {
  return this.setOther(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.CommercialLicenseRevocationReason.prototype.hasOther = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.KindCase = {
  KIND_NOT_SET: 0,
  LICENSE_MISUSE: 1,
  LICENSE_ILLEGAL_DISTRIBUTION: 2,
  LICENSE_INVALIDATED: 3,
  OTHER: 4
};

/**
 * @return {proto.teamdev.licensing.NonCommercialLicenseRevocationReason.KindCase}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.NonCommercialLicenseRevocationReason.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.NonCommercialLicenseRevocationReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseMisuse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    licenseIllegalDistribution: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    licenseInvalidated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    other: (f = msg.getOther()) && proto.teamdev.licensing.OtherReasonChoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.NonCommercialLicenseRevocationReason;
  return proto.teamdev.licensing.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseMisuse(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseIllegalDistribution(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseInvalidated(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.OtherReasonChoice;
      reader.readMessage(value,proto.teamdev.licensing.OtherReasonChoice.deserializeBinaryFromReader);
      msg.setOther(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.NonCommercialLicenseRevocationReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOther();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.teamdev.licensing.OtherReasonChoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool license_misuse = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.getLicenseMisuse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.setLicenseMisuse = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.clearLicenseMisuse = function() {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.hasLicenseMisuse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool license_illegal_distribution = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.getLicenseIllegalDistribution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.setLicenseIllegalDistribution = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.clearLicenseIllegalDistribution = function() {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.hasLicenseIllegalDistribution = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool license_invalidated = 3;
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.getLicenseInvalidated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.setLicenseInvalidated = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.clearLicenseInvalidated = function() {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.hasLicenseInvalidated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OtherReasonChoice other = 4;
 * @return {?proto.teamdev.licensing.OtherReasonChoice}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.getOther = function() {
  return /** @type{?proto.teamdev.licensing.OtherReasonChoice} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.OtherReasonChoice, 4));
};


/**
 * @param {?proto.teamdev.licensing.OtherReasonChoice|undefined} value
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
*/
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.setOther = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.teamdev.licensing.NonCommercialLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.NonCommercialLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.clearOther = function() {
  return this.setOther(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.prototype.hasOther = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.KindCase = {
  KIND_NOT_SET: 0,
  LICENSE_MISUSE: 1,
  LICENSE_ILLEGAL_DISTRIBUTION: 2,
  LICENSE_INVALIDATED: 3,
  OTHER: 4
};

/**
 * @return {proto.teamdev.licensing.EvaluationLicenseRevocationReason.KindCase}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.EvaluationLicenseRevocationReason.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.EvaluationLicenseRevocationReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseMisuse: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    licenseIllegalDistribution: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    licenseInvalidated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    other: (f = msg.getOther()) && proto.teamdev.licensing.OtherReasonChoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.EvaluationLicenseRevocationReason;
  return proto.teamdev.licensing.EvaluationLicenseRevocationReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseMisuse(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseIllegalDistribution(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseInvalidated(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.OtherReasonChoice;
      reader.readMessage(value,proto.teamdev.licensing.OtherReasonChoice.deserializeBinaryFromReader);
      msg.setOther(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.EvaluationLicenseRevocationReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOther();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.teamdev.licensing.OtherReasonChoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool license_misuse = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.getLicenseMisuse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.setLicenseMisuse = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.clearLicenseMisuse = function() {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.hasLicenseMisuse = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool license_illegal_distribution = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.getLicenseIllegalDistribution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.setLicenseIllegalDistribution = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.clearLicenseIllegalDistribution = function() {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.hasLicenseIllegalDistribution = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool license_invalidated = 3;
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.getLicenseInvalidated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.setLicenseInvalidated = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.clearLicenseInvalidated = function() {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.hasLicenseInvalidated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OtherReasonChoice other = 4;
 * @return {?proto.teamdev.licensing.OtherReasonChoice}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.getOther = function() {
  return /** @type{?proto.teamdev.licensing.OtherReasonChoice} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.OtherReasonChoice, 4));
};


/**
 * @param {?proto.teamdev.licensing.OtherReasonChoice|undefined} value
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
*/
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.setOther = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.teamdev.licensing.EvaluationLicenseRevocationReason.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.EvaluationLicenseRevocationReason} returns this
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.clearOther = function() {
  return this.setOther(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.EvaluationLicenseRevocationReason.prototype.hasOther = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.OtherReasonChoice.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.OtherReasonChoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.OtherReasonChoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.OtherReasonChoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.OtherReasonChoice}
 */
proto.teamdev.licensing.OtherReasonChoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.OtherReasonChoice;
  return proto.teamdev.licensing.OtherReasonChoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.OtherReasonChoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.OtherReasonChoice}
 */
proto.teamdev.licensing.OtherReasonChoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.OtherReasonChoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.OtherReasonChoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.OtherReasonChoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.OtherReasonChoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.teamdev.licensing.OtherReasonChoice.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.OtherReasonChoice} returns this
 */
proto.teamdev.licensing.OtherReasonChoice.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.KindCase = {
  KIND_NOT_SET: 0,
  ADDITIONAL_INFORMATION_REQUIRED: 1,
  REQUEST_INVALID: 2,
  NOT_ELIGIBLE: 3,
  SPAM: 4
};

/**
 * @return {proto.teamdev.licensing.LicenseRequestVerificationFailureReason.KindCase}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.getKindCase = function() {
  return /** @type {proto.teamdev.licensing.LicenseRequestVerificationFailureReason.KindCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.LicenseRequestVerificationFailureReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    additionalInformationRequired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    requestInvalid: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    notEligible: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    spam: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.LicenseRequestVerificationFailureReason;
  return proto.teamdev.licensing.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdditionalInformationRequired(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestInvalid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotEligible(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.LicenseRequestVerificationFailureReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool additional_information_required = 1;
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.getAdditionalInformationRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.setAdditionalInformationRequired = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.clearAdditionalInformationRequired = function() {
  return jspb.Message.setOneofField(this, 1, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.hasAdditionalInformationRequired = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool request_invalid = 2;
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.getRequestInvalid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.setRequestInvalid = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.clearRequestInvalid = function() {
  return jspb.Message.setOneofField(this, 2, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.hasRequestInvalid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool not_eligible = 3;
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.getNotEligible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.setNotEligible = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.clearNotEligible = function() {
  return jspb.Message.setOneofField(this, 3, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.hasNotEligible = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool spam = 4;
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.getSpam = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.setSpam = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.teamdev.licensing.LicenseRequestVerificationFailureReason} returns this
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.clearSpam = function() {
  return jspb.Message.setOneofField(this, 4, proto.teamdev.licensing.LicenseRequestVerificationFailureReason.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.prototype.hasSpam = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.PhoneNumber.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.PhoneNumber.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.PhoneNumber} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.PhoneNumber.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.PhoneNumber}
 */
proto.teamdev.licensing.PhoneNumber.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.PhoneNumber;
  return proto.teamdev.licensing.PhoneNumber.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.PhoneNumber} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.PhoneNumber}
 */
proto.teamdev.licensing.PhoneNumber.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.PhoneNumber.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.PhoneNumber.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.PhoneNumber} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.PhoneNumber.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.teamdev.licensing.PhoneNumber.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.PhoneNumber} returns this
 */
proto.teamdev.licensing.PhoneNumber.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.CreatedFrom = {
  CF_UNDEFINED: 0,
  CREATED_FROM_REQUEST: 1,
  CREATED_FROM_SCRATCH: 2
};

goog.object.extend(exports, proto.teamdev.licensing);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.OtherReasonChoice.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.OtherReasonChoice.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.OtherReasonChoice.Parser.prototype.constructor = proto.teamdev.licensing.OtherReasonChoice.Parser;
proto.teamdev.licensing.OtherReasonChoice.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.OtherReasonChoice();
  
  if (obj.description !== undefined) {
    if (obj.description !== null) {
      let value = obj.description;
      msg.setDescription(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.LicenseRequestVerificationFailureReason.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.Parser.prototype.constructor = proto.teamdev.licensing.LicenseRequestVerificationFailureReason.Parser;
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.LicenseRequestVerificationFailureReason();
  
  if (obj.additionalInformationRequired !== undefined) {
    if (obj.additionalInformationRequired !== null) {
      let value = obj.additionalInformationRequired;
      msg.setAdditionalInformationRequired(value);
    }
  }
  
  if (obj.requestInvalid !== undefined) {
    if (obj.requestInvalid !== null) {
      let value = obj.requestInvalid;
      msg.setRequestInvalid(value);
    }
  }
  
  if (obj.notEligible !== undefined) {
    if (obj.notEligible !== null) {
      let value = obj.notEligible;
      msg.setNotEligible(value);
    }
  }
  
  if (obj.spam !== undefined) {
    if (obj.spam !== null) {
      let value = obj.spam;
      msg.setSpam(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.CommercialLicenseRevocationReason.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.CommercialLicenseRevocationReason.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.CommercialLicenseRevocationReason.Parser.prototype.constructor = proto.teamdev.licensing.CommercialLicenseRevocationReason.Parser;
proto.teamdev.licensing.CommercialLicenseRevocationReason.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.CommercialLicenseRevocationReason();
  
  if (obj.licenseOveruse !== undefined) {
    if (obj.licenseOveruse !== null) {
      let value = obj.licenseOveruse;
      msg.setLicenseOveruse(value);
    }
  }
  
  if (obj.licenseIllegalDistribution !== undefined) {
    if (obj.licenseIllegalDistribution !== null) {
      let value = obj.licenseIllegalDistribution;
      msg.setLicenseIllegalDistribution(value);
    }
  }
  
  if (obj.licenseInvalidated !== undefined) {
    if (obj.licenseInvalidated !== null) {
      let value = obj.licenseInvalidated;
      msg.setLicenseInvalidated(value);
    }
  }
  
  if (obj.refund !== undefined) {
    if (obj.refund !== null) {
      let value = obj.refund;
      msg.setRefund(value);
    }
  }
  
  if (obj.other !== undefined) {
    if (obj.other === null) {
      msg.setOther(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OtherReasonChoice').fromObject(obj.other);
      msg.setOther(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.EvaluationLicenseRevocationReason.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.EvaluationLicenseRevocationReason.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.EvaluationLicenseRevocationReason.Parser.prototype.constructor = proto.teamdev.licensing.EvaluationLicenseRevocationReason.Parser;
proto.teamdev.licensing.EvaluationLicenseRevocationReason.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.EvaluationLicenseRevocationReason();
  
  if (obj.licenseMisuse !== undefined) {
    if (obj.licenseMisuse !== null) {
      let value = obj.licenseMisuse;
      msg.setLicenseMisuse(value);
    }
  }
  
  if (obj.licenseIllegalDistribution !== undefined) {
    if (obj.licenseIllegalDistribution !== null) {
      let value = obj.licenseIllegalDistribution;
      msg.setLicenseIllegalDistribution(value);
    }
  }
  
  if (obj.licenseInvalidated !== undefined) {
    if (obj.licenseInvalidated !== null) {
      let value = obj.licenseInvalidated;
      msg.setLicenseInvalidated(value);
    }
  }
  
  if (obj.other !== undefined) {
    if (obj.other === null) {
      msg.setOther(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OtherReasonChoice').fromObject(obj.other);
      msg.setOther(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.PhoneNumber.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.PhoneNumber.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.PhoneNumber.Parser.prototype.constructor = proto.teamdev.licensing.PhoneNumber.Parser;
proto.teamdev.licensing.PhoneNumber.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.PhoneNumber();
  
  if (obj.value !== undefined) {
    if (obj.value !== null) {
      let value = obj.value;
      msg.setValue(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.NonCommercialLicenseRevocationReason.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.Parser.prototype.constructor = proto.teamdev.licensing.NonCommercialLicenseRevocationReason.Parser;
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.NonCommercialLicenseRevocationReason();
  
  if (obj.licenseMisuse !== undefined) {
    if (obj.licenseMisuse !== null) {
      let value = obj.licenseMisuse;
      msg.setLicenseMisuse(value);
    }
  }
  
  if (obj.licenseIllegalDistribution !== undefined) {
    if (obj.licenseIllegalDistribution !== null) {
      let value = obj.licenseIllegalDistribution;
      msg.setLicenseIllegalDistribution(value);
    }
  }
  
  if (obj.licenseInvalidated !== undefined) {
    if (obj.licenseInvalidated !== null) {
      let value = obj.licenseInvalidated;
      msg.setLicenseInvalidated(value);
    }
  }
  
  if (obj.other !== undefined) {
    if (obj.other === null) {
      msg.setOther(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OtherReasonChoice').fromObject(obj.other);
      msg.setOther(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.OtherReasonChoice.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.OtherReasonChoice';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.LicenseRequestVerificationFailureReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.CommercialLicenseRevocationReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.CommercialLicenseRevocationReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.EvaluationLicenseRevocationReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.EvaluationLicenseRevocationReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.PhoneNumber.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.PhoneNumber';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.NonCommercialLicenseRevocationReason.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.CreatedFrom.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.CreatedFrom';
};
