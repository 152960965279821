// source: teamdev/licensing/c/academic/events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_license_artifact_pb = require('../../../../teamdev/licensing/license_artifact_pb.js');
goog.object.extend(proto, teamdev_licensing_license_artifact_pb);
var teamdev_licensing_c_academic_values_pb = require('../../../../teamdev/licensing/c/academic/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_values_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseIssued', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRequested', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.AcademicLicenseRevoked', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequested, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequested.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequested';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseGranted.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseIssued, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseIssued.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseIssued';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.displayName = 'proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.displayName = 'proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseRevoked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseRevoked';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseExpired.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseExpired';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.displayName = 'proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequested.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    mainstreamVersionId: (f = msg.getMainstreamVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    whenRequested: (f = msg.getWhenRequested()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    assurancePromise: (f = msg.getAssurancePromise()) && teamdev_licensing_c_academic_values_pb.AssurancePromise.toObject(includeInstance, f),
    promotionRequirementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequested;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequested.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setMainstreamVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 5:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 7:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRequested(value);
      break;
    case 8:
      var value = new teamdev_licensing_c_academic_values_pb.AssurancePromise;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AssurancePromise.deserializeBinaryFromReader);
      msg.setAssurancePromise(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPromotionRequirementAccepted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequested.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getMainstreamVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWhenRequested();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getAssurancePromise();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_c_academic_values_pb.AssurancePromise.serializeBinaryToWriter
    );
  }
  f = message.getPromotionRequirementAccepted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId mainstream_version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getMainstreamVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setMainstreamVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.clearMainstreamVersionId = function() {
  return this.setMainstreamVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.hasMainstreamVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AcademicLicenseRequester requester = 4;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 5;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 5));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string project_description = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.time.ZonedDateTime when_requested = 7;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getWhenRequested = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 7));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setWhenRequested = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.clearWhenRequested = function() {
  return this.setWhenRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.hasWhenRequested = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AssurancePromise assurance_promise = 8;
 * @return {?proto.teamdev.licensing.c.academic.AssurancePromise}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getAssurancePromise = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AssurancePromise} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AssurancePromise, 8));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AssurancePromise|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setAssurancePromise = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.clearAssurancePromise = function() {
  return this.setAssurancePromise(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.hasAssurancePromise = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool promotion_requirement_accepted = 9;
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.getPromotionRequirementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.prototype.setPromotionRequirementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenStarted: (f = msg.getWhenStarted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenStarted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenStarted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_started = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.getWhenStarted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.setWhenStarted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.clearWhenStarted = function() {
  return this.setWhenStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.prototype.hasWhenStarted = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenVerified: (f = msg.getWhenVerified()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    licensingTarget: (f = msg.getLicensingTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerified(value);
      break;
    case 3:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 4:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setLicensingTarget(value);
      break;
    case 5:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerified();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getLicensingTarget();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verified = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.getWhenVerified = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.setWhenVerified = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.clearWhenVerified = function() {
  return this.setWhenVerified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.hasWhenVerified = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 3;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 3));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget licensing_target = 4;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.getLicensingTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 4));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.setLicensingTarget = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.clearLicensingTarget = function() {
  return this.setLicensingTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.hasLicensingTarget = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.core.UserId verified_by = 5;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 5));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenVerificationFailed: (f = msg.getWhenVerificationFailed()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerificationFailed(value);
      break;
    case 3:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerificationFailed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verification_failed = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.getWhenVerificationFailed = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.setWhenVerificationFailed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.clearWhenVerificationFailed = function() {
  return this.setWhenVerificationFailed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.hasWhenVerificationFailed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 3;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId verified_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenGranted: (f = msg.getWhenGranted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseGranted;
  return proto.teamdev.licensing.c.academic.AcademicLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenGranted(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 6:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenGranted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_granted = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.getWhenGranted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.setWhenGranted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.clearWhenGranted = function() {
  return this.setWhenGranted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.hasWhenGranted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 3;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AcademicLicenseRequester requester = 5;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 6;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 6));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string project_description = 7;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseIssued.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseIssued;
  return proto.teamdev.licensing.c.academic.AcademicLicenseIssued.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 3:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseIssued.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 3;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenRefused: (f = msg.getWhenRefused()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_academic_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRefused(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_academic_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRefused();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_academic_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_refused = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.getWhenRefused = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.setWhenRefused = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.clearWhenRefused = function() {
  return this.setWhenRefused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.hasWhenRefused = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RequestRefusalReason reason = 3;
 * @return {?proto.teamdev.licensing.c.academic.RequestRefusalReason}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.RequestRefusalReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId refused_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenRequested: (f = msg.getWhenRequested()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRequested(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_academic_values_pb.ExtensionRequestReason;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRequested();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_academic_values_pb.ExtensionRequestReason.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_requested = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.getWhenRequested = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.setWhenRequested = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.clearWhenRequested = function() {
  return this.setWhenRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.hasWhenRequested = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExtensionRequestReason reason = 3;
 * @return {?proto.teamdev.licensing.c.academic.ExtensionRequestReason}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.ExtensionRequestReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.ExtensionRequestReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.ExtensionRequestReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 4;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 4));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenStarted: (f = msg.getWhenStarted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenStarted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenStarted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_started = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.getWhenStarted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.setWhenStarted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.clearWhenStarted = function() {
  return this.setWhenStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.prototype.hasWhenStarted = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenVerified: (f = msg.getWhenVerified()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerified(value);
      break;
    case 3:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerified();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verified = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.getWhenVerified = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.setWhenVerified = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.clearWhenVerified = function() {
  return this.setWhenVerified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.hasWhenVerified = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 3;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 3));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId verified_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenVerificationFailed: (f = msg.getWhenVerificationFailed()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerificationFailed(value);
      break;
    case 3:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerificationFailed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verification_failed = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.getWhenVerificationFailed = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.setWhenVerificationFailed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.clearWhenVerificationFailed = function() {
  return this.setWhenVerificationFailed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.hasWhenVerificationFailed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 3;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId verified_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenGranted: (f = msg.getWhenGranted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted;
  return proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenGranted(value);
      break;
    case 3:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenGranted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_granted = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.getWhenGranted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.setWhenGranted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.clearWhenGranted = function() {
  return this.setWhenGranted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.hasWhenGranted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.LocalDate active_through = 3;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 3));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} returns this
*/
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted} returns this
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued;
  return proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 3:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 3;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
*/
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued} returns this
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_academic_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    whenRefused: (f = msg.getWhenRefused()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_academic_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRefused(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_academic_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getWhenRefused();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestRefusalReason reason = 2;
 * @return {?proto.teamdev.licensing.c.academic.RequestRefusalReason}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.RequestRefusalReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.ZonedDateTime when_refused = 3;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.getWhenRefused = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 3));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.setWhenRefused = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.clearWhenRefused = function() {
  return this.setWhenRefused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.hasWhenRefused = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId refused_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenRevoked: (f = msg.getWhenRevoked()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRevoked;
  return proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRevoked(value);
      break;
    case 3:
      var value = new teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRevoked();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_revoked = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.getWhenRevoked = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.setWhenRevoked = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.clearWhenRevoked = function() {
  return this.setWhenRevoked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.hasWhenRevoked = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.NonCommercialLicenseRevocationReason reason = 3;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId revoked_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    whenExpired: (f = msg.getWhenExpired()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExpired;
  return proto.teamdev.licensing.c.academic.AcademicLicenseExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhenExpired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenExpired();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.LocalDate when_expired = 2;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.getWhenExpired = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 2));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.setWhenExpired = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseExpired} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.clearWhenExpired = function() {
  return this.setWhenExpired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.prototype.hasWhenExpired = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted;
  return proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 2;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch;
  return proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 5:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 7:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 8:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AcademicLicenseRequester requester = 4;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 5;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 5));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string project_description = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.time.LocalDate active_through = 7;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 7));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional spine.core.UserId created_by = 8;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.getCreatedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 8));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    mainstreamVersionId: (f = msg.getMainstreamVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    projectCompletionDate: (f = msg.getProjectCompletionDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest;
  return proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setMainstreamVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 5:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setProjectCompletionDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 7:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getMainstreamVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectCompletionDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId id = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId mainstream_version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.getMainstreamVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.setMainstreamVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.clearMainstreamVersionId = function() {
  return this.setMainstreamVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.hasMainstreamVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AcademicLicenseRequester requester = 4;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.LocalDate project_completion_date = 5;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.getProjectCompletionDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 5));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.setProjectCompletionDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.clearProjectCompletionDate = function() {
  return this.setProjectCompletionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.hasProjectCompletionDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string project_description = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.time.LocalDate active_through = 7;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 7));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.academic);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerificationFailed !== undefined) {
    if (obj.whenVerificationFailed === null) {
      msg.setWhenVerificationFailed(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerificationFailed);
      msg.setWhenVerificationFailed(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExpired.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenExpired !== undefined) {
    if (obj.whenExpired === null) {
      msg.setWhenExpired(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.whenExpired);
      msg.setWhenExpired(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRequested !== undefined) {
    if (obj.whenRequested === null) {
      msg.setWhenRequested(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRequested);
      msg.setWhenRequested(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.ExtensionRequestReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRevoked();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRevoked !== undefined) {
    if (obj.whenRevoked === null) {
      msg.setWhenRevoked(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRevoked);
      msg.setWhenRevoked(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.Parser;
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenGranted !== undefined) {
    if (obj.whenGranted === null) {
      msg.setWhenGranted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenGranted);
      msg.setWhenGranted(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRefused !== undefined) {
    if (obj.whenRefused === null) {
      msg.setWhenRefused(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRefused);
      msg.setWhenRefused(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseGranted.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenGranted !== undefined) {
    if (obj.whenGranted === null) {
      msg.setWhenGranted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenGranted);
      msg.setWhenGranted(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseIssued.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseIssued.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseIssued();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRequested.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequested.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequested();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.mainstreamVersionId !== undefined) {
    if (obj.mainstreamVersionId === null) {
      msg.setMainstreamVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.mainstreamVersionId);
      msg.setMainstreamVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.whenRequested !== undefined) {
    if (obj.whenRequested === null) {
      msg.setWhenRequested(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRequested);
      msg.setWhenRequested(value);
    }
  }
  
  if (obj.assurancePromise !== undefined) {
    if (obj.assurancePromise === null) {
      msg.setAssurancePromise(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AssurancePromise').fromObject(obj.assurancePromise);
      msg.setAssurancePromise(value);
    }
  }
  
  if (obj.promotionRequirementAccepted !== undefined) {
    if (obj.promotionRequirementAccepted !== null) {
      let value = obj.promotionRequirementAccepted;
      msg.setPromotionRequirementAccepted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerificationFailed !== undefined) {
    if (obj.whenVerificationFailed === null) {
      msg.setWhenVerificationFailed(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerificationFailed);
      msg.setWhenVerificationFailed(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenStarted !== undefined) {
    if (obj.whenStarted === null) {
      msg.setWhenStarted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenStarted);
      msg.setWhenStarted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.mainstreamVersionId !== undefined) {
    if (obj.mainstreamVersionId === null) {
      msg.setMainstreamVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.mainstreamVersionId);
      msg.setMainstreamVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.whenRefused !== undefined) {
    if (obj.whenRefused === null) {
      msg.setWhenRefused(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRefused);
      msg.setWhenRefused(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenStarted !== undefined) {
    if (obj.whenStarted === null) {
      msg.setWhenStarted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenStarted);
      msg.setWhenStarted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerified !== undefined) {
    if (obj.whenVerified === null) {
      msg.setWhenVerified(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerified);
      msg.setWhenVerified(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.licensingTarget !== undefined) {
    if (obj.licensingTarget === null) {
      msg.setLicensingTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.licensingTarget);
      msg.setLicensingTarget(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.createdBy !== undefined) {
    if (obj.createdBy === null) {
      msg.setCreatedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.createdBy);
      msg.setCreatedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.Parser;
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerified !== undefined) {
    if (obj.whenVerified === null) {
      msg.setWhenVerified(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerified);
      msg.setWhenVerified(value);
    }
  }
  
  if (obj.projectCompletionDate !== undefined) {
    if (obj.projectCompletionDate === null) {
      msg.setProjectCompletionDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.projectCompletionDate);
      msg.setProjectCompletionDate(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.Parser.prototype.constructor = proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.Parser;
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequested.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequested';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRevoked.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRevoked';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.ExtendedAcademicLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequestRefused.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestRefused';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseIssued.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseIssued';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequested.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequested';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerificationFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerificationStarted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseCreatedFromRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestRefused';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerificationStarted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseRequestVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequestVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseAgreementAccepted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseCreatedFromScratch';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseExtensionRequestVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.academic.ExtendedAcademicLicenseIssued';
};
