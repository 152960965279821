// source: teamdev/licensing/q/report/daily_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
goog.exportSymbol('proto.teamdev.licensing.q.report.CommercialStats', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.IdCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.DailyStatsReport', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.EvaluationStats', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.NonCommercialStats', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.IdCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.report.DailyStatsReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.report.DailyStatsReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.report.DailyStatsReport.displayName = 'proto.teamdev.licensing.q.report.DailyStatsReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.report.EvaluationStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.report.EvaluationStats.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.q.report.EvaluationStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.report.EvaluationStats.displayName = 'proto.teamdev.licensing.q.report.EvaluationStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.displayName = 'proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.report.CommercialStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.report.CommercialStats.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.q.report.CommercialStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.report.CommercialStats.displayName = 'proto.teamdev.licensing.q.report.CommercialStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.displayName = 'proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.report.NonCommercialStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.report.NonCommercialStats.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.q.report.NonCommercialStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.report.NonCommercialStats.displayName = 'proto.teamdev.licensing.q.report.NonCommercialStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.displayName = 'proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.report.DailyStatsReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.report.DailyStatsReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.DailyStatsReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.DailyStatsReportId.toObject(includeInstance, f),
    evaluationInfo: (f = msg.getEvaluationInfo()) && proto.teamdev.licensing.q.report.EvaluationStats.toObject(includeInstance, f),
    commercialInfo: (f = msg.getCommercialInfo()) && proto.teamdev.licensing.q.report.CommercialStats.toObject(includeInstance, f),
    nonCommercialInfo: (f = msg.getNonCommercialInfo()) && proto.teamdev.licensing.q.report.NonCommercialStats.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.report.DailyStatsReport;
  return proto.teamdev.licensing.q.report.DailyStatsReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.report.DailyStatsReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.DailyStatsReportId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.DailyStatsReportId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.q.report.EvaluationStats;
      reader.readMessage(value,proto.teamdev.licensing.q.report.EvaluationStats.deserializeBinaryFromReader);
      msg.setEvaluationInfo(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.q.report.CommercialStats;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.deserializeBinaryFromReader);
      msg.setCommercialInfo(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.q.report.NonCommercialStats;
      reader.readMessage(value,proto.teamdev.licensing.q.report.NonCommercialStats.deserializeBinaryFromReader);
      msg.setNonCommercialInfo(value);
      break;
    case 6:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.report.DailyStatsReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.report.DailyStatsReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.DailyStatsReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.DailyStatsReportId.serializeBinaryToWriter
    );
  }
  f = message.getEvaluationInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.q.report.EvaluationStats.serializeBinaryToWriter
    );
  }
  f = message.getCommercialInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.serializeBinaryToWriter
    );
  }
  f = message.getNonCommercialInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.teamdev.licensing.q.report.NonCommercialStats.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.DailyStatsReportId id = 1;
 * @return {?proto.teamdev.licensing.DailyStatsReportId}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.DailyStatsReportId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.DailyStatsReportId, 1));
};


/**
 * @param {?proto.teamdev.licensing.DailyStatsReportId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
*/
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EvaluationStats evaluation_info = 2;
 * @return {?proto.teamdev.licensing.q.report.EvaluationStats}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.getEvaluationInfo = function() {
  return /** @type{?proto.teamdev.licensing.q.report.EvaluationStats} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.q.report.EvaluationStats, 2));
};


/**
 * @param {?proto.teamdev.licensing.q.report.EvaluationStats|undefined} value
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
*/
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.setEvaluationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.clearEvaluationInfo = function() {
  return this.setEvaluationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.hasEvaluationInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CommercialStats commercial_info = 3;
 * @return {?proto.teamdev.licensing.q.report.CommercialStats}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.getCommercialInfo = function() {
  return /** @type{?proto.teamdev.licensing.q.report.CommercialStats} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats, 3));
};


/**
 * @param {?proto.teamdev.licensing.q.report.CommercialStats|undefined} value
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
*/
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.setCommercialInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.clearCommercialInfo = function() {
  return this.setCommercialInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.hasCommercialInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NonCommercialStats non_commercial_info = 4;
 * @return {?proto.teamdev.licensing.q.report.NonCommercialStats}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.getNonCommercialInfo = function() {
  return /** @type{?proto.teamdev.licensing.q.report.NonCommercialStats} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.q.report.NonCommercialStats, 4));
};


/**
 * @param {?proto.teamdev.licensing.q.report.NonCommercialStats|undefined} value
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
*/
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.setNonCommercialInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.clearNonCommercialInfo = function() {
  return this.setNonCommercialInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.hasNonCommercialInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductName product_name = 6;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.getProductName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 6));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
*/
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.DailyStatsReport} returns this
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.DailyStatsReport.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.report.EvaluationStats.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.report.EvaluationStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.EvaluationStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    commercialList: jspb.Message.toObjectList(msg.getCommercialList(),
    proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.toObject, includeInstance),
    academicList: jspb.Message.toObjectList(msg.getAcademicList(),
    proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.toObject, includeInstance),
    openSourceList: jspb.Message.toObjectList(msg.getOpenSourceList(),
    proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats}
 */
proto.teamdev.licensing.q.report.EvaluationStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.report.EvaluationStats;
  return proto.teamdev.licensing.q.report.EvaluationStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats}
 */
proto.teamdev.licensing.q.report.EvaluationStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.deserializeBinaryFromReader);
      msg.addCommercial(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.deserializeBinaryFromReader);
      msg.addAcademic(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.deserializeBinaryFromReader);
      msg.addOpenSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.report.EvaluationStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.EvaluationStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommercialList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getAcademicList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem}
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem;
  return proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem}
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem} returns this
*/
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem} returns this
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EvaluationStatsItem commercial = 1;
 * @return {!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.getCommercialList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem, 1));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats} returns this
*/
proto.teamdev.licensing.q.report.EvaluationStats.prototype.setCommercialList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.addCommercial = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats} returns this
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.clearCommercialList = function() {
  return this.setCommercialList([]);
};


/**
 * repeated EvaluationStatsItem academic = 2;
 * @return {!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.getAcademicList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats} returns this
*/
proto.teamdev.licensing.q.report.EvaluationStats.prototype.setAcademicList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.addAcademic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats} returns this
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.clearAcademicList = function() {
  return this.setAcademicList([]);
};


/**
 * repeated EvaluationStatsItem open_source = 3;
 * @return {!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.getOpenSourceList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats} returns this
*/
proto.teamdev.licensing.q.report.EvaluationStats.prototype.setOpenSourceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem}
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.addOpenSource = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.EvaluationStats} returns this
 */
proto.teamdev.licensing.q.report.EvaluationStats.prototype.clearOpenSourceList = function() {
  return this.setOpenSourceList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.report.CommercialStats.repeatedFields_ = [1,2,3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.report.CommercialStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.report.CommercialStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.CommercialStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    personalList: jspb.Message.toObjectList(msg.getPersonalList(),
    proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject, includeInstance),
    singleDevList: jspb.Message.toObjectList(msg.getSingleDevList(),
    proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject, includeInstance),
    projectList: jspb.Message.toObjectList(msg.getProjectList(),
    proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject, includeInstance),
    companyWideList: jspb.Message.toObjectList(msg.getCompanyWideList(),
    proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject, includeInstance),
    singleDevSourceCodeList: jspb.Message.toObjectList(msg.getSingleDevSourceCodeList(),
    proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject, includeInstance),
    projectSourceCodeList: jspb.Message.toObjectList(msg.getProjectSourceCodeList(),
    proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject, includeInstance),
    companyWideSourceCodeList: jspb.Message.toObjectList(msg.getCompanyWideSourceCodeList(),
    proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats}
 */
proto.teamdev.licensing.q.report.CommercialStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.report.CommercialStats;
  return proto.teamdev.licensing.q.report.CommercialStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.report.CommercialStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats}
 */
proto.teamdev.licensing.q.report.CommercialStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader);
      msg.addPersonal(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader);
      msg.addSingleDev(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader);
      msg.addProject(value);
      break;
    case 4:
      var value = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader);
      msg.addCompanyWide(value);
      break;
    case 5:
      var value = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader);
      msg.addSingleDevSourceCode(value);
      break;
    case 6:
      var value = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader);
      msg.addProjectSourceCode(value);
      break;
    case 7:
      var value = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader);
      msg.addCompanyWideSourceCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.report.CommercialStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.report.CommercialStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.CommercialStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonalList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getProjectList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevSourceCodeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getProjectSourceCodeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideSourceCodeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.IdCase = {
  ID_NOT_SET: 0,
  PERSONAL: 1,
  SINGLE_DEV: 2,
  PROJECT: 3,
  COMPANY_WIDE: 4,
  SINGLE_DEV_SOURCE_CODE: 5,
  PROJECT_SOURCE_CODE: 6,
  COMPANY_WIDE_SOURCE_CODE: 7
};

/**
 * @return {proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.IdCase}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getIdCase = function() {
  return /** @type {proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.IdCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    personal: (f = msg.getPersonal()) && teamdev_licensing_identifiers_pb.PersonalLicenseId.toObject(includeInstance, f),
    singleDev: (f = msg.getSingleDev()) && teamdev_licensing_identifiers_pb.SingleDevLicenseId.toObject(includeInstance, f),
    project: (f = msg.getProject()) && teamdev_licensing_identifiers_pb.ProjectLicenseId.toObject(includeInstance, f),
    companyWide: (f = msg.getCompanyWide()) && teamdev_licensing_identifiers_pb.CompanyWideLicenseId.toObject(includeInstance, f),
    singleDevSourceCode: (f = msg.getSingleDevSourceCode()) && teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.toObject(includeInstance, f),
    projectSourceCode: (f = msg.getProjectSourceCode()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    companyWideSourceCode: (f = msg.getCompanyWideSourceCode()) && teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem;
  return proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.PersonalLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.PersonalLicenseId.deserializeBinaryFromReader);
      msg.setPersonal(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.SingleDevLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.setSingleDev(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProjectLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectLicenseId.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWide(value);
      break;
    case 5:
      var value = new teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setSingleDevSourceCode(value);
      break;
    case 6:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setProjectSourceCode(value);
      break;
    case 7:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setCompanyWideSourceCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingleDev();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWide();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getSingleDevSourceCode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProjectSourceCode();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getCompanyWideSourceCode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.PersonalLicenseId personal = 1;
 * @return {?proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getPersonal = function() {
  return /** @type{?proto.teamdev.licensing.PersonalLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.PersonalLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.PersonalLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.setPersonal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.clearPersonal = function() {
  return this.setPersonal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.hasPersonal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.SingleDevLicenseId single_dev = 2;
 * @return {?proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getSingleDev = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevLicenseId, 2));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.setSingleDev = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.clearSingleDev = function() {
  return this.setSingleDev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.hasSingleDev = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProjectLicenseId project = 3;
 * @return {?proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getProject = function() {
  return /** @type{?proto.teamdev.licensing.ProjectLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectLicenseId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProjectLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.setProject = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.hasProject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.CompanyWideLicenseId company_wide = 4;
 * @return {?proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getCompanyWide = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideLicenseId, 4));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.setCompanyWide = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.clearCompanyWide = function() {
  return this.setCompanyWide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.hasCompanyWide = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional teamdev.licensing.SingleDevSourceCodeLicenseId single_dev_source_code = 5;
 * @return {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getSingleDevSourceCode = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId, 5));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.setSingleDevSourceCode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.clearSingleDevSourceCode = function() {
  return this.setSingleDevSourceCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.hasSingleDevSourceCode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId project_source_code = 6;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getProjectSourceCode = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.setProjectSourceCode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.clearProjectSourceCode = function() {
  return this.setProjectSourceCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.hasProjectSourceCode = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional teamdev.licensing.CompanyWideSourceCodeLicenseId company_wide_source_code = 7;
 * @return {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.getCompanyWideSourceCode = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId, 7));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.setCompanyWideSourceCode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.clearCompanyWideSourceCode = function() {
  return this.setCompanyWideSourceCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.prototype.hasCompanyWideSourceCode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated CommercialStatsItem personal = 1;
 * @return {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.getPersonalList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, 1));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.prototype.setPersonalList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.addPersonal = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.clearPersonalList = function() {
  return this.setPersonalList([]);
};


/**
 * repeated CommercialStatsItem single_dev = 2;
 * @return {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.getSingleDevList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.prototype.setSingleDevList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.addSingleDev = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.clearSingleDevList = function() {
  return this.setSingleDevList([]);
};


/**
 * repeated CommercialStatsItem project = 3;
 * @return {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.getProjectList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.prototype.setProjectList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.addProject = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.clearProjectList = function() {
  return this.setProjectList([]);
};


/**
 * repeated CommercialStatsItem company_wide = 4;
 * @return {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.getCompanyWideList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, 4));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.prototype.setCompanyWideList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.addCompanyWide = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.clearCompanyWideList = function() {
  return this.setCompanyWideList([]);
};


/**
 * repeated CommercialStatsItem single_dev_source_code = 5;
 * @return {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.getSingleDevSourceCodeList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, 5));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.prototype.setSingleDevSourceCodeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.addSingleDevSourceCode = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.clearSingleDevSourceCodeList = function() {
  return this.setSingleDevSourceCodeList([]);
};


/**
 * repeated CommercialStatsItem project_source_code = 6;
 * @return {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.getProjectSourceCodeList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, 6));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.prototype.setProjectSourceCodeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.addProjectSourceCode = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.clearProjectSourceCodeList = function() {
  return this.setProjectSourceCodeList([]);
};


/**
 * repeated CommercialStatsItem company_wide_source_code = 7;
 * @return {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.getCompanyWideSourceCodeList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, 7));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
*/
proto.teamdev.licensing.q.report.CommercialStats.prototype.setCompanyWideSourceCodeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem}
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.addCompanyWideSourceCode = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.CommercialStats} returns this
 */
proto.teamdev.licensing.q.report.CommercialStats.prototype.clearCompanyWideSourceCodeList = function() {
  return this.setCompanyWideSourceCodeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.report.NonCommercialStats.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.report.NonCommercialStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.NonCommercialStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    academicList: jspb.Message.toObjectList(msg.getAcademicList(),
    proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.toObject, includeInstance),
    openSourceList: jspb.Message.toObjectList(msg.getOpenSourceList(),
    proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.report.NonCommercialStats;
  return proto.teamdev.licensing.q.report.NonCommercialStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.deserializeBinaryFromReader);
      msg.addAcademic(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem;
      reader.readMessage(value,proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.deserializeBinaryFromReader);
      msg.addOpenSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.report.NonCommercialStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.NonCommercialStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcademicList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.IdCase = {
  ID_NOT_SET: 0,
  ACADEMIC: 1,
  OPEN_SOURCE: 2
};

/**
 * @return {proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.IdCase}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.getIdCase = function() {
  return /** @type {proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.IdCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    academic: (f = msg.getAcademic()) && teamdev_licensing_identifiers_pb.AcademicLicenseId.toObject(includeInstance, f),
    openSource: (f = msg.getOpenSource()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem;
  return proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.AcademicLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.AcademicLicenseId.deserializeBinaryFromReader);
      msg.setAcademic(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setOpenSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcademic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.AcademicLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getOpenSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.AcademicLicenseId academic = 1;
 * @return {?proto.teamdev.licensing.AcademicLicenseId}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.getAcademic = function() {
  return /** @type{?proto.teamdev.licensing.AcademicLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.AcademicLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.AcademicLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.setAcademic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.clearAcademic = function() {
  return this.setAcademic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.hasAcademic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId open_source = 2;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.getOpenSource = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 2));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem} returns this
*/
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.setOpenSource = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem} returns this
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.clearOpenSource = function() {
  return this.setOpenSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.prototype.hasOpenSource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated NonCommercialStatsItem academic = 1;
 * @return {!Array<!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.getAcademicList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem, 1));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats} returns this
*/
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.setAcademicList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.addAcademic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats} returns this
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.clearAcademicList = function() {
  return this.setAcademicList([]);
};


/**
 * repeated NonCommercialStatsItem open_source = 2;
 * @return {!Array<!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem>}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.getOpenSourceList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem, 2));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem>} value
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats} returns this
*/
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.setOpenSourceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem}
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.addOpenSource = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.report.NonCommercialStats} returns this
 */
proto.teamdev.licensing.q.report.NonCommercialStats.prototype.clearOpenSourceList = function() {
  return this.setOpenSourceList([]);
};


goog.object.extend(exports, proto.teamdev.licensing.q.report);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.q.report.EvaluationStats.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.report.EvaluationStats.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.report.EvaluationStats.Parser.prototype.constructor = proto.teamdev.licensing.q.report.EvaluationStats.Parser;
proto.teamdev.licensing.q.report.EvaluationStats.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.report.EvaluationStats();
  
  if (obj.commercial !== undefined && obj.commercial !== null) {
    obj.commercial.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCommercial(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem').fromObject(listItem);
          msg.addCommercial(value);
        }
      }
    );
  }
  
  if (obj.academic !== undefined && obj.academic !== null) {
    obj.academic.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addAcademic(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem').fromObject(listItem);
          msg.addAcademic(value);
        }
      }
    );
  }
  
  if (obj.openSource !== undefined && obj.openSource !== null) {
    obj.openSource.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addOpenSource(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem').fromObject(listItem);
          msg.addOpenSource(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.Parser.prototype.constructor = proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.Parser;
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.Parser.prototype.constructor = proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.Parser;
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem();
  
  if (obj.personal !== undefined) {
    if (obj.personal === null) {
      msg.setPersonal(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(obj.personal);
      msg.setPersonal(value);
    }
  }
  
  if (obj.singleDev !== undefined) {
    if (obj.singleDev === null) {
      msg.setSingleDev(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(obj.singleDev);
      msg.setSingleDev(value);
    }
  }
  
  if (obj.project !== undefined) {
    if (obj.project === null) {
      msg.setProject(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(obj.project);
      msg.setProject(value);
    }
  }
  
  if (obj.companyWide !== undefined) {
    if (obj.companyWide === null) {
      msg.setCompanyWide(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(obj.companyWide);
      msg.setCompanyWide(value);
    }
  }
  
  if (obj.singleDevSourceCode !== undefined) {
    if (obj.singleDevSourceCode === null) {
      msg.setSingleDevSourceCode(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(obj.singleDevSourceCode);
      msg.setSingleDevSourceCode(value);
    }
  }
  
  if (obj.projectSourceCode !== undefined) {
    if (obj.projectSourceCode === null) {
      msg.setProjectSourceCode(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.projectSourceCode);
      msg.setProjectSourceCode(value);
    }
  }
  
  if (obj.companyWideSourceCode !== undefined) {
    if (obj.companyWideSourceCode === null) {
      msg.setCompanyWideSourceCode(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(obj.companyWideSourceCode);
      msg.setCompanyWideSourceCode(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.Parser.prototype.constructor = proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.Parser;
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem();
  
  if (obj.academic !== undefined) {
    if (obj.academic === null) {
      msg.setAcademic(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.AcademicLicenseId').fromObject(obj.academic);
      msg.setAcademic(value);
    }
  }
  
  if (obj.openSource !== undefined) {
    if (obj.openSource === null) {
      msg.setOpenSource(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.openSource);
      msg.setOpenSource(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.report.DailyStatsReport.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.report.DailyStatsReport.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.report.DailyStatsReport.Parser.prototype.constructor = proto.teamdev.licensing.q.report.DailyStatsReport.Parser;
proto.teamdev.licensing.q.report.DailyStatsReport.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.report.DailyStatsReport();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.DailyStatsReportId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.evaluationInfo !== undefined) {
    if (obj.evaluationInfo === null) {
      msg.setEvaluationInfo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.EvaluationStats').fromObject(obj.evaluationInfo);
      msg.setEvaluationInfo(value);
    }
  }
  
  if (obj.commercialInfo !== undefined) {
    if (obj.commercialInfo === null) {
      msg.setCommercialInfo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats').fromObject(obj.commercialInfo);
      msg.setCommercialInfo(value);
    }
  }
  
  if (obj.nonCommercialInfo !== undefined) {
    if (obj.nonCommercialInfo === null) {
      msg.setNonCommercialInfo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats').fromObject(obj.nonCommercialInfo);
      msg.setNonCommercialInfo(value);
    }
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName === null) {
      msg.setProductName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.productName);
      msg.setProductName(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.q.report.CommercialStats.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.report.CommercialStats.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.report.CommercialStats.Parser.prototype.constructor = proto.teamdev.licensing.q.report.CommercialStats.Parser;
proto.teamdev.licensing.q.report.CommercialStats.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.report.CommercialStats();
  
  if (obj.personal !== undefined && obj.personal !== null) {
    obj.personal.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addPersonal(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem').fromObject(listItem);
          msg.addPersonal(value);
        }
      }
    );
  }
  
  if (obj.singleDev !== undefined && obj.singleDev !== null) {
    obj.singleDev.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addSingleDev(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem').fromObject(listItem);
          msg.addSingleDev(value);
        }
      }
    );
  }
  
  if (obj.project !== undefined && obj.project !== null) {
    obj.project.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProject(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem').fromObject(listItem);
          msg.addProject(value);
        }
      }
    );
  }
  
  if (obj.companyWide !== undefined && obj.companyWide !== null) {
    obj.companyWide.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCompanyWide(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem').fromObject(listItem);
          msg.addCompanyWide(value);
        }
      }
    );
  }
  
  if (obj.singleDevSourceCode !== undefined && obj.singleDevSourceCode !== null) {
    obj.singleDevSourceCode.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addSingleDevSourceCode(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem').fromObject(listItem);
          msg.addSingleDevSourceCode(value);
        }
      }
    );
  }
  
  if (obj.projectSourceCode !== undefined && obj.projectSourceCode !== null) {
    obj.projectSourceCode.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addProjectSourceCode(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem').fromObject(listItem);
          msg.addProjectSourceCode(value);
        }
      }
    );
  }
  
  if (obj.companyWideSourceCode !== undefined && obj.companyWideSourceCode !== null) {
    obj.companyWideSourceCode.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addCompanyWideSourceCode(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem').fromObject(listItem);
          msg.addCompanyWideSourceCode(value);
        }
      }
    );
  }
  return msg;
};

proto.teamdev.licensing.q.report.NonCommercialStats.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.report.NonCommercialStats.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.report.NonCommercialStats.Parser.prototype.constructor = proto.teamdev.licensing.q.report.NonCommercialStats.Parser;
proto.teamdev.licensing.q.report.NonCommercialStats.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.report.NonCommercialStats();
  
  if (obj.academic !== undefined && obj.academic !== null) {
    obj.academic.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addAcademic(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem').fromObject(listItem);
          msg.addAcademic(value);
        }
      }
    );
  }
  
  if (obj.openSource !== undefined && obj.openSource !== null) {
    obj.openSource.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addOpenSource(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem').fromObject(listItem);
          msg.addOpenSource(value);
        }
      }
    );
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.report.EvaluationStats.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.report.EvaluationStats';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.report.EvaluationStats.EvaluationStatsItem';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.report.CommercialStats.CommercialStatsItem.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.report.CommercialStats.CommercialStatsItem';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats.NonCommercialStatsItem';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.report.DailyStatsReport.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.report.DailyStatsReport';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.report.CommercialStats.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.report.CommercialStats';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.report.NonCommercialStats.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.report.NonCommercialStats';
};
