// source: teamdev/licensing/importado/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_importado_license_data_pb = require('../../../teamdev/licensing/importado/license_data_pb.js');
goog.object.extend(proto, teamdev_licensing_importado_license_data_pb);
var teamdev_licensing_importado_product_data_pb = require('../../../teamdev/licensing/importado/product_data_pb.js');
goog.object.extend(proto, teamdev_licensing_importado_product_data_pb);
var teamdev_licensing_importado_product_version_data_pb = require('../../../teamdev/licensing/importado/product_version_data_pb.js');
goog.object.extend(proto, teamdev_licensing_importado_product_version_data_pb);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportCompanyWideLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportPersonalLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportProduct', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportProductVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportProjectLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportSingleDevLicense', null, global);
goog.exportSymbol('proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportProduct.displayName = 'proto.teamdev.licensing.importado.ImportProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportProductVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportProductVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportProductVersion.displayName = 'proto.teamdev.licensing.importado.ImportProductVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportPersonalLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportPersonalLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportPersonalLicense.displayName = 'proto.teamdev.licensing.importado.ImportPersonalLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportSingleDevLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportSingleDevLicense.displayName = 'proto.teamdev.licensing.importado.ImportSingleDevLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportCompanyWideLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportCompanyWideLicense.displayName = 'proto.teamdev.licensing.importado.ImportCompanyWideLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportProjectLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportProjectLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportProjectLicense.displayName = 'proto.teamdev.licensing.importado.ImportProjectLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.displayName = 'proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.displayName = 'proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.displayName = 'proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_product_data_pb.ProductData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportProduct}
 */
proto.teamdev.licensing.importado.ImportProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportProduct;
  return proto.teamdev.licensing.importado.ImportProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportProduct}
 */
proto.teamdev.licensing.importado.ImportProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_product_data_pb.ProductData;
      reader.readMessage(value,teamdev_licensing_importado_product_data_pb.ProductData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_product_data_pb.ProductData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProduct} returns this
*/
proto.teamdev.licensing.importado.ImportProduct.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProduct} returns this
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductData data = 2;
 * @return {?proto.teamdev.licensing.importado.ProductData}
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.ProductData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_product_data_pb.ProductData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.ProductData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProduct} returns this
*/
proto.teamdev.licensing.importado.ImportProduct.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProduct} returns this
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProduct.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportProductVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportProductVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProductVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_product_version_data_pb.ProductVersionData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportProductVersion}
 */
proto.teamdev.licensing.importado.ImportProductVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportProductVersion;
  return proto.teamdev.licensing.importado.ImportProductVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportProductVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportProductVersion}
 */
proto.teamdev.licensing.importado.ImportProductVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_product_version_data_pb.ProductVersionData;
      reader.readMessage(value,teamdev_licensing_importado_product_version_data_pb.ProductVersionData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportProductVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportProductVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProductVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_product_version_data_pb.ProductVersionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductVersionId id = 1;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProductVersion} returns this
*/
proto.teamdev.licensing.importado.ImportProductVersion.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProductVersion} returns this
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductVersionData data = 2;
 * @return {?proto.teamdev.licensing.importado.ProductVersionData}
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.ProductVersionData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_product_version_data_pb.ProductVersionData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.ProductVersionData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProductVersion} returns this
*/
proto.teamdev.licensing.importado.ImportProductVersion.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProductVersion} returns this
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProductVersion.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportPersonalLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportPersonalLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.PersonalLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportPersonalLicense}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportPersonalLicense;
  return proto.teamdev.licensing.importado.ImportPersonalLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportPersonalLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportPersonalLicense}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.PersonalLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.PersonalLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportPersonalLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportPersonalLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.PersonalLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.PersonalLicenseId id = 1;
 * @return {?proto.teamdev.licensing.PersonalLicenseId}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.PersonalLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.PersonalLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.PersonalLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportPersonalLicense} returns this
*/
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportPersonalLicense} returns this
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportPersonalLicense} returns this
*/
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportPersonalLicense} returns this
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportPersonalLicense.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportSingleDevLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportSingleDevLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SingleDevLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevLicense}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportSingleDevLicense;
  return proto.teamdev.licensing.importado.ImportSingleDevLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportSingleDevLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevLicense}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SingleDevLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportSingleDevLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportSingleDevLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SingleDevLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SingleDevLicenseId id = 1;
 * @return {?proto.teamdev.licensing.SingleDevLicenseId}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevLicense} returns this
*/
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevLicense} returns this
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevLicense} returns this
*/
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevLicense} returns this
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportSingleDevLicense.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportCompanyWideLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportCompanyWideLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.CompanyWideLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideLicense}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportCompanyWideLicense;
  return proto.teamdev.licensing.importado.ImportCompanyWideLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportCompanyWideLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideLicense}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportCompanyWideLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportCompanyWideLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.CompanyWideLicenseId id = 1;
 * @return {?proto.teamdev.licensing.CompanyWideLicenseId}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideLicense} returns this
*/
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideLicense} returns this
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideLicense} returns this
*/
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideLicense} returns this
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportCompanyWideLicense.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportProjectLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportProjectLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProjectLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportProjectLicense}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportProjectLicense;
  return proto.teamdev.licensing.importado.ImportProjectLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportProjectLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportProjectLicense}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportProjectLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportProjectLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProjectLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectLicenseId}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProjectLicense} returns this
*/
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProjectLicense} returns this
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProjectLicense} returns this
*/
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProjectLicense} returns this
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProjectLicense.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense;
  return proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SingleDevSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SingleDevSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SingleDevSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SingleDevSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense} returns this
*/
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense} returns this
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense} returns this
*/
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense} returns this
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense;
  return proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProjectSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.ProjectSourceCodeLicenseId}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProjectSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProjectSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProjectSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense} returns this
*/
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense} returns this
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense} returns this
*/
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense} returns this
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.toObject(includeInstance, f),
    data: (f = msg.getData()) && teamdev_licensing_importado_license_data_pb.CommercialLicenseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense;
  return proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_importado_license_data_pb.CommercialLicenseData;
      reader.readMessage(value,teamdev_licensing_importado_license_data_pb.CommercialLicenseData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_importado_license_data_pb.CommercialLicenseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.CompanyWideSourceCodeLicenseId id = 1;
 * @return {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CompanyWideSourceCodeLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.CompanyWideSourceCodeLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense} returns this
*/
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense} returns this
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CommercialLicenseData data = 2;
 * @return {?proto.teamdev.licensing.importado.CommercialLicenseData}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.getData = function() {
  return /** @type{?proto.teamdev.licensing.importado.CommercialLicenseData} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_importado_license_data_pb.CommercialLicenseData, 2));
};


/**
 * @param {?proto.teamdev.licensing.importado.CommercialLicenseData|undefined} value
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense} returns this
*/
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense} returns this
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.prototype.hasData = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.importado);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.importado.ImportPersonalLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportPersonalLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportPersonalLicense.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportPersonalLicense.Parser;
proto.teamdev.licensing.importado.ImportPersonalLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportPersonalLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.PersonalLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportProduct.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportProduct.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportProduct.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportProduct.Parser;
proto.teamdev.licensing.importado.ImportProduct.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportProduct();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.ProductData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.Parser;
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.Parser;
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportProductVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportProductVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportProductVersion.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportProductVersion.Parser;
proto.teamdev.licensing.importado.ImportProductVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportProductVersion();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.ProductVersionData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportCompanyWideLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportCompanyWideLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportCompanyWideLicense.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportCompanyWideLicense.Parser;
proto.teamdev.licensing.importado.ImportCompanyWideLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportCompanyWideLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CompanyWideLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportProjectLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportProjectLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportProjectLicense.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportProjectLicense.Parser;
proto.teamdev.licensing.importado.ImportProjectLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportProjectLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.Parser;
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectSourceCodeLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.importado.ImportSingleDevLicense.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.importado.ImportSingleDevLicense.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.importado.ImportSingleDevLicense.Parser.prototype.constructor = proto.teamdev.licensing.importado.ImportSingleDevLicense.Parser;
proto.teamdev.licensing.importado.ImportSingleDevLicense.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.importado.ImportSingleDevLicense();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SingleDevLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.data !== undefined) {
    if (obj.data === null) {
      msg.setData(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.importado.CommercialLicenseData').fromObject(obj.data);
      msg.setData(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportPersonalLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportPersonalLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportProduct.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportProduct';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportSingleDevSourceCodeLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportSingleDevSourceCodeLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportCompanyWideSourceCodeLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportProductVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportProductVersion';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportCompanyWideLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportCompanyWideLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportProjectLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportProjectLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportProjectSourceCodeLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportProjectSourceCodeLicense';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.importado.ImportSingleDevLicense.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.importado.ImportSingleDevLicense';
};
