// source: teamdev/licensing/q/evaluation/evaluation_view.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_c_evaluation_evaluation_pb = require('../../../../teamdev/licensing/c/evaluation/evaluation_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_evaluation_pb);
var teamdev_licensing_c_evaluation_values_pb = require('../../../../teamdev/licensing/c/evaluation/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_evaluation_values_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
var teamdev_licensing_q_licensed_period_pb = require('../../../../teamdev/licensing/q/licensed_period_pb.js');
goog.object.extend(proto, teamdev_licensing_q_licensed_period_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.q.evaluation.EvaluationView', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.evaluation.EvaluationView.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.evaluation.EvaluationView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.evaluation.EvaluationView.repeatedFields_, null);
};
goog.inherits(proto.teamdev.licensing.q.evaluation.EvaluationView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.evaluation.EvaluationView.displayName = 'proto.teamdev.licensing.q.evaluation.EvaluationView';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.evaluation.EvaluationView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.evaluation.EvaluationView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.EvaluationId.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    whenCreated: (f = msg.getWhenCreated()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    whenExpired: (f = msg.getWhenExpired()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    whenRevoked: (f = msg.getWhenRevoked()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    revocationReason: (f = msg.getRevocationReason()) && teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.toObject(includeInstance, f),
    whenRefused: (f = msg.getWhenRefused()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    refusalReason: (f = msg.getRefusalReason()) && teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    product: (f = msg.getProduct()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    version: (f = msg.getVersion()) && teamdev_licensing_c_product_values_pb.VersionName.toObject(includeInstance, f),
    evaluator: (f = msg.getEvaluator()) && teamdev_licensing_c_evaluation_values_pb.Evaluator.toObject(includeInstance, f),
    clientEnvironment: (f = msg.getClientEnvironment()) && teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.toObject(includeInstance, f),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    teamdev_licensing_q_licensed_period_pb.LicensedPeriod.toObject, includeInstance),
    extensionCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f),
    productLogo: (f = msg.getProductLogo()) && teamdev_licensing_c_product_values_pb.ProductLogo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.evaluation.EvaluationView;
  return proto.teamdev.licensing.q.evaluation.EvaluationView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.evaluation.EvaluationView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.EvaluationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.EvaluationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.q.evaluation.EvaluationView.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.teamdev.licensing.c.evaluation.EvaluationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenCreated(value);
      break;
    case 5:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 6:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhenExpired(value);
      break;
    case 7:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRevoked(value);
      break;
    case 8:
      var value = new teamdev_licensing_values_pb.EvaluationLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setRevocationReason(value);
      break;
    case 9:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRefused(value);
      break;
    case 10:
      var value = new teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason.deserializeBinaryFromReader);
      msg.setRefusalReason(value);
      break;
    case 11:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 12:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 13:
      var value = new teamdev_licensing_c_product_values_pb.VersionName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.VersionName.deserializeBinaryFromReader);
      msg.setVersion(value);
      break;
    case 14:
      var value = new teamdev_licensing_c_evaluation_values_pb.Evaluator;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.Evaluator.deserializeBinaryFromReader);
      msg.setEvaluator(value);
      break;
    case 15:
      var value = new teamdev_licensing_c_evaluation_values_pb.ClientEnvironment;
      reader.readMessage(value,teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.deserializeBinaryFromReader);
      msg.setClientEnvironment(value);
      break;
    case 16:
      var value = new teamdev_licensing_q_licensed_period_pb.LicensedPeriod;
      reader.readMessage(value,teamdev_licensing_q_licensed_period_pb.LicensedPeriod.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExtensionCount(value);
      break;
    case 18:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    case 19:
      var value = new teamdev_licensing_c_product_values_pb.ProductLogo;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductLogo.deserializeBinaryFromReader);
      msg.setProductLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.evaluation.EvaluationView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.evaluation.EvaluationView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.EvaluationId.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getWhenCreated();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getWhenExpired();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getWhenRevoked();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRevocationReason();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_values_pb.EvaluationLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getWhenRefused();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRefusalReason();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      teamdev_licensing_c_product_values_pb.VersionName.serializeBinaryToWriter
    );
  }
  f = message.getEvaluator();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      teamdev_licensing_c_evaluation_values_pb.Evaluator.serializeBinaryToWriter
    );
  }
  f = message.getClientEnvironment();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      teamdev_licensing_c_evaluation_values_pb.ClientEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      teamdev_licensing_q_licensed_period_pb.LicensedPeriod.serializeBinaryToWriter
    );
  }
  f = message.getExtensionCount();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
  f = message.getProductLogo();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      teamdev_licensing_c_product_values_pb.ProductLogo.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.Status = {
  EVS_UNDEFINED: 0,
  CREATED: 1,
  ACTIVE: 2,
  EXPIRED: 3,
  REVOKED: 4,
  REFUSED: 5
};

/**
 * optional teamdev.licensing.EvaluationId id = 1;
 * @return {?proto.teamdev.licensing.EvaluationId}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.EvaluationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationId|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView.Status}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.q.evaluation.EvaluationView.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.evaluation.EvaluationView.Status} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional teamdev.licensing.c.evaluation.EvaluationType type = 3;
 * @return {!proto.teamdev.licensing.c.evaluation.EvaluationType}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getType = function() {
  return /** @type {!proto.teamdev.licensing.c.evaluation.EvaluationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.teamdev.licensing.c.evaluation.EvaluationType} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional spine.time.ZonedDateTime when_created = 4;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getWhenCreated = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 4));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setWhenCreated = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearWhenCreated = function() {
  return this.setWhenCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasWhenCreated = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 5;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 5));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional spine.time.LocalDate when_expired = 6;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getWhenExpired = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 6));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setWhenExpired = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearWhenExpired = function() {
  return this.setWhenExpired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasWhenExpired = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional spine.time.ZonedDateTime when_revoked = 7;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getWhenRevoked = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 7));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setWhenRevoked = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearWhenRevoked = function() {
  return this.setWhenRevoked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasWhenRevoked = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional teamdev.licensing.EvaluationLicenseRevocationReason revocation_reason = 8;
 * @return {?proto.teamdev.licensing.EvaluationLicenseRevocationReason}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getRevocationReason = function() {
  return /** @type{?proto.teamdev.licensing.EvaluationLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.EvaluationLicenseRevocationReason, 8));
};


/**
 * @param {?proto.teamdev.licensing.EvaluationLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setRevocationReason = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearRevocationReason = function() {
  return this.setRevocationReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasRevocationReason = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional spine.time.ZonedDateTime when_refused = 9;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getWhenRefused = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 9));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setWhenRefused = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearWhenRefused = function() {
  return this.setWhenRefused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasWhenRefused = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional teamdev.licensing.c.evaluation.EvaluationRefusalReason refusal_reason = 10;
 * @return {?proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getRefusalReason = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.EvaluationRefusalReason, 10));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.EvaluationRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setRefusalReason = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearRefusalReason = function() {
  return this.setRefusalReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasRefusalReason = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional spine.time.LocalDate active_through = 11;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 11));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductName product = 12;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getProduct = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 12));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional teamdev.licensing.c.product.VersionName version = 13;
 * @return {?proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getVersion = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.VersionName, 13));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionName|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setVersion = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearVersion = function() {
  return this.setVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional teamdev.licensing.c.evaluation.Evaluator evaluator = 14;
 * @return {?proto.teamdev.licensing.c.evaluation.Evaluator}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getEvaluator = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.Evaluator} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.Evaluator, 14));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.Evaluator|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setEvaluator = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearEvaluator = function() {
  return this.setEvaluator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasEvaluator = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional teamdev.licensing.c.evaluation.ClientEnvironment client_environment = 15;
 * @return {?proto.teamdev.licensing.c.evaluation.ClientEnvironment}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getClientEnvironment = function() {
  return /** @type{?proto.teamdev.licensing.c.evaluation.ClientEnvironment} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_evaluation_values_pb.ClientEnvironment, 15));
};


/**
 * @param {?proto.teamdev.licensing.c.evaluation.ClientEnvironment|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setClientEnvironment = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearClientEnvironment = function() {
  return this.setClientEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasClientEnvironment = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated teamdev.licensing.q.LicensedPeriod history = 16;
 * @return {!Array<!proto.teamdev.licensing.q.LicensedPeriod>}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.LicensedPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_q_licensed_period_pb.LicensedPeriod, 16));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.LicensedPeriod>} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.teamdev.licensing.q.LicensedPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.LicensedPeriod}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.teamdev.licensing.q.LicensedPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * optional uint32 extension_count = 17;
 * @return {number}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getExtensionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setExtensionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional spine.core.UserId revoked_by = 18;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 18));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductLogo product_logo = 19;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.getProductLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductLogo, 19));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
*/
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.setProductLogo = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.evaluation.EvaluationView} returns this
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.clearProductLogo = function() {
  return this.setProductLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.evaluation.EvaluationView.prototype.hasProductLogo = function() {
  return jspb.Message.getField(this, 19) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.q.evaluation);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.q.evaluation.EvaluationView.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.evaluation.EvaluationView.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.evaluation.EvaluationView.Parser.prototype.constructor = proto.teamdev.licensing.q.evaluation.EvaluationView.Parser;
proto.teamdev.licensing.q.evaluation.EvaluationView.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.evaluation.EvaluationView();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.q.evaluation.EvaluationView.Status[obj.status];
      msg.setStatus(value);
    }
  }
  
  if (obj.type !== undefined) {
    if (obj.type !== null) {
      let value = proto.teamdev.licensing.c.evaluation.EvaluationType[obj.type];
      msg.setType(value);
    }
  }
  
  if (obj.whenCreated !== undefined) {
    if (obj.whenCreated === null) {
      msg.setWhenCreated(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenCreated);
      msg.setWhenCreated(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.whenExpired !== undefined) {
    if (obj.whenExpired === null) {
      msg.setWhenExpired(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.whenExpired);
      msg.setWhenExpired(value);
    }
  }
  
  if (obj.whenRevoked !== undefined) {
    if (obj.whenRevoked === null) {
      msg.setWhenRevoked(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRevoked);
      msg.setWhenRevoked(value);
    }
  }
  
  if (obj.revocationReason !== undefined) {
    if (obj.revocationReason === null) {
      msg.setRevocationReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.EvaluationLicenseRevocationReason').fromObject(obj.revocationReason);
      msg.setRevocationReason(value);
    }
  }
  
  if (obj.whenRefused !== undefined) {
    if (obj.whenRefused === null) {
      msg.setWhenRefused(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRefused);
      msg.setWhenRefused(value);
    }
  }
  
  if (obj.refusalReason !== undefined) {
    if (obj.refusalReason === null) {
      msg.setRefusalReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.EvaluationRefusalReason').fromObject(obj.refusalReason);
      msg.setRefusalReason(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.product !== undefined) {
    if (obj.product === null) {
      msg.setProduct(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.product);
      msg.setProduct(value);
    }
  }
  
  if (obj.version !== undefined) {
    if (obj.version === null) {
      msg.setVersion(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionName').fromObject(obj.version);
      msg.setVersion(value);
    }
  }
  
  if (obj.evaluator !== undefined) {
    if (obj.evaluator === null) {
      msg.setEvaluator(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.Evaluator').fromObject(obj.evaluator);
      msg.setEvaluator(value);
    }
  }
  
  if (obj.clientEnvironment !== undefined) {
    if (obj.clientEnvironment === null) {
      msg.setClientEnvironment(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.evaluation.ClientEnvironment').fromObject(obj.clientEnvironment);
      msg.setClientEnvironment(value);
    }
  }
  
  if (obj.history !== undefined && obj.history !== null) {
    obj.history.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addHistory(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.LicensedPeriod').fromObject(listItem);
          msg.addHistory(value);
        }
      }
    );
  }
  
  if (obj.extensionCount !== undefined) {
    if (obj.extensionCount !== null) {
      let value = obj.extensionCount;
      msg.setExtensionCount(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  
  if (obj.productLogo !== undefined) {
    if (obj.productLogo === null) {
      msg.setProductLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.productLogo);
      msg.setProductLogo(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.evaluation.EvaluationView.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.evaluation.EvaluationView';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.evaluation.EvaluationView.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.evaluation.EvaluationView.Status';
};
