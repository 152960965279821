// source: teamdev/licensing/c/product/product.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_c_product_licensing_policy_pb = require('../../../../teamdev/licensing/c/product/licensing_policy_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_licensing_policy_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
goog.exportSymbol('proto.teamdev.licensing.c.product.Product', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.Product.displayName = 'proto.teamdev.licensing.c.product.Product';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    displayName: (f = msg.getDisplayName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    whenCreated: (f = msg.getWhenCreated()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    defaultPolicy: (f = msg.getDefaultPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f),
    logo: (f = msg.getLogo()) && teamdev_licensing_c_product_values_pb.ProductLogo.toObject(includeInstance, f),
    mainstreamVersion: (f = msg.getMainstreamVersion()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.Product}
 */
proto.teamdev.licensing.c.product.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.Product;
  return proto.teamdev.licensing.c.product.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.Product}
 */
proto.teamdev.licensing.c.product.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenCreated(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setDefaultPolicy(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_product_values_pb.ProductLogo;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductLogo.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    case 6:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setMainstreamVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getWhenCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getDefaultPolicy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_product_values_pb.ProductLogo.serializeBinaryToWriter
    );
  }
  f = message.getMainstreamVersion();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.ProductId id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.product.Product.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
*/
proto.teamdev.licensing.c.product.Product.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
 */
proto.teamdev.licensing.c.product.Product.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.Product.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductName display_name = 2;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.product.Product.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
*/
proto.teamdev.licensing.c.product.Product.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
 */
proto.teamdev.licensing.c.product.Product.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.Product.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.ZonedDateTime when_created = 3;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.product.Product.prototype.getWhenCreated = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 3));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
*/
proto.teamdev.licensing.c.product.Product.prototype.setWhenCreated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
 */
proto.teamdev.licensing.c.product.Product.prototype.clearWhenCreated = function() {
  return this.setWhenCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.Product.prototype.hasWhenCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LicensingPolicy default_policy = 4;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.Product.prototype.getDefaultPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
*/
proto.teamdev.licensing.c.product.Product.prototype.setDefaultPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
 */
proto.teamdev.licensing.c.product.Product.prototype.clearDefaultPolicy = function() {
  return this.setDefaultPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.Product.prototype.hasDefaultPolicy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProductLogo logo = 5;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.c.product.Product.prototype.getLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductLogo, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
*/
proto.teamdev.licensing.c.product.Product.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
 */
proto.teamdev.licensing.c.product.Product.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.Product.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId mainstream_version = 6;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.product.Product.prototype.getMainstreamVersion = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
*/
proto.teamdev.licensing.c.product.Product.prototype.setMainstreamVersion = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.Product} returns this
 */
proto.teamdev.licensing.c.product.Product.prototype.clearMainstreamVersion = function() {
  return this.setMainstreamVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.Product.prototype.hasMainstreamVersion = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.product);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.product.Product.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.Product.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.Product.Parser.prototype.constructor = proto.teamdev.licensing.c.product.Product.Parser;
proto.teamdev.licensing.c.product.Product.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.Product();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.whenCreated !== undefined) {
    if (obj.whenCreated === null) {
      msg.setWhenCreated(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenCreated);
      msg.setWhenCreated(value);
    }
  }
  
  if (obj.defaultPolicy !== undefined) {
    if (obj.defaultPolicy === null) {
      msg.setDefaultPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.defaultPolicy);
      msg.setDefaultPolicy(value);
    }
  }
  
  if (obj.logo !== undefined) {
    if (obj.logo === null) {
      msg.setLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.logo);
      msg.setLogo(value);
    }
  }
  
  if (obj.mainstreamVersion !== undefined) {
    if (obj.mainstreamVersion === null) {
      msg.setMainstreamVersion(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.mainstreamVersion);
      msg.setMainstreamVersion(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.Product.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.Product';
};
