// source: teamdev/licensing/licensing_target.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.LicensingTarget', null, global);
goog.exportSymbol('proto.teamdev.licensing.LicensingTarget.ByMajorVersion', null, global);
goog.exportSymbol('proto.teamdev.licensing.LicensingTarget.ByTime', null, global);
goog.exportSymbol('proto.teamdev.licensing.LicensingTarget.LimitCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.LicensingTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.teamdev.licensing.LicensingTarget.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.LicensingTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.LicensingTarget.displayName = 'proto.teamdev.licensing.LicensingTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.LicensingTarget.ByTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.LicensingTarget.ByTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.LicensingTarget.ByTime.displayName = 'proto.teamdev.licensing.LicensingTarget.ByTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.LicensingTarget.ByMajorVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.LicensingTarget.ByMajorVersion.displayName = 'proto.teamdev.licensing.LicensingTarget.ByMajorVersion';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.LicensingTarget.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.LicensingTarget.LimitCase = {
  LIMIT_NOT_SET: 0,
  BY_TIME: 2,
  BY_MAJOR_VERSION: 3
};

/**
 * @return {proto.teamdev.licensing.LicensingTarget.LimitCase}
 */
proto.teamdev.licensing.LicensingTarget.prototype.getLimitCase = function() {
  return /** @type {proto.teamdev.licensing.LicensingTarget.LimitCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.LicensingTarget.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.LicensingTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.LicensingTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.LicensingTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicensingTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    byTime: (f = msg.getByTime()) && proto.teamdev.licensing.LicensingTarget.ByTime.toObject(includeInstance, f),
    byMajorVersion: (f = msg.getByMajorVersion()) && proto.teamdev.licensing.LicensingTarget.ByMajorVersion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.LicensingTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.LicensingTarget;
  return proto.teamdev.licensing.LicensingTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.LicensingTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.LicensingTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 2:
      var value = new proto.teamdev.licensing.LicensingTarget.ByTime;
      reader.readMessage(value,proto.teamdev.licensing.LicensingTarget.ByTime.deserializeBinaryFromReader);
      msg.setByTime(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.LicensingTarget.ByMajorVersion;
      reader.readMessage(value,proto.teamdev.licensing.LicensingTarget.ByMajorVersion.deserializeBinaryFromReader);
      msg.setByMajorVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.LicensingTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.LicensingTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.LicensingTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicensingTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getByTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.teamdev.licensing.LicensingTarget.ByTime.serializeBinaryToWriter
    );
  }
  f = message.getByMajorVersion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.LicensingTarget.ByMajorVersion.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.LicensingTarget.ByTime.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.LicensingTarget.ByTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.LicensingTarget.ByTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicensingTarget.ByTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    whenEnds: (f = msg.getWhenEnds()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.LicensingTarget.ByTime}
 */
proto.teamdev.licensing.LicensingTarget.ByTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.LicensingTarget.ByTime;
  return proto.teamdev.licensing.LicensingTarget.ByTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.LicensingTarget.ByTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.LicensingTarget.ByTime}
 */
proto.teamdev.licensing.LicensingTarget.ByTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhenEnds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.LicensingTarget.ByTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.LicensingTarget.ByTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.LicensingTarget.ByTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicensingTarget.ByTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWhenEnds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional spine.time.LocalDate when_ends = 1;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.LicensingTarget.ByTime.prototype.getWhenEnds = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 1));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.LicensingTarget.ByTime} returns this
*/
proto.teamdev.licensing.LicensingTarget.ByTime.prototype.setWhenEnds = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicensingTarget.ByTime} returns this
 */
proto.teamdev.licensing.LicensingTarget.ByTime.prototype.clearWhenEnds = function() {
  return this.setWhenEnds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicensingTarget.ByTime.prototype.hasWhenEnds = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.LicensingTarget.ByMajorVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.LicensingTarget.ByMajorVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    majorVersion: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.LicensingTarget.ByMajorVersion}
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.LicensingTarget.ByMajorVersion;
  return proto.teamdev.licensing.LicensingTarget.ByMajorVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.LicensingTarget.ByMajorVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.LicensingTarget.ByMajorVersion}
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMajorVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.LicensingTarget.ByMajorVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.LicensingTarget.ByMajorVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajorVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 major_version = 1;
 * @return {number}
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.prototype.getMajorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.teamdev.licensing.LicensingTarget.ByMajorVersion} returns this
 */
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.prototype.setMajorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ProductId product_id = 1;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.LicensingTarget.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 1));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.LicensingTarget} returns this
*/
proto.teamdev.licensing.LicensingTarget.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicensingTarget} returns this
 */
proto.teamdev.licensing.LicensingTarget.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicensingTarget.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ByTime by_time = 2;
 * @return {?proto.teamdev.licensing.LicensingTarget.ByTime}
 */
proto.teamdev.licensing.LicensingTarget.prototype.getByTime = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget.ByTime} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.LicensingTarget.ByTime, 2));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget.ByTime|undefined} value
 * @return {!proto.teamdev.licensing.LicensingTarget} returns this
*/
proto.teamdev.licensing.LicensingTarget.prototype.setByTime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.teamdev.licensing.LicensingTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicensingTarget} returns this
 */
proto.teamdev.licensing.LicensingTarget.prototype.clearByTime = function() {
  return this.setByTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicensingTarget.prototype.hasByTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ByMajorVersion by_major_version = 3;
 * @return {?proto.teamdev.licensing.LicensingTarget.ByMajorVersion}
 */
proto.teamdev.licensing.LicensingTarget.prototype.getByMajorVersion = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget.ByMajorVersion} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.LicensingTarget.ByMajorVersion, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget.ByMajorVersion|undefined} value
 * @return {!proto.teamdev.licensing.LicensingTarget} returns this
*/
proto.teamdev.licensing.LicensingTarget.prototype.setByMajorVersion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.teamdev.licensing.LicensingTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.LicensingTarget} returns this
 */
proto.teamdev.licensing.LicensingTarget.prototype.clearByMajorVersion = function() {
  return this.setByMajorVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.LicensingTarget.prototype.hasByMajorVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.teamdev.licensing);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.LicensingTarget.ByTime.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.LicensingTarget.ByTime.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.LicensingTarget.ByTime.Parser.prototype.constructor = proto.teamdev.licensing.LicensingTarget.ByTime.Parser;
proto.teamdev.licensing.LicensingTarget.ByTime.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.LicensingTarget.ByTime();
  
  if (obj.whenEnds !== undefined) {
    if (obj.whenEnds === null) {
      msg.setWhenEnds(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.whenEnds);
      msg.setWhenEnds(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.LicensingTarget.ByMajorVersion.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.Parser.prototype.constructor = proto.teamdev.licensing.LicensingTarget.ByMajorVersion.Parser;
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.LicensingTarget.ByMajorVersion();
  
  if (obj.majorVersion !== undefined) {
    if (obj.majorVersion !== null) {
      let value = obj.majorVersion;
      msg.setMajorVersion(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.LicensingTarget.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.LicensingTarget.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.LicensingTarget.Parser.prototype.constructor = proto.teamdev.licensing.LicensingTarget.Parser;
proto.teamdev.licensing.LicensingTarget.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.LicensingTarget();
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.byTime !== undefined) {
    if (obj.byTime === null) {
      msg.setByTime(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget.ByTime').fromObject(obj.byTime);
      msg.setByTime(value);
    }
  }
  
  if (obj.byMajorVersion !== undefined) {
    if (obj.byMajorVersion === null) {
      msg.setByMajorVersion(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget.ByMajorVersion').fromObject(obj.byMajorVersion);
      msg.setByMajorVersion(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.LicensingTarget.ByTime.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.LicensingTarget.ByTime';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.LicensingTarget.ByMajorVersion.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.LicensingTarget.ByMajorVersion';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.LicensingTarget.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.LicensingTarget';
};
