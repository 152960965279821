// source: teamdev/licensing/q/commercial/commercial_license_view.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_people_person_name_pb = require('spine-web/proto/spine/people/person_name_pb.js');
goog.object.extend(proto, spine_people_person_name_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_client_pb = require('../../../../teamdev/licensing/client_pb.js');
goog.object.extend(proto, teamdev_licensing_client_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
var teamdev_licensing_project_binding_pb = require('../../../../teamdev/licensing/project_binding_pb.js');
goog.object.extend(proto, teamdev_licensing_project_binding_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.q.commercial.CommercialLicenseView', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.q.commercial.CommercialLicenseView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.commercial.CommercialLicenseView.displayName = 'proto.teamdev.licensing.q.commercial.CommercialLicenseView';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.commercial.CommercialLicenseView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.CommercialLicenseViewId.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    whenRevoked: (f = msg.getWhenRevoked()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    revocationReason: (f = msg.getRevocationReason()) && teamdev_licensing_values_pb.CommercialLicenseRevocationReason.toObject(includeInstance, f),
    client: (f = msg.getClient()) && teamdev_licensing_client_pb.Client.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    productLogo: (f = msg.getProductLogo()) && teamdev_licensing_c_product_values_pb.ProductLogo.toObject(includeInstance, f),
    licensingTarget: (f = msg.getLicensingTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f),
    grantedBy: (f = msg.getGrantedBy()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 13, ""),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    supportExpirationDate: (f = msg.getSupportExpirationDate()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.commercial.CommercialLicenseView;
  return proto.teamdev.licensing.q.commercial.CommercialLicenseView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.CommercialLicenseViewId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.CommercialLicenseViewId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 5:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRevoked(value);
      break;
    case 6:
      var value = new teamdev_licensing_values_pb.CommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.CommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setRevocationReason(value);
      break;
    case 7:
      var value = new teamdev_licensing_client_pb.Client;
      reader.readMessage(value,teamdev_licensing_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 8:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 9:
      var value = new teamdev_licensing_c_product_values_pb.ProductLogo;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductLogo.deserializeBinaryFromReader);
      msg.setProductLogo(value);
      break;
    case 10:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setLicensingTarget(value);
      break;
    case 11:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    case 12:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 14:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 15:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setSupportExpirationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.commercial.CommercialLicenseView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.CommercialLicenseViewId.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getWhenRevoked();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRevocationReason();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_values_pb.CommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      teamdev_licensing_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getProductLogo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      teamdev_licensing_c_product_values_pb.ProductLogo.serializeBinaryToWriter
    );
  }
  f = message.getLicensingTarget();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getSupportExpirationDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type = {
  CLT_UNDEFINED: 0,
  SINGLE_DEV: 1,
  PROJECT: 2,
  COMPANY_WIDE: 3,
  PERSONAL: 4,
  SINGLE_DEV_SOURCE_CODE: 5,
  PROJECT_SOURCE_CODE: 6,
  COMPANY_WIDE_SOURCE_CODE: 7
};

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status = {
  CLS_UNDEFINED: 0,
  CREATED: 1,
  ISSUED: 2,
  REVOKED: 3
};

/**
 * optional teamdev.licensing.CommercialLicenseViewId id = 1;
 * @return {?proto.teamdev.licensing.CommercialLicenseViewId}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.CommercialLicenseViewId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.CommercialLicenseViewId, 1));
};


/**
 * @param {?proto.teamdev.licensing.CommercialLicenseViewId|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Type type = 2;
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getType = function() {
  return /** @type {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional spine.time.ZonedDateTime when_issued = 4;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 4));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional spine.time.ZonedDateTime when_revoked = 5;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getWhenRevoked = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 5));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setWhenRevoked = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearWhenRevoked = function() {
  return this.setWhenRevoked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasWhenRevoked = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.CommercialLicenseRevocationReason revocation_reason = 6;
 * @return {?proto.teamdev.licensing.CommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getRevocationReason = function() {
  return /** @type{?proto.teamdev.licensing.CommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.CommercialLicenseRevocationReason, 6));
};


/**
 * @param {?proto.teamdev.licensing.CommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setRevocationReason = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearRevocationReason = function() {
  return this.setRevocationReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasRevocationReason = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional teamdev.licensing.commercial.Client client = 7;
 * @return {?proto.teamdev.licensing.commercial.Client}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getClient = function() {
  return /** @type{?proto.teamdev.licensing.commercial.Client} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_client_pb.Client, 7));
};


/**
 * @param {?proto.teamdev.licensing.commercial.Client|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasClient = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductName product_name = 8;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getProductName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 8));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional teamdev.licensing.c.product.ProductLogo product_logo = 9;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getProductLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductLogo, 9));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setProductLogo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearProductLogo = function() {
  return this.setProductLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasProductLogo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget licensing_target = 10;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getLicensingTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 10));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setLicensingTarget = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearLicensingTarget = function() {
  return this.setLicensingTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasLicensingTarget = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional spine.people.PersonName revoked_by = 11;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 11));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional spine.people.PersonName granted_by = 12;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getGrantedBy = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 12));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string comment = 13;
 * @return {string}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 14;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 14));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional spine.time.LocalDate support_expiration_date = 15;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.getSupportExpirationDate = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 15));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.setSupportExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.commercial.CommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.clearSupportExpirationDate = function() {
  return this.setSupportExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.commercial.CommercialLicenseView.prototype.hasSupportExpirationDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.q.commercial);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.q.commercial.CommercialLicenseView.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.commercial.CommercialLicenseView.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.commercial.CommercialLicenseView.Parser.prototype.constructor = proto.teamdev.licensing.q.commercial.CommercialLicenseView.Parser;
proto.teamdev.licensing.q.commercial.CommercialLicenseView.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.commercial.CommercialLicenseView();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CommercialLicenseViewId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.type !== undefined) {
    if (obj.type !== null) {
      let value = proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type[obj.type];
      msg.setType(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status[obj.status];
      msg.setStatus(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.whenRevoked !== undefined) {
    if (obj.whenRevoked === null) {
      msg.setWhenRevoked(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRevoked);
      msg.setWhenRevoked(value);
    }
  }
  
  if (obj.revocationReason !== undefined) {
    if (obj.revocationReason === null) {
      msg.setRevocationReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.CommercialLicenseRevocationReason').fromObject(obj.revocationReason);
      msg.setRevocationReason(value);
    }
  }
  
  if (obj.client !== undefined) {
    if (obj.client === null) {
      msg.setClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.commercial.Client').fromObject(obj.client);
      msg.setClient(value);
    }
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName === null) {
      msg.setProductName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.productName);
      msg.setProductName(value);
    }
  }
  
  if (obj.productLogo !== undefined) {
    if (obj.productLogo === null) {
      msg.setProductLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.productLogo);
      msg.setProductLogo(value);
    }
  }
  
  if (obj.licensingTarget !== undefined) {
    if (obj.licensingTarget === null) {
      msg.setLicensingTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.licensingTarget);
      msg.setLicensingTarget(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  
  if (obj.grantedBy !== undefined) {
    if (obj.grantedBy === null) {
      msg.setGrantedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.grantedBy);
      msg.setGrantedBy(value);
    }
  }
  
  if (obj.comment !== undefined) {
    if (obj.comment !== null) {
      let value = obj.comment;
      msg.setComment(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.supportExpirationDate !== undefined) {
    if (obj.supportExpirationDate === null) {
      msg.setSupportExpirationDate(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.supportExpirationDate);
      msg.setSupportExpirationDate(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.commercial.CommercialLicenseView.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.commercial.CommercialLicenseView';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.commercial.CommercialLicenseView.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.commercial.CommercialLicenseView.Status';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.commercial.CommercialLicenseView.Type.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.commercial.CommercialLicenseView.Type';
};
