var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('records-viewer',{ref:"recordsViewer",attrs:{"items":_vm.licenses,"item-to-id":_vm.uuid,"item-to-group-def":_vm.getLicenseGroupDef},scopedSlots:_vm._u([{key:"filter-bar-content",fn:function(ref){
var value = ref.value;
var input = ref.input;
return [_c('single-selection-filter',{attrs:{"options":_vm.licenseTypesFilterOptions,"option-text":_vm.typeText,"option-icon":_vm.typeIcon,"all-items-text":_vm.$t(
                                 'lserv-app.views.licenses.commercial.LicensesViewer.allLicenseTypesFilter'
                               ),"item-acceptable":_vm.commercialLicenseHasSelectedType,"value":value},on:{"input":input}}),_vm._v(" "),_c('filter-divider'),_vm._v(" "),_c('customer-details-filter',{attrs:{"customer-name":_vm.clientName,"customer-email":_vm.clientEmail,"organization-name":_vm.organizationName,"value":value},on:{"input":input}}),_vm._v(" "),_c('date-range-filter',{attrs:{"item-acceptable":_vm.issuedDuringDateRange,"value":value},on:{"input":input}})]}},{key:"record-collapsed",fn:function(ref){
                               var item = ref.item;
                               var focused = ref.focused;
return [_c('collapsed-commercial-license',{attrs:{"license":item,"focused":focused},on:{"license-action":function($event){return _vm.handleLicenseAction($event, item)}}})]}},{key:"record-expanded-header",fn:function(ref){
                               var item = ref.item;
return [_c('expanded-license-record-header',{attrs:{"license-is-revoked":_vm.isRevoked(item),"icon-name":_vm.typeIcon(item.getType()),"header-text":_vm.organizationName(item)},on:{"license-action":function($event){return _vm.handleLicenseAction($event, item)}}})]}},{key:"record-expanded-content",fn:function(ref){
                               var item = ref.item;
return [_c('expanded-commercial-license-content',{attrs:{"license":item},on:{"license-action":function($event){return _vm.handleLicenseAction($event, item)}}})]}},{key:"dialogs",fn:function(ref){
                               var item = ref.item;
return [_c('deliver-license-dialog',{ref:"deliverLicenseDialog",attrs:{"company-first":"","title":"Deliver the license","user-name":_vm.clientName(item),"user-email":_vm.clientEmail(item),"organization-name":_vm.organizationName(item),"organization-url":_vm.organizationWebsite(item),"product-name":item.getProductName(),"product-version":_vm.productVersion(item),"product-logo":item.getProductLogo()},scopedSlots:_vm._u([{key:"license-type",fn:function(){return [_c('license-type-icon',{attrs:{"icon-name":_vm.typeIcon(item.getType())}})]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }