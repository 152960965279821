// source: teamdev/licensing/c/sampledata/events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleLicensesAdded', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleLicensesCreated', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleProductsAdded', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleProductsCreated', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.sampledata.SampleVersionsCreated', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.displayName = 'proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleProductsCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleProductsCreated.displayName = 'proto.teamdev.licensing.c.sampledata.SampleProductsCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleVersionsCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.displayName = 'proto.teamdev.licensing.c.sampledata.SampleVersionsCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.displayName = 'proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.displayName = 'proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleLicensesCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.displayName = 'proto.teamdev.licensing.c.sampledata.SampleLicensesCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleProductsAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleProductsAdded.displayName = 'proto.teamdev.licensing.c.sampledata.SampleProductsAdded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.displayName = 'proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.displayName = 'proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.sampledata.SampleLicensesAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.displayName = 'proto.teamdev.licensing.c.sampledata.SampleLicensesAdded';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted;
  return proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleProductsCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductsCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsCreated}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleProductsCreated;
  return proto.teamdev.licensing.c.sampledata.SampleProductsCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductsCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsCreated}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleProductsCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductsCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsCreated} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsCreated} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleVersionsCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleVersionsCreated}
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleVersionsCreated;
  return proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleVersionsCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleVersionsCreated}
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleVersionsCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleVersionsCreated} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleVersionsCreated} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned;
  return proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned}
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned;
  return proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned}
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned} returns this
*/
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned} returns this
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleLicensesCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesCreated}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleLicensesCreated;
  return proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleLicensesCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesCreated}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleLicensesCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesCreated} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesCreated} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleProductsAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductsAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleProductsAdded;
  return proto.teamdev.licensing.c.sampledata.SampleProductsAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductsAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleProductsAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductsAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsAdded} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductsAdded} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded;
  return proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded;
  return proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.sampledata.SampleLicensesAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.SampleDataGenerationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.sampledata.SampleLicensesAdded;
  return proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleLicensesAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesAdded}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.SampleDataGenerationId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.SampleDataGenerationId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.sampledata.SampleLicensesAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.SampleDataGenerationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.SampleDataGenerationId id = 1;
 * @return {?proto.teamdev.licensing.SampleDataGenerationId}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.SampleDataGenerationId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.SampleDataGenerationId, 1));
};


/**
 * @param {?proto.teamdev.licensing.SampleDataGenerationId|undefined} value
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesAdded} returns this
*/
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.sampledata.SampleLicensesAdded} returns this
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.sampledata);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.sampledata.SampleProductsCreated.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleProductsCreated.Parser;
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleProductsCreated();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.Parser;
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.Parser;
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.Parser;
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.Parser;
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.Parser;
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleVersionsCreated();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.Parser;
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleLicensesCreated();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.Parser;
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.Parser;
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleLicensesAdded();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.sampledata.SampleProductsAdded.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.Parser.prototype.constructor = proto.teamdev.licensing.c.sampledata.SampleProductsAdded.Parser;
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.sampledata.SampleProductsAdded();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.SampleDataGenerationId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleProductsCreated.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductsCreated';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleDataGenerationCompleted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleDataGenerationCompleted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleMainstreamVersionsAssigned';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleProductVersionsAdded.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductVersionsAdded';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.NoMainstreamVersionsAssigned';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleVersionsCreated.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleVersionsCreated';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleLicensesCreated.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleLicensesCreated';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleMainstreamVersionsAdded';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleLicensesAdded.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleLicensesAdded';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.sampledata.SampleProductsAdded.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.sampledata.SampleProductsAdded';
};
