// source: teamdev/licensing/q/noncommercial/noncommercial_license_view.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_license_artifact_pb = require('../../../../teamdev/licensing/license_artifact_pb.js');
goog.object.extend(proto, teamdev_licensing_license_artifact_pb);
var teamdev_licensing_c_academic_values_pb = require('../../../../teamdev/licensing/c/academic/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_academic_values_pb);
var teamdev_licensing_c_opensource_values_pb = require('../../../../teamdev/licensing/c/opensource/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_opensource_values_pb);
var teamdev_licensing_c_product_values_pb = require('../../../../teamdev/licensing/c/product/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_values_pb);
var teamdev_licensing_q_licensed_period_pb = require('../../../../teamdev/licensing/q/licensed_period_pb.js');
goog.object.extend(proto, teamdev_licensing_q_licensed_period_pb);
var teamdev_licensing_q_noncommercial_project_profile_pb = require('../../../../teamdev/licensing/q/noncommercial/project_profile_pb.js');
goog.object.extend(proto, teamdev_licensing_q_noncommercial_project_profile_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ClientCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ProjectProfileCase', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status', null, global);
goog.exportSymbol('proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.repeatedFields_, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_);
};
goog.inherits(proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.displayName = 'proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.repeatedFields_ = [3];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_ = [[8,9],[13,14]];

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ClientCase = {
  CLIENT_NOT_SET: 0,
  ACADEMIC_CLIENT: 8,
  OPEN_SOURCE_CLIENT: 9
};

/**
 * @return {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ClientCase}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getClientCase = function() {
  return /** @type {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ClientCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ProjectProfileCase = {
  PROJECT_PROFILE_NOT_SET: 0,
  ACADEMIC_PROJECT_PROFILE: 13,
  OPEN_SOURCE_PROJECT_PROFILE: 14
};

/**
 * @return {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ProjectProfileCase}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getProjectProfileCase = function() {
  return /** @type {proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.ProjectProfileCase} */(jspb.Message.computeOneofCase(this, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.NonCommercialLicenseId.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    teamdev_licensing_q_licensed_period_pb.LicensedPeriod.toObject, includeInstance),
    productName: (f = msg.getProductName()) && teamdev_licensing_c_product_values_pb.ProductName.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    licensingTarget: (f = msg.getLicensingTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    academicClient: (f = msg.getAcademicClient()) && teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.toObject(includeInstance, f),
    openSourceClient: (f = msg.getOpenSourceClient()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    revocationReason: (f = msg.getRevocationReason()) && teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    academicProjectProfile: (f = msg.getAcademicProjectProfile()) && teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile.toObject(includeInstance, f),
    openSourceProjectProfile: (f = msg.getOpenSourceProjectProfile()) && teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView;
  return proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.NonCommercialLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.NonCommercialLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new teamdev_licensing_q_licensed_period_pb.LicensedPeriod;
      reader.readMessage(value,teamdev_licensing_q_licensed_period_pb.LicensedPeriod.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_product_values_pb.ProductName;
      reader.readMessage(value,teamdev_licensing_c_product_values_pb.ProductName.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 5:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 6:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setLicensingTarget(value);
      break;
    case 7:
      var value = /** @type {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.deserializeBinaryFromReader);
      msg.setAcademicClient(value);
      break;
    case 9:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setOpenSourceClient(value);
      break;
    case 10:
      var value = new teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setRevocationReason(value);
      break;
    case 11:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    case 12:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 13:
      var value = new teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile;
      reader.readMessage(value,teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile.deserializeBinaryFromReader);
      msg.setAcademicProjectProfile(value);
      break;
    case 14:
      var value = new teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile;
      reader.readMessage(value,teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile.deserializeBinaryFromReader);
      msg.setOpenSourceProjectProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.NonCommercialLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      teamdev_licensing_q_licensed_period_pb.LicensedPeriod.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_product_values_pb.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getLicensingTarget();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAcademicClient();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceClient();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getRevocationReason();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getAcademicProjectProfile();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile.serializeBinaryToWriter
    );
  }
  f = message.getOpenSourceProjectProfile();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type = {
  UNDEFINED: 0,
  ACADEMIC: 1,
  OPEN_SOURCE: 2
};

/**
 * @enum {number}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status = {
  NCL_UNDEFINED: 0,
  GRANTED: 1,
  ISSUED: 2,
  EXTENDED: 3,
  EXTENSION_ISSUED: 4,
  EXPIRED: 5,
  REVOKED: 6,
  CREATED: 7
};

/**
 * optional teamdev.licensing.NonCommercialLicenseId id = 1;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseId}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.NonCommercialLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Type type = 2;
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getType = function() {
  return /** @type {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated teamdev.licensing.q.LicensedPeriod history = 3;
 * @return {!Array<!proto.teamdev.licensing.q.LicensedPeriod>}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.teamdev.licensing.q.LicensedPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, teamdev_licensing_q_licensed_period_pb.LicensedPeriod, 3));
};


/**
 * @param {!Array<!proto.teamdev.licensing.q.LicensedPeriod>} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.teamdev.licensing.q.LicensedPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.teamdev.licensing.q.LicensedPeriod}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.teamdev.licensing.q.LicensedPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * optional teamdev.licensing.c.product.ProductName product_name = 4;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getProductName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_values_pb.ProductName, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 5;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 5));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget licensing_target = 6;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getLicensingTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 6));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setLicensingTarget = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearLicensingTarget = function() {
  return this.setLicensingTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasLicensingTarget = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Status status = 7;
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getStatus = function() {
  return /** @type {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional teamdev.licensing.c.academic.AcademicLicenseRequester academic_client = 8;
 * @return {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getAcademicClient = function() {
  return /** @type{?proto.teamdev.licensing.c.academic.AcademicLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_academic_values_pb.AcademicLicenseRequester, 8));
};


/**
 * @param {?proto.teamdev.licensing.c.academic.AcademicLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setAcademicClient = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearAcademicClient = function() {
  return this.setAcademicClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasAcademicClient = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional teamdev.licensing.c.opensource.OpenSourceLicenseRequester open_source_client = 9;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getOpenSourceClient = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 9));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setOpenSourceClient = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearOpenSourceClient = function() {
  return this.setOpenSourceClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasOpenSourceClient = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional teamdev.licensing.NonCommercialLicenseRevocationReason revocation_reason = 10;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getRevocationReason = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason, 10));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setRevocationReason = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearRevocationReason = function() {
  return this.setRevocationReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasRevocationReason = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional spine.core.UserId revoked_by = 11;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 11));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 12;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 12));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional AcademicProjectProfile academic_project_profile = 13;
 * @return {?proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getAcademicProjectProfile = function() {
  return /** @type{?proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_q_noncommercial_project_profile_pb.AcademicProjectProfile, 13));
};


/**
 * @param {?proto.teamdev.licensing.q.noncommercial.AcademicProjectProfile|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setAcademicProjectProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearAcademicProjectProfile = function() {
  return this.setAcademicProjectProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasAcademicProjectProfile = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional OpenSourceProjectProfile open_source_project_profile = 14;
 * @return {?proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.getOpenSourceProjectProfile = function() {
  return /** @type{?proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_q_noncommercial_project_profile_pb.OpenSourceProjectProfile, 14));
};


/**
 * @param {?proto.teamdev.licensing.q.noncommercial.OpenSourceProjectProfile|undefined} value
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
*/
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.setOpenSourceProjectProfile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView} returns this
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.clearOpenSourceProjectProfile = function() {
  return this.setOpenSourceProjectProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.prototype.hasOpenSourceProjectProfile = function() {
  return jspb.Message.getField(this, 14) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.q.noncommercial);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Parser.prototype.constructor = proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Parser;
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.type !== undefined) {
    if (obj.type !== null) {
      let value = proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type[obj.type];
      msg.setType(value);
    }
  }
  
  if (obj.history !== undefined && obj.history !== null) {
    obj.history.forEach(
      (listItem, index, array) => {
        if (listItem === null) {
          msg.addHistory(null);
        } else {
          let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.LicensedPeriod').fromObject(listItem);
          msg.addHistory(value);
        }
      }
    );
  }
  
  if (obj.productName !== undefined) {
    if (obj.productName === null) {
      msg.setProductName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.productName);
      msg.setProductName(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.licensingTarget !== undefined) {
    if (obj.licensingTarget === null) {
      msg.setLicensingTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.licensingTarget);
      msg.setLicensingTarget(value);
    }
  }
  
  if (obj.status !== undefined) {
    if (obj.status !== null) {
      let value = proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status[obj.status];
      msg.setStatus(value);
    }
  }
  
  if (obj.academicClient !== undefined) {
    if (obj.academicClient === null) {
      msg.setAcademicClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.academic.AcademicLicenseRequester').fromObject(obj.academicClient);
      msg.setAcademicClient(value);
    }
  }
  
  if (obj.openSourceClient !== undefined) {
    if (obj.openSourceClient === null) {
      msg.setOpenSourceClient(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.openSourceClient);
      msg.setOpenSourceClient(value);
    }
  }
  
  if (obj.revocationReason !== undefined) {
    if (obj.revocationReason === null) {
      msg.setRevocationReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason').fromObject(obj.revocationReason);
      msg.setRevocationReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.academicProjectProfile !== undefined) {
    if (obj.academicProjectProfile === null) {
      msg.setAcademicProjectProfile(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.noncommercial.AcademicProjectProfile').fromObject(obj.academicProjectProfile);
      msg.setAcademicProjectProfile(value);
    }
  }
  
  if (obj.openSourceProjectProfile !== undefined) {
    if (obj.openSourceProjectProfile === null) {
      msg.setOpenSourceProjectProfile(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.q.noncommercial.OpenSourceProjectProfile').fromObject(obj.openSourceProjectProfile);
      msg.setOpenSourceProjectProfile(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseView';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Type';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.q.noncommercial.NonCommercialLicenseView.Status';
};
