// source: teamdev/users/c/commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_net_email_address_pb = require('spine-web/proto/spine/net/email_address_pb.js');
goog.object.extend(proto, spine_net_email_address_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_people_person_name_pb = require('spine-web/proto/spine/people/person_name_pb.js');
goog.object.extend(proto, spine_people_person_name_pb);
var teamdev_users_identifiers_pb = require('../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.users.c.CreateUser', null, global);
goog.exportSymbol('proto.teamdev.users.c.DeleteUser', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.users.c.CreateUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.users.c.CreateUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.users.c.CreateUser.displayName = 'proto.teamdev.users.c.CreateUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.users.c.DeleteUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.users.c.DeleteUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.users.c.DeleteUser.displayName = 'proto.teamdev.users.c.DeleteUser';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.users.c.CreateUser.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.users.c.CreateUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.users.c.CreateUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.users.c.CreateUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f),
    email: (f = msg.getEmail()) && spine_net_email_address_pb.EmailAddress.toObject(includeInstance, f),
    name: (f = msg.getName()) && spine_people_person_name_pb.PersonName.toObject(includeInstance, f),
    photoUrl: (f = msg.getPhotoUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.users.c.CreateUser}
 */
proto.teamdev.users.c.CreateUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.users.c.CreateUser;
  return proto.teamdev.users.c.CreateUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.users.c.CreateUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.users.c.CreateUser}
 */
proto.teamdev.users.c.CreateUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_net_email_address_pb.EmailAddress;
      reader.readMessage(value,spine_net_email_address_pb.EmailAddress.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new spine_people_person_name_pb.PersonName;
      reader.readMessage(value,spine_people_person_name_pb.PersonName.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setPhotoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.users.c.CreateUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.users.c.CreateUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.users.c.CreateUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.users.c.CreateUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_email_address_pb.EmailAddress.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_people_person_name_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getPhotoUrl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
};


/**
 * optional spine.core.UserId id = 1;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.users.c.CreateUser.prototype.getId = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 1));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.users.c.CreateUser} returns this
*/
proto.teamdev.users.c.CreateUser.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.users.c.CreateUser} returns this
 */
proto.teamdev.users.c.CreateUser.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.users.c.CreateUser.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.net.EmailAddress email = 2;
 * @return {?proto.spine.net.EmailAddress}
 */
proto.teamdev.users.c.CreateUser.prototype.getEmail = function() {
  return /** @type{?proto.spine.net.EmailAddress} */ (
    jspb.Message.getWrapperField(this, spine_net_email_address_pb.EmailAddress, 2));
};


/**
 * @param {?proto.spine.net.EmailAddress|undefined} value
 * @return {!proto.teamdev.users.c.CreateUser} returns this
*/
proto.teamdev.users.c.CreateUser.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.users.c.CreateUser} returns this
 */
proto.teamdev.users.c.CreateUser.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.users.c.CreateUser.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.people.PersonName name = 3;
 * @return {?proto.spine.people.PersonName}
 */
proto.teamdev.users.c.CreateUser.prototype.getName = function() {
  return /** @type{?proto.spine.people.PersonName} */ (
    jspb.Message.getWrapperField(this, spine_people_person_name_pb.PersonName, 3));
};


/**
 * @param {?proto.spine.people.PersonName|undefined} value
 * @return {!proto.teamdev.users.c.CreateUser} returns this
*/
proto.teamdev.users.c.CreateUser.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.users.c.CreateUser} returns this
 */
proto.teamdev.users.c.CreateUser.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.users.c.CreateUser.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.net.Url photo_url = 4;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.users.c.CreateUser.prototype.getPhotoUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 4));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.users.c.CreateUser} returns this
*/
proto.teamdev.users.c.CreateUser.prototype.setPhotoUrl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.users.c.CreateUser} returns this
 */
proto.teamdev.users.c.CreateUser.prototype.clearPhotoUrl = function() {
  return this.setPhotoUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.users.c.CreateUser.prototype.hasPhotoUrl = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.users.c.DeleteUser.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.users.c.DeleteUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.users.c.DeleteUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.users.c.DeleteUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.users.c.DeleteUser}
 */
proto.teamdev.users.c.DeleteUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.users.c.DeleteUser;
  return proto.teamdev.users.c.DeleteUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.users.c.DeleteUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.users.c.DeleteUser}
 */
proto.teamdev.users.c.DeleteUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.users.c.DeleteUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.users.c.DeleteUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.users.c.DeleteUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.users.c.DeleteUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional spine.core.UserId id = 1;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.users.c.DeleteUser.prototype.getId = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 1));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.users.c.DeleteUser} returns this
*/
proto.teamdev.users.c.DeleteUser.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.users.c.DeleteUser} returns this
 */
proto.teamdev.users.c.DeleteUser.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.users.c.DeleteUser.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.teamdev.users.c);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.users.c.CreateUser.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.users.c.CreateUser.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.users.c.CreateUser.Parser.prototype.constructor = proto.teamdev.users.c.CreateUser.Parser;
proto.teamdev.users.c.CreateUser.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.users.c.CreateUser();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.email !== undefined) {
    if (obj.email === null) {
      msg.setEmail(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.EmailAddress').fromObject(obj.email);
      msg.setEmail(value);
    }
  }
  
  if (obj.name !== undefined) {
    if (obj.name === null) {
      msg.setName(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.people.PersonName').fromObject(obj.name);
      msg.setName(value);
    }
  }
  
  if (obj.photoUrl !== undefined) {
    if (obj.photoUrl === null) {
      msg.setPhotoUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.photoUrl);
      msg.setPhotoUrl(value);
    }
  }
  return msg;
};

proto.teamdev.users.c.DeleteUser.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.users.c.DeleteUser.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.users.c.DeleteUser.Parser.prototype.constructor = proto.teamdev.users.c.DeleteUser.Parser;
proto.teamdev.users.c.DeleteUser.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.users.c.DeleteUser();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.users.c.CreateUser.typeUrl = function() {
  return 'type.teamdev.com/teamdev.users.c.CreateUser';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.users.c.DeleteUser.typeUrl = function() {
  return 'type.teamdev.com/teamdev.users.c.DeleteUser';
};
