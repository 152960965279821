import { render, staticRenderFns } from "./MenuItems.vue?vue&type=template&id=28d91dae&scoped=true&"
import script from "./MenuItems.vue?vue&type=script&lang=ts&"
export * from "./MenuItems.vue?vue&type=script&lang=ts&"
import style0 from "./MenuItems.vue?vue&type=style&index=0&id=28d91dae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d91dae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListItemGroup})
