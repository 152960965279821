// source: teamdev/licensing/c/product/values.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_c_product_licensing_policy_pb = require('../../../../teamdev/licensing/c/product/licensing_policy_pb.js');
goog.object.extend(proto, teamdev_licensing_c_product_licensing_policy_pb);
goog.exportSymbol('proto.teamdev.licensing.c.product.ProductDetails', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.ProductLogo', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.ProductName', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.ProductVersionDetails', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.VersionDescription', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.product.VersionName', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.VersionName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.VersionName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.VersionName.displayName = 'proto.teamdev.licensing.c.product.VersionName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.VersionDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.VersionDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.VersionDescription.displayName = 'proto.teamdev.licensing.c.product.VersionDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.ProductName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.ProductName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.ProductName.displayName = 'proto.teamdev.licensing.c.product.ProductName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.ProductLogo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.ProductLogo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.ProductLogo.displayName = 'proto.teamdev.licensing.c.product.ProductLogo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.ProductDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.ProductDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.ProductDetails.displayName = 'proto.teamdev.licensing.c.product.ProductDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.product.ProductVersionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.product.ProductVersionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.product.ProductVersionDetails.displayName = 'proto.teamdev.licensing.c.product.ProductVersionDetails';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.VersionName.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.VersionName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.VersionName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.VersionName.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.c.product.VersionName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.VersionName;
  return proto.teamdev.licensing.c.product.VersionName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.VersionName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.c.product.VersionName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.VersionName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.VersionName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.VersionName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.VersionName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.product.VersionName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.product.VersionName} returns this
 */
proto.teamdev.licensing.c.product.VersionName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.VersionDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.VersionDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.VersionDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.VersionDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.VersionDescription}
 */
proto.teamdev.licensing.c.product.VersionDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.VersionDescription;
  return proto.teamdev.licensing.c.product.VersionDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.VersionDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.VersionDescription}
 */
proto.teamdev.licensing.c.product.VersionDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.VersionDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.VersionDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.VersionDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.VersionDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.product.VersionDescription.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.product.VersionDescription} returns this
 */
proto.teamdev.licensing.c.product.VersionDescription.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.ProductName.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.ProductName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.ProductName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductName.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.product.ProductName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.ProductName;
  return proto.teamdev.licensing.c.product.ProductName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.ProductName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.product.ProductName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.ProductName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.ProductName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.ProductName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.teamdev.licensing.c.product.ProductName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.product.ProductName} returns this
 */
proto.teamdev.licensing.c.product.ProductName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.ProductLogo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.ProductLogo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductLogo.toObject = function(includeInstance, msg) {
  var f, obj = {
    logoLink40Dp: (f = msg.getLogoLink40Dp()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    logoLink24DpSingleColor: (f = msg.getLogoLink24DpSingleColor()) && spine_net_url_pb.Url.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.c.product.ProductLogo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.ProductLogo;
  return proto.teamdev.licensing.c.product.ProductLogo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.ProductLogo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.c.product.ProductLogo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setLogoLink40Dp(value);
      break;
    case 2:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setLogoLink24DpSingleColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.ProductLogo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.ProductLogo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductLogo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogoLink40Dp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getLogoLink24DpSingleColor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
};


/**
 * optional spine.net.Url logo_link_40_dp = 1;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.getLogoLink40Dp = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 1));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductLogo} returns this
*/
proto.teamdev.licensing.c.product.ProductLogo.prototype.setLogoLink40Dp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductLogo} returns this
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.clearLogoLink40Dp = function() {
  return this.setLogoLink40Dp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.hasLogoLink40Dp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.net.Url logo_link_24_dp_single_color = 2;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.getLogoLink24DpSingleColor = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 2));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductLogo} returns this
*/
proto.teamdev.licensing.c.product.ProductLogo.prototype.setLogoLink24DpSingleColor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductLogo} returns this
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.clearLogoLink24DpSingleColor = function() {
  return this.setLogoLink24DpSingleColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductLogo.prototype.hasLogoLink24DpSingleColor = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.ProductDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.ProductDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: (f = msg.getDisplayName()) && proto.teamdev.licensing.c.product.ProductName.toObject(includeInstance, f),
    defaultPolicy: (f = msg.getDefaultPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f),
    logo: (f = msg.getLogo()) && proto.teamdev.licensing.c.product.ProductLogo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.ProductDetails}
 */
proto.teamdev.licensing.c.product.ProductDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.ProductDetails;
  return proto.teamdev.licensing.c.product.ProductDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.ProductDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.ProductDetails}
 */
proto.teamdev.licensing.c.product.ProductDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.c.product.ProductName;
      reader.readMessage(value,proto.teamdev.licensing.c.product.ProductName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setDefaultPolicy(value);
      break;
    case 3:
      var value = new proto.teamdev.licensing.c.product.ProductLogo;
      reader.readMessage(value,proto.teamdev.licensing.c.product.ProductLogo.deserializeBinaryFromReader);
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.ProductDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.ProductDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.c.product.ProductName.serializeBinaryToWriter
    );
  }
  f = message.getDefaultPolicy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.teamdev.licensing.c.product.ProductLogo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProductName display_name = 1;
 * @return {?proto.teamdev.licensing.c.product.ProductName}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductName} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.product.ProductName, 1));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductName|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductDetails} returns this
*/
proto.teamdev.licensing.c.product.ProductDetails.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductDetails} returns this
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicensingPolicy default_policy = 2;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.getDefaultPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductDetails} returns this
*/
proto.teamdev.licensing.c.product.ProductDetails.prototype.setDefaultPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductDetails} returns this
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.clearDefaultPolicy = function() {
  return this.setDefaultPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.hasDefaultPolicy = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProductLogo logo = 3;
 * @return {?proto.teamdev.licensing.c.product.ProductLogo}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.getLogo = function() {
  return /** @type{?proto.teamdev.licensing.c.product.ProductLogo} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.product.ProductLogo, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.product.ProductLogo|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductDetails} returns this
*/
proto.teamdev.licensing.c.product.ProductDetails.prototype.setLogo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductDetails} returns this
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.clearLogo = function() {
  return this.setLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductDetails.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.product.ProductVersionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.product.ProductVersionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: (f = msg.getDisplayName()) && proto.teamdev.licensing.c.product.VersionName.toObject(includeInstance, f),
    licensingPolicy: (f = msg.getLicensingPolicy()) && teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.toObject(includeInstance, f),
    whenReleased: (f = msg.getWhenReleased()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.product.ProductVersionDetails;
  return proto.teamdev.licensing.c.product.ProductVersionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.product.ProductVersionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.teamdev.licensing.c.product.VersionName;
      reader.readMessage(value,proto.teamdev.licensing.c.product.VersionName.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy;
      reader.readMessage(value,teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.deserializeBinaryFromReader);
      msg.setLicensingPolicy(value);
      break;
    case 4:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenReleased(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.product.ProductVersionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.product.ProductVersionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.teamdev.licensing.c.product.VersionName.serializeBinaryToWriter
    );
  }
  f = message.getLicensingPolicy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy.serializeBinaryToWriter
    );
  }
  f = message.getWhenReleased();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional VersionName display_name = 1;
 * @return {?proto.teamdev.licensing.c.product.VersionName}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.getDisplayName = function() {
  return /** @type{?proto.teamdev.licensing.c.product.VersionName} */ (
    jspb.Message.getWrapperField(this, proto.teamdev.licensing.c.product.VersionName, 1));
};


/**
 * @param {?proto.teamdev.licensing.c.product.VersionName|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails} returns this
*/
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails} returns this
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicensingPolicy licensing_policy = 2;
 * @return {?proto.teamdev.licensing.c.product.LicensingPolicy}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.getLicensingPolicy = function() {
  return /** @type{?proto.teamdev.licensing.c.product.LicensingPolicy} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_product_licensing_policy_pb.LicensingPolicy, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.product.LicensingPolicy|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails} returns this
*/
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.setLicensingPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails} returns this
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.clearLicensingPolicy = function() {
  return this.setLicensingPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.hasLicensingPolicy = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.ZonedDateTime when_released = 4;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.getWhenReleased = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 4));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails} returns this
*/
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.setWhenReleased = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.product.ProductVersionDetails} returns this
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.clearWhenReleased = function() {
  return this.setWhenReleased(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.product.ProductVersionDetails.prototype.hasWhenReleased = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.product);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.product.ProductVersionDetails.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.ProductVersionDetails.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.ProductVersionDetails.Parser.prototype.constructor = proto.teamdev.licensing.c.product.ProductVersionDetails.Parser;
proto.teamdev.licensing.c.product.ProductVersionDetails.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.ProductVersionDetails();
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.VersionName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.licensingPolicy !== undefined) {
    if (obj.licensingPolicy === null) {
      msg.setLicensingPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.licensingPolicy);
      msg.setLicensingPolicy(value);
    }
  }
  
  if (obj.whenReleased !== undefined) {
    if (obj.whenReleased === null) {
      msg.setWhenReleased(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenReleased);
      msg.setWhenReleased(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.VersionDescription.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.VersionDescription.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.VersionDescription.Parser.prototype.constructor = proto.teamdev.licensing.c.product.VersionDescription.Parser;
proto.teamdev.licensing.c.product.VersionDescription.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.VersionDescription();
  
  if (obj.description !== undefined) {
    if (obj.description !== null) {
      let value = obj.description;
      msg.setDescription(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.ProductLogo.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.ProductLogo.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.ProductLogo.Parser.prototype.constructor = proto.teamdev.licensing.c.product.ProductLogo.Parser;
proto.teamdev.licensing.c.product.ProductLogo.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.ProductLogo();
  
  if (obj.logoLink40Dp !== undefined) {
    if (obj.logoLink40Dp === null) {
      msg.setLogoLink40Dp(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.logoLink40Dp);
      msg.setLogoLink40Dp(value);
    }
  }
  
  if (obj.logoLink24DpSingleColor !== undefined) {
    if (obj.logoLink24DpSingleColor === null) {
      msg.setLogoLink24DpSingleColor(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.logoLink24DpSingleColor);
      msg.setLogoLink24DpSingleColor(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.ProductName.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.ProductName.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.ProductName.Parser.prototype.constructor = proto.teamdev.licensing.c.product.ProductName.Parser;
proto.teamdev.licensing.c.product.ProductName.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.ProductName();
  
  if (obj.name !== undefined) {
    if (obj.name !== null) {
      let value = obj.name;
      msg.setName(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.VersionName.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.VersionName.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.VersionName.Parser.prototype.constructor = proto.teamdev.licensing.c.product.VersionName.Parser;
proto.teamdev.licensing.c.product.VersionName.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.VersionName();
  
  if (obj.name !== undefined) {
    if (obj.name !== null) {
      let value = obj.name;
      msg.setName(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.product.ProductDetails.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.product.ProductDetails.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.product.ProductDetails.Parser.prototype.constructor = proto.teamdev.licensing.c.product.ProductDetails.Parser;
proto.teamdev.licensing.c.product.ProductDetails.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.product.ProductDetails();
  
  if (obj.displayName !== undefined) {
    if (obj.displayName === null) {
      msg.setDisplayName(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductName').fromObject(obj.displayName);
      msg.setDisplayName(value);
    }
  }
  
  if (obj.defaultPolicy !== undefined) {
    if (obj.defaultPolicy === null) {
      msg.setDefaultPolicy(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.LicensingPolicy').fromObject(obj.defaultPolicy);
      msg.setDefaultPolicy(value);
    }
  }
  
  if (obj.logo !== undefined) {
    if (obj.logo === null) {
      msg.setLogo(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.product.ProductLogo').fromObject(obj.logo);
      msg.setLogo(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.ProductVersionDetails.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.ProductVersionDetails';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.VersionDescription.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.VersionDescription';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.ProductLogo.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.ProductLogo';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.ProductName.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.ProductName';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.VersionName.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.VersionName';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.product.ProductDetails.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.product.ProductDetails';
};
