// source: teamdev/licensing/c/opensource/events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var spine_options_pb = require('spine-web/proto/spine/options_pb.js');
goog.object.extend(proto, spine_options_pb);
var spine_core_user_id_pb = require('spine-web/proto/spine/core/user_id_pb.js');
goog.object.extend(proto, spine_core_user_id_pb);
var spine_net_url_pb = require('spine-web/proto/spine/net/url_pb.js');
goog.object.extend(proto, spine_net_url_pb);
var spine_time_time_pb = require('spine-web/proto/spine/time/time_pb.js');
goog.object.extend(proto, spine_time_time_pb);
var teamdev_licensing_identifiers_pb = require('../../../../teamdev/licensing/identifiers_pb.js');
goog.object.extend(proto, teamdev_licensing_identifiers_pb);
var teamdev_licensing_values_pb = require('../../../../teamdev/licensing/values_pb.js');
goog.object.extend(proto, teamdev_licensing_values_pb);
var teamdev_licensing_c_opensource_values_pb = require('../../../../teamdev/licensing/c/opensource/values_pb.js');
goog.object.extend(proto, teamdev_licensing_c_opensource_values_pb);
var teamdev_licensing_licensing_target_pb = require('../../../../teamdev/licensing/licensing_target_pb.js');
goog.object.extend(proto, teamdev_licensing_licensing_target_pb);
var teamdev_licensing_license_artifact_pb = require('../../../../teamdev/licensing/license_artifact_pb.js');
goog.object.extend(proto, teamdev_licensing_license_artifact_pb);
var teamdev_licensing_project_binding_pb = require('../../../../teamdev/licensing/project_binding_pb.js');
goog.object.extend(proto, teamdev_licensing_project_binding_pb);
var teamdev_users_identifiers_pb = require('../../../../teamdev/users/identifiers_pb.js');
goog.object.extend(proto, teamdev_users_identifiers_pb);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested', null, global);
goog.exportSymbol('proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.displayName = 'proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.displayName = 'proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.displayName = 'proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    mainstreamVersionId: (f = msg.getMainstreamVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    whenRequested: (f = msg.getWhenRequested()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    projectName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    distributionLicense: jspb.Message.getFieldWithDefault(msg, 7, ""),
    repositoryUrl: (f = msg.getRepositoryUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    promotionRequirementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    disclaimerNoticeRequirementAccepted: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setMainstreamVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRequested(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionLicense(value);
      break;
    case 8:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 10:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPromotionRequirementAccepted(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisclaimerNoticeRequirementAccepted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getMainstreamVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRequested();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDistributionLicense();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRepositoryUrl();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getPromotionRequirementAccepted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getDisclaimerNoticeRequirementAccepted();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId mainstream_version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getMainstreamVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setMainstreamVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.clearMainstreamVersionId = function() {
  return this.setMainstreamVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.hasMainstreamVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.time.ZonedDateTime when_requested = 4;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getWhenRequested = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 4));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setWhenRequested = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.clearWhenRequested = function() {
  return this.setWhenRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.hasWhenRequested = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OpenSourceLicenseRequester requester = 5;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string project_name = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string distribution_license = 7;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getDistributionLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setDistributionLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional spine.net.Url repository_url = 8;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getRepositoryUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 8));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setRepositoryUrl = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.clearRepositoryUrl = function() {
  return this.setRepositoryUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.hasRepositoryUrl = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string project_description = 9;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 10;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 10));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool promotion_requirement_accepted = 11;
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getPromotionRequirementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setPromotionRequirementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool disclaimer_notice_requirement_accepted = 12;
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.getDisclaimerNoticeRequirementAccepted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.prototype.setDisclaimerNoticeRequirementAccepted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenStarted: (f = msg.getWhenStarted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenStarted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenStarted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_started = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.getWhenStarted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.setWhenStarted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.clearWhenStarted = function() {
  return this.setWhenStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.prototype.hasWhenStarted = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenVerified: (f = msg.getWhenVerified()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    licensingTarget: (f = msg.getLicensingTarget()) && teamdev_licensing_licensing_target_pb.LicensingTarget.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerified(value);
      break;
    case 3:
      var value = new teamdev_licensing_licensing_target_pb.LicensingTarget;
      reader.readMessage(value,teamdev_licensing_licensing_target_pb.LicensingTarget.deserializeBinaryFromReader);
      msg.setLicensingTarget(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerified();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getLicensingTarget();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_licensing_target_pb.LicensingTarget.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verified = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.getWhenVerified = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.setWhenVerified = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.clearWhenVerified = function() {
  return this.setWhenVerified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.hasWhenVerified = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicensingTarget licensing_target = 3;
 * @return {?proto.teamdev.licensing.LicensingTarget}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.getLicensingTarget = function() {
  return /** @type{?proto.teamdev.licensing.LicensingTarget} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_licensing_target_pb.LicensingTarget, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicensingTarget|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.setLicensingTarget = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.clearLicensingTarget = function() {
  return this.setLicensingTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.hasLicensingTarget = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId verified_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenVerificationFailed: (f = msg.getWhenVerificationFailed()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerificationFailed(value);
      break;
    case 3:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerificationFailed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verification_failed = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.getWhenVerificationFailed = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.setWhenVerificationFailed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.clearWhenVerificationFailed = function() {
  return this.setWhenVerificationFailed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.hasWhenVerificationFailed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 3;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId verified_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenGranted: (f = msg.getWhenGranted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    projectProfile: (f = msg.getProjectProfile()) && teamdev_licensing_c_opensource_values_pb.OpenSourceProjectProfile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenGranted(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 5:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 9:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceProjectProfile;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceProjectProfile.deserializeBinaryFromReader);
      msg.setProjectProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenGranted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectProfile();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceProjectProfile.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_granted = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.getWhenGranted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.setWhenGranted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.clearWhenGranted = function() {
  return this.setWhenGranted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.hasWhenGranted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 3;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OpenSourceLicenseRequester requester = 5;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 5));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OpenSourceProjectProfile project_profile = 9;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceProjectProfile}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.getProjectProfile = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceProjectProfile} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceProjectProfile, 9));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceProjectProfile|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.setProjectProfile = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.clearProjectProfile = function() {
  return this.setProjectProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.prototype.hasProjectProfile = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 3:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 3;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenRefused: (f = msg.getWhenRefused()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRefused(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_opensource_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRefused();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_refused = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.getWhenRefused = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.setWhenRefused = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.clearWhenRefused = function() {
  return this.setWhenRefused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.hasWhenRefused = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RequestRefusalReason reason = 3;
 * @return {?proto.teamdev.licensing.c.opensource.RequestRefusalReason}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.RequestRefusalReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId refused_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenRequested: (f = msg.getWhenRequested()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason.toObject(includeInstance, f),
    distributionLicense: jspb.Message.getFieldWithDefault(msg, 4, ""),
    repositoryUrl: (f = msg.getRepositoryUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRequested(value);
      break;
    case 3:
      var value = new teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionLicense(value);
      break;
    case 5:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryUrl(value);
      break;
    case 6:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRequested();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason.serializeBinaryToWriter
    );
  }
  f = message.getDistributionLicense();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepositoryUrl();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_requested = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.getWhenRequested = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.setWhenRequested = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.clearWhenRequested = function() {
  return this.setWhenRequested(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.hasWhenRequested = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExtensionRequestReason reason = 3;
 * @return {?proto.teamdev.licensing.c.opensource.ExtensionRequestReason}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.ExtensionRequestReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.ExtensionRequestReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.ExtensionRequestReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string distribution_license = 4;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.getDistributionLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.setDistributionLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional spine.net.Url repository_url = 5;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.getRepositoryUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 5));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.setRepositoryUrl = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.clearRepositoryUrl = function() {
  return this.setRepositoryUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.hasRepositoryUrl = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 6;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 6));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenStarted: (f = msg.getWhenStarted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenStarted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenStarted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_started = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.getWhenStarted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.setWhenStarted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.clearWhenStarted = function() {
  return this.setWhenStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.prototype.hasWhenStarted = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenVerified: (f = msg.getWhenVerified()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerified(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerified();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verified = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.getWhenVerified = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.setWhenVerified = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.clearWhenVerified = function() {
  return this.setWhenVerified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.hasWhenVerified = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.core.UserId verified_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenVerificationFailed: (f = msg.getWhenVerificationFailed()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.toObject(includeInstance, f),
    verifiedBy: (f = msg.getVerifiedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenVerificationFailed(value);
      break;
    case 3:
      var value = new teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason;
      reader.readMessage(value,teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setVerifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenVerificationFailed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason.serializeBinaryToWriter
    );
  }
  f = message.getVerifiedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_verification_failed = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.getWhenVerificationFailed = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.setWhenVerificationFailed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.clearWhenVerificationFailed = function() {
  return this.setWhenVerificationFailed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.hasWhenVerificationFailed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseRequestVerificationFailureReason reason = 3;
 * @return {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.LicenseRequestVerificationFailureReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.LicenseRequestVerificationFailureReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseRequestVerificationFailureReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId verified_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.getVerifiedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.setVerifiedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.clearVerifiedBy = function() {
  return this.setVerifiedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.prototype.hasVerifiedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenGranted: (f = msg.getWhenGranted()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted;
  return proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenGranted(value);
      break;
    case 3:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenGranted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_granted = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.getWhenGranted = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.setWhenGranted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.clearWhenGranted = function() {
  return this.setWhenGranted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.hasWhenGranted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.LocalDate active_through = 3;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 3));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} returns this
*/
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted} returns this
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenIssued: (f = msg.getWhenIssued()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    licenseArtifact: (f = msg.getLicenseArtifact()) && teamdev_licensing_license_artifact_pb.LicenseArtifact.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued;
  return proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenIssued(value);
      break;
    case 3:
      var value = new teamdev_licensing_license_artifact_pb.LicenseArtifact;
      reader.readMessage(value,teamdev_licensing_license_artifact_pb.LicenseArtifact.deserializeBinaryFromReader);
      msg.setLicenseArtifact(value);
      break;
    case 4:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenIssued();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getLicenseArtifact();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_license_artifact_pb.LicenseArtifact.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_issued = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.getWhenIssued = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.setWhenIssued = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.clearWhenIssued = function() {
  return this.setWhenIssued(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.hasWhenIssued = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.LicenseArtifact license_artifact = 3;
 * @return {?proto.teamdev.licensing.LicenseArtifact}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.getLicenseArtifact = function() {
  return /** @type{?proto.teamdev.licensing.LicenseArtifact} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_license_artifact_pb.LicenseArtifact, 3));
};


/**
 * @param {?proto.teamdev.licensing.LicenseArtifact|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.setLicenseArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.clearLicenseArtifact = function() {
  return this.setLicenseArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.hasLicenseArtifact = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 4;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 4));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
*/
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued} returns this
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.toObject(includeInstance, f),
    whenRefused: (f = msg.getWhenRefused()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    refusedBy: (f = msg.getRefusedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_c_opensource_values_pb.RequestRefusalReason;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 3:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRefused(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRefusedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_c_opensource_values_pb.RequestRefusalReason.serializeBinaryToWriter
    );
  }
  f = message.getWhenRefused();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getRefusedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequestRefusalReason reason = 2;
 * @return {?proto.teamdev.licensing.c.opensource.RequestRefusalReason}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.RequestRefusalReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.RequestRefusalReason, 2));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.RequestRefusalReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional spine.time.ZonedDateTime when_refused = 3;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.getWhenRefused = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 3));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.setWhenRefused = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.clearWhenRefused = function() {
  return this.setWhenRefused(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.hasWhenRefused = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId refused_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.getRefusedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.setRefusedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.clearRefusedBy = function() {
  return this.setRefusedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.prototype.hasRefusedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenRevoked: (f = msg.getWhenRevoked()) && spine_time_time_pb.ZonedDateTime.toObject(includeInstance, f),
    reason: (f = msg.getReason()) && teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.toObject(includeInstance, f),
    revokedBy: (f = msg.getRevokedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.ZonedDateTime;
      reader.readMessage(value,spine_time_time_pb.ZonedDateTime.deserializeBinaryFromReader);
      msg.setWhenRevoked(value);
      break;
    case 3:
      var value = new teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason;
      reader.readMessage(value,teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    case 4:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setRevokedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenRevoked();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.ZonedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason.serializeBinaryToWriter
    );
  }
  f = message.getRevokedBy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.ZonedDateTime when_revoked = 2;
 * @return {?proto.spine.time.ZonedDateTime}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.getWhenRevoked = function() {
  return /** @type{?proto.spine.time.ZonedDateTime} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.ZonedDateTime, 2));
};


/**
 * @param {?proto.spine.time.ZonedDateTime|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.setWhenRevoked = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.clearWhenRevoked = function() {
  return this.setWhenRevoked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.hasWhenRevoked = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.NonCommercialLicenseRevocationReason reason = 3;
 * @return {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.getReason = function() {
  return /** @type{?proto.teamdev.licensing.NonCommercialLicenseRevocationReason} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_values_pb.NonCommercialLicenseRevocationReason, 3));
};


/**
 * @param {?proto.teamdev.licensing.NonCommercialLicenseRevocationReason|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.hasReason = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional spine.core.UserId revoked_by = 4;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.getRevokedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 4));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.setRevokedBy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.clearRevokedBy = function() {
  return this.setRevokedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.prototype.hasRevokedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    whenExpired: (f = msg.getWhenExpired()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setWhenExpired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getWhenExpired();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional spine.time.LocalDate when_expired = 2;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.getWhenExpired = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 2));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.setWhenExpired = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.clearWhenExpired = function() {
  return this.setWhenExpired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.prototype.hasWhenExpired = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 2;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    versionId: (f = msg.getVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    projectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    distributionLicense: jspb.Message.getFieldWithDefault(msg, 6, ""),
    repositoryUrl: (f = msg.getRepositoryUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f),
    createdBy: (f = msg.getCreatedBy()) && spine_core_user_id_pb.UserId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionLicense(value);
      break;
    case 8:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 10:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 11:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    case 12:
      var value = new spine_core_user_id_pb.UserId;
      reader.readMessage(value,spine_core_user_id_pb.UserId.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDistributionLicense();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRepositoryUrl();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      spine_core_user_id_pb.UserId.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearVersionId = function() {
  return this.setVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OpenSourceLicenseRequester requester = 4;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string project_name = 5;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string distribution_license = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getDistributionLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setDistributionLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.net.Url repository_url = 8;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getRepositoryUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 8));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setRepositoryUrl = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearRepositoryUrl = function() {
  return this.setRepositoryUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasRepositoryUrl = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string project_description = 9;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 10;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 10));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional spine.time.LocalDate active_through = 11;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 11));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional spine.core.UserId created_by = 12;
 * @return {?proto.spine.core.UserId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.getCreatedBy = function() {
  return /** @type{?proto.spine.core.UserId} */ (
    jspb.Message.getWrapperField(this, spine_core_user_id_pb.UserId, 12));
};


/**
 * @param {?proto.spine.core.UserId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && teamdev_licensing_identifiers_pb.OpenSourceLicenseId.toObject(includeInstance, f),
    mainstreamVersionId: (f = msg.getMainstreamVersionId()) && teamdev_licensing_identifiers_pb.ProductVersionId.toObject(includeInstance, f),
    productId: (f = msg.getProductId()) && teamdev_licensing_identifiers_pb.ProductId.toObject(includeInstance, f),
    requester: (f = msg.getRequester()) && teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.toObject(includeInstance, f),
    projectName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    distributionLicense: jspb.Message.getFieldWithDefault(msg, 6, ""),
    repositoryUrl: (f = msg.getRepositoryUrl()) && spine_net_url_pb.Url.toObject(includeInstance, f),
    projectDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    projectBinding: (f = msg.getProjectBinding()) && teamdev_licensing_project_binding_pb.ProjectBinding.toObject(includeInstance, f),
    activeThrough: (f = msg.getActiveThrough()) && spine_time_time_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest;
  return proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new teamdev_licensing_identifiers_pb.OpenSourceLicenseId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.OpenSourceLicenseId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new teamdev_licensing_identifiers_pb.ProductVersionId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductVersionId.deserializeBinaryFromReader);
      msg.setMainstreamVersionId(value);
      break;
    case 3:
      var value = new teamdev_licensing_identifiers_pb.ProductId;
      reader.readMessage(value,teamdev_licensing_identifiers_pb.ProductId.deserializeBinaryFromReader);
      msg.setProductId(value);
      break;
    case 4:
      var value = new teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester;
      reader.readMessage(value,teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.deserializeBinaryFromReader);
      msg.setRequester(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionLicense(value);
      break;
    case 8:
      var value = new spine_net_url_pb.Url;
      reader.readMessage(value,spine_net_url_pb.Url.deserializeBinaryFromReader);
      msg.setRepositoryUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectDescription(value);
      break;
    case 10:
      var value = new teamdev_licensing_project_binding_pb.ProjectBinding;
      reader.readMessage(value,teamdev_licensing_project_binding_pb.ProjectBinding.deserializeBinaryFromReader);
      msg.setProjectBinding(value);
      break;
    case 11:
      var value = new spine_time_time_pb.LocalDate;
      reader.readMessage(value,spine_time_time_pb.LocalDate.deserializeBinaryFromReader);
      msg.setActiveThrough(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      teamdev_licensing_identifiers_pb.OpenSourceLicenseId.serializeBinaryToWriter
    );
  }
  f = message.getMainstreamVersionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      teamdev_licensing_identifiers_pb.ProductVersionId.serializeBinaryToWriter
    );
  }
  f = message.getProductId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      teamdev_licensing_identifiers_pb.ProductId.serializeBinaryToWriter
    );
  }
  f = message.getRequester();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDistributionLicense();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRepositoryUrl();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      spine_net_url_pb.Url.serializeBinaryToWriter
    );
  }
  f = message.getProjectDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProjectBinding();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      teamdev_licensing_project_binding_pb.ProjectBinding.serializeBinaryToWriter
    );
  }
  f = message.getActiveThrough();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      spine_time_time_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional teamdev.licensing.OpenSourceLicenseId id = 1;
 * @return {?proto.teamdev.licensing.OpenSourceLicenseId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getId = function() {
  return /** @type{?proto.teamdev.licensing.OpenSourceLicenseId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.OpenSourceLicenseId, 1));
};


/**
 * @param {?proto.teamdev.licensing.OpenSourceLicenseId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional teamdev.licensing.ProductVersionId mainstream_version_id = 2;
 * @return {?proto.teamdev.licensing.ProductVersionId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getMainstreamVersionId = function() {
  return /** @type{?proto.teamdev.licensing.ProductVersionId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductVersionId, 2));
};


/**
 * @param {?proto.teamdev.licensing.ProductVersionId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setMainstreamVersionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.clearMainstreamVersionId = function() {
  return this.setMainstreamVersionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.hasMainstreamVersionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional teamdev.licensing.ProductId product_id = 3;
 * @return {?proto.teamdev.licensing.ProductId}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getProductId = function() {
  return /** @type{?proto.teamdev.licensing.ProductId} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_identifiers_pb.ProductId, 3));
};


/**
 * @param {?proto.teamdev.licensing.ProductId|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setProductId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.clearProductId = function() {
  return this.setProductId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.hasProductId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OpenSourceLicenseRequester requester = 4;
 * @return {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getRequester = function() {
  return /** @type{?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_c_opensource_values_pb.OpenSourceLicenseRequester, 4));
};


/**
 * @param {?proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequester|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setRequester = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.clearRequester = function() {
  return this.setRequester(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.hasRequester = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string project_name = 5;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string distribution_license = 6;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getDistributionLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setDistributionLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional spine.net.Url repository_url = 8;
 * @return {?proto.spine.net.Url}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getRepositoryUrl = function() {
  return /** @type{?proto.spine.net.Url} */ (
    jspb.Message.getWrapperField(this, spine_net_url_pb.Url, 8));
};


/**
 * @param {?proto.spine.net.Url|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setRepositoryUrl = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.clearRepositoryUrl = function() {
  return this.setRepositoryUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.hasRepositoryUrl = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string project_description = 9;
 * @return {string}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getProjectDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setProjectDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional teamdev.licensing.ProjectBinding project_binding = 10;
 * @return {?proto.teamdev.licensing.ProjectBinding}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getProjectBinding = function() {
  return /** @type{?proto.teamdev.licensing.ProjectBinding} */ (
    jspb.Message.getWrapperField(this, teamdev_licensing_project_binding_pb.ProjectBinding, 10));
};


/**
 * @param {?proto.teamdev.licensing.ProjectBinding|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setProjectBinding = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.clearProjectBinding = function() {
  return this.setProjectBinding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.hasProjectBinding = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional spine.time.LocalDate active_through = 11;
 * @return {?proto.spine.time.LocalDate}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.getActiveThrough = function() {
  return /** @type{?proto.spine.time.LocalDate} */ (
    jspb.Message.getWrapperField(this, spine_time_time_pb.LocalDate, 11));
};


/**
 * @param {?proto.spine.time.LocalDate|undefined} value
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
*/
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.setActiveThrough = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest} returns this
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.clearActiveThrough = function() {
  return this.setActiveThrough(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.prototype.hasActiveThrough = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.teamdev.licensing.c.opensource);

// Generated by Spine ProtoJs Plugin

let ObjectParser = require('spine-web/client/parser/object-parser.js').default;
let TypeParsers = require('spine-web/client/parser/type-parsers.js').default;

proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.mainstreamVersionId !== undefined) {
    if (obj.mainstreamVersionId === null) {
      msg.setMainstreamVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.mainstreamVersionId);
      msg.setMainstreamVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectName !== undefined) {
    if (obj.projectName !== null) {
      let value = obj.projectName;
      msg.setProjectName(value);
    }
  }
  
  if (obj.distributionLicense !== undefined) {
    if (obj.distributionLicense !== null) {
      let value = obj.distributionLicense;
      msg.setDistributionLicense(value);
    }
  }
  
  if (obj.repositoryUrl !== undefined) {
    if (obj.repositoryUrl === null) {
      msg.setRepositoryUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryUrl);
      msg.setRepositoryUrl(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenGranted !== undefined) {
    if (obj.whenGranted === null) {
      msg.setWhenGranted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenGranted);
      msg.setWhenGranted(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectProfile !== undefined) {
    if (obj.projectProfile === null) {
      msg.setProjectProfile(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceProjectProfile').fromObject(obj.projectProfile);
      msg.setProjectProfile(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.whenRefused !== undefined) {
    if (obj.whenRefused === null) {
      msg.setWhenRefused(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRefused);
      msg.setWhenRefused(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.versionId !== undefined) {
    if (obj.versionId === null) {
      msg.setVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.versionId);
      msg.setVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectName !== undefined) {
    if (obj.projectName !== null) {
      let value = obj.projectName;
      msg.setProjectName(value);
    }
  }
  
  if (obj.distributionLicense !== undefined) {
    if (obj.distributionLicense !== null) {
      let value = obj.distributionLicense;
      msg.setDistributionLicense(value);
    }
  }
  
  if (obj.repositoryUrl !== undefined) {
    if (obj.repositoryUrl === null) {
      msg.setRepositoryUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryUrl);
      msg.setRepositoryUrl(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  
  if (obj.createdBy !== undefined) {
    if (obj.createdBy === null) {
      msg.setCreatedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.createdBy);
      msg.setCreatedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerified !== undefined) {
    if (obj.whenVerified === null) {
      msg.setWhenVerified(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerified);
      msg.setWhenVerified(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerificationFailed !== undefined) {
    if (obj.whenVerificationFailed === null) {
      msg.setWhenVerificationFailed(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerificationFailed);
      msg.setWhenVerificationFailed(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerificationFailed !== undefined) {
    if (obj.whenVerificationFailed === null) {
      msg.setWhenVerificationFailed(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerificationFailed);
      msg.setWhenVerificationFailed(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseRequestVerificationFailureReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRequested !== undefined) {
    if (obj.whenRequested === null) {
      msg.setWhenRequested(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRequested);
      msg.setWhenRequested(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.ExtensionRequestReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.distributionLicense !== undefined) {
    if (obj.distributionLicense !== null) {
      let value = obj.distributionLicense;
      msg.setDistributionLicense(value);
    }
  }
  
  if (obj.repositoryUrl !== undefined) {
    if (obj.repositoryUrl === null) {
      msg.setRepositoryUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryUrl);
      msg.setRepositoryUrl(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenStarted !== undefined) {
    if (obj.whenStarted === null) {
      msg.setWhenStarted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenStarted);
      msg.setWhenStarted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenExpired !== undefined) {
    if (obj.whenExpired === null) {
      msg.setWhenExpired(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.whenExpired);
      msg.setWhenExpired(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenVerified !== undefined) {
    if (obj.whenVerified === null) {
      msg.setWhenVerified(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenVerified);
      msg.setWhenVerified(value);
    }
  }
  
  if (obj.licensingTarget !== undefined) {
    if (obj.licensingTarget === null) {
      msg.setLicensingTarget(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicensingTarget').fromObject(obj.licensingTarget);
      msg.setLicensingTarget(value);
    }
  }
  
  if (obj.verifiedBy !== undefined) {
    if (obj.verifiedBy === null) {
      msg.setVerifiedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.verifiedBy);
      msg.setVerifiedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRefused !== undefined) {
    if (obj.whenRefused === null) {
      msg.setWhenRefused(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRefused);
      msg.setWhenRefused(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.RequestRefusalReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.refusedBy !== undefined) {
    if (obj.refusedBy === null) {
      msg.setRefusedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.refusedBy);
      msg.setRefusedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.Parser;
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenGranted !== undefined) {
    if (obj.whenGranted === null) {
      msg.setWhenGranted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenGranted);
      msg.setWhenGranted(value);
    }
  }
  
  if (obj.activeThrough !== undefined) {
    if (obj.activeThrough === null) {
      msg.setActiveThrough(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.LocalDate').fromObject(obj.activeThrough);
      msg.setActiveThrough(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenRevoked !== undefined) {
    if (obj.whenRevoked === null) {
      msg.setWhenRevoked(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRevoked);
      msg.setWhenRevoked(value);
    }
  }
  
  if (obj.reason !== undefined) {
    if (obj.reason === null) {
      msg.setReason(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.NonCommercialLicenseRevocationReason').fromObject(obj.reason);
      msg.setReason(value);
    }
  }
  
  if (obj.revokedBy !== undefined) {
    if (obj.revokedBy === null) {
      msg.setRevokedBy(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.core.UserId').fromObject(obj.revokedBy);
      msg.setRevokedBy(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.mainstreamVersionId !== undefined) {
    if (obj.mainstreamVersionId === null) {
      msg.setMainstreamVersionId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductVersionId').fromObject(obj.mainstreamVersionId);
      msg.setMainstreamVersionId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  
  if (obj.whenRequested !== undefined) {
    if (obj.whenRequested === null) {
      msg.setWhenRequested(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenRequested);
      msg.setWhenRequested(value);
    }
  }
  
  if (obj.requester !== undefined) {
    if (obj.requester === null) {
      msg.setRequester(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequester').fromObject(obj.requester);
      msg.setRequester(value);
    }
  }
  
  if (obj.projectName !== undefined) {
    if (obj.projectName !== null) {
      let value = obj.projectName;
      msg.setProjectName(value);
    }
  }
  
  if (obj.distributionLicense !== undefined) {
    if (obj.distributionLicense !== null) {
      let value = obj.distributionLicense;
      msg.setDistributionLicense(value);
    }
  }
  
  if (obj.repositoryUrl !== undefined) {
    if (obj.repositoryUrl === null) {
      msg.setRepositoryUrl(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.net.Url').fromObject(obj.repositoryUrl);
      msg.setRepositoryUrl(value);
    }
  }
  
  if (obj.projectDescription !== undefined) {
    if (obj.projectDescription !== null) {
      let value = obj.projectDescription;
      msg.setProjectDescription(value);
    }
  }
  
  if (obj.projectBinding !== undefined) {
    if (obj.projectBinding === null) {
      msg.setProjectBinding(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProjectBinding').fromObject(obj.projectBinding);
      msg.setProjectBinding(value);
    }
  }
  
  if (obj.promotionRequirementAccepted !== undefined) {
    if (obj.promotionRequirementAccepted !== null) {
      let value = obj.promotionRequirementAccepted;
      msg.setPromotionRequirementAccepted(value);
    }
  }
  
  if (obj.disclaimerNoticeRequirementAccepted !== undefined) {
    if (obj.disclaimerNoticeRequirementAccepted !== null) {
      let value = obj.disclaimerNoticeRequirementAccepted;
      msg.setDisclaimerNoticeRequirementAccepted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenStarted !== undefined) {
    if (obj.whenStarted === null) {
      msg.setWhenStarted(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenStarted);
      msg.setWhenStarted(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.Parser;
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.whenIssued !== undefined) {
    if (obj.whenIssued === null) {
      msg.setWhenIssued(null);
    } else {
      let value = TypeParsers.parserFor('type.spine.io/spine.time.ZonedDateTime').fromObject(obj.whenIssued);
      msg.setWhenIssued(value);
    }
  }
  
  if (obj.licenseArtifact !== undefined) {
    if (obj.licenseArtifact === null) {
      msg.setLicenseArtifact(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.LicenseArtifact').fromObject(obj.licenseArtifact);
      msg.setLicenseArtifact(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.Parser = function() {
  ObjectParser.call(this);
};
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.Parser.prototype = Object.create(ObjectParser.prototype);
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.Parser.prototype.constructor = proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.Parser;
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.Parser.prototype.fromObject = function(obj) {
  if (obj === null) {
    return null;
  }
  
  let msg = new proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted();
  
  if (obj.id !== undefined) {
    if (obj.id === null) {
      msg.setId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.OpenSourceLicenseId').fromObject(obj.id);
      msg.setId(value);
    }
  }
  
  if (obj.productId !== undefined) {
    if (obj.productId === null) {
      msg.setProductId(null);
    } else {
      let value = TypeParsers.parserFor('type.teamdev.com/teamdev.licensing.ProductId').fromObject(obj.productId);
      msg.setProductId(value);
    }
  }
  return msg;
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromRequest';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestRefused';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseCreatedFromScratch';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationFailed';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseIssued.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseIssued';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequested';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExtensionRequestVerificationStarted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseExpired.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseExpired';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerified';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestRefused';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseGranted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRevoked.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRevoked';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequested.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequested';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseRequestVerificationStarted';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.ExtendedOpenSourceLicenseIssued';
};

// Generated by Spine ProtoJs Plugin
proto.teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted.typeUrl = function() {
  return 'type.teamdev.com/teamdev.licensing.c.opensource.OpenSourceLicenseAgreementAccepted';
};
